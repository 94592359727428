import fetch from 'services/Interceptors/MdsInterceptor';
import * as config from 'configs/AppConfig';

const TodolistService = {};
const TD_LIST = 'gtdl';

TodolistService.getTodolistList = (data) => {
  return fetch({
    url: TD_LIST,
    method: 'post',
    headers: {
      'public-request': 'true',
    },
    data: {
      sOpe: '11',
      Data: data,
    },
  });
};

TodolistService.closeTodolistItem = (data) => {
  return fetch({
    url: TD_LIST,
    method: 'post',
    headers: {
      'public-request': 'true',
    },
    data: {
      sOpe: '1',
      Data: data,
    },
  });
};


export default TodolistService;
