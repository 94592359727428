import {
    ADD_USER_MANAGEMENT_ITEM,
    ADD_USER_MANAGEMENT_ITEM_SUCCESS,
    ADD_USER_MANAGEMENT_ITEM_ERROR,
    GET_USER_MANAGEMENT_LIST,
    GET_USER_MANAGEMENT_LIST_SUCCESS,
    GET_USER_MANAGEMENT_LIST_ERROR,
    GET_USER_MANAGEMENT_ITEM,
    GET_USER_MANAGEMENT_ITEM_SUCCESS,
    GET_USER_MANAGEMENT_ITEM_ERROR,
    UPDATE_USER_MANAGEMENT_ITEM,
    UPDATE_USER_MANAGEMENT_ITEM_SUCCESS,
    UPDATE_USER_MANAGEMENT_ITEM_ERROR,
    SHOW_LOADING,
  } from '../constants/UsersManagement';
  
  export const addUsersManagementItem = (item) => {
    return {
      type: ADD_USER_MANAGEMENT_ITEM,
      data: item,
    };
  };
  
  export const addUsersManagementItemSuccess = (data) => {
    return {
      type: ADD_USER_MANAGEMENT_ITEM_SUCCESS,
      message: 'La demande de droits a bien été ajoutée',
      data,
    };
  };
  
  export const addUsersManagementItemError = (error) => {
    return {
      type: ADD_USER_MANAGEMENT_ITEM_ERROR,
      ...error,
    };
  };
  
  export const getUsersManagementList = () => {
    return {
      type: GET_USER_MANAGEMENT_LIST,
      data: {
        sC: 0,
        nN: 0,
      },
    };
  };
  
  export const getUsersManagementListSuccess = (data) => {
    return {
      type: GET_USER_MANAGEMENT_LIST_SUCCESS,
      data,
    };
  };
  
  export const getUsersManagementListError = (error) => {
    return {
      type: GET_USER_MANAGEMENT_LIST_ERROR,
      ...error,
    };
  };
  
  export const getUsersManagementItem = (id) => {
    return {
      type: GET_USER_MANAGEMENT_ITEM,
      data: { DataREG_DD: { nIDREG_DD: id } },
    };
  };
  
  export const getUsersManagementItemSuccess = (item) => {
    return {
      type: GET_USER_MANAGEMENT_ITEM_SUCCESS,
      item,
    };
  };
  
  export const getUsersManagementItemError = (error) => {
    return {
      type: GET_USER_MANAGEMENT_ITEM_ERROR,
      ...error,
    };
  };
  
  export const updateUsersManagementItem = (item) => {
    return {
      type: UPDATE_USER_MANAGEMENT_ITEM,
      data: { DataREG_DD: item },
    };
  };
  
  export const updateUsersManagementItemSuccess = (item) => {
    return {
      type: UPDATE_USER_MANAGEMENT_ITEM_SUCCESS,
      message: 'La demande de droits a bien été mise a jour',
      item,
    };
  };
  
  export const updateUsersManagementItemError = (error) => {
    return {
      type: UPDATE_USER_MANAGEMENT_ITEM_ERROR,
      ...error,
    };
  };
  
  export const showLoading = () => {
    return {
      type: SHOW_LOADING,
    };
  };
  