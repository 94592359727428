import React from 'react';

export const BuildingSVG = () => (
  <span role="img" aria-label="dashboard" className="anticon anticon-dashboard">
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 17 20"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16.5446 18.75H15.7857V0.9375C15.7857 0.419727 15.378 0 14.875 0H2.125C1.62202 0 1.21429 
        0.419727 1.21429 0.9375V18.75H0.455357C0.203886 18.75 0 18.9599 0 19.2188V20H17V19.2188C17 18.9599 
        16.7961 18.75 16.5446 18.75ZM4.85714 2.96875C4.85714 2.70988 5.06103 2.5 5.3125 2.5H6.83036C7.08183
        2.5 7.28571 2.70988 7.28571 2.96875V4.53125C7.28571 4.79012 7.08183 5 6.83036 5H5.3125C5.06103 5 
        4.85714 4.79012 4.85714 4.53125V2.96875ZM4.85714 6.71875C4.85714 6.45988 5.06103 6.25 5.3125 
        6.25H6.83036C7.08183 6.25 7.28571 6.45988 7.28571 6.71875V8.28125C7.28571 8.54012 7.08183 8.75 
        6.83036 8.75H5.3125C5.06103 8.75 4.85714 8.54012 4.85714 8.28125V6.71875ZM6.83036 12.5H5.3125C5.06103 
        12.5 4.85714 12.2901 4.85714 12.0312V10.4688C4.85714 10.2099 5.06103 10 5.3125 10H6.83036C7.08183 
        10 7.28571 10.2099 7.28571 10.4688V12.0312C7.28571 12.2901 7.08183 12.5 6.83036 12.5ZM9.71429 
        18.75H7.28571V15.4688C7.28571 15.2099 7.4896 15 7.74107 15H9.25893C9.5104 15 9.71429 15.2099 
        9.71429 15.4688V18.75ZM12.1429 12.0312C12.1429 12.2901 11.939 12.5 11.6875 12.5H10.1696C9.91817 
        12.5 9.71429 12.2901 9.71429 12.0312V10.4688C9.71429 10.2099 9.91817 10 10.1696 10H11.6875C11.939 
        10 12.1429 10.2099 12.1429 10.4688V12.0312ZM12.1429 8.28125C12.1429 8.54012 11.939 8.75 11.6875 
        8.75H10.1696C9.91817 8.75 9.71429 8.54012 9.71429 8.28125V6.71875C9.71429 6.45988 9.91817 6.25 
        10.1696 6.25H11.6875C11.939 6.25 12.1429 6.45988 12.1429 6.71875V8.28125ZM12.1429 4.53125C12.1429 
        4.79012 11.939 5 11.6875 5H10.1696C9.91817 5 9.71429 4.79012 9.71429 4.53125V2.96875C9.71429 
        2.70988 9.91817 2.5 10.1696 2.5H11.6875C11.939 2.5 12.1429 2.70988 12.1429 2.96875V4.53125Z"
        fill="currentColor"
      />
    </svg>
  </span>
);

export const SuitcaseSVG = () => (
  <span role="img" aria-label="dashboard" className="anticon anticon-dashboard">
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16 21V5C16 4.46957 15.7893 3.96086 15.4142 3.58579C15.0391 3.21071 14.5304 3 14 3H10C9.46957 3 8.96086 3.21071 8.58579 3.58579C8.21071 3.96086 8 4.46957 8 5V21M4 7H20C21.1046 7 22 7.89543 22 9V19C22 20.1046 21.1046 21 20 21H4C2.89543 21 2 20.1046 2 19V9C2 7.89543 2.89543 7 4 7Z"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  </span>
);

export const NotepadSVG = () => (
  <span role="img" aria-label="dashboard" className="anticon anticon-dashboard">
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16 4H18C18.5304 4 19.0391 4.21071 19.4142 4.58579C19.7893 4.96086 20 5.46957 20 6V20C20 20.5304 19.7893 21.0391 19.4142 21.4142C19.0391 21.7893 18.5304 22 18 22H6C5.46957 22 4.96086 21.7893 4.58579 21.4142C4.21071 21.0391 4 20.5304 4 20V6C4 5.46957 4.21071 4.96086 4.58579 4.58579C4.96086 4.21071 5.46957 4 6 4H8M9 2H15C15.5523 2 16 2.44772 16 3V5C16 5.55228 15.5523 6 15 6H9C8.44772 6 8 5.55228 8 5V3C8 2.44772 8.44772 2 9 2Z"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  </span>
);

export const CheckCircleFilled = () => (
  <span role="img" aria-label="dashboard" className="anticon anticon-dashboard">
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10 0C4.47715 0 0 4.47715 0 10C0 15.5228 4.47715 20 10 20C15.5228 20 20 15.5228 20 10C19.984 4.4838 15.5162 0.0160189 10 0ZM8.33333 14.5117L3.82167 10L5 8.82167L8.33333 12.155L15 5.48833L16.1783 6.66667L8.33333 14.5117Z"
        fill="#E4E7E6"
      />
    </svg>
  </span>
);
