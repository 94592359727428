import React, { useEffect, useState } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import * as Dashboard from 'store/actions/Dashboard';
import * as AuthActions from 'store/actions/Auth';
import { Card, Table, Col, Row, Button } from 'antd';
import { useHistory } from 'react-router-dom';

const backgroundStyle = {
  backgroundImage: 'url(/img/others/img-17.jpg)',
  backgroundRepeat: 'no-repeat',
  backgroundSize: 'cover',
};

const OrganisationChoice = (props) => {
  const { t, user } = props;
  const [mcData, setMcData] = useState([]);
  const [keyCle, setKeyCle] = useState('');
  const history = useHistory();

  useEffect(() => {
    if (user == null) {
      history.push('/auth/login');
    } else {
      setMcData(user?.MC);
    }
  });

  const choiceOrga = (id) => {
    props.signIn({ nIDUser: id });
  };

  const columns = [
    {
      title: t('ORGANISATIONS'),
      dataIndex: 'sNom',
      render: (text, row) => <a onClick={() => choiceOrga(row.nIdUser)}>{text}</a>,
    },
  ];
  return (
    <div className="auth-container">
      <div className="container d-flex flex-column justify-content-center h-100">
        <div className="h-100" style={backgroundStyle}>
          <div className="container d-flex flex-column justify-content-center h-100">
            <Row justify="center">
              <Col xs={20} sm={20} md={20} lg={9}>
                <Card>
                  <div className="my-2">
                    <div className="text-center">
                      <img className="img-fluid" src="/img/new_logo.png" alt="" />
                      <h3 className="mt-3 font-weight-bold">
                        {t('CHOISIR_ORGANISATION')}
                      </h3>
                    </div>
                    <Row justify="center">
                      <Col xs={24} sm={24} md={20} lg={20}>
                        <Table columns={columns} dataSource={mcData} />
                      </Col>
                    </Row>
                  </div>
                </Card>
              </Col>
            </Row>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = ({ dashboard, auth }) => {
  return {
    isLoading: dashboard.isLoading,
    user: auth.user,
    user: auth.user,
    isInChoiceOrga: auth.isInChoiceOrga,
    isChoosedOrga: auth.isChoosedOrga,
  };
};

const mapDispatchToProps = {
  ...Dashboard,
  ...AuthActions,
};

export default withTranslation()(
  connect(mapStateToProps, mapDispatchToProps)(OrganisationChoice),
);
