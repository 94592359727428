import fetch from 'services/Interceptors/MdsInterceptor';
import { API_SCLE_READ, API_SCLE_EDIT } from 'configs/AppConfig';

const OrganisationService = {};
const PROC = 'go2';

OrganisationService.getOrganisation = (data) => {
  return fetch({
    url: `/${PROC}`,
    method: 'post',
    headers: {
      'public-request': 'true',
    },
    data: {
      sOpe: API_SCLE_READ,
      Data: data,
    },
  });
};

OrganisationService.updateOrganisation = (data) => {
  return fetch({
    url: `/${PROC}`,
    method: 'post',
    headers: {
      'public-request': 'true',
    },
    data: {
      sOpe: API_SCLE_EDIT,
      Data: data,
    },
  });
};


export default OrganisationService;
