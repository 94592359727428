import fetch from 'services/Interceptors/MdsInterceptor';
import * as config from 'configs/AppConfig';

const RaciService = {};
const PROC_RACI = 'gpfraci';

RaciService.getRaciList = (data) => {
  return fetch({
    url: PROC_RACI,
    method: 'post',
    headers: {
      'public-request': 'true',
    },
    data: {
      sOpe: '13',
      Data: data,
    },
  });
};

RaciService.getRaciItem = (data) => {
  return fetch({
    url: PROC_RACI,
    method: 'post',
    headers: {
      'public-request': 'true',
    },
    data: {
      sOpe: config.API_SCLE_READ,
      Data: data,
    },
  });
};

RaciService.createRaciItem = (data) => {
  return fetch({
    url: PROC_RACI,
    method: 'post',
    headers: {
      'public-request': 'true',
    },
    data: {
      sOpe: config.API_SCLE_ADD,
      Data: data,
    },
  });
};

RaciService.clickBtnRaci = (data) => {
  return fetch({
    url: PROC_RACI,
    method: 'post',
    headers: {
      'public-request': 'true',
    },
    data: {
      sOpe: '3-CASE',
      Data: data,
    },
  });
};

export default RaciService;
