import {
  GET_FUNC_PROC_ITEM,
  GET_FUNC_PROC_ITEM_SUCCESS,
  GET_FUNC_PROC_ITEM_ERROR,
  ADD_FUNC_PROC_ITEM,
  ADD_FUNC_PROC_ITEM_SUCCESS,
  ADD_FUNC_PROC_ITEM_ERROR,
  UPDATE_FUNC_PROC_ITEM,
  UPDATE_FUNC_PROC_ITEM_SUCCESS,
  UPDATE_FUNC_PROC_ITEM_ERROR,
  GET_FUNC_PROC_STEP_ITEM,
  GET_FUNC_PROC_STEP_ITEM_SUCCESS,
  GET_FUNC_PROC_STEP_ITEM_ERROR,
  ADD_FUNC_PROC_STEP_ITEM,
  ADD_FUNC_PROC_STEP_ITEM_SUCCESS,
  ADD_FUNC_PROC_STEP_ITEM_ERROR,
  UPDATE_FUNC_PROC_STEP_ITEM,
  UPDATE_FUNC_PROC_STEP_ITEM_SUCCESS,
  UPDATE_FUNC_PROC_STEP_ITEM_ERROR,
  DELETE_FUNC_PROC_STEP_ITEM,
  DELETE_FUNC_PROC_STEP_ITEM_SUCCESS,
  DELETE_FUNC_PROC_STEP_ITEM_ERROR,
  GET_FUNC_PROC_LIST,
  GET_FUNC_PROC_LIST_SUCCESS,
  GET_FUNC_PROC_LIST_ERROR,
  GET_FUNC_PROC_WITH_STEP_ITEM,
  GET_FUNC_PROC_WITH_STEP_ITEM_SUCCESS,
  GET_FUNC_PROC_WITH_STEP_ITEM_ERROR,
  GET_BLANK_FUNC_PROC,
  GET_BLANK_FUNC_PROC_SUCCESS,
  GET_BLANK_FUNC_PROC_ERROR,
  ADD_NEW_VERSION_FUNC_PROC_ITEM,
  ADD_NEW_VERSION_FUNC_PROC_ITEM_SUCCESS,
  ADD_NEW_VERSION_FUNC_PROC_ITEM_ERROR,
  SHOW_LOADING
} from '../constants/FunctionalProcedure';

const initState = {
  isLoading: false,
  redirect: '',
  data: [],
  item: {},
  itemStep: {},
  itemWithStep: {},
  blankItem: {}
};

const auth = (state = initState, action) => {
  switch (action.type) {
    case ADD_FUNC_PROC_ITEM:
    case ADD_FUNC_PROC_STEP_ITEM:
    case GET_FUNC_PROC_LIST:
    case DELETE_FUNC_PROC_STEP_ITEM:
    case GET_BLANK_FUNC_PROC:
    case GET_FUNC_PROC_ITEM:
      return {
        ...state,
        isLoading: true,
      };
    case GET_FUNC_PROC_ITEM_SUCCESS:
      return {
        ...state,
        item: action.data,
        isLoading: false,
      };
    case GET_FUNC_PROC_LIST_SUCCESS: {
      return {
        ...state,
        data: action.data,
        dataShouldUpdate: false,
        isLoading: false,
      };
    }
    case UPDATE_FUNC_PROC_ITEM:
      return {
        ...state,
        isLoading: true,
      };
    case UPDATE_FUNC_PROC_ITEM_SUCCESS:
      return {
        ...state,
        isLoading: false,
        item: action.item,
      };
    case DELETE_FUNC_PROC_STEP_ITEM_SUCCESS:
      return {
        ...state,
        isLoading: false,
        item: action.item,
      };
    case ADD_FUNC_PROC_ITEM_SUCCESS:
      return {
        ...state,
        isLoading: false,
        item: action.item,
      };
    case ADD_FUNC_PROC_STEP_ITEM_SUCCESS:
      return {
        ...state,
        isLoading: false,
        itemStep: action.itemStep,
      };
    case GET_FUNC_PROC_STEP_ITEM:
      return {
        ...state,
        isLoading: true,
      };
    case GET_FUNC_PROC_STEP_ITEM_SUCCESS:
      return {
        ...state,
        itemStep: action.item,
        isLoading: false,
      };
    case UPDATE_FUNC_PROC_STEP_ITEM:
      return {
        ...state,
        isLoading: true,
      };
    case UPDATE_FUNC_PROC_STEP_ITEM_SUCCESS:
      return {
        ...state,
        isLoading: false,
        itemStep: action.item,
      };
    case GET_FUNC_PROC_WITH_STEP_ITEM:
      return {
        ...state,
        isLoading: true,
      };
    case GET_FUNC_PROC_WITH_STEP_ITEM_SUCCESS:
      return {
        ...state,
        itemWithStep: action.item,
        isLoading: false,
      };
    case GET_BLANK_FUNC_PROC_SUCCESS:
      return {
        blankItem: action.item,
        isLoading: false,
      };
    case ADD_FUNC_PROC_ITEM_ERROR:
    case ADD_FUNC_PROC_STEP_ITEM_ERROR:
    case UPDATE_FUNC_PROC_ITEM_ERROR:
    case UPDATE_FUNC_PROC_STEP_ITEM_ERROR:
    case GET_FUNC_PROC_LIST_ERROR:
    case GET_FUNC_PROC_WITH_STEP_ITEM_ERROR:
    case GET_FUNC_PROC_STEP_ITEM_ERROR:
    case GET_BLANK_FUNC_PROC_ERROR:
    case GET_FUNC_PROC_ITEM_ERROR:
    case DELETE_FUNC_PROC_STEP_ITEM_ERROR:
      return {
        ...state,
        isLoading: false,
      };
    case SHOW_LOADING: {
      return {
        ...state,
        isLoading: true,
      };
    }
    default:
      return state;
  }
};

export default auth;
