export const GET_AXE_FCT = 'GET_AXE_FCT';
export const GET_AXE_FCT_SUCCESS = 'GET_AXE_FCT_SUCCESS';
export const GET_AXE_FCT_ERROR = 'GET_AXE_FCT_ERROR';

export const GET_AXE_GEO = 'GET_AXE_GEO';
export const GET_AXE_GEO_SUCCESS = 'GET_AXE_GEO_SUCCESS';
export const GET_AXE_GEO_ERROR = 'GET_AXE_GEO_ERROR';

export const GET_AXE_MET = 'GET_AXE_MET';
export const GET_AXE_MET_SUCCESS = 'GET_AXE_MET_SUCCESS';
export const GET_AXE_MET_ERROR = 'GET_AXE_MET_ERROR';

export const GET_AXE_ALL = 'GET_AXE_ALL';
export const GET_AXE_ALL_SUCCESS = 'GET_AXE_ALL_SUCCESS';
export const GET_AXE_ALL_ERROR = 'GET_AXE_ALL_ERROR';

export const SHOW_LOADING = 'SHOW_AXE_LOADING';
