export const ADD_CREATION_DOCUMENT_ITEM = 'ADD_CREATION_DOCUMENT_ITEM';
export const ADD_CREATION_DOCUMENT_ITEM_SUCCESS = 'ADD_CREATION_DOCUMENT_ITEM_SUCCESS';
export const ADD_CREATION_DOCUMENT_ITEM_ERROR = 'ADD_CREATION_DOCUMENT_ITEM_ERROR';

export const LIST_DOCUMENT_CREATED_ITEM = 'LIST_DOCUMENT_CREATED_ITEM';
export const LIST_DOCUMENT_CREATED_ITEM_SUCCESS = 'LIST_DOCUMENT_CREATED_ITEM_SUCCESS';
export const LIST_DOCUMENT_CREATED_ITEM_ERROR = 'LIST_DOCUMENT_CREATED_ITEM_ERROR';

export const GET_DOCUMENT_CREATED_ITEM = 'GET_DOCUMENT_CREATED_ITEM';
export const GET_DOCUMENT_CREATED_ITEM_SUCCESS = 'GET_DOCUMENT_CREATED_ITEM_SUCCESS';
export const GET_DOCUMENT_CREATED_ITEM_ERROR = 'GET_DOCUMENT_CREATED_ITEM_ERROR';

export const GET_FICHE_PDF = 'GET_FICHE_PDF';
export const GET_FICHE_PDF_SUCCESS = 'GET_FICHE_PDF_SUCCESS';
export const GET_FICHE_PDF_ERROR = 'GET_FICHE_PDF_ERROR';

export const SHOW_LOADING = 'SHOW_DOCUMENT_LOADING';

export const DO_NOTHING = 'DO_NOTHING';
