import {
  ADD_RACI_ITEM,
  ADD_RACI_ITEM_SUCCESS,
  ADD_RACI_ITEM_ERROR,
  GET_RACI_LIST,
  GET_RACI_LIST_SUCCESS,
  GET_RACI_LIST_ERROR,
  GET_RACI_ITEM,
  GET_RACI_ITEM_SUCCESS,
  GET_RACI_ITEM_ERROR,
  CLIC_BTN_RACI,
  CLIC_BTN_RACI_SUCCESS,
  CLIC_BTN_RACI_ERROR,
  SHOW_LOADING,
} from '../constants/Raci';

export const addRaciItem = (item) => {
  return {
    type: ADD_RACI_ITEM,
    data: item,
  };
};

export const addRaciItemSuccess = (data) => {
  return {
    type: ADD_RACI_ITEM_SUCCESS,
    message: 'Le traitement a bien été ajouté',
    data,
  };
};

export const addRaciItemError = (error) => {
  return {
    type: ADD_RACI_ITEM_ERROR,
    ...error,
  };
};

export const getRaciList = (idObjet, cible) => {
  return {
    type: GET_RACI_LIST,
    data: { sCibleFonc: cible, nIDObjet: idObjet },
  };
};

export const getRaciListSuccess = (data) => {
  return {
    type: GET_RACI_LIST_SUCCESS,
    data,
  };
};

export const getRaciListError = (error) => {
  return {
    type: GET_RACI_LIST_ERROR,
    ...error,
  };
};

export const getRaciItem = (id) => {
  return {
    type: GET_RACI_ITEM,
    data: { nIDPF_RACI: id },
  };
};

export const getRaciItemSuccess = (item) => {
  return {
    type: GET_RACI_ITEM_SUCCESS,
    item
  };
};

export const getRaciItemError = (error) => {
  return {
    type: GET_RACI_ITEM_ERROR,
    ...error,
  };
};

export const clickBtnRaci = (data) => {
  return {
    type: CLIC_BTN_RACI,
    data,
  };
};

export const clickBtnRaciSuccess = (item) => {
  return {
    type: CLIC_BTN_RACI_SUCCESS,
    item
  };
};

export const clickBtnRaciError = (error) => {
  return {
    type: CLIC_BTN_RACI_ERROR,
    ...error,
  };
};

export const showLoading = () => {
  return {
    type: SHOW_LOADING,
  };
};
