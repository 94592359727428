import fetch from 'services/Interceptors/MdsInterceptor';
import * as config from 'configs/AppConfig';


const DocumentModelService = {};
const DOC_MODEL = 'gbu';
const DOC_ITEM = 'gdr';

DocumentModelService.getDocumentModel = (data) => {
  return fetch({
    url: DOC_MODEL,
    method: 'post',
    headers: {
      'public-request': 'true',
    },
    data: {
      sOpe: config.API_SCLE_READ,
      Data: {},
    },
  });
};

DocumentModelService.ddlDocumentItem = (data) => {
  return fetch({
    url: DOC_ITEM,
    method: 'post',
    headers: {
      'public-request': 'true',
    },
    data: {
      sOpe: '1',
      Data: data,
    },
  });
};


export default DocumentModelService;
