import React, { useState, useEffect } from 'react';
import { bindActionCreators } from 'redux';
import { useLocation } from 'react-router-dom';
import { connect } from 'react-redux';
import * as AuthActions from 'store/actions/Auth';
import { Tooltip } from 'antd';
import { QuestionCircleOutlined } from '@ant-design/icons';


export const NavHelp = (props) => {
  // const {t} = props;
  const location = useLocation();
  const { pathname } = location;
  useEffect(() => {

  }, []);

  const renderAide = () => {
    if (pathname.includes('/dashboard')) 
      return "Le composant Tableau de bord vous restitue sous forme de graphiques (histogrammes, camemberts, …) des indicateurs de comptage des enregistrements effectués dans le logiciel. En cliquant dans le menu, ces indicateurs vous sont restitués soit de manière globale, soit de manière spécifique sur différents sujets : bases légales, actions en cours, PIA, …";
    if (pathname.includes('/todolist')) 
      return "Le composant Mes tâches reprend la liste des actions pour lesquels l’utilisateur, à titre individuel, a été notifié.";
    if (pathname.includes('/multi-orga')) 
      return "Ce composant vous permet de décrire votre organisation qu’elle soit simple ou complexe pour faciliter le bon rattachement dans le logiciel des traitements, des PIA / AIPD, des actions et des acteurs. La description de votre organisation doit refléter au plus juste la réalité de votre structuration pour faciliter l’établissement des reportings de suivi de la mise / maintien en conformité en fonction du périmètre d’action et de responsabilités des utilisateurs du logiciel.";
    if (pathname.includes('/actorsbook')) 
      return "Le composant Annuaire des acteurs permet de recenser l’ensemble des contacts – personnes physiques internes ou externes (prestataires ou sous-traitants) à la chaîne de conformité RGPD.";
    if (pathname.includes('/provider')) 
      return "Le composant Prestataires et sous-traitants vous permet de créer / documenter l’ensemble de vos prestataires et sous-traitants intervenant dans le traitement des données à caractère personnel ou sensible.";
    if (pathname.includes('/contrat')) 
      return "Le composant « Contrats » vous permet de documenter l’ensemble des contrats qui vous lient avec des prestataires ou des sous-traitants. Il vous permet de rattacher ces prestataires / sous-traitants aux différents types de contrats. Vous pouvez y qualifier la relation / niveau de responsabilité au sens RGPD qui vous lie contractuellement avec les sous-traitants.";
    if (pathname.includes('/traitement')) 
      return "Ce composant s’appuie sur le référentiel de la CNIL. Il vous guide dans la documentation exhaustive de l’ensemble de vos traitements de données à caractère personnel ou sensible.";
    if (pathname.includes('app/pia')) 
      return "Une Privacy Impact Analysis (PIA) ou Analyse d’Impact sur la Protection des Données (AIPD) est une étude qui doit être menée lorsqu'un traitement de données personnelles est susceptible d'engendrer un risque élevé pour les droits et libertés des personnes concernées. Source CNIL. Ce module s’appuie sur le référentiel de la CNIL et la méthode EBIOS de qualification des risques promue par l’ANSSI. Il vous permet de documenter l’ensemble des PIA / AIPD nécessaires en fonction du niveau de risque associé à vos traitements";
    if (pathname.includes('/actions')) 
      return "Le composant Actions reprend la liste de toutes les actions à réaliser sur les traitements, les PIA / AIPD ou les incidents. Il permet au DPO de suivre leur avancement.";
    if (pathname.includes('/demande-de-droits')) 
      return "Ce composant vous permet de piloter les demandes d’évolution de droit que vous recevez de la part des personnes concernées.";
    if (pathname.includes('app/document')) 
      return "Pour permettre à l’ensemble des acteurs de la chaîne de conformité de partager la même base documentaire, le composant « Gestion des documents » vous permet de les stocker et de les mettre à disposition.";
    if (pathname.includes('/demande-document')) 
      return " L’extraction en PDF d’un (plusieurs) traitement(s) ou PIA / AIPD ou incident(s) peut s’effectuer directement via ce composant.";
    if (pathname.includes('/gestion-incident')) 
      return "Ce composant vous permet d’enregistrer et de traiter l’ensemble des incidents, y compris les fuites / violations de données. Comme pour les autres modules, la vision tabulaire vous permet d’avoir une synthèse de l’ensemble des incidents déjà enregistrés pour en opérer le suivi.";
    if (pathname.includes('/gestion-options-reference')) 
      return "";
    if (pathname.includes('/procedure')) 
      return "Le composant Gestion des procédures vous permet de documenter, dans le logiciel, l’ensemble des processus et procédures définies pour le pilotage de votre conformité RGPD.";
    if (pathname.includes('/template')) 
      return "";
    if (pathname.includes('/faq')) 
      return "";
  }

  return (
    <>
      <ul className='ant-menu-overflow ant-menu ant-menu-root ant-menu-horizontal ant-menu-light'>
        <li className='ant-menu-item'>
          <Tooltip placement="topLeft" title={renderAide()}> 
            <QuestionCircleOutlined className="nav-icon mr-0" style={{ fontSize : "1.35rem" }} />
          </Tooltip>                
        </li>
      </ul>
    </>
  );
};

const mapStateToProps = ({ auth }) => {
  return {
    user: auth.user,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(AuthActions, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(NavHelp);
