import React, { Component, lazy, Suspense } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import { I18nextProvider } from 'react-i18next';

import getIi18nAppItem from 'configs/LangConfig';

import Loading from 'components/Loading/Spinner';
import { APP_PREFIX_PATH } from 'configs/AppConfig';

export class AppViews extends Component {
  componentDidMount() {
    this.i18nAppInstance = getIi18nAppItem(
      this.props.user,
      this.props.locale.toUpperCase(),
    );
  }

  render() {
    return (
      <Suspense fallback={<Loading cover="content" />}>
        <I18nextProvider i18n={this.i18nAppInstance}>
          <Switch>
            <Route
              path={`${APP_PREFIX_PATH}/dashboard`}
              component={lazy(() => import(`./dashboard`))}
            />
            <Route
              path={`${APP_PREFIX_PATH}/todolist`}
              component={lazy(() => import(`./todolist`))}
            />
            <Route
              path={`${APP_PREFIX_PATH}/organisation`}
              component={lazy(() => import(`./organisation`))}
            />
            <Route
              path={`${APP_PREFIX_PATH}/actorsbook`}
              component={lazy(() => import(`./actorsBook`))}
            />
            <Route
              path={`${APP_PREFIX_PATH}/provider`}
              component={lazy(() => import(`./provider`))}
            />
            <Route
              path={`${APP_PREFIX_PATH}/contrat`}
              component={lazy(() => import(`./contrat`))}
            />
            <Route
              path={`${APP_PREFIX_PATH}/traitement`}
              component={lazy(() => import(`./process`))}
            />
            <Route
              path={`${APP_PREFIX_PATH}/pia`}
              component={lazy(() => import(`./pia`))}
            />
            <Route
              path={`${APP_PREFIX_PATH}/grpc`}
              component={lazy(() => import(`./grpc`))}
            />
            <Route
              path={`${APP_PREFIX_PATH}/demande-de-droits`}
              component={lazy(() => import(`./demandeDeDroits`))}
            />
            <Route
              path={`${APP_PREFIX_PATH}/document`}
              component={lazy(() => import(`./document`))}
            />
            <Route
              path={`${APP_PREFIX_PATH}/template`}
              component={lazy(() => import(`./template`))}
            />
            <Route
              path={`${APP_PREFIX_PATH}/faq`}
              component={lazy(() => import(`./faq`))}
            />
            <Route
              path={`${APP_PREFIX_PATH}/actions`}
              component={lazy(() => import(`./actions`))}
            />
            <Route
              path={`${APP_PREFIX_PATH}/demande-document`}
              component={lazy(() => import(`./creationDocument`))}
            />
            <Route
              path={`${APP_PREFIX_PATH}/choice-organisation`}
              component={lazy(() => import(`./orgaChoice`))}
            />
            <Route
              path={`${APP_PREFIX_PATH}/edit-profile`}
              component={lazy(() => import(`./editProfile`))}
            />
            <Route
              path={`${APP_PREFIX_PATH}/gestion-incident`}
              component={lazy(() => import(`./riskManagement`))}
            />
            <Route
              path={`${APP_PREFIX_PATH}/gestion-options-reference`}
              component={lazy(() => import(`./referenceManagement`))}
            />
            <Route
              path={`${APP_PREFIX_PATH}/multi-orga`}
              component={lazy(() => import(`./multiOrga`))}
            />
            <Route
              path={`${APP_PREFIX_PATH}/procedure`}
              component={lazy(() => import(`./procedure`))}
            />
            <Route path={`${APP_PREFIX_PATH}/gddrd`} component={lazy(() => import(`./creationDocManRegLawReq`))} />
            <Redirect
              from={`${APP_PREFIX_PATH}`}
              to={`${APP_PREFIX_PATH}/dashboard`}
            />
          </Switch>
        </I18nextProvider>
      </Suspense>
    );
  }
}

const mapStateToProps = ({ theme, auth }) => {
  return {
    locale: theme.locale,
    user: auth.user,
  };
};

export default connect(mapStateToProps)(AppViews);
