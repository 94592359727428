export const DATE_FORMAT_YYYYMMDD = 'YYYYMMdd';
export const DATE_FORMAT_YYYY_MM_DD = 'YYYY-MM-DD';
export const DATE_FORMAT_YYYY_MM_DD_COMBINED = 'YYYYMMddHHmm';
export const DATE_FORMAT_YYYY_MM_DD_HH_MM = 'YYYY-MM-dd HH:mm';
export const DATE_FORMAT_DD_MM_YYYY = 'DD-MM-YYYY';
export const DATE_FORMAT_DD_MM_YYYY_WITH_DOT = 'DD.MM.YYYY';
export const DATE_FORMAT_MM_DD_YYYY_WITH_SLASH = 'MM/dd/YYYY';
export const DATE_FORMAT_M_D_YYYY_WITH_SLASH = 'M/d/YYYY';
export const DATE_FORMAT_DD_MM_YYYY_WITH_SLASH = 'DD/MM/YYYY';
export const DATE_FORMAT_DD_MMM = 'ddMMM';
export const DATE_FORMAT_DD_MM_YY = 'ddMMyy';
export const DATE_FORMAT_DD_MMM_YYYY = 'ddMMMYYYY';
export const DATE_FORMAT_DD_MM_YYYY_COMBINED = 'DDMMYYYY';
export const DATE_FORMAT_DD_MM_YYYY_HH_MM_SS = "YYYY-MM-dd'T'HH:mm:ss";
export const DATE_FORMAT_DD_MM_YYYY_HH_MM_SS_SZ = "YYYY-MM-dd'T'HH:mm:ss.SSS";
export const DATE_FORMAT_DD_MM_YYYY_HH_MM = 'dd-MM-YYYY HH:mm';
export const DATE_FORMAT_HH_MM = 'HH:mm';
export const DATE_FORMAT_YYYY_MM_DDTHH_MM_SSZ = "YYYY-MM-DD'T'HH:mm:ssZ";
