import React, { useEffect } from 'react';
import { connect } from 'react-redux';

import { CheckOutlined, GlobalOutlined, DownOutlined } from '@ant-design/icons';
import { Menu, Dropdown } from 'antd';

import { langConfig, changeAppLocale } from 'configs/LangConfig';
import { onLocaleChange } from 'store/actions/Theme';
import { getAxeALL } from 'store/actions/Axe';
import { AUTH_USER } from 'store/constants/Auth';

function getLanguageDetail(locale) {
  const data = langConfig.filter((elm) => elm.langId === locale);
  return data[0];
}

const SelectedLanguage = ({ locale }) => {
  const language = getLanguageDetail(locale);
  const { langName, icon } = language;
  return (
    <div className="d-flex align-items-center">
      <img
        style={{ maxWidth: '20px' }}
        src={`/img/flags/${icon}.png`}
        alt={langName}
      />
      <span className="font-weight-semibold ml-2">
        {langName}

        <DownOutlined className="font-size-xs" />
      </span>
    </div>
  );
};

export const NavLanguage = (props) => {
  const { locale, configDisplay } = props

  const changeLocale = (lang) => {
    // Retelecharger les Axes
    if (props.axeALL.length == 0) {
      props.getAxeALL();
    }
    const userInfo = props.user;
    // console.log(userInfo)
    userInfo.Op.sLangue = lang;
    userInfo.langue = lang;
    userInfo.axes = props.axeALL;

    changeAppLocale(lang.toUpperCase());
    props.onLocaleChange(lang);
    setTimeout(() => {
      localStorage.setItem(AUTH_USER, JSON.stringify(userInfo));
      localStorage.setItem('defaultLang', lang);
    }, 3000);
  }

  useEffect(() => {
    const lang = JSON.parse(localStorage.getItem(AUTH_USER));
    const defaultLang = localStorage.getItem('defaultLang')
    // changeLocale(lang.langue)
    if (defaultLang) {
      changeLocale(defaultLang)
    } else {
      const defaultL = 'fr';
      changeLocale(defaultL);
    }

  }, [props.user, props.axeALL])

  const languageOption = (
    <Menu>
      {langConfig.map((elm) => {
        return (
          <Menu.Item
            key={`menu-${elm.langId}`}
            className={locale === elm.langId ? 'ant-dropdown-menu-item-active' : ''}
            onClick={() => changeLocale(elm.langId)}
          >
            <span className="d-flex justify-content-between align-items-center">
              <div>
                <img
                  style={{ maxWidth: '20px' }}
                  src={`/img/flags/${elm.icon}.png`}
                  alt={elm.langName}
                />
                <span className="font-weight-normal ml-2">{elm.langName}</span>
              </div>
              {locale === elm.langId ? (
                <CheckOutlined className="text-success" />
              ) : null}
            </span>
          </Menu.Item>
        );
      })}
    </Menu>
  );
  return (
    <Dropdown placement="bottomRight" overlay={languageOption} trigger={['click']}>
      {configDisplay ? (
        <a href="#/" className="text-gray" onClick={(e) => e.preventDefault()}>
          <SelectedLanguage locale={locale} />
        </a>
      ) : (
        <Menu mode="horizontal">
          <Menu.Item>
            <a href="#/" onClick={(e) => e.preventDefault()}>
              <GlobalOutlined className="nav-icon mr-0" />
            </a>
          </Menu.Item>
        </Menu>
      )}
    </Dropdown>
  );
};

const mapStateToProps = ({ auth, theme, axe }) => ({
  locale: theme.locale,
  user: auth.user,
  axeALL: axe.axeALL
});

export default connect(mapStateToProps, { onLocaleChange, getAxeALL })(NavLanguage);
