import { all, takeEvery, put, fork, call } from 'redux-saga/effects';

import ProcessModelService from 'services/ProcessModelService';
import {
  GET_PROCESS_MODEL_LIST,
  GET_PROCESS_MODEL_ITEM,
  ADD_PROCESS_MODELE,
  UPDATE_PROCESS_MODELE,
  DISABLE_PROCESS_MODELE,
} from '../constants/ProcessModel';

import {
  getProcessModelItemError,
  getProcessModelItemSuccess,
  getProcessModelListError,
  getProcessModelListSuccess,
  addProcessModeleSuccess,
  addProcessModeleError,
  updateProcessModeleSuccess,
  updateProcessModeleError,
  disableProcessModeleSuccess,
  disableProcessModeleError,
} from '../actions/ProcessModel';

export function* getProcessModelList() {
  yield takeEvery(GET_PROCESS_MODEL_LIST, function* ({ data }) {
    try {
      const response = yield call(ProcessModelService.getProcessModel, data);
      yield put(getProcessModelListSuccess(response.Data));
    } catch (err) {
      yield put(getProcessModelListError(err));
    }
  });
}
export function* getProcessModelItem() {
  yield takeEvery(GET_PROCESS_MODEL_ITEM, function* ({ data }) {
    try {
      const response = yield call(ProcessModelService.getProcessModel, data);
      yield put(
        getProcessModelItemSuccess(
          response.Data,
          data.tab_ModeleTrait[0].nIdModeleTrait,
        ),
      );
    } catch (err) {
      yield put(getProcessModelItemError(err));
    }
  });
}
export function* addProcessModele() {
  yield takeEvery(ADD_PROCESS_MODELE, function* ({ data }) {
    try {
      const response = yield call(ProcessModelService.createProcessModel, data);
      yield put(addProcessModeleSuccess(response.Data));
    } catch (err) {
      yield put(addProcessModeleError(err));
    }
  });
}
export function* updateProcessModele() {
  yield takeEvery(UPDATE_PROCESS_MODELE, function* ({ data }) {
    try {
      const response = yield call(ProcessModelService.updateProcessModel, data);
      yield put(updateProcessModeleSuccess(response.Data));
    } catch (err) {
      yield put(updateProcessModeleError(err));
    }
  });
}

export function* disableProcessModele() {
  yield takeEvery(DISABLE_PROCESS_MODELE, function* ({ data }) {
    try {
      yield call(ProcessModelService.disableProcessModel, data);
      yield put(disableProcessModeleSuccess(data));
    } catch (err) {
      yield put(disableProcessModeleError(err));
    }
  });
}

export default function* rootSaga() {
  yield all([
    fork(getProcessModelList),
    fork(getProcessModelItem),
    fork(addProcessModele),
    fork(updateProcessModele),
    fork(disableProcessModele),
  ]);
}
