import fetch from 'services/Interceptors/MdsInterceptor';
import * as config from 'configs/AppConfig';


const FaqService = {};
const FAQ_LIST = 'lfaq';

FaqService.getFaqList = (data) => {
  return fetch({
    url: FAQ_LIST,
    method: 'post',
    headers: {
      'public-request': 'true',
    },
    data: {
      sOpe: config.API_SCLE_READ,
      Data: data,
    },
  });
};


export default FaqService;
