import React, { Suspense } from 'react';
import { Provider } from 'react-redux';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import CacheBuster from 'react-cache-buster';
import  version  from '../package.json';
import Spinner from 'components/Loading/Spinner';
import store from './store';
import Views from './views';
import MaintenancePage from './maintenancePage';
const isProduction = process.env.REACT_APP_NODE_ENV === "production" || true;
const isMaintenance = process.env.REACT_APP_IS_MAINTENANCE === "1" || false;

function App() {
  return (
      <CacheBuster
        currentVersion={version}
        isEnabled={isProduction}
        isVerboseMode={false}
        loadingComponent={<Spinner verticalAlign="center" withText />}
        metaFileDirectory={'.'}
      >
        <div className="App">
          <Suspense fallback={<Spinner verticalAlign="center" withText />}>
            <Provider store={store}>
              <Router>
                <Switch>
                  {isMaintenance ? (
                    <Route path="*" component={MaintenancePage} />
                    ) : (
                      <Route path="/" component={Views} />
                  )}
                </Switch>
              </Router>
            </Provider>
          </Suspense>
        </div>
      </CacheBuster>
  );
}

export default App;
