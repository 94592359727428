import fetch from 'services/Interceptors/MdsInterceptor';
import * as config from 'configs/AppConfig';

const FunctionalProcedureService = {};
const FUNC_PROC_PROC = 'gpf';

FunctionalProcedureService.getFuncProcItem = (data) => {
  return fetch({
    url: `/${FUNC_PROC_PROC}`,
    method: 'post',
    headers: {
      'public-request': 'true',
    },
    data: {
      sOpe: '1-PF',
      Data: data,
    },
  });
};

FunctionalProcedureService.addFuncProcItem = (data) => {
  return fetch({
    url: `/${FUNC_PROC_PROC}`,
    method: 'post',
    headers: {
      'public-request': 'true',
    },
    data: {
      sOpe: '2',
      Data: data,
    },
  });
}

FunctionalProcedureService.updateFuncProcItem = (data) => {
  return fetch({
    url: FUNC_PROC_PROC,
    method: 'post',
    headers: {
      'public-request': 'true',
    },
    data: {
      sOpe: '3',
      Data: data,
    },
  });
};


FunctionalProcedureService.getFuncProcStepItem = (data) => {
  return fetch({
    url: `/${FUNC_PROC_PROC}`,
    method: 'post',
    headers: {
      'public-request': 'true',
    },
    data: {
      sOpe: '1-PFE',
      Data: data,
    },
  });
};

FunctionalProcedureService.addFuncProcStepItem = (data) => {
  return fetch({
    url: `/${FUNC_PROC_PROC}`,
    method: 'post',
    headers: {
      'public-request': 'true',
    },
    data: {
      sOpe: '2-PFE',
      Data: data,
    },
  });
}

FunctionalProcedureService.updateFuncProcStepItem = (data) => {
  return fetch({
    url: FUNC_PROC_PROC,
    method: 'post',
    headers: {
      'public-request': 'true',
    },
    data: {
      sOpe: '3-PFE',
      Data: data,
    },
  });
};

FunctionalProcedureService.deleteFuncProcStepItem = (data) => {
  return fetch({
    url: FUNC_PROC_PROC,
    method: 'post',
    headers: {
      'public-request': 'true',
    },
    data: {
      sOpe: '4',
      Data: data,
    },
  });
};

FunctionalProcedureService.getFuncProcList = (data) => {
  return fetch({
    url: FUNC_PROC_PROC,
    method: 'post',
    headers: {
      'public-request': 'true',
    },
    data: {
      sOpe: '11',
      Data: data,
    },
  });
};

FunctionalProcedureService.getFuncProcWithStepItem = (data) => {
  return fetch({
    url: `/${FUNC_PROC_PROC}`,
    method: 'post',
    headers: {
      'public-request': 'true',
    },
    data: {
      sOpe: '1',
      Data: data,
    },
  });
};

FunctionalProcedureService.getBlankFuncProc = (data) => {
  return fetch({
    url: `/${FUNC_PROC_PROC}`,
    method: 'post',
    headers: {
      'public-request': 'true',
    },
    data: {
      sOpe: '10-PFTT',
      Data: data,
    },
  });
};

FunctionalProcedureService.addNewVersionFuncProcItem = (data) => {
  return fetch({
    url: `/${FUNC_PROC_PROC}`,
    method: 'post',
    headers: {
      'public-request': 'true',
    },
    data: {
      sOpe: '2-PFTT',
      Data: data,
    },
  });
}

export default FunctionalProcedureService;
