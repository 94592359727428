import fetch from 'services/Interceptors/MdsInterceptor';
import * as config from 'configs/AppConfig';


const ProcessService = {};
const PROC_HISTO = 'ght';

ProcessService.getProcessHistory = (data) => {
  return fetch({
    url: PROC_HISTO,
    method: 'post',
    headers: {
      'public-request': 'true',
    },
    data: {
      sOpe: config.API_SCLE_READ,
      Data: data,
    },
  });
};
ProcessService.addProcessHistory = (data) => {
  return fetch({
    url: PROC_HISTO,
    method: 'post',
    headers: {
      'public-request': 'true',
    },
    data: {
      sOpe: config.API_SCLE_ADD,
      Data: data,
    },
  });
};



export default ProcessService;