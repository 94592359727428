export const defaultProcessData = {
    tab_Trait: [
        {
            key: '1',
            id: '1',
            name: 'Fichiers clients-prospects et vente en ligne',
            category: 'Commercial',
            creation_date: new Date(2020, 8, 14),
            status: 'Terminé',
            update_date: new Date(2020, 11, 5),
        },
        {
            key: '2',
            id: '2',
            name: 'Prospection commerciale',
            category: 'Marketing',
            creation_date: new Date(2020, 8, 14),
            status: 'Terminé',
            update_date: new Date(2020, 11, 5),
        },
        {
            key: '3',
            id: '3',
            name: 'Réalisation d’enquête de satisfaction clients',
            category: 'Marketing',
            creation_date: new Date(2020, 8, 14),
            status: 'Terminé',
            update_date: new Date(2020, 11, 5),
        },
        {
            key: '4',
            id: '4',
            name: 'Gestion des habilitations du personnel',
            category: 'RH',
            creation_date: new Date(2020, 8, 14),
            status: 'Terminé',
            update_date: new Date(2020, 11, 5),
        },
        {
            key: '5',
            id: '5',
            name: 'Annuaire du personnel et organigramme',
            category: 'RH',
            creation_date: new Date(2020, 8, 14),
            status: 'Terminé',
            update_date: new Date(2020, 11, 5),
        },
        {
            key: '6',
            id: '6',
            name: 'CRM, logiciel de gestion de la relation client',
            category: 'Marketing',
            creation_date: new Date(2020, 8, 14),
            status: 'Terminé',
            update_date: new Date(2020, 11, 5),
        },
        {
            key: '7',
            id: '7',
            name: 'Réalisation d’études marketing',
            category: 'Marketing',
            creation_date: new Date(2020, 8, 14),
            status: 'En cours',
            update_date: new Date(2020, 11, 5),
        },
        {
            key: '8',
            id: '8',
            name: 'Gestion des infrascrutures sur les lieux de ventes',
            category: 'Juridique',
            creation_date: new Date(2020, 8, 14),
            status: 'En cours',
            update_date: new Date(2020, 11, 5),
        },
        {
            key: '9',
            id: '9',
            name: 'Gestion du parcours patient',
            category: 'Santé',
            creation_date: new Date(2020, 8, 14),
            status: 'En cours',
            update_date: new Date(2020, 11, 5),
        },
        {
            key: '0',
            id: '0',
            name: 'Gestion de l\'etat civil',
            category: 'Collectivités',
            creation_date: new Date(2020, 8, 14),
            status: 'En cours',
            update_date: new Date(2020, 11, 5),
        },
    ]
};

export default defaultProcessData;