import fetch from 'services/Interceptors/MdsInterceptor';
import * as config from 'configs/AppConfig';


const DocumentService = {};
const DOC_LIST = 'lld';
const DOC_ITEM = 'gd';

DocumentService.getDocumentList = (data) => {
  return fetch({
    url: DOC_LIST,
    method: 'post',
    headers: {
      'public-request': 'true',
    },
    data: {
      sOpe: config.API_SCLE_READ,
      Data: data,
    },
  });
};

DocumentService.getDocumentInfo = (data) => {
  return fetch({
    url: DOC_ITEM,
    method: 'post',
    headers: {
      'public-request': 'true',
    },
    data: {
      sOpe: config.API_SCLE_READ_6,
      Data: data,
    },
  });
};

DocumentService.ddlDocumentItem = (data) => {
  return fetch({
    url: DOC_ITEM,
    method: 'post',
    // responseType: 'blob',
    headers: {
      'public-request': 'true',
    },
    data: {
      sOpe: config.API_SCLE_DOWNLOAD,
      Data: data,
    },
  });
};

DocumentService.createDocumentItem = (data) => {
  return fetch({
    url: DOC_ITEM,
    method: 'post',
    headers: {
      'public-request': 'true',
    },
    data: {
      sOpe: config.API_SCLE_ADD,
      Data: data,
    },
  });
};


export default DocumentService;
