import { all, takeEvery, put, fork, call } from 'redux-saga/effects';

import RaciService from 'services/RaciService';

import {
  addRaciItemError,
  addRaciItemSuccess,
  clickBtnRaciError,
  clickBtnRaciSuccess,
  getRaciItemError,
  getRaciItemSuccess,
  getRaciListError,
  getRaciListSuccess,
} from '../actions/Raci';

import {
  GET_RACI_LIST,
  GET_RACI_ITEM,
  ADD_RACI_ITEM,
  CLIC_BTN_RACI,
} from '../constants/Raci';

export function* getRaciList() {
  yield takeEvery(GET_RACI_LIST, function* ({ data }) {
    try {
      const response = yield call(RaciService.getRaciList, data);
      yield put(getRaciListSuccess(response.Data));
    } catch (err) {
      yield put(getRaciListError(err));
    }
  });
}
export function* getRaciItem() {
  yield takeEvery(GET_RACI_ITEM, function* ({ data }) {
    try {
      const response = yield call(RaciService.getRaciItem, data);
      yield put(getRaciItemSuccess(response.Data));
    } catch (err) {
      yield put(getRaciItemError(err));
    }
  });
}

export function* addRaciItem() {
  yield takeEvery(ADD_RACI_ITEM, function* ({ data }) {
    try {
      const response = yield call(RaciService.createRaciItem, data);
      yield put(addRaciItemSuccess(response.Data));
    } catch (err) {
      yield put(addRaciItemError(err));
    }
  });
}

export function* clickBtnRaci() {
  yield takeEvery(CLIC_BTN_RACI, function* ({ data }) {
    try {
      const response = yield call(RaciService.clickBtnRaci, data);
      yield put(clickBtnRaciSuccess(response.Data));
    } catch (err) {
      yield put(clickBtnRaciError(err));
    }
  });
}

export default function* rootSaga() {
  yield all([
    fork(getRaciList),
    fork(getRaciItem),
    fork(addRaciItem),
    fork(clickBtnRaci),
  ]);
}
