/**
 * Generic interceptor *
 *
 * His purpose is to transform json to be ISO with MDS API requirement
 */

import axios from 'axios';
import { API_BASE_URL, API_PROXY_URL } from 'configs/AppConfig';
import { notification } from 'antd';
import Utils from 'configs/UtilFunctions';
import { AUTH_USER } from 'store/constants/Auth';
import axiosRetry from 'axios-retry';
import history from '../../history';


const service = axios.create({
  baseURL: API_PROXY_URL + API_BASE_URL,
  timeout: 60000,
});
axiosRetry(service, {
  retries: 3,
  noResponseRetries: 3,
  retryDelay: 500,
  httpMethodsToRetry: ['POST', 'HEAD', 'OPTIONS', 'DELETE', 'PUT'],
})

// API Request interceptor
service.interceptors.request.use(
  (config) => {
    const nTaille = JSON.stringify(config.data.Data).length;
    config.data = {
      sCle: JSON.parse(localStorage.getItem(AUTH_USER)).token,
      sOpe: config.data.sOpe,
      nTaille: nTaille === 0 ? 2 : nTaille,
      Data: config.data.Data,
      VersonSite: 2
    };

    // config.headers['Access-Control-Allow-Origin'] = '*';
    // config.headers['Access-Control-Allow-Headers'] = 'Origin, X-Requested-With, Content-Type, Accept';
    config.headers['Content-Type'] = 'application/json';

    return config;
  },
  (error) => {
    // Do something with request error here
    notification.error({
      message: 'Error',
    });
    Promise.reject(error);
  },
);

// API response interceptor
service.interceptors.response.use(
  (response) => {
    if (response.data.sMsg !== undefined) {
      response.data.message = response.data.sMsg;
    }
    return response.data;
  },
  (error) => {
    const notificationParam = {
      message: '',
    };
    if (error.response !== undefined && error.response.data !== undefined && error.response.data.sMsg !== undefined) {
      notificationParam.message = error.response.data.sMsg;
    } else if (error.response !== undefined) {

      if (error.response.status === 404) {
        notificationParam.message = 'Not Found';
      } else if (error.response.status === 500) {
        notificationParam.message = 'Internal Server Error';
      } else if (error.response.status === 508) {
        notificationParam.message = 'Time Out';
      } else if (error.response.status === 400) {
        notificationParam.message = 'Session expiré';
        // console.log(notificationParam.message);
        // window.location.href = `${process.env.PUBLIC_URL}/auth/login`;
        // localStorage.removeItem(AUTH_USER);
      }
      else {
        notificationParam.message = error.response.statusText
      }
    } else {
      console.log('Error with the w');
      // notificationParam.message = 'Error with the ws';
    }

    if (error.response.status === 400 && error.response.data.CodeErreur === 999999) {
      notificationParam.message = "Vous avez une ancienne version du site dans le cache de votre navigateur, veuiller appuyez sur F5 pour l'actualiser";
      notificationParam.description = '';
      // localStorage.removeItem(AUTH_USER);
    }

    if (error.response.status === 400 && error.response.data.CodeErreur == '100021') {
      notificationParam.message = 'Session expirée';
      setTimeout(() => {
        window.location.href = `${process.env.PUBLIC_URL}/auth/login`;
        localStorage.clear();
      }, 500);
    }
    if (error.response.status === 400 && error.response.data.CodeErreur == '213002') {
      notificationParam.message = "Il y a déjà un PIA en cours pour ce traitement.";
      // window.location.href = `${process.env.PUBLIC_URL}/app/pia`;
      // setTimeout(() => {
      // }, 500);
    }
    notification.error(notificationParam);
    // {
    //   message: error.response.data.sMsg,
    //   code: error.response.data.CodeErreur
    // }
    return Promise.reject(error);
  },
);

export default service;
