
import moment from "moment";

export const convertDocumentList = (data) => {
  const { nNbDocCree, tabDocCree } = data;

  const dataArray = [];

  tabDocCree.forEach(element => {
    dataArray.push(transformLabel(element))
  });

  return dataArray;
}

export const transformLabel = (element) => {
  return {
    key: element.nIDDocCree,
    id: element.nIDDocCree,
    name: element.sNomFichier,
    category: element.sTypeDemDoc,
    creation_date: moment(new Date(element.dhDemande)),
    status: getStatusDocument(element.sEtatDocCree),
    extension: element.sExtension,
    originalStatus: element.sEtatDocCree
  }
}

export const getStatusDocument = (status) => {
  switch (status) {
    case '10DEM':
      return 'En attente'

    case '30CRE':
      return 'CREE'

    case '90ERR':
      return 'ERREUR'

    default:
      return ''
  }
}
