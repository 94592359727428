import {
  APP_GET,
  APP_UPDATE,
  APP_SUCCESS,
  APP_ITEM_SUCCESS,
  APP_ERROR,
  // APP_LIST_SUCCESS,
  // SHOW_LOADING,
  HIDE_MESSAGE,
} from '../constants/App';

const initState = {
  message: {
    type: null,
    content: '',
  },
};

const app = (state = initState, action) => {
  if (
    (action.type.endsWith(APP_ITEM_SUCCESS) && !action.type.startsWith(APP_GET)) ||
    (action.type.startsWith(APP_UPDATE) && action.type.endsWith(APP_SUCCESS))) {
    return {
      ...state,
      message: { type: 'success', content: action.message }
    }
  } if (action.type.endsWith(APP_ERROR)) {
    return {
      ...state,
      message: { type: 'error', content: action.message }
    }
  } if (action.type === HIDE_MESSAGE) {
    return {
      ...state,
      message: initState.message
    }
  }
  return state;
};

export default app;
