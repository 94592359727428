import fetch from 'services/Interceptors/MdsInterceptor';
import * as config from 'configs/AppConfig';

const OrganisationService = {};
const MULTI_ORGA_PROC = 'go3';

OrganisationService.getOrganisationItem = (data) => {
  return fetch({
    url: `/${MULTI_ORGA_PROC}`,
    method: 'post',
    headers: {
      'public-request': 'true',
    },
    data: {
      sOpe: config.API_SCLE_READ,
      Data: data,
    },
  });
};

OrganisationService.addOrganisationItem = (data) => {
  return fetch({
    url: `/${MULTI_ORGA_PROC}`,
    method: 'post',
    headers: {
      'public-request': 'true',
    },
    data: {
      sOpe: config.API_SCLE_ADD,
      Data: data,
    },
  });
};

OrganisationService.updateOrganisationItem = (data) => {
  return fetch({
    url: MULTI_ORGA_PROC,
    method: 'post',
    headers: {
      'public-request': 'true',
    },
    data: {
      sOpe: config.API_SCLE_EDIT,
      Data: data,
    },
  });
};

OrganisationService.getOrganisationList = (data) => {
  return fetch({
    url: MULTI_ORGA_PROC,
    method: 'post',
    headers: {
      'public-request': 'true',
    },
    data: {
      sOpe: 11,
      Data: data,
    },
  });
};

OrganisationService.getOrganisationTypeList = (data) => {
  return fetch({
    url: 'llto',
    method: 'post',
    headers: {
      'public-request': 'true',
    },
    data: {
      sOpe: 1,
      Data: data,
    },
  });
};

OrganisationService.getOrganisationInfos = (data) => {
  return fetch({
    url: `/${MULTI_ORGA_PROC}`,
    method: 'post',
    headers: {
      'public-request': 'true',
    },
    data: {
      sOpe: 14,
      Data: data,
    },
  });
};

export default OrganisationService;
