import { all, takeEvery, put, fork, call } from 'redux-saga/effects';
import ActionsService from 'services/ActionsService';

import {
  GET_ACTIONS_LIST,
  GET_ACTIONS_LIST_SCENARIOS,
  GET_ACTIONS_ITEM,
  UPDATE_ACTION_ITEM,
} from '../constants/Actions';

import {
  getActionsListSuccess,
  getActionsListError,
  getActionsScenariosListSuccess,
  getActionsScenariosListError,
  getActionItemSuccess,
  getActionItemError,
  updateActionSuccess,
  updateActionError,
} from "../actions/Actions";

export function* getActionsList() {
  yield takeEvery(GET_ACTIONS_LIST, function* ({ data }) {
    try {
      const response = yield call(ActionsService.getActionsList, data);
      yield put(getActionsListSuccess(response.Data));
    } catch (err) {
      // console.log(err);
      yield put(getActionsListError(err));
    }
  });
}

export function* getScenarioList() {
  yield takeEvery(GET_ACTIONS_LIST_SCENARIOS, function* ({ data }) {
    try {
      const response = yield call(ActionsService.getActionsScenariosList, data);
      yield put(getActionsScenariosListSuccess(response.Data));
    } catch (err) {
      // console.log(err);
      yield put(getActionsScenariosListError(err));
    }
  });
}

export function* getActionItem() {
  yield takeEvery(GET_ACTIONS_ITEM, function* ({ data }) {
    try {
      const response = yield call(ActionsService.getActionItem, data);
      yield put(getActionItemSuccess(response.Data));
    } catch (err) {
      // console.log(err);
      yield put(getActionItemError(err));
    }
  });
}

export function* updateAction() {
  yield takeEvery(UPDATE_ACTION_ITEM, function* ({ data }) {
    try {
      const response = yield call(ActionsService.updateAction, data);
      yield put(updateActionSuccess(response.Data));
    } catch (err) {
      // console.log(err);
      yield put(updateActionError(err));
    }
  });
}

export default function* rootSaga() {
  yield all([
    fork(getActionsList),
    fork(getScenarioList),
    fork(getActionItem),
    fork(updateAction),
  ]);
}
