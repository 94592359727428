import fetch from 'services/Interceptors/MdsInterceptor';
import * as config from 'configs/AppConfig';

const ActorsBookService = {};

const PROC = 'gi3';
const LMI = 'lmi';

ActorsBookService.getActorsBook = (data) => {
    return fetch({
        url: `/${PROC}`,
        method: 'post',
        headers: {
            'public-request': 'true',
        },
        data: {
            sOpe: config.API_SCLE_READ,
            Data: data,
        },
    });
};
ActorsBookService.getActorsBookList = (data) => {
    return fetch({
        url: `/${PROC}`,
        method: 'post',
        headers: {
            'public-request': 'true',
        },
        data: {
            sOpe: config.API_SCLE_READ_LIST_11,
            Data: data,
        },
    });
};
ActorsBookService.getMissionActorsBookList = (data) => {
    return fetch({
        url: `/${LMI}`,
        method: 'post',
        headers: {
            'public-request': 'true',
        },
        data: {
            sOpe: config.API_SCLE_READ,
            Data: data,
        },
    });
};

ActorsBookService.updateActorsBook = (data) => {
    return fetch({
        url: `/${PROC}`,
        method: 'post',
        headers: {
            'public-request': 'true',
        },
        data: {
            sOpe: config.API_SCLE_EDIT,
            Data: data,
        },
    });
};

ActorsBookService.createActorsBook = (data) => {
    return fetch({
        url: `/${PROC}`,
        method: 'post',
        headers: {
            'public-request': 'true',
        },
        data: {
            sOpe: config.API_SCLE_ADD,
            Data: data,
        },
    });
};

ActorsBookService.getActorsBookWithAxe = (data) => {
    return fetch({
        url: `/${PROC}`,
        method: 'post',
        headers: {
            'public-request': 'true',
        },
        data: {
            sOpe: "12-1",
            Data: data, // {tabAxeGEO : [], tabAxeFCT: [], tabAxeMET: [], tabAxeACT}
        },
    });
};

ActorsBookService.deleteActorsBook = (data) => {
    return fetch({
        url: `/${PROC}`,
        method: 'post',
        headers: {
            'public-request': 'true',
        },
        data: {
            sOpe: config.API_SCLE_DELETE,
            Data: data,
        },
    });
};


export default ActorsBookService;
