import Utils from 'configs/UtilFunctions';
import openNotification from 'components/Notification';
import {
  AUTH_USER,
  AUTH_MAIL_USER,
  SIGNIN,
  SIGNIN_SUCCESS,
  SIGNIN_ERROR,
  SIGNOUT,
  SIGNOUT_SUCCESS,
  SIGNOUT_ERROR,
  SHOW_AUTH_MESSAGE,
  HIDE_AUTH_MESSAGE,
  SIGNUP,
  SIGNUP_SUCCESS,
  SIGNUP_ERROR,
  SHOW_AUTH_LOADING,
  SIGNIN_MAIL,
  SIGNIN_MAIL_SUCCESS,
  SIGNIN_MAIL_ERROR,
  SIGNIN_ORGA,
  SIGNIN_ORGA_SUCCESS,
  SIGNIN_ORGA_ERROR,
  LIST_MULTI_ORGA,
  LIST_MULTI_ORGA_SUCCESS,
  LIST_MULTI_ORGA_ERROR,
  CONNEXION_MONO_COMPTE,
  CONNEXION_MONO_COMPTE_SUCCESS,
  CONNEXION_MONO_COMPTE_ERROR,
  CONNEXION_MULTI_COMPTE_SANS_MAITRE,
  CONNEXION_MULTI_COMPTE_SANS_MAITRE_SUCCESS,
  CONNEXION_MULTI_COMPTE_SANS_MAITRE_ERROR,
  CONNEXION_MULTI_COMPTE_AVEC_MAITRE,
  CONNEXION_MULTI_COMPTE_AVEC_MAITRE_SUCCESS,
  CONNEXION_MULTI_COMPTE_AVEC_MAITRE_ERROR,
  LEC_CHG_INFO_MDP,
  LEC_CHG_INFO_MDP_SUCCESS,
  LEC_CHG_INFO_MDP_ERROR,
  LEC_CHG_INFO_NOMMAIL,
  LEC_CHG_INFO_NOMMAIL_SUCCESS,
  LEC_CHG_INFO_NOMMAIL_ERROR,
  DEMANDE_MODIF_MDP,
  DEMANDE_MODIF_MDP_SUCCESS,
  DEMANDE_MODIF_MDP_ERROR,
  DEMANDE_MODIF_NOMMAIL,
  DEMANDE_MODIF_NOMMAIL_SUCCESS,
  DEMANDE_MODIF_NOMMAIL_ERROR,
  MODIF_MDP,
  MODIF_MDP_SUCCESS,
  MODIF_MDP_ERROR,
  MODIF_NOMMAIL,
  MODIF_NOMMAIL_SUCCESS,
  MODIF_NOMMAIL_ERROR,
  RECEVOIR_MAIL_CACHEE,
  RECEVOIR_MAIL_CACHEE_SUCCESS,
  RECEVOIR_MAIL_CACHEE_ERROR,
  RECEVOIR_PASS_TEMP,
  RECEVOIR_PASS_TEMP_SUCCESS,
  RECEVOIR_PASS_TEMP_ERROR
} from '../constants/Auth';

export const signIn = (user) => {
  return {
    type: SIGNIN,
    data: user,
  };
};

export const signInOrga = (user) => {
  return {
    type: SIGNIN_ORGA,
    data: converUserToMdsUser(user),
  };
};

export const signInMail = (user) => {
  return {
    type: SIGNIN_MAIL,
    data: converUserToMdsUser(user),
  };
};

const converUserToMdsUser = (user) => {
  return {S1: user.organisation, S2: user.login, Ca: user.password, Op: '', dhDateCX: new Date(), dhDatedeCx: new Date()};
};

export const signInSuccess = (mdsUser, data) => {
  const userConverted = converMdsUserToUser(mdsUser, true, data);
  const message = {};
  message.type = 'success';
  message.title = "Changement d'organisation avec succès.";
  if (!localStorage.getItem(AUTH_MAIL_USER)) {
    openNotification(message);
  }
  localStorage.removeItem(AUTH_MAIL_USER);
  localStorage.setItem(AUTH_USER, JSON.stringify(userConverted));
  setTimeout(() => {
    window.location.href = `${process.env.PUBLIC_URL}/`;
  }, 1000);
  return {
    type: SIGNIN_MAIL_SUCCESS,
    user: userConverted,
  };
};

export const signInOrgaSuccess = (mdsUser) => {
  const userConverted = converMdsUserToUser(mdsUser, false);
  // console.log('userConverted ', userConverted)
  localStorage.setItem(AUTH_USER, JSON.stringify(userConverted));
  return {
    type: SIGNIN_ORGA_SUCCESS,
    user: userConverted,
  };
};

export const signInMailSuccess = (mdsUser) => {
  const userConverted = converMdsUserToUserMail(mdsUser);
  localStorage.setItem(AUTH_MAIL_USER, JSON.stringify(userConverted));
  return {
    type: SIGNIN_MAIL_SUCCESS,
    keyMail: userConverted,
  };
};

const converMdsUserToUserMail = (mdsUser) => {
  return {
    keyMail: `A${mdsUser.nIDses}-${mdsUser.N1}`,
    MC: mdsUser.MC,
  };
};

const converMdsUserToUser = (mdsUser, isMilti = false, data = null) => {
  let roleUser = "";
  if (mdsUser.Rl != undefined) {
    roleUser = mdsUser.Rl;
  }
  if (mdsUser.RL != undefined) {
    roleUser = mdsUser.RL;
  }
  return {
    organisation: mdsUser.S1,
    idUser: mdsUser.N1,
    login: mdsUser.S2,
    isMiltiCompte: isMilti,
    token: `A${mdsUser.nIDses}-${mdsUser.N1}`,
    Op: {
      ...mdsUser.Op,
      sLangue: mdsUser.Op.sLangue ? mdsUser.Op.sLangue : 'FR',
      nContexte: mdsUser.Op.nContexte ? mdsUser.Op.nContexte : 0,
    },
    idOrg: mdsUser.nIDorg,
    idMulti: data != null ? data.nIDUser : null,
    idSess: mdsUser.nIDses,
    langue: mdsUser.Op.sLangue ? mdsUser.Op.sLangue : 'FR',
    contexte: mdsUser.Op.nContexte ? mdsUser.Op.nContexte : 0,
    role: roleUser,
    niveau: mdsUser.Nv,
    nom: mdsUser.sNomUser,
    prenom: mdsUser.sPrenomUser,
    mail: mdsUser.sMailUser,
    bChMdP: mdsUser.bChMdP,
    bEstMaitre: mdsUser.bEstMaitre,
    bMC: mdsUser.bMC,
    sMsgLabel: mdsUser.sMsgLabel,
    MC: mdsUser.MC,
  };
};

export const signInError = (message) => {
  return {
    type: SIGNIN_ERROR,
    message,
  };
};

export const signInOrgaError = (message) => {
  return {
    type: SIGNIN_ORGA_ERROR,
    message,
  };
};

export const signInMailError = (message) => {
  return {
    type: SIGNIN_MAIL_ERROR,
    message,
  };
};

export const signOut = () => {
  return {
    type: SIGNOUT,
  };
};

export const signOutSuccess = () => {
  return {
    type: SIGNOUT_SUCCESS,
  };
};

export const signOutError = (message) => {
  return {
    type: SIGNOUT_ERROR,
    message,
  };
};

export const signUp = (user) => {
  return {
    type: SIGNUP,
    data: user,
  };
};

export const signUpSuccess = (token) => {
  return {
    type: SIGNUP_SUCCESS,
    token,
  };
};

export const signUpError = (message) => {
  return {
    type: SIGNUP_ERROR,
    message,
  };
};

export const showAuthMessage = (message) => {
  return {
    type: SHOW_AUTH_MESSAGE,
    message,
  };
};

export const hideAuthMessage = () => {
  return {
    type: HIDE_AUTH_MESSAGE,
  };
};

export const showLoading = () => {
  return {
    type: SHOW_AUTH_LOADING,
  };
};

export const listMultiOrga = () => {
  return {
    type: LIST_MULTI_ORGA,
    data: null,
  };
};

export const listMultiOrgaSuccess = (data) => {
  return {
    type: LIST_MULTI_ORGA_SUCCESS,
    data,
  };
};

export const listMultiOrgaError = (message) => {
  return {
    type: LIST_MULTI_ORGA_ERROR,
    message,
  };
};

export const connexMonoCompte = (user) => {
  return {
    type: CONNEXION_MONO_COMPTE,
    data: converUserToMdsUser(user),
  };
};

export const connexMonoCompteSuccess = (mdsUser) => {
  const userConverted = converMdsUserToUser(mdsUser, false);
  // console.log('userConverted ', userConverted)
  localStorage.setItem(AUTH_USER, JSON.stringify(userConverted));
  return {
    type: SIGNIN_ORGA_SUCCESS,
    user: userConverted,
  };
};

export const connexMonoCompteError = (message) => {
  return {
    type: CONNEXION_MONO_COMPTE_ERROR,
    message,
  };
};

export const connexMultiCompteSansMaitre = (data) => {
  return {
    type: CONNEXION_MULTI_COMPTE_SANS_MAITRE,
    data,
  };
};

export const connexMultiCompteSansMaitreSuccess = (data) => {
  return {
    type: CONNEXION_MULTI_COMPTE_SANS_MAITRE_SUCCESS,
    data,
  };
};

export const connexMultiCompteSansMaitreError = (message) => {
  return {
    type: CONNEXION_MULTI_COMPTE_SANS_MAITRE_ERROR,
    message,
  };
};

export const connexMultiCompteAvecMaitre = (data) => {
  return {
    type: CONNEXION_MULTI_COMPTE_AVEC_MAITRE,
    data,
  };
};

export const connexMultiCompteAvecMaitreSuccess = (data) => {
  return {
    type: CONNEXION_MULTI_COMPTE_AVEC_MAITRE_SUCCESS,
    data,
  };
};

export const connexMultiCompteAvecMaitreError = (message) => {
  return {
    type: CONNEXION_MULTI_COMPTE_AVEC_MAITRE_ERROR,
    message,
  };
};

export const lecInfoChgMdp = (data) => {
  return {
    type: LEC_CHG_INFO_MDP,
    data,
  };
};

export const lecInfoChgMdpSuccess = (data) => {
  return {
    type: LEC_CHG_INFO_MDP_SUCCESS,
    lecMdp: data,
  };
};

export const lecInfoChgMdpError = (message) => {
  return {
    type: LEC_CHG_INFO_MDP_ERROR,
    message,
  };
};

export const lecInfoChgNomMail = () => {
  return {
    type: LEC_CHG_INFO_NOMMAIL,
    data: {},
  };
};

export const lecInfoChgNomMailSuccess = (data) => {
  return {
    type: LEC_CHG_INFO_NOMMAIL_SUCCESS,
    lecNomMail: data,
  };
};

export const lecInfoChgNomMailError = (message) => {
  return {
    type: LEC_CHG_INFO_NOMMAIL_ERROR,
    message,
  };
};

export const dmdModifMdp = (data) => {
  return {
    type: DEMANDE_MODIF_MDP,
    data,
  };
};

export const dmdModifMdpSuccess = (data) => {
  return {
    type: DEMANDE_MODIF_MDP_SUCCESS,
    dmdModifMdpRet: data,
  };
};

export const dmdModifMdpError = (message) => {
  return {
    type: DEMANDE_MODIF_MDP_ERROR,
    message,
  };
};

export const dmdModifNomMail = (data) => {
  return {
    type: DEMANDE_MODIF_NOMMAIL,
    data,
  };
};

export const dmdModifNomMailSuccess = (data) => {
  return {
    type: DEMANDE_MODIF_NOMMAIL_SUCCESS,
    dmdModifNomMailRet: data,
  };
};

export const dmdModifNomMailError = (message) => {
  return {
    type: DEMANDE_MODIF_NOMMAIL_ERROR,
    message,
  };
};

export const modifMdp = (data) => {
  console.log('DATA ACTIONS 0', data)
  return {
    type: MODIF_MDP,
    data,
  };
};

export const modifMdpSuccess = (data) => {
  console.log('DATA ACTIONS', data)
  return {
    type: MODIF_MDP_SUCCESS,
    modifMdpRet: data,
  };
};

export const modifMdpError = (message) => {
  return {
    type: MODIF_MDP_ERROR,
    message,
  };
};

export const modifNomMail = (data) => {
  return {
    type: MODIF_NOMMAIL,
    data,
  };
};

export const modifNomMailSuccess = (data) => {
  return {
    type: MODIF_NOMMAIL_SUCCESS,
    modifNomMailRet: data,
  };
};

export const modifNomMailError = (message) => {
  return {
    type: MODIF_NOMMAIL_ERROR,
    message,
  };
};

export const recevoirMailCachee = (data) => {
  return {
    type: RECEVOIR_MAIL_CACHEE,
    data,
  };
};

export const recevoirMailCacheeSuccess = (data) => {
  return {
    type: RECEVOIR_MAIL_CACHEE_SUCCESS,
    mailHidden: data,
  };
};

export const recevoirMailCacheeError = (message) => {
  return {
    type: RECEVOIR_MAIL_CACHEE_ERROR,
    message,
  };
};
export const recevoirPassTemp = (data) => {
  return {
    type: RECEVOIR_PASS_TEMP,
    data,
  };
};

export const recevoirPassTempSuccess = (data) => {
  return {
    type: RECEVOIR_PASS_TEMP_SUCCESS,
    passTemp: data,
  };
};

export const recevoirPassTempError = (message) => {
  return {
    type: RECEVOIR_PASS_TEMP_ERROR,
    message,
  };
};
