import React from 'react';
import { connect } from 'react-redux';
import { Menu, Layout } from 'antd';
import { MenuFoldOutlined, MenuUnfoldOutlined } from '@ant-design/icons';
import { toggleCollapsedNav, onMobileNavToggle } from 'store/actions/Theme';
import {
  NAV_TYPE_TOP,
  SIDE_NAV_COLLAPSED_WIDTH,
  SIDE_NAV_WIDTH,
} from 'configs/constants/ThemeConstant';
import utils from 'configs/UtilFunctions';
import { withTranslation } from 'react-i18next';
import Logo from './Logo';
import NavProfile from './NavProfile';
import NavLanguage from './NavLanguage';
import NavHelp from './NavHelp';

const { Header } = Layout;

export const HeaderNav = (props) => {
  const { navCollapsed, mobileNav, navType, headerNavColor, isMobile, t } = props;

  const onToggle = () => {
    if (!isMobile) {
      props.toggleCollapsedNav(!navCollapsed);
    } else {
      props.onMobileNavToggle(!mobileNav);
    }
  };

  const isNavTop = navType === NAV_TYPE_TOP;
  const mode = utils.getColorContrast(headerNavColor);
  const getNavWidth = () => {
    if (isNavTop || isMobile) {
      return '0px';
    }
    if (navCollapsed) {
      return `${SIDE_NAV_COLLAPSED_WIDTH}px`;
    }
    return `${SIDE_NAV_WIDTH}px`;
  };
  return (
    <Header
      className={`app-header ${mode}`}
      style={{ backgroundColor: headerNavColor }}
    >
      <div className={`app-header-wrapper ${isNavTop ? 'layout-top-nav' : ''}`}>
        <Logo logoType={mode} />
        <div className="nav" style={{ width: `calc(100% - ${getNavWidth()})` }}>
          <div className="nav-left">
            <Menu mode="horizontal">
              {isNavTop && !isMobile ? null : (
                <Menu.Item
                  key="0"
                  onClick={() => {
                    onToggle();
                  }}
                >
                  {navCollapsed || isMobile ? (
                    <MenuUnfoldOutlined className="nav-icon" />
                  ) : (
                    <MenuFoldOutlined className="nav-icon" />
                  )}
                </Menu.Item>
              )}
            </Menu>
          </div>
          <div className="nav-right">
            {props.token ? (
              <>
                <NavLanguage />
                <NavProfile />
                <NavHelp t={t}/>
              </>
            ) : null}
            {/* <a href="https://www.regtek-decision.fr/Registre_mds/">
              Retourner à la version précédente.
            </a> */}
          </div>
        </div>
      </div>
    </Header>
  );
};

const mapStateToProps = ({ theme, auth }) => {
  const { navCollapsed, navType, headerNavColor, mobileNav } = theme;
  const { token } = auth.user;
  return { navCollapsed, navType, headerNavColor, mobileNav, token };
};

// export default connect(mapStateToProps, { toggleCollapsedNav, onMobileNavToggle })(
//   HeaderNav,
// );
export default withTranslation()(
  connect(mapStateToProps, { toggleCollapsedNav, onMobileNavToggle })(HeaderNav),
);
