import {
  ADD_DEMANDE_DE_DROITS_ITEM,
  ADD_DEMANDE_DE_DROITS_ITEM_SUCCESS,
  ADD_DEMANDE_DE_DROITS_ITEM_ERROR,
  GET_DEMANDE_DE_DROITS_LIST,
  GET_DEMANDE_DE_DROITS_LIST_SUCCESS,
  GET_DEMANDE_DE_DROITS_LIST_ERROR,
  GET_DEMANDE_DE_DROITS_ITEM,
  GET_DEMANDE_DE_DROITS_ITEM_SUCCESS,
  GET_DEMANDE_DE_DROITS_ITEM_ERROR,
  UPDATE_DEMANDE_DE_DROITS_ITEM,
  UPDATE_DEMANDE_DE_DROITS_ITEM_SUCCESS,
  UPDATE_DEMANDE_DE_DROITS_ITEM_ERROR,
  SHOW_LOADING,
  GET_DEMANDE_DE_DROITS_ERROR,
} from '../constants/DemandeDeDroits';

const initState = {
  isLoading: false,
  data: [],
  dataShouldUpdate: true,
  itemShouldRedirect: false,
  item: {},
};

const auth = (state = initState, action) => {
  switch (action.type) {
    case SHOW_LOADING: {
      return {
        ...state,
        isLoading: true,
      };
    }

    case ADD_DEMANDE_DE_DROITS_ITEM:
    case GET_DEMANDE_DE_DROITS_LIST:
    case UPDATE_DEMANDE_DE_DROITS_ITEM:
    case GET_DEMANDE_DE_DROITS_ITEM: {
      return {
        ...state,
        isLoading: true,
      };
    }

    case GET_DEMANDE_DE_DROITS_ITEM_SUCCESS: {
      return {
        ...state,
        item: action.item,
        dataShouldUpdate: true,
        isLoading: false,
      };
    }
    case GET_DEMANDE_DE_DROITS_LIST_SUCCESS: {
      return {
        ...state,
        data: action.data,
        dataShouldUpdate: false,
        isLoading: false,
        itemShouldRedirect: false,
      };
    }
    case UPDATE_DEMANDE_DE_DROITS_ITEM_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        dataShouldUpdate: true,
        item: action.item,
      };
    }

    case ADD_DEMANDE_DE_DROITS_ITEM_SUCCESS: {
      return {
        ...state,
        item: action.item,
        dataShouldUpdate: true,
        isLoading: false,
      };
    }

    case ADD_DEMANDE_DE_DROITS_ITEM_ERROR:
    case GET_DEMANDE_DE_DROITS_ERROR:
    case GET_DEMANDE_DE_DROITS_LIST_ERROR:
    case GET_DEMANDE_DE_DROITS_ITEM_ERROR:
    case UPDATE_DEMANDE_DE_DROITS_ITEM_ERROR: {
      return {
        ...state,
        itemShouldRedirect: true,
        isLoading: false,
      };
    }

    default:
      return state;
  }
};

export default auth;
