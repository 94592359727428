import {
  GET_DASHBOARD_DATA,
  GET_DASHBOARD_DATA_SUCCESS,
  GET_DASHBOARD_DATA_ERROR,
  SHOW_LOADING,
  GET_CRITERE_QUANTITATIF_DATA_ERROR,
  GET_BASE_LEGALE_DATA_ERROR,
  GET_SYNTHESE_ACTION_DATA_ERROR,
  GET_CRITERE_QUANTITATIF_DATA,
  GET_SYNTHESE_ACTION_DATA,
  GET_BASE_LEGALE_DATA,
  GET_BASE_LEGALE_DATA_SUCCESS,
  GET_SYNTHESE_ACTION_DATA_SUCCESS,
  GET_CRITERE_QUANTITATIF_DATA_SUCCESS,
} from '../constants/Dashboard';

const initState = {
  isLoading: false,
  dataShouldUpdate: false,
  redirect: '',
  data: null,
  syntheseActionData: null,
  critereQuantitatifData: null,
  baseLegalData: null
};

const auth = (state = initState, action) => {
  switch (action.type) {

    case GET_DASHBOARD_DATA :
    case GET_CRITERE_QUANTITATIF_DATA :
    case GET_SYNTHESE_ACTION_DATA :
    case GET_BASE_LEGALE_DATA :
      return {
        ...state,
        isLoading: true,
      };

    case GET_DASHBOARD_DATA_SUCCESS :
      return {
        ...state,
        isLoading: false,
        dataShouldUpdate: false,
        data: action.data,
      };

    case GET_BASE_LEGALE_DATA_SUCCESS :
      return {
        ...state,
        isLoading: false,
        dataShouldUpdate: false,
        baseLegalData: action.data,
        };

    case GET_SYNTHESE_ACTION_DATA_SUCCESS : 
      return {
        ...state,
        isLoading: false,
        dataShouldUpdate: false,
        syntheseActionData: action.data,
      };

    case GET_CRITERE_QUANTITATIF_DATA_SUCCESS :
      return {
        ...state,
        isLoading: false,
        dataShouldUpdate: false,
        critereQuantitatifData: action.data,
      };

    case GET_DASHBOARD_DATA_ERROR :
    case GET_CRITERE_QUANTITATIF_DATA_ERROR :
    case GET_BASE_LEGALE_DATA_ERROR :
    case GET_SYNTHESE_ACTION_DATA_ERROR : 
      return {
        ...state,
        isLoading: false,
      };

    case SHOW_LOADING: {
      return {
        ...state,
        isLoading: true,
      };
    }
    
    default:
      return state;
  }
};
 
export default auth;
