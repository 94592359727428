export const SIGNIN = 'SIGNIN';
export const SIGNIN_SUCCESS = 'SIGNIN_SUCCESS';
export const SIGNIN_ERROR = 'SIGNIN_ERROR';
export const AUTHENTICATED = 'AUTHENTICATED';

export const SIGNIN_MAIL = 'SIGNIN_MAIL';
export const SIGNIN_MAIL_SUCCESS = 'SIGNIN_MAIL_SUCCESS';
export const SIGNIN_MAIL_ERROR = 'SIGNIN_MAIL_ERROR';

export const SIGNIN_ORGA = 'SIGNIN_ORGA';
export const SIGNIN_ORGA_SUCCESS = 'SIGNIN_ORGA_SUCCESS';
export const SIGNIN_ORGA_ERROR = 'SIGNIN_ORGA_ERROR';

export const SIGNOUT = 'SIGNOUT';
export const SIGNOUT_SUCCESS = 'SIGNOUT_SUCCESS';
export const SIGNOUT_ERROR = 'SIGNOUT_ERROR';

export const LIST_MULTI_ORGA = 'LIST_MULTI_ORGA';
export const LIST_MULTI_ORGA_SUCCESS = 'LIST_MULTI_ORGA_SUCCESS';
export const LIST_MULTI_ORGA_ERROR = 'LIST_MULTI_ORGA_ERROR';

export const SIGNUP = 'SIGNUP';
export const SIGNUP_SUCCESS = 'SIGNUP_SUCCESS';
export const SIGNUP_ERROR = 'SIGNUP_ERROR';
export const SHOW_AUTH_MESSAGE = 'SHOW_AUTH_MESSAGE';
export const HIDE_AUTH_MESSAGE = 'HIDE_AUTH_MESSAGE';
export const SHOW_AUTH_LOADING = 'SHOW_AUTH_LOADING';
export const AUTH_USER = 'AUTH_USER';
export const AUTH_MAIL_USER = 'AUTH_MAIL_USER';

export const LEC_CHG_INFO_MDP = 'LEC_CHG_INFO_MDP';
export const LEC_CHG_INFO_MDP_SUCCESS = 'LEC_CHG_INFO_MDP_SUCCESS';
export const LEC_CHG_INFO_MDP_ERROR = 'LEC_CHG_INFO_MDP_ERROR';

export const LEC_CHG_INFO_NOMMAIL = 'LEC_CHG_INFO_NOMMAIL';
export const LEC_CHG_INFO_NOMMAIL_SUCCESS = 'LEC_CHG_INFO_NOMMAIL_SUCCESS';
export const LEC_CHG_INFO_NOMMAIL_ERROR = 'LEC_CHG_INFO_NOMMAIL_ERROR';

export const DEMANDE_MODIF_MDP = 'DEMANDE_MODIF_MDP';
export const DEMANDE_MODIF_MDP_SUCCESS = 'DEMANDE_MODIF_MDP_SUCCESS';
export const DEMANDE_MODIF_MDP_ERROR = 'DEMANDE_MODIF_MDP_ERROR';

export const DEMANDE_MODIF_NOMMAIL = 'DEMANDE_MODIF_NOMMAIL';
export const DEMANDE_MODIF_NOMMAIL_SUCCESS = 'DEMANDE_MODIF_NOMMAIL_SUCCESS';
export const DEMANDE_MODIF_NOMMAIL_ERROR = 'DEMANDE_MODIF_NOMMAIL_ERROR';

export const MODIF_MDP = 'MODIF_MDP';
export const MODIF_MDP_SUCCESS = 'MODIF_MDP_SUCCESS';
export const MODIF_MDP_ERROR = 'MODIF_MDP_ERROR';

export const MODIF_NOMMAIL = 'MODIF_NOMMAIL';
export const MODIF_NOMMAIL_SUCCESS = 'MODIF_NOMMAIL_SUCCESS';
export const MODIF_NOMMAIL_ERROR = 'MODIF_NOMMAIL_ERROR';

export const CONNEXION_MONO_COMPTE = 'CONNEXION_MONO_COMPTE';
export const CONNEXION_MONO_COMPTE_SUCCESS = 'CONNEXION_MONO_COMPTE_SUCCESS';
export const CONNEXION_MONO_COMPTE_ERROR = 'CONNEXION_MONO_COMPTE_ERROR';

export const CONNEXION_MULTI_COMPTE_SANS_MAITRE = 'CONNEXION_MULTI_COMPTE_SANS_MAITRE';
export const CONNEXION_MULTI_COMPTE_SANS_MAITRE_SUCCESS = 'CONNEXION_MULTI_COMPTE_SANS_MAITRE_SUCCESS';
export const CONNEXION_MULTI_COMPTE_SANS_MAITRE_ERROR = 'CONNEXION_MULTI_COMPTE_SANS_MAITRE_ERROR';

export const CONNEXION_MULTI_COMPTE_AVEC_MAITRE = 'CONNEXION_MULTI_COMPTE_AVEC_MAITRE';
export const CONNEXION_MULTI_COMPTE_AVEC_MAITRE_SUCCESS = 'CONNEXION_MULTI_COMPTE_AVEC_MAITRE_SUCCESS';
export const CONNEXION_MULTI_COMPTE_AVEC_MAITRE_ERROR = 'CONNEXION_MULTI_COMPTE_AVEC_MAITRE_ERROR';

export const RECEVOIR_MAIL_CACHEE = 'RECEVOIR_MAIL_CACHEE';
export const RECEVOIR_MAIL_CACHEE_SUCCESS = 'RECEVOIR_MAIL_CACHEE_SUCCESS';
export const RECEVOIR_MAIL_CACHEE_ERROR = 'RECEVOIR_MAIL_CACHEE_ERROR';

export const RECEVOIR_PASS_TEMP = 'RECEVOIR_PASS_TEMP';
export const RECEVOIR_PASS_TEMP_SUCCESS = 'RECEVOIR_PASS_TEMP_SUCCESS';
export const RECEVOIR_PASS_TEMP_ERROR = 'RECEVOIR_PASS_TEMP_ERROR';
