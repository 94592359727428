import React, { Component, lazy, Suspense } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import { I18nextProvider } from "react-i18next";

import { AUTH_PREFIX_PATH } from 'configs/AppConfig';
import getIi18nAppItem from 'configs/LangConfig'

import Loading from 'components/Loading/Spinner';

export class AppViews extends Component {

  componentDidMount() {
    this.i18nAuthnstance = getIi18nAppItem(this.props.user);
  }

  render() {
    return (
      <Suspense fallback={<Loading verticalAlign="center" withText />}>
        <I18nextProvider i18n={this.i18nAuthnstance}>
          <Switch>
            <Route
              path={`${AUTH_PREFIX_PATH}/login`}
              component={lazy(() => import(`./login`))}
            />
            <Route
              path={`${AUTH_PREFIX_PATH}/register`}
              component={lazy(() => import(`./register`))}
            />
            <Route
              path={`${AUTH_PREFIX_PATH}/add-infos-user`}
              component={lazy(() => import(`./changeInfosUser`))}
            />
            <Route
              path={`${AUTH_PREFIX_PATH}/change-password`}
              component={lazy(() => import(`./changePassword`))}
            />
            <Route
              path={`${AUTH_PREFIX_PATH}/forgot-password`}
              component={lazy(() => import(`./forgot-password`))}
            />
            <Redirect from={`${AUTH_PREFIX_PATH}`} to={`${AUTH_PREFIX_PATH}/login`} />
          </Switch>
        </I18nextProvider>
      </Suspense>
    );
  }
}

export default AppViews;
