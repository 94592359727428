import antdEnUS from 'antd/es/locale/en_US';
import antdFr from 'antd/es/locale/fr_FR';
import antdEs from 'antd/es/locale/es_ES';
import antdPt from 'antd/es/locale/pt_PT';

import { initReactI18next } from "react-i18next";
import i18n from "i18next";
import Fetch from 'i18next-fetch-backend';

import Utils from 'configs/UtilFunctions'

export const langConfig = [
  // {
  //   langName: "English",
  //   langId: "en",
  //   icon: "us",
  //   lang: "En",
  //   locale: 'en-US',
  //   antd: antdEnUS,

  // }, 
  {
    langName: "Français",
    langId: "fr",
    icon: "fr",
    lang: "Fr",
    locale: 'fr-FR',
    antd: antdFr,

  }, 
  // {
  //   langName: "Espagnol",
  //   langId: "es",
  //   icon: "es",
  //   lang: "Es",
  //   locale: 'es-ES',
  //   antd: antdEs,

  // }, 
  // {
  //   langName: "Portuguese",
  //   langId: "pt",
  //   icon: "pt",
  //   lang: "Pt",
  //   locale: 'pt-PT',
  //   antd: antdPt,

  // }
]

const defaultUser = {
  token: Utils.getEnv('REACT_APP_I18N_SCLE'),
  Op: {
    nContexte: 0
  }
}
const i18nInstance = i18n.createInstance();

export function getIi18nAppItem(user = defaultUser, lang = 'FR') {
  // console.log("consoleuser", [user.Op.nContexte])

  i18nInstance
    .use(Fetch)
    .use(initReactI18next)
    .init({
      // fallbackLng: langConfig.map(l => l.langId.toUpperCase()),
      fallbackLng: null,
      lng: user.Op.sLangue ? user.Op.sLangue : lang,
      // ns: [user.Op.nContexte],
      // defaultNS: user.Op.nContexte,
      backend: {
        loadPath: `${Utils.getEnv('REACT_APP_API_PROXY_URL') + Utils.getEnv('REACT_APP_API_BASE_URL')}/ltrd/${[user.Op.nContexte]}/{{lng}}`,
        headers: {
          authorization: 'foo',
          "Content-Type": "application/json",
          // ...
        },
        parse: (data) => {
          const trad = JSON.parse(data).Data.tabTradObjet;
          const tradParsed = [];
          Object.keys(trad).forEach(function (k) {
            if (trad[k].sLibelle !== '<<TRAD_MANQUANTE>>') {
              tradParsed[trad[k].sLabel] = trad[k].sLibelle
            }
          });
          return tradParsed
        },
        requestOptions: {
          method: "POST",
          headers: {
            authorization: 'foo',
            "Content-Type": "application/json",
            // ...
          },
          body: JSON.stringify({
            sOpe: "1-1",
            sCle: user.token,
            nTaille: 4,
            Data: {}
          })
        }
      }
    })
    .then(() => {
      // console.log(t);
    });
  return i18nInstance;
}

export function changeAppLocale(locale) {
  i18nInstance.changeLanguage(locale.toUpperCase());
}
export default getIi18nAppItem;
