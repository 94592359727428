export const UPDATE_USER_MANAGEMENT = 'UPDATE_USER_MANAGEMENT';
export const UPDATE_USER_MANAGEMENT_SUCCESS = 'UPDATE_USER_MANAGEMENT_SUCCESS';
export const UPDATE_USER_MANAGEMENT_ERROR = 'UPDATE_USER_MANAGEMENT_ERROR';

export const ADD_USER_MANAGEMENT_ITEM = 'ADD_USER_MANAGEMENT_ITEM';
export const ADD_USER_MANAGEMENT_ITEM_SUCCESS = 'ADD_USER_MANAGEMENT_ITEM_SUCCESS';
export const ADD_USER_MANAGEMENT_ITEM_ERROR = 'ADD_USER_MANAGEMENT_ITEM_ERROR';

export const GET_USER_MANAGEMENT_LIST = 'GET_USER_MANAGEMENT_LIST';
export const GET_USER_MANAGEMENT_LIST_SUCCESS = 'GET_USER_MANAGEMENT_LIST_SUCCESS';
export const GET_USER_MANAGEMENT_LIST_ERROR = 'GET_USER_MANAGEMENT_LIST_ERROR';

export const GET_USER_MANAGEMENT_ITEM = 'GET_USER_MANAGEMENT_ITEM';
export const GET_USER_MANAGEMENT_ITEM_SUCCESS = 'GET_USER_MANAGEMENT_ITEM_SUCCESS';
export const GET_USER_MANAGEMENT_ITEM_ERROR = 'GET_USER_MANAGEMENT_ITEM_ERROR';

export const UPDATE_USER_MANAGEMENT_ITEM = 'UPDATE_USER_MANAGEMENT_ITEM';
export const UPDATE_USER_MANAGEMENT_ITEM_SUCCESS = 'UPDATE_USER_MANAGEMENT_ITEM_SUCCESS';
export const UPDATE_USER_MANAGEMENT_ITEM_ERROR = 'UPDATE_USER_MANAGEMENT_ITEM_ERROR';


export const SHOW_LOADING = 'SHOW_USER_MANAGEMENT_LOADING';
