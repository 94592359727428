import { all, takeEvery, put, fork, call } from 'redux-saga/effects';
import DashboardService from 'services/DashboardService';

import {
  GET_BASE_LEGALE_DATA,
  GET_CRITERE_QUANTITATIF_DATA,
  GET_DASHBOARD_DATA,
  GET_SYNTHESE_ACTION_DATA,
} from '../constants/Dashboard';

import {
  getDashboardDataSuccess,
  getDashboardDataError,
  getSyntheseActionDataSuccess,
  getSyntheseActionDataError,
  getCritereQuantitatifDataSuccess,
  getCritereQuantitatifDataError,
  getBaseLegaleDataSuccess,
  getBaseLegaleDataError,
} from '../actions/Dashboard';

export function* getDashboardData() {
  yield takeEvery(GET_DASHBOARD_DATA, function* ({ data }) {
    try {
      const response = yield call(DashboardService.getDashboardData, data);
      // console.log('getDashboardData ', response.Data)
      yield put(getDashboardDataSuccess(response.Data));
    } catch (err) {
      yield put(getDashboardDataError(err));
    }
  });
}

export function* getSyntheseActionData() {
  yield takeEvery(GET_SYNTHESE_ACTION_DATA, function* ({ data }) {
    try {
      const response = yield call(DashboardService.getSyntheseActionData, data);
      yield put(getSyntheseActionDataSuccess(response.Data));
    } catch (err) {
      yield put(getSyntheseActionDataError(err));
    }
  });
}

export function* getCritereQuantitatifData() {
  yield takeEvery(GET_CRITERE_QUANTITATIF_DATA, function* ({ data }) {
    try {
      const response = yield call(DashboardService.getCritereQuantitatifData, data);
      // console.log('getCritereQuantitatifData ', response.Data)
      yield put(getCritereQuantitatifDataSuccess(response.Data));
    } catch (err) {
      console.log('erreur getCritereQuantitatifData ', err);
      yield put(getCritereQuantitatifDataError(err));
    }
  });
}

export function* getBaseLegaleData() {
  yield takeEvery(GET_BASE_LEGALE_DATA, function* ({ data }) {
    try {
      const response = yield call(DashboardService.getBaseLegaleData, data);
      // console.log('getBaseLegaleData ', response.Data)
      yield put(getBaseLegaleDataSuccess(response.Data));
    } catch (err) {
      yield put(getBaseLegaleDataError(err));
    }
  });
}

export default function* rootSaga() {
  yield all([
    fork(getDashboardData),
    fork(getSyntheseActionData),
    fork(getCritereQuantitatifData),
    fork(getBaseLegaleData),
  ]);
}
