import React from 'react';
import { connect } from 'react-redux';
import { NAV_TYPE_TOP } from 'configs/constants/ThemeConstant';
import utils from 'configs/UtilFunctions';
import MenuContent from './MenuContent';
import { withTranslation } from 'react-i18next';

export const TopNav = ({ topNavColor, localization = true }) => {
  const props = { topNavColor, localization };
  return (
    <div
      className={`top-nav ${utils.getColorContrast(topNavColor)}`}
      style={{ backgroundColor: topNavColor }}
    >
      <div className="top-nav-wrapper">
        <MenuContent type={NAV_TYPE_TOP} {...props} />
      </div>
    </div>
  );
};

const mapStateToProps = ({ theme }) => {
  const { topNavColor } = theme;
  return { topNavColor };
};

export default withTranslation()(
  connect(mapStateToProps)(TopNav),
);