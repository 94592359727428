
import {
  GET_ACTIONS_LIST,
  GET_ACTIONS_LIST_SUCCESS,
  GET_ACTIONS_LIST_ERROR,
  GET_ACTIONS_LIST_SCENARIOS,
  GET_ACTIONS_LIST_SCENARIOS_SUCCESS,
  GET_ACTIONS_LIST_SCENARIOS_ERROR,
  GET_ACTIONS_ITEM,
  GET_ACTIONS_ITEM_SUCCESS,
  GET_ACTIONS_ITEM_ERROR,
  UPDATE_ACTION_ITEM,
  UPDATE_ACTION_ITEM_SUCCESS,
  UPDATE_ACTION_ITEM_ERROR,
  SHOW_LOADING,
} from '../constants/Actions';

import * as converter from '../converter/Actions';

export const getActionsList = () => {
  return {
    type: GET_ACTIONS_LIST,
    data: {
      sC: 0,
      nN: 0,
    },
  };
};

export const getActionsListSuccess = (mdsActions) => {
  return {
    type: GET_ACTIONS_LIST_SUCCESS,
    data: {
      tab_ActTRT: converter.convertMdsActionListInActionsList(mdsActions.tab_ActTRT),
      tab_ActPIA: converter.convertMdsActionListInActionsList(mdsActions.tab_ActPIA),
      tab_ActINC: converter.convertMdsActionListInActionsList(mdsActions.tab_ActINC)
    },
  };
};

export const getActionsListError = (error) => {
  return {
    type: GET_ACTIONS_LIST_ERROR,
    ...error,
  };
};

export const getActionsScenariosList = () => {
  return {
    type: GET_ACTIONS_LIST_SCENARIOS,
    data: {
      sC: 0,
      nN: 0,
    },
  };
};

export const getActionsScenariosListSuccess = (mdsActions) => {
  return {
    type: GET_ACTIONS_LIST_SCENARIOS_SUCCESS,
    scenario: {
      data: converter.convertAllScenarioList(mdsActions),
    },
  };
};

export const getActionsScenariosListError = (error) => {
  return {
    type: GET_ACTIONS_LIST_SCENARIOS_ERROR,
    ...error,
  };
};

export const updateAction = (id, action) => {
  return {
    type: UPDATE_ACTION_ITEM,
    data: {
      nBPresta: 1,
      tab_ST_Plan_Act: [converter.convertActionToMdsAction(action, id)],
    },
  };
};

export const updateActionSuccess = (mdsAction) => {
  return {
    type: UPDATE_ACTION_ITEM_SUCCESS,
    item: converter.convertMdsActionDetailInAction(mdsAction.tab_ST_Plan_Act[0]),
  };
};

export const updateActionError = (error) => {
  return {
    type: UPDATE_ACTION_ITEM_ERROR,
    ...error,
  };
};

export const getActionItem = (id) => {
  return {
    type: GET_ACTIONS_ITEM,
    data: {
      nNbAct: 1,
      tab_ST_Plan_Act: [{ nIDPlan_Act: id }]
    }
  };
};

export const getActionItemSuccess = (item) => {
  return {
    type: GET_ACTIONS_ITEM_SUCCESS,
    item: converter.convertMdsActionDetailInAction(item.tab_ST_Plan_Act[0]),
  };
};

export const getActionItemError = (error) => {
  return {
    type: GET_ACTIONS_ITEM_ERROR,
    ...error
  };
};

export const showLoading = () => {
  return {
    type: SHOW_LOADING,
  };
};
