export const ADD_CONTRAT_ITEM = 'ADD_CONTRAT_ITEM';
export const ADD_CONTRAT_ITEM_SUCCESS = 'ADD_CONTRAT_ITEM_SUCCESS';
export const ADD_CONTRAT_ITEM_ERROR = 'ADD_CONTRAT_ITEM_ERROR';
export const GET_CONTRAT_LIST = 'GET_CONTRAT_LIST';
export const GET_CONTRAT_LIST_SUCCESS = 'GET_CONTRAT_LIST_SUCCESS';
export const GET_CONTRAT_LIST_ERROR = 'GET_CONTRAT_LIST_ERROR';
export const GET_CONTRAT_ITEM = 'GET_CONTRAT_ITEM';
export const GET_CONTRAT_ITEM_SUCCESS = 'GET_CONTRAT_ITEM_SUCCESS';
export const GET_CONTRAT_ITEM_ERROR = 'GET_CONTRAT_ITEM_ERROR';
export const GET_NATURE_CONTRAT_LIST = 'GET_NATURE_CONTRAT_LIST';
export const GET_NATURE_CONTRAT_LIST_SUCCESS = 'GET_NATURE_CONTRAT_LIST_SUCCESS';
export const GET_NATURE_CONTRAT_LIST_ERROR = 'GET_NATURE_CONTRAT_LIST_ERROR';
export const UPDATE_CONTRAT_ITEM = 'UPDATE_CONTRAT_ITEM';
export const UPDATE_CONTRAT_ITEM_SUCCESS = 'UPDATE_CONTRAT_ITEM_SUCCESS';
export const UPDATE_CONTRAT_ITEM_ERROR = 'UPDATE_CONTRAT_ITEM_ERROR';
export const DELETE_CONTRAT_ITEM = 'DELETE_CONTRAT_ITEM';
export const DELETE_CONTRAT_ITEM_SUCCESS = 'DELETE_CONTRAT_ITEM_SUCCESS';
export const DELETE_CONTRAT_ITEM_ERROR = 'DELETE_CONTRAT_ITEM_ERROR';
export const GET_CONTRAT_BY_PROVIDER_ITEM = 'GET_CONTRAT_BY_PROVIDER_ITEM';
export const GET_CONTRAT_BY_PROVIDER_ITEM_SUCCESS =
  'GET_CONTRAT_BY_PROVIDER_ITEM_SUCCESS';
export const GET_CONTRAT_BY_PROVIDER_ITEM_ERROR =
  'GET_CONTRAT_BY_PROVIDER_ITEM_ERROR';
export const DISABLE_CONTRAT_ITEM = 'DISABLE_CONTRAT_ITEM';
export const DISABLE_CONTRAT_ITEM_SUCCESS = 'DISABLE_CONTRAT_ITEM_SUCCESS';
export const DISABLE_CONTRAT_ITEM_ERROR = 'DISABLE_CONTRAT_ITEM_ERROR';
export const SHOW_LOADING = 'SHOW_CONTRAT_LOADING';
