export const GET_DEMANDE_DE_DROITS = 'GET_DEMANDE_DE_DROITS';
export const GET_DEMANDE_DE_DROITS_SUCCESS = 'GET_DEMANDE_DE_DROITS_SUCCESS';
export const GET_DEMANDE_DE_DROITS_ERROR = 'GET_DEMANDE_DE_DROITS_ERROR';
export const UPDATE_DEMANDE_DE_DROITS = 'UPDATE_DEMANDE_DE_DROITS';
export const UPDATE_DEMANDE_DE_DROITS_SUCCESS = 'UPDATE_DEMANDE_DE_DROITS_SUCCESS';
export const UPDATE_DEMANDE_DE_DROITS_ERROR = 'UPDATE_DEMANDE_DE_DROITS_ERROR';

export const ADD_DEMANDE_DE_DROITS_ITEM = 'ADD_DEMANDE_DE_DROITS_ITEM';
export const ADD_DEMANDE_DE_DROITS_ITEM_SUCCESS =
  'ADD_DEMANDE_DE_DROITS_ITEM_SUCCESS';
export const ADD_DEMANDE_DE_DROITS_ITEM_ERROR = 'ADD_DEMANDE_DE_DROITS_ITEM_ERROR';
export const GET_DEMANDE_DE_DROITS_LIST = 'GET_DEMANDE_DE_DROITS_LIST';
export const GET_DEMANDE_DE_DROITS_LIST_SUCCESS =
  'GET_DEMANDE_DE_DROITS_LIST_SUCCESS';
export const GET_DEMANDE_DE_DROITS_LIST_ERROR = 'GET_DEMANDE_DE_DROITS_LIST_ERROR';
export const GET_DEMANDE_DE_DROITS_ITEM = 'GET_DEMANDE_DE_DROITS_ITEM';
export const GET_DEMANDE_DE_DROITS_ITEM_SUCCESS =
  'GET_DEMANDE_DE_DROITS_ITEM_SUCCESS';
export const GET_DEMANDE_DE_DROITS_ITEM_ERROR = 'GET_DEMANDE_DE_DROITS_ITEM_ERROR';
export const UPDATE_DEMANDE_DE_DROITS_ITEM = 'UPDATE_DEMANDE_DE_DROITS_ITEM';
export const UPDATE_DEMANDE_DE_DROITS_ITEM_SUCCESS =
  'UPDATE_DEMANDE_DE_DROITS_ITEM_SUCCESS';
export const UPDATE_DEMANDE_DE_DROITS_ITEM_ERROR =
  'UPDATE_DEMANDE_DE_DROITS_ITEM_ERROR';

export const GET_DEMANDE_DE_DROITS_TYPE_LIST = 'GET_DEMANDE_DE_DROITS_TYPE_LIST';
export const GET_DEMANDE_DE_DROITS_TYPE_LIST_SUCCESS =
  'GET_DEMANDE_DE_DROITS_TYPE_LIST_SUCCESS';
export const GET_DEMANDE_DE_DROITS_TYPE_LIST_ERROR =
  'GET_DEMANDE_DE_DROITS_TYPE_LIST_ERROR';

export const SHOW_LOADING = 'SHOW_DEMANDE_DE_DROITS_LOADING';
