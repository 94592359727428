import fetch from 'services/Interceptors/MdsInterceptor';
import * as config from 'configs/AppConfig';

const DemandeDeDroitsService = {};
const GDDR_PROC = 'gddr';

DemandeDeDroitsService.getDemandeDeDroitsItem = (data) => {
  return fetch({
    url: `/${GDDR_PROC}`,
    method: 'post',
    headers: {
      'public-request': 'true',
    },
    data: {
      sOpe: config.API_SCLE_READ,
      Data: data,
    },
  });
};

DemandeDeDroitsService.addDemandeDeDroitsItem = (data) => {
  return fetch({
    url: `/${GDDR_PROC}`,
    method: 'post',
    headers: {
      'public-request': 'true',
    },
    data: {
      sOpe: config.API_SCLE_ADD,
      Data: data,
    },
  });
};

DemandeDeDroitsService.updateDemandeDeDroitsItem = (data) => {
  return fetch({
    url: GDDR_PROC,
    method: 'post',
    headers: {
      'public-request': 'true',
    },
    data: {
      sOpe: config.API_SCLE_EDIT,
      Data: data,
    },
  });
};

DemandeDeDroitsService.getDemandeDeDroitsList = (data) => {
  return fetch({
    url: GDDR_PROC,
    method: 'post',
    headers: {
      'public-request': 'true',
    },
    data: {
      sOpe: '11-1',
      Data: data,
    },
  });
};

export default DemandeDeDroitsService;
