import moment from 'moment';
import Utils from 'configs/UtilFunctions';

// send our provider model to MDS API provider
export const convertActionToMdsAction = (action, idAction = null) => {
  /*
  action.beginDate =
    action.beginDate instanceof moment
      ? action.beginDate.format('YYYY-MM-DD')
      : action.beginDate; // 2016-07-15
  action.endDate =
    action.endDate instanceof moment
      ? action.endDate.format('YYYY-MM-DD')
      : action.endDate; // 2016-07-15

  action.beginDatePl =
    action.beginDatePl instanceof moment
      ? action.beginDatePl.format('YYYY-MM-DD')
      : action.beginDatePl; // 2016-07-15
  action.endDatePl =
    action.endDatePl instanceof moment
      ? action.endDatePl.format('YYYY-MM-DD')
      : action.endDatePl; // 2016-07-15

  action.prDate =
    action.prDate instanceof moment
      ? action.prDate.format('YYYY-MM-DD')
      : action.prDate; // 2016-07-15
  action.vlDate =
    action.vlDate instanceof moment
      ? action.vlDate.format('YYYY-MM-DD')
      : action.vlDate; // 2016-07-15
  */

  return {
    nIDPlan_Act: idAction,
    nIDAct: action.idScenario,
    nIDOrg: action.idorga,
    nIDTrait: action.idtrait,
    nIDIncident: action.idinc,
    nIDAnaImpact: action.idimpact,
    nIDVulnExploit: action.idvuln,
    nIDInterl: action.idActor,
    sConstat: action.constat,
    sNomAction: action.actionName,
    sComment: action.comment,
    sStatut: action.statut,
    dDateDEB: action.beginDate ? action.beginDate : '',
    dDateFIN: action.endDate ? action.endDate : '',
    dDateDebPL: action.beginDatePl ? action.beginDatePl : '',
    dDateFinPL: action.endDatePl ? action.endDatePl : '',
    dDatePR: action.prDate ? action.prDate : '',
    dDateVL: action.vlDate ? action.vlDate : '',
    sNomTrait: action.nametrait,
    nCoutEstime: action.coutEstime,
    nPriorite: action.priorite,
  };
};
// ITEM SUCCESS
export const convertMdsActionDetailInAction = (mdsActions) => {
  /*
  mdsActions.dDateDEB = mdsActions.dDateDEB
    ? Utils.convertItemDateToMoment(mdsActions.dDateDEB)
    : '';
  mdsActions.dDateFIN = mdsActions.dDateFIN
    ? Utils.convertItemDateToMoment(mdsActions.dDateFIN)
    : '';
  mdsActions.dDateDebPL = mdsActions.dDateDebPL
    ? Utils.convertItemDateToMoment(mdsActions.dDateDebPL)
    : '';
  mdsActions.dDateFinPL = mdsActions.dDateFinPL
    ? Utils.convertItemDateToMoment(mdsActions.dDateFinPL)
    : '';
  mdsActions.dDatePR = mdsActions.dDatePR
    ? Utils.convertItemDateToMoment(mdsActions.dDatePR)
    : '';
  mdsActions.dDateVL = mdsActions.dDateVL
    ? Utils.convertItemDateToMoment(mdsActions.dDateVL)
    : '';
  */
  return {
    key: mdsActions.nIDPlan_Act,
    idAction: mdsActions.nIDPlan_Act,
    idScenario: mdsActions.nIDAct,
    idorga: mdsActions.nIDOrg,
    idtrait: mdsActions.nIDTrait,
    idinc: mdsActions.nIDIncident,
    idimpact: mdsActions.nIDAnaImpact,
    idvuln: mdsActions.nIDVulnExploit,
    idActor: mdsActions.nIDInterl,
    constat: mdsActions.sConstat,
    actionName: mdsActions.sNomAction,
    comment: mdsActions.sComment,
    statut: mdsActions.sStatut,
    idstatut: mdsActions.sStatutVal,
    beginDate: mdsActions.dDateDEB,
    endDate: mdsActions.dDateFIN,
    beginDatePl: mdsActions.dDateDebPL,
    endDatePl: mdsActions.dDateFinPL,
    prDate: mdsActions.dDatePR,
    vlDate: mdsActions.dDateVL,
    nameTrait: mdsActions.sNomTrait,
    coutEstime: mdsActions.nCoutEstime,
    priorite: mdsActions.nPriorite,
  };
};

export const convertMdsActionInAction = (mdsActions) => {
  switch (mdsActions.sStatutVal) {
    case 0:
      mdsActions.sStatutVal = '';
      break;
    case 1:
      mdsActions.sStatutVal = 'Proposée';
      break;
    case 2:
      mdsActions.sStatutVal = 'Planifiée';
      break;
    case 3:
      mdsActions.sStatutVal = 'Validée';
      break;
    case 4:
      mdsActions.sStatutVal = 'Réalisée';
      break;
    default:
      mdsActions.sStatutVal = '';
      break;
  }
  /*
  // Set The Begin Date as a Moment Object
  mdsActions.dDateDEB = mdsActions.dDateDEB
    ? Utils.convertItemDateToMoment(mdsActions.dDateDEB)
    : '';
  mdsActions.dDateFIN = mdsActions.dDateFIN
    ? Utils.convertItemDateToMoment(mdsActions.dDateFIN)
    : '';
  mdsActions.dDateDebPL = mdsActions.dDateDebPL
    ? Utils.convertItemDateToMoment(mdsActions.dDateDebPL)
    : '';
  mdsActions.dDateFinPL = mdsActions.dDateFinPL
    ? Utils.convertItemDateToMoment(mdsActions.dDateFinPL)
    : '';
  mdsActions.dDatePR = mdsActions.dDatePR
    ? Utils.convertItemDateToMoment(mdsActions.dDatePR)
    : '';
  mdsActions.dDateVL = mdsActions.dDateVL
    ? Utils.convertItemDateToMoment(mdsActions.dDateVL)
    : '';
  */
  return {
    key: mdsActions.nIDPlan_Act,
    idAction: mdsActions.nIDPlan_Act,
    idActor: mdsActions.nIDInterl,
    idScenario: mdsActions.nIDAct,
    idOrga: mdsActions.nIDOrg,
    constat: mdsActions.sConstat,
    actionName: mdsActions.sNomAction,
    comment: mdsActions.sComment,
    beginDate: mdsActions.dDateDEB ?? "",
    endDate: mdsActions.dDateFIN ?? "",
    beginDatePl: mdsActions.dDateDebPL ?? "",
    endDatePl: mdsActions.dDateFinPL ?? "",
    prDate: mdsActions.dDatePR ?? "",
    vlDate: mdsActions.dDateVL ?? "",
    statuVal: mdsActions.sStatutVal,
    statut: mdsActions.sStatut,
    nameTrait: mdsActions.sNomTrait,
    coutEstime: mdsActions.nCoutEstime,
    priorite: mdsActions.nPriorite,
  };
};

export const convertMdsActionListInActionsList = (mdsActions) => {
  const dataArray = [];
  if (typeof mdsActions === 'object' && mdsActions !== null) {
    mdsActions.forEach((element) => {
      dataArray.push(convertMdsActionInAction(element));
    });
  }
  return dataArray;
};

export const convertActionsListInMdsActionsList = (mdsActions) => {
  const dataArray = [];
  mdsActions.forEach((element) => {
    // idAction
    dataArray.push(convertActionToMdsAction(element, element.idAction));
  });
  return dataArray;
};

export const convertScenarioActionListItems = (mdsActions) => {
  return {
    key: mdsActions.nIDSceAction,
    idAction: mdsActions.nIDSceAction,
    idScenario: mdsActions.nIDScenario,
    value: mdsActions.sAction,
    comment: mdsActions.sCommentaire,
  };
};

export const convertAllScenarioListItems = (mdsActions, actionArray) => {
  return {
    key: mdsActions.nIDScenario,
    idScenario: mdsActions.nIDScenario,
    value: mdsActions.sScenario,
    actions: actionArray,
  };
};

// Put Action inside of Scenario directly
export const convertAllScenarioList = (mdsActions) => {
  const dataArray = [];

  mdsActions.tab_Scenario.forEach((element) => {
    const actionArray = [];

    mdsActions.tab_SceAction.forEach((elementAct) => {
      if (element.nIDScenario === elementAct.nIDScenario) {
        actionArray.push(convertScenarioActionListItems(elementAct));
      }
    });

    dataArray.push(convertAllScenarioListItems(element, actionArray));
  });

  return dataArray;
};
