// OrgaInterceptor
import axios from 'axios';
import { API_BASE_URL, API_PROXY_URL } from 'configs/AppConfig';
import { AUTH_MAIL_USER, AUTH_USER } from 'store/constants/Auth';
import { notification } from 'antd';
import Utils from 'configs/UtilFunctions';
import axiosRetry from 'axios-retry';


import history from '../../history';

const service = axios.create({
  // baseURL: API_PROXY_URL + API_BASE_URL,
  baseURL: API_PROXY_URL + API_BASE_URL,
  timeout: 60000,
});
axiosRetry(service, { retries: 3 });

// Config
const ENTRY_ROUTE = '/auth/login';
const PUBLIC_REQUEST_KEY = 'public-request';
const S_CLE = '0-0';
const S_OPE = '99';

// API Request interceptor
service.interceptors.request.use(
  (config) => {
    const nTaille = JSON.stringify(config.data).length
    config.data = {
        sCle: localStorage.getItem(AUTH_MAIL_USER) ? JSON.parse(localStorage.getItem(AUTH_MAIL_USER)).keyMail : JSON.parse(localStorage.getItem(AUTH_USER)).token,
        sOpe: "2",
        nTaille: nTaille === 0 ? 2 : nTaille,
        Data: config.data,
        VersonSite: 2
      };

    // config.headers['Access-Control-Allow-Origin'] = '*';
    // config.headers['Access-Control-Allow-Headers'] = 'Origin, X-Requested-With, Content-Type, Accept';
    config.headers['Content-Type'] = 'application/json';

    if (!config.headers[PUBLIC_REQUEST_KEY]) {
      history.push(ENTRY_ROUTE);
      window.location.reload();
    }
    return config;
  },
  (error) => {
    // Do something with request error here
    notification.error({
      message: 'Error',
    });
    Promise.reject(error);
  },
);

// API response interceptor
service.interceptors.response.use(
  (response) => {
    response.data.message = response.data.sMsg;
    return response.data;
  },
  (error) => {
    const notificationParam = {
      message: '',
    };

    // Remove token and redirect
    if (error.response.status === 400 && error.response.data.CodeErreur === 203002) {
      notificationParam.message = 'Login ou mot de passe inconnu';
      notificationParam.description = '';
      localStorage.removeItem(AUTH_USER);
    }

    if (error.response.status === 400 && error.response.data.CodeErreur === 999999) {
      notificationParam.message = "Vous avez une ancienne version du site dans le cache de votre navigateur, veuiller appuyez sur F5 pour l'actualiser";
      notificationParam.description = '';
      // localStorage.removeItem(AUTH_USER);
    }

    if (error.response.status === 404) {
      notificationParam.message = 'Not Found';
    }

    if (error.response.status === 500) {
      notificationParam.message = 'Internal Server Error';
    }

    if (error.response.status === 508) {
      notificationParam.message = 'Time Out';
    }

    notification.error(notificationParam);

    return Promise.reject(error);
  },
);

export default service;
