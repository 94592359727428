import { all, takeEvery, put, fork, call } from 'redux-saga/effects';

import ProcessService from 'services/ProcessService';

import {
  addProcessItemError,
  addProcessItemSuccess,
  getProcessItemError,
  getProcessItemSuccess,
  updateProcessItemError,
  updateProcessItemSuccess,
  getProcessListError,
  getProcessListSuccess,
  disableProcessItemError,
  disableProcessItemSuccess,
} from '../actions/Process';

import {
  GET_PROCESS_LIST,
  GET_PROCESS_ITEM,
  UPDATE_PROCESS_ITEM,
  ADD_PROCESS_ITEM,
  DISABLE_PROCESS_ITEM,
} from '../constants/Process';

export function* getProcessList() {
  yield takeEvery(GET_PROCESS_LIST, function* ({ data }) {
    try {
      const response = yield call(ProcessService.getProcessList, data);
      yield put(getProcessListSuccess(response.Data));
    } catch (err) {
      yield put(getProcessListError(err));
    }
  });
}
export function* getProcessItem() {
  yield takeEvery(GET_PROCESS_ITEM, function* ({ data }) {
    try {
      const response = yield call(ProcessService.getProcessItem, data);
      yield put(getProcessItemSuccess(response.Data));
    } catch (err) {
      yield put(getProcessItemError(err));
    }
  });
}

export function* updateProcessItem() {
  yield takeEvery(UPDATE_PROCESS_ITEM, function* ({ data }) {
    try {
      yield call(ProcessService.updateProcessItem, data);
      yield put(updateProcessItemSuccess(data));
    } catch (err) {
      yield put(updateProcessItemError(err));
    }
  });
}

export function* addProcessItem() {
  yield takeEvery(ADD_PROCESS_ITEM, function* ({ data }) {
    try {
      const response = yield call(ProcessService.createProcessItem, data);
      yield put(addProcessItemSuccess(response.Data));
    } catch (err) {
      yield put(addProcessItemError(err));
    }
  });
}

export function* disableProcessItem() {
  yield takeEvery(DISABLE_PROCESS_ITEM, function* ({ data }) {
    try {
      yield call(ProcessService.disableProcessItem, data);
      yield put(disableProcessItemSuccess(data));
    } catch (err) {
      yield put(disableProcessItemError(err));
    }
  });
}

export default function* rootSaga() {
  yield all([
    fork(getProcessList),
    fork(getProcessItem),
    fork(addProcessItem),
    fork(updateProcessItem),
    fork(disableProcessItem),
  ]);
}
