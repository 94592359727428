import {
  ADD_ORGANISATION_ITEM,
  ADD_ORGANISATION_ITEM_SUCCESS,
  ADD_ORGANISATION_ITEM_ERROR,
  GET_ORGANISATION_LIST,
  GET_ORGANISATION_LIST_SUCCESS,
  GET_ORGANISATION_LIST_ERROR,
  GET_ORGANISATION_ITEM,
  GET_ORGANISATION_ITEM_SUCCESS,
  GET_ORGANISATION_ITEM_ERROR,
  UPDATE_ORGANISATION_ITEM,
  UPDATE_ORGANISATION_ITEM_SUCCESS,
  UPDATE_ORGANISATION_ITEM_ERROR,
  SHOW_LOADING,
  GET_ORGANISATION_ERROR,
  GET_ORGANISATION_TYPE_LIST,
  GET_ORGANISATION_TYPE_LIST_SUCCESS,
  GET_ORGANISATION_TYPE_LIST_ERROR,
  GET_ORGANISATION_INFOS_ERROR,
  GET_ORGANISATION_INFOS,
  GET_ORGANISATION_INFOS_SUCCESS,
} from '../constants/Organisation';

const initState = {
  isLoading: false,
  data: [],
  dataResp: {},
  dataShouldUpdate: true,
  itemShouldRedirect: false,
  orgaTypes: [],
  item: {},

};

const auth = (state = initState, action) => {
  switch (action.type) {
    case SHOW_LOADING: {
      return {
        ...state,
        isLoading: true,
      };
    }

    case ADD_ORGANISATION_ITEM:
    case GET_ORGANISATION_LIST:
    case UPDATE_ORGANISATION_ITEM:
    case GET_ORGANISATION_ITEM: {
      return {
        ...state,
        isLoading: true,
      };
    }

    case GET_ORGANISATION_TYPE_LIST: {
      return {
        ...state,
        isLoading: true,
      }
    }

    case GET_ORGANISATION_ITEM_SUCCESS:
      {
        return {
          ...state,
          item: action.item,
          dataShouldUpdate: true,
          isLoading: false,
        };
      }
    case GET_ORGANISATION_LIST_SUCCESS: {
      return {
        ...state,
        data: action.data,
        dataShouldUpdate: false,
        isLoading: false,
        itemShouldRedirect: false,
      };
    }
    case GET_ORGANISATION_TYPE_LIST_SUCCESS: {
      return {
        ...state,
        orgaTypes: action.data,
        dataShouldUpdate: false,
        isLoading: false,
      };
    }
    case UPDATE_ORGANISATION_ITEM_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        dataShouldUpdate: true,
        item: action.item,
      };
    }

    case ADD_ORGANISATION_ITEM_SUCCESS: {
      return {
        ...state,
        item: action.item,
        dataShouldUpdate: true,
        isLoading: false,
      };
    }

    case GET_ORGANISATION_INFOS:
      return {
        ...state,
        isLoading: true,
      };
    case GET_ORGANISATION_INFOS_SUCCESS:
      return {
        ...state,
        dataResp: action.item,
        isLoading: false,
      };

    case ADD_ORGANISATION_ITEM_ERROR:
    case GET_ORGANISATION_ERROR:
    case GET_ORGANISATION_LIST_ERROR:
    case GET_ORGANISATION_TYPE_LIST_ERROR:
    case UPDATE_ORGANISATION_ITEM_ERROR:
    case GET_ORGANISATION_INFOS_ERROR:
      {
        return {
          ...state,
          itemShouldRedirect: true,
          isLoading: false,
        };
      }

    default:
      return state;
  }
};

export default auth;
