import { all, takeEvery, put, fork, call } from 'redux-saga/effects';

import DocumentModelService from 'services/DocumentModelService';
import {
  GET_DOCUMENT_MODEL_LIST,
} from '../constants/DocumentModel';

import {
  getDocumentModelListSuccess,
  getDocumentModelListError,
} from '../actions/DocumentModel';

export function* getDocumentModelList() {
  yield takeEvery(GET_DOCUMENT_MODEL_LIST, function* ({ data }) {
    try {
      const response = yield call(DocumentModelService.getDocumentModel, data);
      yield put(getDocumentModelListSuccess(response.Data));
    } catch (err) {
      yield put(getDocumentModelListError(err));
    }
  });
}

export default function* rootSaga() {
  yield all([
    fork(getDocumentModelList)
  ]);
}
