export const ADD_TODOLIST_ITEM = 'ADD_TODOLIST_ITEM';
export const ADD_TODOLIST_ITEM_SUCCESS = 'ADD_TODOLIST_ITEM_SUCCESS';
export const ADD_TODOLIST_ITEM_ERROR = 'ADD_TODOLIST_ITEM_ERROR';
export const GET_TODOLIST_LIST = 'GET_TODOLIST_LIST';
export const GET_TODOLIST_LIST_SUCCESS = 'GET_TODOLIST_LIST_SUCCESS';
export const GET_TODOLIST_LIST_ERROR = 'GET_TODOLIST_LIST_ERROR';
export const GET_TODOLIST_ITEM = 'GET_TODOLIST_ITEM';
export const GET_TODOLIST_ITEM_SUCCESS = 'GET_TODOLIST_ITEM_SUCCESS';
export const GET_TODOLIST_ITEM_ERROR = 'GET_TODOLIST_ITEM_ERROR';
export const UPDATE_TODOLIST_ITEM = 'UPDATE_TODOLIST_ITEM';
export const UPDATE_TODOLIST_ITEM_SUCCESS = 'UPDATE_TODOLIST_ITEM_SUCCESS';
export const UPDATE_TODOLIST_ITEM_ERROR = 'UPDATE_TODOLIST_ITEM_ERROR';
export const DISABLE_TODOLIST_ITEM = 'DISABLE_TODOLIST_ITEM';
export const DISABLE_TODOLIST_ITEM_SUCCESS = 'DISABLE_TODOLIST_ITEM_SUCCESS';
export const DISABLE_TODOLIST_ITEM_ERROR = 'DISABLE_TODOLIST_ITEM_ERROR';
export const RESET_LAST_ITEM_ADDED = 'RESET_LAST_ITEM_ADDED';
export const RESET_LAST_ITEM_EDITED = 'RESET_LAST_ITEM_EDITED';

export const CLOSE_TODOLIST_ITEM = 'CLOSE_TODOLIST_ITEM';
export const CLOSE_TODOLIST_ITEM_SUCCESS = 'CLOSE_TODOLIST_ITEM_SUCCESS';
export const CLOSE_TODOLIST_ITEM_ERROR = 'CLOSE_TODOLIST_ITEM_ERROR';

export const SHOW_LOADING = 'SHOW_TODOLIST_LOADING';
