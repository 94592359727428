
// send our organisation model to MDS API organisation
export const mdsDocumentListToDocument = (mdsDocument) => {
    return mdsDocument[0];
}

export const mdsDocumentListToDocumentList = (mdsDocumentList) => {

    const dataArray = [];
    mdsDocumentList.forEach(element => {
        dataArray.push(mdsDocumentItemToDocumentItem(element))
    });
    return dataArray;

};

export const mdsDocumentItemToDocumentItem = (mdsDocument) => {

    return {
        dateEnreg: mdsDocument.dDateEnreg,
        dateModif: mdsDocument.dDateModif,
        idAuteur: mdsDocument.nIDAuteur,
        idContrat: mdsDocument.nIDContrat,
        idInc: mdsDocument.nIDInc,
        idNumRapport: mdsDocument.nIDNumRapport,
        idPIA: mdsDocument.nIDPIA,
        idPresta: mdsDocument.nIDPresta,
        idRapport: mdsDocument.nIDRapport,
        idTRait: mdsDocument.nIDTRait,
        cheminCompletFichier: mdsDocument.sCheminCompletFichier,
        commentaire: mdsDocument.sCommentaire,
        commentaireDev: mdsDocument.sCommentaireDev,
        format: mdsDocument.sFormat,
        hashRapport: mdsDocument.sHashRapport,
        idSign: mdsDocument.sIdentSign,
        motRech: mdsDocument.sMotRech,
        nomRapp: mdsDocument.sNomRapp,
        rapport: mdsDocument.sRapport,
        signRapport: mdsDocument.sSignRapport,
        statut: mdsDocument.sStatut,
        typeDoc: mdsDocument.sTypeDoc,
        version: mdsDocument.sVersion,
    };
}

export const documentItemToMdsDocumentItem = (document) => {
    return {
        dDateEnreg: document.dateEnreg,
        dDateModif: document.dateModif,
        nIDAuteur: document.idAuteur,
        nIDContrat: document.idContrat,
        nIDInc: document.idInc,
        nIDNumRapport: document.idNumRapport,
        nIDPIA: document.idPIA,
        nIDPresta: document.idPresta,
        nIDRapport: document.idRapport,
        nIDTRait: document.idTRait,
        sCheminCompletFichier: document.cheminCompletFichier,
        sCommentaire: document.commentaire,
        sCommentaireDev: document.commentaireDev,
        sFormat: document.format,
        sHashRapport: document.hashRapport,
        sIdentSign: document.idSign,
        sMotRech: document.motRech,
        sNomRapp: document.nomRapp,
        sRapport: document.rapport,
        sSignRapport: document.signRapport,
        sStatut: document.statut,
        sTypeDoc: document.typeDoc,
        sVersion: document.version,
    };
}

