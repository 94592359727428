// UsersManagement
import { all, takeEvery, put, fork, call } from 'redux-saga/effects';

import UsersManagementService from 'services/UsersManagementService';

import {
  addUsersManagementItemError,
  addUsersManagementItemSuccess,
  getUsersManagementItemError,
  getUsersManagementItemSuccess,
  updateUsersManagementItemError,
  updateUsersManagementItemSuccess,
  getUsersManagementListError,
  getUsersManagementListSuccess,
} from '../actions/UsersManagement';

import {
  GET_USER_MANAGEMENT_LIST,
  GET_USER_MANAGEMENT_ITEM,
  UPDATE_USER_MANAGEMENT_ITEM,
  ADD_USER_MANAGEMENT_ITEM,
} from '../constants/UsersManagement';

export function* getUsersManagementList() {
  yield takeEvery(GET_USER_MANAGEMENT_LIST, function* ({ data }) {
    try {
      const response = yield call(
        UsersManagementService.getUsersManagementList,
        data,
      );
      yield put(getUsersManagementListSuccess(response.Data));
    } catch (err) {
      yield put(getUsersManagementListError(err));
    }
  });
}
export function* getUsersManagementItem() {
  yield takeEvery(GET_USER_MANAGEMENT_ITEM, function* ({ data }) {
    try {
      const response = yield call(
        UsersManagementService.getUsersManagementItem,
        data,
      );
      yield put(getUsersManagementItemSuccess(response.Data));
    } catch (err) {
      yield put(getUsersManagementItemError(err));
    }
  });
}

export function* updateUsersManagementItem() {
  yield takeEvery(UPDATE_USER_MANAGEMENT_ITEM, function* ({ data }) {
    try {
      yield call(UsersManagementService.updateUsersManagementItem, data);
      yield put(updateUsersManagementItemSuccess(data));
    } catch (err) {
      yield put(updateUsersManagementItemError(err));
    }
  });
}

export function* addUsersManagementItem() {
  yield takeEvery(ADD_USER_MANAGEMENT_ITEM, function* ({ data }) {
    try {
      const response = yield call(
        UsersManagementService.addUsersManagementItem,
        data,
      );
      yield put(addUsersManagementItemSuccess(response.Data));
    } catch (err) {
      yield put(addUsersManagementItemError(err));
    }
  });
}

export default function* rootSaga() {
  yield all([
    fork(getUsersManagementList),
    fork(getUsersManagementItem),
    fork(addUsersManagementItem),
    fork(updateUsersManagementItem),
  ]);
}
