import { LOADING_ITEM, LOADING_LIST } from 'configs/AppConfig';
import {
  RECORD_DOCUMENT_REG_LAW_ITEM,
  RECORD_DOCUMENT_REG_LAW_ITEM_SUCCESS,
  RECORD_DOCUMENT_REG_LAW_ITEM_ERROR,
  LIST_DOCUMENT_REG_LAW,
  LIST_DOCUMENT_REG_LAW_SUCCESS,
  LIST_DOCUMENT_REG_LAW_ERROR,
  GET_DOCUMENT_REG_LAW,
  GET_DOCUMENT_REG_LAW_SUCCESS,
  GET_DOCUMENT_REG_LAW_ERROR,
  SHOW_LOADING,
} from '../constants/DocManRegLawReq';


const initState = {
  isLoading: false,
  data: [],
  item: {},
  needRefreshData: true,
  file: null,
  typeRapport: [{
    key: 1,
    value: 'Facture',
  }, {
    key: 2,
    value: 'Document commercial',
  }, {
    key: 3,
    value: 'Document juridique',
  }, {
    key: 4,
    value: 'Document social (bul. paie, etc)',
  }, {
    key: 5,
    value: 'Rapport',
  }, {
    key: 6,
    value: 'Pièce justificative',
  }, {
    key: 7,
    value: 'Autre',
  }]
};

const auth = (state = initState, action) => {
  switch (action.type) {
    case SHOW_LOADING: {
      return {
        ...state,
        isLoading: true,
      };
    }
    case LIST_DOCUMENT_REG_LAW: {
      return {
        ...state,
        data: [],
        isLoading: LOADING_LIST,
        needRefreshData: false,
      };
    }
    case RECORD_DOCUMENT_REG_LAW_ITEM: {
      return { ...state, isLoading: true }
    }
    case GET_DOCUMENT_REG_LAW: {
      return {
        ...state,
        isLoading: LOADING_ITEM,
      };
    }

    case RECORD_DOCUMENT_REG_LAW_ITEM_ERROR:
    case LIST_DOCUMENT_REG_LAW_ERROR:
    case GET_DOCUMENT_REG_LAW_ERROR: {
      return {
        ...state,
        isLoading: false,
      };
    }

    case RECORD_DOCUMENT_REG_LAW_ITEM_SUCCESS: {
      return {
        ...state,
        item: action.item,
        needRefreshData: true,
        isLoading: false,
      };
    }

    case LIST_DOCUMENT_REG_LAW_SUCCESS: {
      return {
        ...state,
        data: action.data,
        needRefreshData: false,
        isLoading: false,
      };
    }
    case GET_DOCUMENT_REG_LAW_SUCCESS: {
      return {
        ...state,
        item: action.item,
        isLoading: false,
      };
    }
    default:
      return state;
  }
};

export default auth;
