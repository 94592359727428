import { all, takeEvery, put, fork, call } from 'redux-saga/effects';
import PiaService from 'services/PiaService';
import {
  createPIAError,
  createPIASuccess,
  deletePIAError,
  deletePIASuccess,
  getPIAError,
  getPIAListError,
  getPIAListSuccess,
  getPIASuccess,
  getBasePIASuccess,
  getBasePIAError,
  createTraitPIASuccess,
  createTraitPIAError,
  updatePIAItemSuccess,
  updatePIAItemError
} from 'store/actions/Pia';
import { ADD_PIA, DELETE_PIA, GET_PIA, GET_PIA_LIST, GET_BASE_PIA, ADD_TRAIT_PIA, UPDATE_PIA_ITEM } from 'store/constants/Pia';

export function* getPIAList() {
  yield takeEvery(GET_PIA_LIST, function* ({ data }) {
    try {
      const response = yield call(PiaService.getPIAList, data);
      yield put(getPIAListSuccess(response.Data));
    } catch (err) {
      yield put(getPIAListError(err));
    }
  });
}
export function* getPIA() {
  yield takeEvery(GET_PIA, function* ({ data }) {
    try {
      const response = yield call(PiaService.getPIA, data);
      // console.log('Get organisation width data : ', data, ' and response ', response);
      yield put(getPIASuccess(response.Data));
    } catch (err) {
      // console.log(err);
      yield put(getPIAError(err));
    }
  });
}

export function* createTraitPIA() {
  yield takeEvery(ADD_TRAIT_PIA, function* ({ data }) {
    try {
      const response = yield call(PiaService.addTraitPIA, data);
      yield put(createTraitPIASuccess(response.Data));
    } catch (err) {
      yield put(createTraitPIAError(err));
    }
  });
}

export function* updatePIAItem() {
  yield takeEvery(UPDATE_PIA_ITEM, function* ({ data }) {
    try {
      yield call(PiaService.updatePIAItem, data);
      yield put(updatePIAItemSuccess(data));
    } catch (err) {
      yield put(updatePIAItemError(err));
    }
  });
}

export function* getBasePIA() {
  yield takeEvery(GET_BASE_PIA, function* ({ data }) {
    try {
      const response = yield call(PiaService.getBasePIA, data);
      yield put(getBasePIASuccess(response.Data));
    } catch (err) {
      console.log(err);
      yield put(getBasePIAError(err));
    }
  });
}

export function* createPIA() {
  yield takeEvery(ADD_PIA, function* ({ data }) {
    try {
      const response = yield call(PiaService.addPIA, data);
      // console.log('Update organisation width data : ', data, 'and response ', response);
      yield put(createPIASuccess(response.Data));
    } catch (err) {
      // console.log(err);
      yield put(createPIAError(err));
    }
  });
}

export function* deletePIA() {
  yield takeEvery(DELETE_PIA, function* ({ data }) {
    try {
      const response = yield call(PiaService.deletePIA, data);
      // console.log('Update organisation width data : ', data, 'and response ', response);
      yield put(deletePIASuccess(response.Data));
    } catch (err) {
      // console.log(err);
      yield put(deletePIAError(err));
    }
  });
}

export default function* rootSaga() {
  yield all([
    fork(getPIA),
    fork(getBasePIA),
    fork(createPIA),
    fork(deletePIA),
    fork(getPIAList),
    fork(createTraitPIA),
    fork(updatePIAItem),
  ]);
}
