export const ADD_CONTRAT_FEAT_ITEM = 'ADD_CONTRAT_FEAT_ITEM';
export const ADD_CONTRAT_FEAT_ITEM_SUCCESS = 'ADD_CONTRAT_FEAT_ITEM_SUCCESS';
export const ADD_CONTRAT_FEAT_ITEM_ERROR = 'ADD_CONTRAT_FEAT_ITEM_ERROR';
export const GET_CONTRAT_FEAT_LIST = 'GET_CONTRAT_FEAT_LIST';
export const GET_CONTRAT_FEAT_LIST_SUCCESS = 'GET_CONTRAT_FEAT_LIST_SUCCESS';
export const GET_CONTRAT_FEAT_LIST_ERROR = 'GET_CONTRAT_FEAT_LIST_ERROR';
export const GET_CONTRAT_FEAT_ITEM = 'GET_CONTRAT_FEAT_ITEM';
export const GET_CONTRAT_FEAT_ITEM_SUCCESS = 'GET_CONTRAT_FEAT_ITEM_SUCCESS';
export const GET_CONTRAT_FEAT_ITEM_ERROR = 'GET_CONTRAT_FEAT_ITEM_ERROR';
export const UPDATE_CONTRAT_FEAT_ITEM = 'UPDATE_CONTRAT_FEAT_ITEM';
export const UPDATE_CONTRAT_FEAT_ITEM_SUCCESS = 'UPDATE_CONTRAT_FEAT_ITEM_SUCCESS';
export const UPDATE_CONTRAT_FEAT_ITEM_ERROR = 'UPDATE_CONTRAT_FEAT_ITEM_ERROR';
export const DELETE_CONTRAT_FEAT_ITEM = 'DELETE_CONTRAT_FEAT_ITEM';
export const DELETE_CONTRAT_FEAT_ITEM_SUCCESS = 'DELETE_CONTRAT_FEAT_ITEM_SUCCESS';
export const DELETE_CONTRAT_FEAT_ITEM_ERROR = 'DELETE_CONTRAT_FEAT_ITEM_ERROR';
export const DISABLE_CONTRAT_FEAT_ITEM = 'DISABLE_CONTRAT_FEAT_ITEM';
export const DISABLE_CONTRAT_FEAT_ITEM_SUCCESS = 'DISABLE_CONTRAT_FEAT_ITEM_SUCCESS';
export const DISABLE_CONTRAT_FEAT_ITEM_ERROR = 'DISABLE_CONTRAT_FEAT_ITEM_ERROR';
export const GET_INFORMATIONS_CONTRAT_ITEM = 'GET_INFORMATIONS_CONTRAT_ITEM';
export const GET_INFORMATIONS_CONTRAT_ITEM_SUCCESS = 'GET_INFORMATIONS_CONTRAT_ITEM_SUCCESS';
export const GET_INFORMATIONS_CONTRAT_ITEM_ERROR = 'GET_INFORMATIONS_CONTRAT_ITEM_ERROR';
export const SHOW_CONTRAT_FEAT_LOADING = 'SHOW_CONTRAT_FEAT_LOADING';
export const GET_CONTRAT_FEAT_BY_PROVIDER_ITEM = 'GET_CONTRAT_FEAT_BY_PROVIDER_ITEM';
export const GET_CONTRAT_FEAT_BY_PROVIDER_ITEM_SUCCESS =
  'GET_CONTRAT_FEAT_BY_PROVIDER_ITEM_SUCCESS';
export const GET_CONTRAT_FEAT_BY_PROVIDER_ITEM_ERROR =
  'GET_CONTRAT_FEAT_BY_PROVIDER_ITEM_ERROR';
