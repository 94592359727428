// send our organisation model to MDS API organisation
export const mdsProcessHistoryListToProcessHistoryList = (mdsProcessHistoryList) => {

  const dataArray = [];
  mdsProcessHistoryList.forEach(element => {
    if (element.nIdHistoTrait != 0) {
      dataArray.push(mdsProcessHistoryListToProcessHistory(element))
    }
  });
  return dataArray;

};

export const mdsProcessHistoryListToProcessHistory = (mdsProcessHistory) => {
  return {
    idHisto: mdsProcessHistory.nIdHistoTrait,
    idTrait: mdsProcessHistory.nIdTrait,
    dateHisto: mdsProcessHistory.dhDateHeureHisto,
    name: mdsProcessHistory.sNomTraitement,
    raison: mdsProcessHistory.sMotifHisto,
    ref: mdsProcessHistory.sRef_Trait,
    version: mdsProcessHistory.sVer_Trait,
  };
}

