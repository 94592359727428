import fetch from 'services/Interceptors/MdsInterceptor';
import { API_SCLE_READ, API_SCLE_ADD, API_SCLE_DELETE, API_SCLE_EDIT } from 'configs/AppConfig';

const PiaService = {};
const listPIA = 'pia_llpia';
const gestionPIA = 'pia_gpia';

PiaService.getPIAList = (data) => {
  return fetch({
    url: listPIA,
    method: 'post',
    headers: {
      'public-request': 'true',
    },
    data: {
      sOpe: 1,
      Data: {},
    },
  });
};

PiaService.getPIA = (data) => {
  return fetch({
    url: gestionPIA,
    method: 'post',
    headers: {
      'public-request': 'true',
    },
    data: {
      sOpe: API_SCLE_READ,
      Data: data,
    },
  });
};

PiaService.addTraitPIA = (data) => {
  return fetch({
    url: gestionPIA,
    method: 'post',
    headers: {
      'public-request': 'true',
    },
    data: {
      sOpe: 20,
      Data: data,
    },
  });
};

PiaService.getBasePIA = (data) => {
  return fetch({
    url: gestionPIA,
    method: 'post',
    headers: {
      'public-request': 'true',
    },
    data: {
      sOpe: 11,
      Data: data,
    },
  });
};

PiaService.addPIA = (data) => {
  return fetch({
    url: `/${gestionPIA}`,
    method: 'post',
    headers: {
      'public-request': 'true',
    },
    data: {
      sOpe: API_SCLE_ADD,
      Data: data,
    },
  });
};

PiaService.updatePIAItem = (data) => {
  return fetch({
    url: gestionPIA,
    method: 'post',
    headers: {
      'public-request': 'true',
    },
    data: {
      sOpe: API_SCLE_EDIT,
      Data: data,
    },
  });
};

PiaService.deletePIA = (data) => {
  return fetch({
    url: `/${gestionPIA}`,
    method: 'post',
    headers: {
      'public-request': 'true',
    },
    data: {
      sOpe: API_SCLE_DELETE,
      Data: data,
    },
  });
};

export default PiaService;
