import {
  ADD_RACI_ITEM,
  ADD_RACI_ITEM_SUCCESS,
  ADD_RACI_ITEM_ERROR,
  GET_RACI_LIST,
  GET_RACI_LIST_SUCCESS,
  GET_RACI_LIST_ERROR,
  GET_RACI_ITEM,
  GET_RACI_ITEM_SUCCESS,
  GET_RACI_ITEM_ERROR,
  CLIC_BTN_RACI,
  CLIC_BTN_RACI_SUCCESS,
  CLIC_BTN_RACI_ERROR,
  SHOW_LOADING,
} from '../constants/Raci';

const initState = {
  isLoading: false,
  isUpdated: false,
  data: [],
  dataShouldUpdate: false,
  item: {},
  addedItem: {},
  editedItem: {},
  history: [],
  clicBtnResp: {}
};

const auth = (state = initState, action) => {
  switch (action.type) {
    case SHOW_LOADING: {
      return {
        ...state,
        isLoading: true,
      };
    }
    case GET_RACI_ITEM: {
      return {
        ...state,
        item: initState.item,
        isLoading: true,
      };
    }
    case ADD_RACI_ITEM:
    case CLIC_BTN_RACI:
    case GET_RACI_LIST: {
      return {
        ...state,
        isLoading: true,
        isUpdated: false,
      };
    }
    case ADD_RACI_ITEM_ERROR:
    case GET_RACI_ITEM_ERROR:
    case CLIC_BTN_RACI_ERROR:
    case GET_RACI_LIST_ERROR: {
      return {
        ...state,
        isLoading: false,
      };
    }

    case ADD_RACI_ITEM_SUCCESS: {
      return {
        ...state,
        addedItem: action.data,
        // item: action.item,
        dataShouldUpdate: true,
        isLoading: false,
      };
    }
    case GET_RACI_LIST_SUCCESS: {
      return {
        ...state,
        data: action.data,
        dataShouldUpdate: false,
        isLoading: false,
      };
    }
    case GET_RACI_ITEM_SUCCESS: {
      return {
        ...state,
        item: action.item,
        isLoading: false,
      };
    }
    case CLIC_BTN_RACI_SUCCESS: {
      console.log("RETOUR RACI REDUCER", action)
      return {
        ...state,
        clicBtnResp: action.item,
        isLoading: false,
      };
    }
    default:
      return state;
  }
};

export default auth;
