export const ADD_RACI_ITEM = 'ADD_RACI_ITEM';
export const ADD_RACI_ITEM_SUCCESS = 'ADD_RACI_ITEM_SUCCESS';
export const ADD_RACI_ITEM_ERROR = 'ADD_RACI_ITEM_ERROR';

export const GET_RACI_LIST = 'GET_RACI_LIST';
export const GET_RACI_LIST_SUCCESS = 'GET_RACI_LIST_SUCCESS';
export const GET_RACI_LIST_ERROR = 'GET_RACI_LIST_ERROR';

export const GET_RACI_ITEM = 'GET_RACI_ITEM';
export const GET_RACI_ITEM_SUCCESS = 'GET_RACI_ITEM_SUCCESS';
export const GET_RACI_ITEM_ERROR = 'GET_RACI_ITEM_ERROR';

export const CLIC_BTN_RACI = 'CLIC_BTN_RACI';
export const CLIC_BTN_RACI_SUCCESS = 'CLIC_BTN_RACI_SUCCESS';
export const CLIC_BTN_RACI_ERROR = 'CLIC_BTN_RACI_ERROR';

export const SHOW_LOADING = 'SHOW_RACI_LOADING';
