import {
  ADD_ACTORS_BOOK_ITEM,
  ADD_ACTORS_BOOK_ITEM_SUCCESS,
  ADD_ACTORS_BOOK_ITEM_ERROR,
  GET_ACTORS_BOOK_ITEM,
  GET_ACTORS_BOOK_ITEM_SUCCESS,
  GET_ACTORS_BOOK_ITEM_ERROR,
  GET_ACTORS_BOOK_LIST,
  GET_ACTORS_BOOK_LIST_SUCCESS,
  GET_ACTORS_BOOK_LIST_ERROR,
  UPDATE_ACTORS_BOOK_ITEM,
  UPDATE_ACTORS_BOOK_ITEM_SUCCESS,
  UPDATE_ACTORS_BOOK_ITEM_ERROR,
  SHOW_LOADING,
  GET_ACTORS_BOOK_WITH_AXE_LIST_SUCCESS,
  GET_ACTORS_BOOK_WITH_AXE_LIST,
  GET_ACTORS_BOOK_WITH_AXE_LIST_ERROR,
  GET_MISSION_ACTORS_BOOK_LIST,
  GET_MISSION_ACTORS_BOOK_LIST_ERROR,
  GET_MISSION_ACTORS_BOOK_LIST_SUCCESS,
  DELETE_ACTORS_BOOK,
  DELETE_ACTORS_BOOK_ITEM_SUCCESS,
  DELETE_ACTORS_BOOK_ERROR,
} from '../constants/ActorsBook';

const initState = {
  isLoading: false,
  dataShouldUpdate: false,
  redirect: '',
  data: [],
  item: {},
  actorsWithAxe: [],
  missions: []
}

const auth = (state = initState, action) => {
  switch (action.type) {
    case UPDATE_ACTORS_BOOK_ITEM_SUCCESS:
    case ADD_ACTORS_BOOK_ITEM_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        dataShouldUpdate: true,
        item: action.item
      }
    }
    case DELETE_ACTORS_BOOK_ITEM_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        dataShouldUpdate: true,
        item: action.item
      }
    }
    case GET_ACTORS_BOOK_LIST_SUCCESS: {
      return {
        ...state,
        data: action.data,
        isLoading: false,
        dataShouldUpdate: false
      }
    }
    case GET_ACTORS_BOOK_WITH_AXE_LIST_SUCCESS: {
      return {
        ...state,
        actorsWithAxe: action.data,
        isLoading: false,
        dataShouldUpdate: false
      }
    }
    case GET_MISSION_ACTORS_BOOK_LIST_SUCCESS: {
      return {
        ...state,
        missions: action.data,
        isLoading: false,
        dataShouldUpdate: false
      }
    }
    case GET_ACTORS_BOOK_ITEM_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        item: action.item,
      }
    }
    case ADD_ACTORS_BOOK_ITEM:
    case DELETE_ACTORS_BOOK:
    case GET_ACTORS_BOOK_LIST:
    case GET_ACTORS_BOOK_WITH_AXE_LIST:
    case GET_MISSION_ACTORS_BOOK_LIST:
    case GET_ACTORS_BOOK_ITEM:
    case UPDATE_ACTORS_BOOK_ITEM: {
      return {
        ...state,
        isLoading: true,
      }
    }
    case ADD_ACTORS_BOOK_ITEM_ERROR:
    case GET_ACTORS_BOOK_LIST_ERROR:
    case GET_ACTORS_BOOK_ITEM_ERROR:
    case DELETE_ACTORS_BOOK_ERROR:
    case GET_ACTORS_BOOK_WITH_AXE_LIST_ERROR:
    case GET_MISSION_ACTORS_BOOK_LIST_ERROR:
    case UPDATE_ACTORS_BOOK_ITEM_ERROR: {
      return {
        ...state,
        isLoading: false,
      }
    }
    case SHOW_LOADING: {
      return {
        ...state,
        isLoading: true,
      };
    }
    default:
      return state;
  }
}

export default auth
