import { all, takeEvery, put, fork, call } from 'redux-saga/effects';

import DocumentService from 'services/DocumentService';

import {
  addDocumentItemError,
  addDocumentItemSuccess,
  ddlDocumentItemError,
  ddlDocumentItemSuccess,
  getDocumentInfoError,
  getDocumentInfoSuccess,
  updateDocumentItemError,
  updateDocumentItemSuccess,
  getDocumentListError,
  getDocumentListSuccess,
} from '../actions/Document';

import {
  GET_DOCUMENT_LIST,
  GET_DOCUMENT_ITEM,
  DDL_DOCUMENT_ITEM,
  UPDATE_DOCUMENT_ITEM,
  ADD_DOCUMENT_ITEM,
} from '../constants/Document';

export function* getDocumentList() {
  yield takeEvery(GET_DOCUMENT_LIST, function* ({ data }) {
    try {
      const response = yield call(DocumentService.getDocumentList, data);
      yield put(getDocumentListSuccess(response.Data));
    } catch (err) {
      yield put(getDocumentListError(err));
    }
  });
}

export function* getDocumentInfo() {
  yield takeEvery(GET_DOCUMENT_ITEM, function* ({ data }) {
    try {
      const response = yield call(DocumentService.getDocumentInfo, data);
      yield put(getDocumentInfoSuccess(response.Data));
    } catch (err) {
      yield put(getDocumentInfoError(err));
    }
  });
}

export function* ddlDocumentItem() {
  yield takeEvery(DDL_DOCUMENT_ITEM, function* ({ data }) {
    try {
      const response = yield call(DocumentService.ddlDocumentItem, data);
      // console.log("ddlDocumentItem", response);
      // const resText = yield (response.text());
      // console.log(resText);
      yield put(ddlDocumentItemSuccess(response));
    } catch (err) {
      // console.log("error ddlDocumentItem", err)
      yield put(ddlDocumentItemError(err));
    }
  });
}

export function* updateDocumentItem() {
  yield takeEvery(UPDATE_DOCUMENT_ITEM, function* ({ data }) {
    try {
      const response = yield call(DocumentService.updateDocumentItem, data);
      yield put(updateDocumentItemSuccess(data));
    } catch (err) {
      yield put(updateDocumentItemError(err));
    }
  });
}

export function* addDocumentItem() {
  yield takeEvery(ADD_DOCUMENT_ITEM, function* ({ data }) {
    try {
      const response = yield call(DocumentService.createDocumentItem, data);
      yield put(addDocumentItemSuccess(response.Data));
    } catch (err) {
      yield put(addDocumentItemError(err));
    }
  });
}

export default function* rootSaga() {
  yield all([
    fork(getDocumentList),
    fork(getDocumentInfo),
    fork(ddlDocumentItem),
    fork(addDocumentItem),
    fork(updateDocumentItem),
  ]);
}
