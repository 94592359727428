import React, { useState, useEffect } from 'react';
import { Drawer } from 'antd';
import { connect } from 'react-redux';
import { NAV_TYPE_SIDE } from 'configs/constants/ThemeConstant';
import { Scrollbars } from 'react-custom-scrollbars';
import { onMobileNavToggle } from 'store/actions/Theme';
import Flex from 'components/Flex';
import { ArrowLeftOutlined } from '@ant-design/icons';
import Logo from './Logo';
import MenuContent from './MenuContent';

export const MobileNav = ({
  sideNavTheme,
  mobileNav,
  // onMobileNavToggle,
  routeInfo,
  hideGroupTitle,
  localization = true,
}) => {
  const props = { sideNavTheme, routeInfo, hideGroupTitle, localization };

  const onClose = () => {
    onMobileNavToggle(false);
    setToggleNav(false);
  };

  const [toggleNav, setToggleNav] = useState()

  useEffect(() => {
    setToggleNav(mobileNav)
  }, [mobileNav])

  return (
    <Drawer
      placement="left"
      closable={false}
      onClose={onClose}
      visible={toggleNav}
      // visible={visible}
      bodyStyle={{ padding: 5 }}
    >
      <Flex flexDirection="column" className="h-100">
        <Flex justifyContent="between" alignItems="center">
          <Logo mobileLogo />
          <div className="nav-close" onClick={onClose} onKeyDown={onClose} 
          style={{position: 'relative', right: 20}}>
            <ArrowLeftOutlined />
          </div>
        </Flex>
        <div className="mobile-nav-menu" style={{width: 260}}>
          <Scrollbars autoHide>
            <MenuContent type={NAV_TYPE_SIDE} {...props} />
          </Scrollbars>
        </div>
      </Flex>
    </Drawer>
  );
};

const mapStateToProps = ({ theme }) => {
  const { navCollapsed, sideNavTheme, mobileNav } = theme;
  return { navCollapsed, sideNavTheme, mobileNav };
};

export default connect(mapStateToProps, { onMobileNavToggle })(MobileNav);
