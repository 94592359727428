import {
  GET_EL_TABLE_ITEM,
  GET_EL_TABLE_ITEM_SUCCESS,
  GET_EL_TABLE_ITEM_ERROR,
  GET_EMPTY_STRUCT,
  GET_EMPTY_STRUCT_SUCCESS,
  GET_EMPTY_STRUCT_ERROR,
  GET_LIST_TABLES,
  GET_LIST_TABLES_SUCCESS,
  GET_LIST_TABLES_ERROR,
  GET_LIST_EL_TABLE,
  GET_LIST_EL_TABLE_SUCCESS,
  GET_LIST_EL_TABLE_ERROR,
  SAVE_NEW_REF_TABLE,
  SAVE_NEW_REF_TABLE_SUCCESS,
  SAVE_NEW_REF_TABLE_ERROR,
  UPDATE_REF_TABLE,
  UPDATE_REF_TABLE_SUCCESS,
  UPDATE_REF_TABLE_ERROR,
  UPDATE_TOTAL_REF_TABLE,
  UPDATE_TOTAL_REF_TABLE_SUCCESS,
  UPDATE_TOTAL_REF_TABLE_ERROR,
  DELETE_REF_TABLE,
  DELETE_REF_TABLE_SUCCESS,
  DELETE_REF_TABLE_ERROR,
  SET_EL_TABLE,
  SHOW_LOADING
} from '../constants/OptRefManagement';


export const getElTableItem = (data) => {
  return {
    type: GET_EL_TABLE_ITEM,
    data,
  };
};
export const getElTableItemSuccess = (item) => {
  return {
    type: GET_EL_TABLE_ITEM_SUCCESS,
    item,
  };
};
export const getElTableItemError = (error) => {
  return {
    type: GET_EL_TABLE_ITEM_ERROR,
    ...error,
  };
};

export const getEmptyStruct = (data) => {
  return {
    type: GET_EMPTY_STRUCT,
    data,
  };
};
export const getEmptyStructSuccess = (item) => {
  return {
    type: GET_EMPTY_STRUCT_SUCCESS,
    item,
  };
};
export const getEmptyStructError = (error) => {
  return {
    type: GET_EMPTY_STRUCT_ERROR,
    ...error,
  };
};

export const getListTables = () => {
  return {
    type: GET_LIST_TABLES,
    data: {
      sC: 0,
      nN: 0,
    },
  };
};
export const getListTablesSuccess = (data) => {
  return {
    type: GET_LIST_TABLES_SUCCESS,
    data
  };
};
export const getListTablesError = (error) => {
  return {
    type: GET_LIST_TABLES_ERROR,
    ...error,
  };
};

export const selectElTable = (data) => {
  return {
    type: SET_EL_TABLE,
    data
  }
}

export const getListElTable = (data) => {
  return {
    type: GET_LIST_EL_TABLE,
    data,
  };
};
export const getListElTableSuccess = (data, code) => {
  return {
    type: GET_LIST_EL_TABLE_SUCCESS,
    data,
    code
  };
};
export const getListElTableError = (error) => {
  return {
    type: GET_LIST_EL_TABLE_ERROR,
    ...error,
  };
};

export const saveNewRefTable = (data) => {
  return {
    type: SAVE_NEW_REF_TABLE,
    data,
  };
};
export const saveNewRefTableSuccess = (item) => {
  return {
    type: SAVE_NEW_REF_TABLE_SUCCESS,
    message: 'La ligne a bien été ajouté',
    item,
  };
};
export const saveNewRefTableError = (error) => {
  return {
    type: SAVE_NEW_REF_TABLE_ERROR,
    ...error,
  };
};

export const updateRefTable = (item) => {
  return {
    type: UPDATE_REF_TABLE,
    data: item,
  };
};
export const updateRefTableSuccess = (item) => {
  return {
    type: UPDATE_REF_TABLE_SUCCESS,
    message: 'Le traitement a bien été mise a jour',
    item
  };
};
export const updateRefTableError = (error) => {
  return {
    type: UPDATE_REF_TABLE_ERROR,
    ...error,
  };
};

export const updateTotalRefTable = (item) => {
  return {
    type: UPDATE_TOTAL_REF_TABLE,
    data: item,
  };
};
export const updateTotalRefTableSuccess = (item) => {
  return {
    type: UPDATE_TOTAL_REF_TABLE_SUCCESS,
    message: 'Le traitement a bien été mise a jour',
    item
  };
};
export const updateTotalRefTableError = (error) => {
  return {
    type: UPDATE_TOTAL_REF_TABLE_ERROR,
    ...error,
  };
};

export const deleteRefTable = (data) => {
  return {
    type: DELETE_REF_TABLE,
    data,
  };
};
export const deleteRefTableSuccess = (item) => {
  return {
    type: DELETE_REF_TABLE_SUCCESS,
    message: 'L\'etape a bien été supprimé',
    item,
  };
};
export const deleteRefTableError = (error) => {
  return {
    type: DELETE_REF_TABLE_ERROR,
    ...error
  };
};


