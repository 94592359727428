import { all, takeEvery, put, fork, call } from 'redux-saga/effects';
import AuthService from 'services/AuthService';
import Utils from 'configs/UtilFunctions'

import {
  AUTH_USER,
  SIGNIN,
  SIGNOUT,
  SIGNIN_MAIL,
  SIGNIN_ORGA,
  LIST_MULTI_ORGA,
  CONNEXION_MONO_COMPTE,
  CONNEXION_MULTI_COMPTE_SANS_MAITRE,
  CONNEXION_MULTI_COMPTE_AVEC_MAITRE,
  LEC_CHG_INFO_MDP,
  LEC_CHG_INFO_NOMMAIL,
  DEMANDE_MODIF_MDP,
  DEMANDE_MODIF_NOMMAIL,
  MODIF_MDP,
  MODIF_NOMMAIL,
  RECEVOIR_MAIL_CACHEE,
  RECEVOIR_PASS_TEMP
} from '../constants/Auth';
import {
  signInSuccess,
  signInError,
  signOut,
  signOutSuccess,
  signOutError,
  signInMailSuccess,
  signInMailError,
  signInOrgaSuccess,
  signInOrgaError,
  listMultiOrgaSuccess,
  listMultiOrgaError,
  connexMonoCompteSuccess,
  connexMonoCompteError,
  connexMultiCompteSansMaitreSuccess,
  connexMultiCompteSansMaitreError,
  connexMultiCompteAvecMaitreSuccess,
  connexMultiCompteAvecMaitreError,
  lecInfoChgMdpSuccess,
  lecInfoChgMdpError,
  lecInfoChgNomMailSuccess,
  lecInfoChgNomMailError,
  dmdModifMdpSuccess,
  dmdModifMdpError,
  dmdModifNomMailSuccess,
  dmdModifNomMailError,
  modifMdpSuccess,
  modifMdpError,
  modifNomMailSuccess,
  modifNomMailError,
  recevoirMailCacheeSuccess,
  recevoirMailCacheeError,
  recevoirPassTempSuccess,
  recevoirPassTempError
} from '../actions/Auth';

export function* listMultiOrga() {
  yield takeEvery(LIST_MULTI_ORGA, function* ({ data }) {
    try {
      const response = yield call(AuthService.listOrga, data);
      yield put(listMultiOrgaSuccess(response.Data));
    } catch (err) {
      // console.log("error getContratList", err)
      yield put(listMultiOrgaError(err));
    }
  });
}

export function* signInWithEmail() {
  yield takeEvery(SIGNIN, function* ({ data }) {
    try {
      const response = yield call(AuthService.login, data);
      // console.log('response login ', response)
      // const user = data;
      yield put(signInSuccess(response.Data, data));
    } catch (err) {
      yield put(signInError(err));
    }
  });
}

export function* signInEmail() {
  yield takeEvery(SIGNIN_MAIL, function* ({ data }) {
    try {
      const response = yield call(AuthService.loginMail, data);
      // console.log('response login ', response)
      // const user = data;
      yield put(signInMailSuccess(response.Data));
    } catch (err) {
      yield put(signInMailError(err));
    }
  });
}

export function* signInOrga() {
  yield takeEvery(SIGNIN_ORGA, function* ({ data }) {
    try {
      const response = yield call(AuthService.loginMail, data);
      // console.log('response login ', response)
      // const user = data;
      yield put(signInOrgaSuccess(response.Data));
    } catch (err) {
      yield put(signInOrgaError(err));
    }
  });
}

export function* signOutInStorage() {
  yield takeEvery(SIGNOUT, function* () {
    localStorage.removeItem(AUTH_USER);
    try {
      yield put(signOutSuccess());
    } catch (err) {
      yield put(signOutError(err));
    }
  });
}

function* handleConnexMonoCompte({ data }) {
  try {
    const response = yield call(AuthService.connexion, data);
    yield put(connexMonoCompteSuccess(response.Data));
  } catch (err) {
    yield put(connexMonoCompteError(err));
  }
}
export function* connexMonoCompteSaga() {
  yield takeEvery(CONNEXION_MONO_COMPTE, handleConnexMonoCompte);
}

export function* connexMultiCompteSansMaitre() {
  yield takeEvery(CONNEXION_MULTI_COMPTE_SANS_MAITRE, function* ({ data }) {
    const response = yield call(AuthService.connexion, data);
    try {
      yield put(connexMultiCompteSansMaitreSuccess(response.Data));
    } catch (err) {
      yield put(connexMultiCompteSansMaitreError(err));
    }
  });
}

export function* connexMultiCompteAvecMaitre() {
  yield takeEvery(CONNEXION_MULTI_COMPTE_AVEC_MAITRE, function* ({ data }) {
    const response = yield call(AuthService.connexion, data);
    try {
      yield put(connexMultiCompteAvecMaitreSuccess(response.Data));
    } catch (err) {
      yield put(connexMultiCompteAvecMaitreError(err));
    }
  });
}

function* handleLecInfoChgMdp({ data }) {
  try {
    const response = yield call(AuthService.lecInfoChgMdp, data);
    yield put(lecInfoChgMdpSuccess(response.Data));
  } catch (err) {
    yield put(lecInfoChgMdpError(err));
  }
}
export function* lecInfoChgMdpSaga() {
  yield takeEvery(LEC_CHG_INFO_MDP, handleLecInfoChgMdp);
}

export function* lecInfoChgNomMail() {
  yield takeEvery(LEC_CHG_INFO_NOMMAIL, function* ({ data }) {
    const response = yield call(AuthService.lecInfoChgNomMail, data);
    try {
      yield put(lecInfoChgNomMailSuccess(response.Data));
    } catch (err) {
      yield put(lecInfoChgNomMailError(err));
    }
  });
}

function* handleDmdModifMdp({ data }) {
  try {
    const response = yield call(AuthService.dmdModifMdp, data);
    yield put(dmdModifMdpSuccess(response.Data));
  } catch (err) {
    yield put(dmdModifMdpError(err));
  }
}
export function* dmdModifMdpSaga() {
  yield takeEvery(DEMANDE_MODIF_MDP, handleDmdModifMdp);
}

export function* dmdModifNomMail() {
  yield takeEvery(DEMANDE_MODIF_NOMMAIL, function* ({ data }) {
    const response = yield call(AuthService.dmdModifNomMail, data);
    try {
      yield put(dmdModifNomMailSuccess(response.Data));
    } catch (err) {
      yield put(dmdModifNomMailError(err));
    }
  });
}

function* handleModifMdp({ data }) {
  try {
    const response = yield call(AuthService.modifMdp, data);
    yield put(modifMdpSuccess(response.Data));
  } catch (err) {
    yield put(modifMdpError(err));
  }
}
export function* modifMdpSaga() {
  yield takeEvery(MODIF_MDP, handleModifMdp);
}

function* handleModifNomMail({ data }) {
  try {
    const response = yield call(AuthService.modifNomMail, data);
    yield put(modifNomMailSuccess(response.Data));
  } catch (err) {
    yield put(modifNomMailError(err));
  }
}
export function* modifNomMailSaga() {
  yield takeEvery(MODIF_NOMMAIL, handleModifNomMail);
}

export function* sessionKo() {
  if (Utils.getEnv('REACT_APP_ENV') !== 'noInternet') {
    yield takeEvery(action => action.type.endsWith('_ERROR'), function* ({ code }) {
      try {
        if (code === 100021 || code === 100024) {
          yield put(signOut())
        }
      } catch (err) {
        yield put(signOutError(err));
      }
    });
  }
}

function* handleRecevoirMailCachee({ data }) {
  try {
    const response = yield call(AuthService.demanderMailCachee, data);
    yield put(recevoirMailCacheeSuccess(response.Data));
  } catch (err) {
    yield put(recevoirMailCacheeError(err));
  }
}
function* handleRecevoirPassTemp({ data }) {
  try {
    const response = yield call(AuthService.demanderPassTemp, data);
    yield put(recevoirPassTempSuccess(response.Data));
  } catch (err) {
    yield put(recevoirPassTempError(err));
  }
}

export function* recevoirMailCacheeSaga() {
  yield takeEvery(RECEVOIR_MAIL_CACHEE, handleRecevoirMailCachee);
}
export function* recevoirPassTempSaga() {
  yield takeEvery(RECEVOIR_PASS_TEMP, handleRecevoirPassTemp);
}


export default function* rootSaga() {
  yield all([
    fork(signInWithEmail),
    fork(signInEmail),
    fork(signInOrga),
    fork(signOutInStorage),
    fork(sessionKo),
    fork(listMultiOrga),
    fork(connexMonoCompteSaga),
    fork(connexMultiCompteSansMaitre),
    fork(connexMultiCompteAvecMaitre),
    fork(lecInfoChgMdpSaga),
    fork(lecInfoChgNomMail),
    fork(dmdModifMdpSaga),
    fork(dmdModifNomMail),
    fork(modifMdpSaga),
    fork(modifNomMailSaga),
    fork(recevoirMailCacheeSaga),
    fork(recevoirPassTempSaga)
  ]);
}
