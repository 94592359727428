import {
    GET_DOCUMENT_MODEL_LIST,
    GET_DOCUMENT_MODEL_LIST_SUCCESS,
    GET_DOCUMENT_MODEL_LIST_ERROR,
    SHOW_DOCUMENT_MODEL_LOADING
  
  } from '../constants/DocumentModel';
  
  export const getDocumentModelList = () => {
    return {
      type: GET_DOCUMENT_MODEL_LIST,
      data: null
    };
  }
  export const getDocumentModelListSuccess = (data) => {
    return {
      type: GET_DOCUMENT_MODEL_LIST_SUCCESS,
      data,
    };
  };
  
  export const getDocumentModelListError = (error) => {
    return {
      type: GET_DOCUMENT_MODEL_LIST_ERROR,
      ...error
    };
  };
  
  /*export const getProcessModelItem = (id) => {
    return {
      type: GET_PROCESS_MODEL_ITEM,
      data: {
        nNb_HistoTraitement: 1,
        tab_ModeleTrait: [{ nIdModeleTrait: id }]
      }
    };
  }
  
  export const getProcessModelItemSuccess = (item, idModel) => {
    item.tab_ModeleTrait[0].jsonModele.nIdModeleTrait = idModel;
    return {
      type: GET_PROCESS_ITEM_SUCCESS,
      item: processConverter.mdsProcessItemToProcess(item.tab_ModeleTrait[0].jsonModele),
    };
  };
  
  export const getProcessModelItemError = (error) => {
    return {
      type: GET_PROCESS_MODEL_ITEM_ERROR,
      ...error
    };
  };*/
  
  export const showLoading = () => {
    return {
      type: SHOW_DOCUMENT_MODEL_LOADING,
    };
  };
  