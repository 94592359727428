import {
  GET_PROCESS_MODEL_LIST,
  GET_PROCESS_MODEL_LIST_SUCCESS,
  GET_PROCESS_MODEL_LIST_ERROR,
  GET_PROCESS_MODEL_ITEM,
  GET_PROCESS_MODEL_ITEM_ERROR,
  SHOW_LOADING,
  ADD_PROCESS_MODELE,
  ADD_PROCESS_MODELE_SUCCESS,
  ADD_PROCESS_MODELE_ERROR,
  UPDATE_PROCESS_MODELE,
  UPDATE_PROCESS_MODELE_SUCCESS,
  UPDATE_PROCESS_MODELE_ERROR,
  DISABLE_PROCESS_MODELE,
  DISABLE_PROCESS_MODELE_SUCCESS,
  DISABLE_PROCESS_MODELE_ERROR,
} from '../constants/ProcessModel';

import { GET_PROCESS_ITEM_SUCCESS } from '../constants/Process';

import * as converter from '../converter/ProcessModel';
import * as processConverter from '../converter/Process';

export const getProcessModelList = () => {
  return {
    type: GET_PROCESS_MODEL_LIST,
    data: {
      nNb_HistoTraitement: 1,
      tab_ModeleTrait: [{ nIdModeleTrait: 0 }],
    },
  };
};
export const getProcessModelListSuccess = (data) => {
  return {
    type: GET_PROCESS_MODEL_LIST_SUCCESS,
    data: converter.mdsProcessModelListToProcessModelList(data.tab_ModeleTrait),
  };
};

export const getProcessModelListError = (error) => {
  return {
    type: GET_PROCESS_MODEL_LIST_ERROR,
    ...error,
  };
};

export const getProcessModelItem = (id, code) => {
  return {
    type: GET_PROCESS_MODEL_ITEM,
    data: {
      nNb_HistoTraitement: 1,
      tab_ModeleTrait: [{ nIdModeleTrait: id, sCodeBaseModele: code }],
    },
  };
};

export const getProcessModelItemSuccess = (item, idModel) => {
  item.tab_ModeleTrait[0].jsonModele.nIdModeleTrait = idModel;
  return {
    type: GET_PROCESS_ITEM_SUCCESS,
    item: converter.mdsModeleItemToProcess(
      item.tab_ModeleTrait[0].jsonModele,
      item.tab_ModeleTrait[0].sCodeBaseModele,
      item.tab_ModeleTrait[0].sNomModele,
      idModel,
    ),
  };
};

export const getProcessModelItemError = (error) => {
  return {
    type: GET_PROCESS_MODEL_ITEM_ERROR,
    ...error,
  };
};

export const showLoading = () => {
  return {
    type: SHOW_LOADING,
  };
};

export const addProcessModele = (item, nomModele, codeModele) => {
  return {
    type: ADD_PROCESS_MODELE,
    data: converter.processModeleToMdsProcess(item, nomModele, codeModele),
  };
};

export const addProcessModeleSuccess = (data) => {
  return {
    type: ADD_PROCESS_MODELE_SUCCESS,
    message: 'Le modele a bien été sauvegardé',
    data,
  };
};

export const addProcessModeleError = (error) => {
  return {
    type: ADD_PROCESS_MODELE_ERROR,
    ...error,
  };
};

export const updateProcessModele = (item, nomModele, codeModele, idModele) => {
  return {
    type: UPDATE_PROCESS_MODELE,
    data: converter.processModeleToMdsProcess(item, nomModele, codeModele, idModele),
  };
};

export const updateProcessModeleSuccess = (data) => {
  return {
    type: UPDATE_PROCESS_MODELE_SUCCESS,
    message: 'Le modele a bien été sauvegardé',
    data,
  };
};

export const updateProcessModeleError = (error) => {
  return {
    type: UPDATE_PROCESS_MODELE_ERROR,
    ...error,
  };
};

export const disableProcessModele = (id, code) => {
  return {
    type: DISABLE_PROCESS_MODELE,
    data: {
      tab_ModeleTrait: [
        {
          nIdModeleTrait: id,
          sCodeBaseModele: code,
        },
      ],
    },
  };
};

export const disableProcessModeleSuccess = (item) => {
  return {
    type: DISABLE_PROCESS_MODELE_SUCCESS,
    message: 'Le modele a bien été supprimé',
    item,
    // item,
  };
};

export const disableProcessModeleError = (error) => {
  return {
    type: DISABLE_PROCESS_MODELE_ERROR,
    ...error,
  };
};
