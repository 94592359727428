export const ADD_DOCUMENT_ITEM = 'ADD_DOCUMENT_ITEM';
export const ADD_DOCUMENT_ITEM_SUCCESS = 'ADD_DOCUMENT_ITEM_SUCCESS';
export const ADD_DOCUMENT_ITEM_ERROR = 'ADD_DOCUMENT_ITEM_ERROR';
export const GET_DOCUMENT_LIST = 'GET_DOCUMENT_LIST';
export const GET_DOCUMENT_LIST_SUCCESS = 'GET_DOCUMENT_LIST_SUCCESS';
export const GET_DOCUMENT_LIST_ERROR = 'GET_DOCUMENT_LIST_ERROR';
export const GET_DOCUMENT_ITEM = 'GET_DOCUMENT_ITEM';
export const GET_DOCUMENT_ITEM_SUCCESS = 'GET_DOCUMENT_ITEM_SUCCESS';
export const GET_DOCUMENT_ITEM_ERROR = 'GET_DOCUMENT_ITEM_ERROR';
export const DDL_DOCUMENT_ITEM = 'DDL_DOCUMENT_ITEM';
export const DDL_DOCUMENT_ITEM_SUCCESS = 'DDL_DOCUMENT_ITEM_SUCCESS';
export const DDL_DOCUMENT_ITEM_ERROR = 'DDL_DOCUMENT_ITEM_ERROR';
export const UPDATE_DOCUMENT_ITEM = 'UPDATE_DOCUMENT_ITEM';
export const UPDATE_DOCUMENT_ITEM_SUCCESS = 'UPDATE_DOCUMENT_ITEM_SUCCESS';
export const UPDATE_DOCUMENT_ITEM_ERROR = 'UPDATE_DOCUMENT_ITEM_ERROR';

export const SHOW_LOADING = 'SHOW_DOCUMENT_LOADING';