import {
  GET_FUNC_PROC_ITEM,
  GET_FUNC_PROC_ITEM_SUCCESS,
  GET_FUNC_PROC_ITEM_ERROR,
  ADD_FUNC_PROC_ITEM,
  ADD_FUNC_PROC_ITEM_SUCCESS,
  ADD_FUNC_PROC_ITEM_ERROR,
  UPDATE_FUNC_PROC_ITEM,
  UPDATE_FUNC_PROC_ITEM_SUCCESS,
  UPDATE_FUNC_PROC_ITEM_ERROR,
  GET_FUNC_PROC_STEP_ITEM,
  GET_FUNC_PROC_STEP_ITEM_SUCCESS,
  GET_FUNC_PROC_STEP_ITEM_ERROR,
  ADD_FUNC_PROC_STEP_ITEM,
  ADD_FUNC_PROC_STEP_ITEM_SUCCESS,
  ADD_FUNC_PROC_STEP_ITEM_ERROR,
  UPDATE_FUNC_PROC_STEP_ITEM,
  UPDATE_FUNC_PROC_STEP_ITEM_SUCCESS,
  UPDATE_FUNC_PROC_STEP_ITEM_ERROR,
  DELETE_FUNC_PROC_STEP_ITEM,
  DELETE_FUNC_PROC_STEP_ITEM_SUCCESS,
  DELETE_FUNC_PROC_STEP_ITEM_ERROR,
  GET_FUNC_PROC_LIST,
  GET_FUNC_PROC_LIST_SUCCESS,
  GET_FUNC_PROC_LIST_ERROR,
  GET_FUNC_PROC_WITH_STEP_ITEM,
  GET_FUNC_PROC_WITH_STEP_ITEM_SUCCESS,
  GET_FUNC_PROC_WITH_STEP_ITEM_ERROR,
  GET_BLANK_FUNC_PROC,
  GET_BLANK_FUNC_PROC_SUCCESS,
  GET_BLANK_FUNC_PROC_ERROR,
  ADD_NEW_VERSION_FUNC_PROC_ITEM,
  ADD_NEW_VERSION_FUNC_PROC_ITEM_SUCCESS,
  ADD_NEW_VERSION_FUNC_PROC_ITEM_ERROR,
  SHOW_LOADING
} from '../constants/FunctionalProcedure';

export const addFuncProcItem = (item) => {
  return {
    type: ADD_FUNC_PROC_ITEM,
    data: item,
  };
};
export const addFuncProcItemSuccess = (item) => {
  return {
    type: ADD_FUNC_PROC_ITEM_SUCCESS,
    message: 'Le procedure a bien été ajouté',
    item,
  };
};
export const addFuncProcItemError = (error) => {
  return {
    type: ADD_FUNC_PROC_ITEM_ERROR,
    ...error,
  };
};

export const getFuncProcItem = (id) => {
  return {
    type: GET_FUNC_PROC_ITEM,
    data: { nIDProcFonc: id },
  };
};
export const getFuncProcItemSuccess = (item) => {
  return {
    type: GET_FUNC_PROC_ITEM_SUCCESS,
    item,
  };
};
export const getFuncProcItemError = (error) => {
  return {
    type: GET_FUNC_PROC_ITEM_ERROR,
    ...error,
  };
};

export const updateFuncProcItem = (item) => {
  return {
    type: UPDATE_FUNC_PROC_ITEM,
    data: item,
  };
};
export const updateFuncProcItemSuccess = (item) => {
  return {
    type: UPDATE_FUNC_PROC_ITEM_SUCCESS,
    message: 'Le traitement a bien été mise a jour',
    item
  };
};
export const updateFuncProcItemError = (error) => {
  return {
    type: UPDATE_FUNC_PROC_ITEM_ERROR,
    ...error,
  };
};

export const addFuncProcStepItem = (item) => {
  return {
    type: ADD_FUNC_PROC_STEP_ITEM,
    data: item,
  };
};
export const addFuncProcStepItemSuccess = (itemStep) => {
  return {
    type: ADD_FUNC_PROC_STEP_ITEM_SUCCESS,
    message: 'Le procedure a bien été ajouté',
    itemStep,
  };
};
export const addFuncProcStepItemError = (error) => {
  return {
    type: ADD_FUNC_PROC_STEP_ITEM_ERROR,
    ...error,
  };
};

export const getFuncProcStepItem = (id) => {
  return {
    type: GET_FUNC_PROC_STEP_ITEM,
    data: { nIDOrganisation: id },
  };
};
export const getFuncProcStepItemSuccess = (item) => {
  return {
    type: GET_FUNC_PROC_STEP_ITEM_SUCCESS,
    item,
  };
};
export const getFuncProcStepItemError = (error) => {
  return {
    type: GET_FUNC_PROC_STEP_ITEM_ERROR,
    ...error,
  };
};

export const updateFuncProcStepItem = (item) => {
  return {
    type: UPDATE_FUNC_PROC_STEP_ITEM,
    data: item,
  };
};
export const updateFuncProcStepItemSuccess = (item) => {
  return {
    type: UPDATE_FUNC_PROC_STEP_ITEM_SUCCESS,
    message: 'Le traitement a bien été mise a jour',
    item
  };
};
export const updateFuncProcStepItemError = (error) => {
  return {
    type: UPDATE_FUNC_PROC_STEP_ITEM_ERROR,
    ...error,
  };
};

export const deleteFuncProcStepItem = (id) => {
  return {
    type: DELETE_FUNC_PROC_STEP_ITEM,
    data: {nIDProcFonc: id},
  };
};
export const deleteFuncProcStepItemSuccess = (item) => {
  return {
    type: DELETE_FUNC_PROC_STEP_ITEM_SUCCESS,
    message: 'La procédure a bien été supprimée',
    item,
  };
};
export const deleteFuncProcStepItemError = (error) => {
  return {
    type: DELETE_FUNC_PROC_STEP_ITEM_ERROR,
    ...error
  };
};

export const getFuncProcWithStepItem = (id) => {
  return {
    type: GET_FUNC_PROC_WITH_STEP_ITEM,
    data: { nIDProcFonc: id },
  };
};
export const getFuncProcWithStepItemSuccess = (item) => {
  return {
    type: GET_FUNC_PROC_WITH_STEP_ITEM_SUCCESS,
    item,
  };
};
export const getFuncProcWithStepItemError = (error) => {
  return {
    type: GET_FUNC_PROC_WITH_STEP_ITEM_ERROR,
    ...error,
  };
};

export const getBlankFuncProc = (id) => {
  return {
    type: GET_BLANK_FUNC_PROC,
    data: { nIDOrganisation: id },
  };
};
export const getBlankFuncProcSuccess = (item) => {
  return {
    type: GET_BLANK_FUNC_PROC_SUCCESS,
    item,
  };
};
export const getBlankFuncProcError = (error) => {
  return {
    type: GET_BLANK_FUNC_PROC_ERROR,
    ...error,
  };
};

export const getFuncProcList = () => {
  return {
    type: GET_FUNC_PROC_LIST,
    data: {
      sC: 0,
      nN: 0,
    },
  };
};
export const getFuncProcListSuccess = (data) => {
  return {
    type: GET_FUNC_PROC_LIST_SUCCESS,
    data
  };
};
export const getFuncProcListError = (error) => {
  return {
    type: GET_FUNC_PROC_LIST_ERROR,
    ...error,
  };
};

export const addNewVersionFuncProcItem = (item) => {
  return {
    type: ADD_NEW_VERSION_FUNC_PROC_ITEM,
    data: item,
  };
};
export const addNewVersionFuncProcItemSuccess = (data) => {
  return {
    type: ADD_NEW_VERSION_FUNC_PROC_ITEM_SUCCESS,
    message: 'Le procedure a bien été ajouté',
    data,
  };
};
export const addNewVersionFuncProcItemError = (error) => {
  return {
    type: ADD_NEW_VERSION_FUNC_PROC_ITEM_ERROR,
    ...error,
  };
};

export const showLoading = () => {
  return {
    type: SHOW_LOADING,
  };
};

