import fetch from 'services/Interceptors/MdsInterceptor';
import * as config from 'configs/AppConfig';


const AxeService = {};

const AXE = 'lav';

AxeService.getAxeFCT = (data) => {
  return fetch({
    url: AXE,
    method: 'post',
    headers: {
      'public-request': 'true',
    },
    data: {
      sOpe: "1-FCT",
      Data: data,
    },
  });
};

AxeService.getAxeMET = (data) => {
  return fetch({
    url: AXE,
    method: 'post',
    headers: {
      'public-request': 'true',
    },
    data: {
      sOpe: "1-MET",
      Data: data,
    },
  });
};

AxeService.getAxeGEO = (data) => {
  return fetch({
    url: AXE,
    method: 'post',
    headers: {
      'public-request': 'true',
    },
    data: {
      sOpe: "1-GEO",
      Data: data,
    },
  });
};

AxeService.getAxeALL = (data) => {
  return fetch({
    url: AXE,
    method: 'post',
    headers: {
      'public-request': 'true',
    },
    data: {
      sOpe: "1-FCT,MET,GEO",
      Data: data,
    },
  });
};

export default AxeService;
