import {
  ADD_ACTORS_BOOK_ITEM,
  ADD_ACTORS_BOOK_ITEM_SUCCESS,
  ADD_ACTORS_BOOK_ITEM_ERROR,
  GET_ACTORS_BOOK_LIST,
  GET_ACTORS_BOOK_LIST_SUCCESS,
  GET_ACTORS_BOOK_LIST_ERROR,
  GET_ACTORS_BOOK_ITEM,
  GET_ACTORS_BOOK_ITEM_SUCCESS,
  GET_ACTORS_BOOK_ITEM_ERROR,
  UPDATE_ACTORS_BOOK_ITEM,
  UPDATE_ACTORS_BOOK_ITEM_SUCCESS,
  UPDATE_ACTORS_BOOK_ITEM_ERROR,
  SHOW_LOADING,
  GET_ACTORS_BOOK_WITH_AXE_LIST_ERROR,
  GET_ACTORS_BOOK_WITH_AXE_LIST_SUCCESS,
  GET_ACTORS_BOOK_WITH_AXE_LIST,
  GET_MISSION_ACTORS_BOOK_LIST_ERROR,
  GET_MISSION_ACTORS_BOOK_LIST_SUCCESS,
  GET_MISSION_ACTORS_BOOK_LIST,
  DELETE_ACTORS_BOOK,
  DELETE_ACTORS_BOOK_ITEM_SUCCESS,
  DELETE_ACTORS_BOOK_ERROR,
} from '../constants/ActorsBook';

import * as converter from '../converter/ActorsBook';
import { mdsActorToActorList } from '../converter/ActorsBook';

export const createActorsBook = (data) => {
  return {
    type: ADD_ACTORS_BOOK_ITEM,
    data: converter.actorToMdsActorCreate(data),
  };
};

export const createActorsBookSuccess = (data) => {
  return {
    type: ADD_ACTORS_BOOK_ITEM_SUCCESS,
    item: converter.actorToMdsActorCreate(data),
    message: "L'item a bien été crée",
  };
};

export const createActorsBookError = (error) => {
  return {
    type: ADD_ACTORS_BOOK_ITEM_ERROR,
    ...error,
  };
};

export const getActorsBookList = () => {
  return {
    type: GET_ACTORS_BOOK_LIST,
    data: {
      sC: 0,
      nN: 0,
      bAvecInactif: true,
    },
  };
};

export const getActorsBookListSuccess = (data) => {
  return {
    type: GET_ACTORS_BOOK_LIST_SUCCESS,
    data: mdsActorToActorList(data),
  };
};

export const getActorsBookListError = (error) => {
  return {
    type: GET_ACTORS_BOOK_LIST_ERROR,
    ...error,
  };
};

export const getActorsBookWithAxeList = (data) => {
  return {
    type: GET_ACTORS_BOOK_WITH_AXE_LIST,
    data,
  };
};

export const getActorsBookWithAxeListSuccess = (data) => {
  return {
    type: GET_ACTORS_BOOK_WITH_AXE_LIST_SUCCESS,
    data,
  };
};

export const getActorsBookWithAxeListError = (error) => {
  return {
    type: GET_ACTORS_BOOK_WITH_AXE_LIST_ERROR,
    ...error,
  };
};

export const getMissionActorsBookList = (data) => {
  return {
    type: GET_MISSION_ACTORS_BOOK_LIST,
    data,
  };
};

export const getMissionActorsBookListSuccess = (data) => {
  return {
    type: GET_MISSION_ACTORS_BOOK_LIST_SUCCESS,
    data,
  };
};

export const getMissionActorsBookListError = (error) => {
  return {
    type: GET_MISSION_ACTORS_BOOK_LIST_ERROR,
    ...error,
  };
};

export const getActorsBookItem = (id) => {
  return {
    type: GET_ACTORS_BOOK_ITEM,
    data: {
      nIDInter: id,
    },
  };
};

export const getActorsBookItemSuccess = (data) => {
  return {
    type: GET_ACTORS_BOOK_ITEM_SUCCESS,
    item: converter.mdsActorToActor(data),
  };
};

export const getActorsBookItemError = (error) => {
  return {
    type: GET_ACTORS_BOOK_ITEM_ERROR,
    ...error,
  };
};

export const updateActorsBook = (id, data) => {
  return {
    type: UPDATE_ACTORS_BOOK_ITEM,
    data: converter.actorToMdsActorCreate({ idInter: id, ...data }),
  };
};

export const updateActorsBookSuccess = (data) => {
  return {
    type: UPDATE_ACTORS_BOOK_ITEM_SUCCESS,
    item: converter.actorToMdsActorCreate(data),
    message: "L'item a bien été mise a jour",
  };
};

export const updateActorsBookError = (error) => {
  return {
    type: UPDATE_ACTORS_BOOK_ITEM_ERROR,
    ...error,
  };
};

export const showLoading = () => {
  return {
    type: SHOW_LOADING,
  };
};

export const deleteActorsBook = (idActorsBook) => {
  return {
    type: DELETE_ACTORS_BOOK,
    data: {
      nIDInter: idActorsBook,
    },
  };
};

export const deleteActorsBookSuccess = (mdsActorsBook) => {
  return {
    type: DELETE_ACTORS_BOOK_ITEM_SUCCESS,
    item: converter.actorToMdsActorCreate(mdsActorsBook),
    message: "L'item a bien été supprimé",
  };
};

export const deleteActorsBookError = (error) => {
  return {
    type: DELETE_ACTORS_BOOK_ERROR,
    ...error,
  };
};
