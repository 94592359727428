import fetch from 'services/Interceptors/MdsInterceptor';
import * as config from 'configs/AppConfig';

const UsersManagementService = {};
const GADGU_PROC = 'gadgu';

UsersManagementService.getUsersManagementItem = (data) => {
  return fetch({
    url: `/${GADGU_PROC}`,
    method: 'post',
    headers: {
      'public-request': 'true',
    },
    data: {
      sOpe: "1-us",
      Data: data,
    },
  });
};

UsersManagementService.addUsersManagementItem = (data) => {
  return fetch({
    url: `/${GADGU_PROC}`,
    method: 'post',
    headers: {
      'public-request': 'true',
    },
    data: {
      sOpe: config.API_SCLE_ADD,
      Data: data,
    },
  });
};

UsersManagementService.updateUsersManagementItem = (data) => {
  return fetch({
    url: GADGU_PROC,
    method: 'post',
    headers: {
      'public-request': 'true',
    },
    data: {
      sOpe: config.API_SCLE_EDIT,
      Data: data,
    },
  });
};

UsersManagementService.getUsersManagementList = (data) => {
  return fetch({
    url: GADGU_PROC,
    method: 'post',
    headers: {
      'public-request': 'true',
    },
    data: {
      sOpe: '11-us',
      Data: data,
    },
  });
};

export default UsersManagementService;
