export const GET_PROCESS_MODEL_LIST = 'GET_PROCESS_MODEL_LIST';
export const GET_PROCESS_MODEL_LIST_SUCCESS = 'GET_PROCESS_MODEL_LIST_SUCCESS';
export const GET_PROCESS_MODEL_LIST_ERROR = 'GET_PROCESS_MODEL_LIST_ERROR';
export const GET_PROCESS_MODEL_ITEM = 'GET_PROCESS_MODEL_ITEM';
export const GET_PROCESS_MODEL_ITEM_SUCCESS = 'GET_PROCESS_MODEL_ITEM_SUCCESS';
export const GET_PROCESS_MODEL_ITEM_ERROR = 'GET_PROCESS_MODEL_ITEM_ERROR';
export const ADD_PROCESS_MODELE = 'ADD_PROCESS_MODELE';
export const ADD_PROCESS_MODELE_SUCCESS = 'ADD_PROCESS_MODELE_SUCCESS';
export const ADD_PROCESS_MODELE_ERROR = 'ADD_PROCESS_MODELE_ERROR';
export const UPDATE_PROCESS_MODELE = 'UPDATE_PROCESS_MODELE';
export const UPDATE_PROCESS_MODELE_SUCCESS = 'UPDATE_PROCESS_MODELE_SUCCESS';
export const UPDATE_PROCESS_MODELE_ERROR = 'UPDATE_PROCESS_MODELE_ERROR';
export const DISABLE_PROCESS_MODELE = 'DISABLE_PROCESS_MODELE';
export const DISABLE_PROCESS_MODELE_SUCCESS = 'DISABLE_PROCESS_MODELE_SUCCESS';
export const DISABLE_PROCESS_MODELE_ERROR = 'DISABLE_PROCESS_MODELE_ERROR';

export const SHOW_LOADING = 'SHOW_PROCESS_MODEL_LOADING';
