export const RECORD_DOCUMENT_REG_LAW_ITEM = 'RECORD_DOCUMENT_REG_LAW_ITEM';
export const RECORD_DOCUMENT_REG_LAW_ITEM_SUCCESS = 'RECORD_DOCUMENT_REG_LAW_ITEM_SUCCESS';
export const RECORD_DOCUMENT_REG_LAW_ITEM_ERROR = 'RECORD_DOCUMENT_REG_LAW_ITEM_ERROR';

export const LIST_DOCUMENT_REG_LAW = 'LIST_DOCUMENT_REG_LAW';
export const LIST_DOCUMENT_REG_LAW_SUCCESS = 'LIST_DOCUMENT_REG_LAW_SUCCESS';
export const LIST_DOCUMENT_REG_LAW_ERROR = 'LIST_DOCUMENT_REG_LAW_ERROR';

export const GET_DOCUMENT_REG_LAW = 'GET_DOCUMENT_REG_LAW';
export const GET_DOCUMENT_REG_LAW_SUCCESS = 'GET_DOCUMENT_REG_LAW_SUCCESS';
export const GET_DOCUMENT_REG_LAW_ERROR = 'GET_DOCUMENT_REG_LAW_ERROR';

export const GET_FICHE_PDF = 'GET_FICHE_PDF';
export const GET_FICHE_PDF_SUCCESS = 'GET_FICHE_PDF_SUCCESS';
export const GET_FICHE_PDF_ERROR = 'GET_FICHE_PDF_ERROR';

export const SHOW_LOADING = 'SHOW_DOCUMENT_LOADING';

export const DO_NOTHING = 'DO_NOTHING';
