import {
  CLOSE_TODOLIST_ITEM,
  CLOSE_TODOLIST_ITEM_SUCCESS,
  CLOSE_TODOLIST_ITEM_ERROR,
  GET_TODOLIST_LIST,
  GET_TODOLIST_LIST_SUCCESS,
  GET_TODOLIST_LIST_ERROR,
  SHOW_LOADING,
} from '../constants/Todolist';

const initState = {
  isLoading: false,
  isUpdated: false,
  data: [],
  dataShouldUpdate: false,
  item: {},
  addedItem: {},
  editedItem: {},
  history: [],
};

const auth = (state = initState, action) => {
  switch (action.type) {
    case SHOW_LOADING: {
      return {
        ...state,
        isLoading: true,
      };
    }

    case CLOSE_TODOLIST_ITEM:
    case GET_TODOLIST_LIST: {
      return {
        ...state,
        isLoading: true,
        isUpdated: false,
      };
    }
    case CLOSE_TODOLIST_ITEM_ERROR:
    case GET_TODOLIST_LIST_ERROR: {
      return {
        ...state,
        isLoading: false,
      };
    }

    case CLOSE_TODOLIST_ITEM_SUCCESS: {
      return {
        ...state,
        isLoading: false,
      };
    }
    case GET_TODOLIST_LIST_SUCCESS: {
      return {
        ...state,
        data: action.data,
        dataShouldUpdate: false,
        isLoading: false,
      };
    }
    default:
      return state;
  }
};

export default auth;
