export const GET_DASHBOARD_DATA = 'GET_DASHBOARD_DATA';
export const GET_DASHBOARD_DATA_SUCCESS = 'GET_DASHBOARD_DATA_SUCCESS';
export const GET_DASHBOARD_DATA_ERROR = 'GET_DASHBOARD_DATA_ERROR';

export const GET_SYNTHESE_ACTION_DATA = 'GET_SYNTHESE_ACTION_DATA';
export const GET_SYNTHESE_ACTION_DATA_SUCCESS = 'GET_SYNTHESE_ACTION_DATA_SUCCESS';
export const GET_SYNTHESE_ACTION_DATA_ERROR = 'GET_SYNTHESE_ACTION_DATA_ERROR';

export const GET_BASE_LEGALE_DATA = 'GET_BASE_LEGALE_DATA';
export const GET_BASE_LEGALE_DATA_SUCCESS = 'GET_BASE_LEGALE_DATA_SUCCESS';
export const GET_BASE_LEGALE_DATA_ERROR = 'GET_BASE_LEGALE_DATA_ERROR';

export const GET_CRITERE_QUANTITATIF_DATA = 'GET_CRITERE_QUANTITATIF_DATA';
export const GET_CRITERE_QUANTITATIF_DATA_SUCCESS = 'GET_CRITERE_QUANTITATIF_DATA_SUCCESS';
export const GET_CRITERE_QUANTITATIF_DATA_ERROR = 'GET_CRITERE_QUANTITATIF_DATA_ERROR';

export const SHOW_LOADING = 'SHOW_ACTIONS_LOADING'; 
