import React from 'react';
import { bindActionCreators } from 'redux';
import { Menu, Dropdown } from 'antd';
import { connect } from 'react-redux';
import { LogoutOutlined, UserOutlined, UserSwitchOutlined } from '@ant-design/icons';
import * as AuthActions from 'store/actions/Auth';
import { withTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

export const NavProfile = (props) => {
  const { t } = props;
  const history = useHistory();

  const choiceCompte = () => {
    history.push("/app/choice-organisation");
  }
  const editerProfile = () => {
    history.push("/app/edit-profile");
  }
  console.log("IN LE USER", props.user);
  const profileMenu = (
    <div className="nav-profile nav-dropdown">
      <div className="nav-profile-body">
        <Menu>
          {
            props.user.isMiltiCompte && (
              <Menu.Item key={1} onClick={() => choiceCompte()}>
                <span>
                  <UserSwitchOutlined className="mr-1" />
                  <span className="font-weight-normal">
                    {t('CHANGER_ORGANISATION')}
                    {' '}
                  </span>
                </span>
              </Menu.Item>
            )

          }
          <Menu.Item key={2} onClick={() => editerProfile()}>
            <span>
              <UserOutlined className="mr-1" />
              <span className="font-weight-normal">
                {t('Mon Profil')}
                {' '}
              </span>
            </span>
          </Menu.Item>
          <Menu.Item key={3} onClick={() => props.signOut()}>
            <span>
              <LogoutOutlined className="mr-1" />
              <span className="font-weight-normal">
                {t('DECONNEXION')}
              </span>
            </span>
          </Menu.Item>
        </Menu>
      </div>
    </div>
  );
  return (
    <Dropdown placement="bottomRight" overlay={profileMenu} trigger={['click']}>
      <Menu className="d-flex align-item-center" mode="horizontal">
        <Menu.Item className="pl-0">
          <div className="nav-profile-header pl-0">
            <div className="d-flex">
              <div className="pl-1">
                <h4 className="mb-0 pt-1">{props.user?.nom}</h4>
              </div>
            </div>
          </div>
        </Menu.Item>
      </Menu>
    </Dropdown>
  );
};

const mapStateToProps = ({ auth }) => {
  return {
    user: auth.user,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(AuthActions, dispatch);
};

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(NavProfile));
