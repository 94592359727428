import {
  ADD_CONTRAT_FEAT_ITEM,
  ADD_CONTRAT_FEAT_ITEM_SUCCESS,
  ADD_CONTRAT_FEAT_ITEM_ERROR,
  GET_CONTRAT_FEAT_LIST,
  GET_CONTRAT_FEAT_LIST_SUCCESS,
  GET_CONTRAT_FEAT_LIST_ERROR,
  GET_CONTRAT_FEAT_ITEM,
  GET_CONTRAT_FEAT_ITEM_SUCCESS,
  GET_CONTRAT_FEAT_ITEM_ERROR,
  UPDATE_CONTRAT_FEAT_ITEM,
  UPDATE_CONTRAT_FEAT_ITEM_SUCCESS,
  UPDATE_CONTRAT_FEAT_ITEM_ERROR,
  DISABLE_CONTRAT_FEAT_ITEM,
  DISABLE_CONTRAT_FEAT_ITEM_SUCCESS,
  DISABLE_CONTRAT_FEAT_ITEM_ERROR,
  GET_INFORMATIONS_CONTRAT_ITEM,
  GET_INFORMATIONS_CONTRAT_ITEM_SUCCESS,
  GET_INFORMATIONS_CONTRAT_ITEM_ERROR,
  SHOW_CONTRAT_FEAT_LOADING,
  GET_CONTRAT_FEAT_BY_PROVIDER_ITEM,
  GET_CONTRAT_FEAT_BY_PROVIDER_ITEM_ERROR,
  GET_CONTRAT_FEAT_BY_PROVIDER_ITEM_SUCCESS,
} from '../constants/ContratFeat';

const initState = {
  isLoading: false,
  data: [],
  dataShouldUpdate: true,
  itemShouldRedirect: false,
  natureList: [],
  item: {},
  dataByProvider: [],
  infosContrat: {}
};

const auth = (state = initState, action) => {
  switch (action.type) {
    case SHOW_CONTRAT_FEAT_LOADING: {
      return {
        ...state,
        isLoading: true,
      };
    }
    case GET_CONTRAT_FEAT_ITEM: {
      return {
        ...state,
        item: initState.item,
        isLoading: true,
      };
    }
    case ADD_CONTRAT_FEAT_ITEM:
    case GET_CONTRAT_FEAT_LIST:
    case DISABLE_CONTRAT_FEAT_ITEM:
    case GET_INFORMATIONS_CONTRAT_ITEM:
    case GET_CONTRAT_FEAT_BY_PROVIDER_ITEM:
    case UPDATE_CONTRAT_FEAT_ITEM: {
      return {
        ...state,
        isLoading: true,
      };
    }
    case DISABLE_CONTRAT_FEAT_ITEM_SUCCESS:
    case ADD_CONTRAT_FEAT_ITEM_SUCCESS: {
      return {
        ...state,
        item: action.item,
        dataShouldUpdate: true,
        itemShouldRedirect: true,
        isLoading: false,
      };
    }
    case GET_CONTRAT_FEAT_LIST_SUCCESS: {
      return {
        ...state,
        data: action.data,
        dataShouldUpdate: false,
        isLoading: false,
      };
    }

    case GET_CONTRAT_FEAT_ITEM_SUCCESS: {
      return {
        ...state,
        item: action.item,
        itemShouldRedirect: false,
        isLoading: false,
      };
    }

    case GET_INFORMATIONS_CONTRAT_ITEM_SUCCESS: {
      return {
        ...state,
        infosContrat: action.infos,
        dataShouldUpdate: false,
        isLoading: false,
      };
    }

    case GET_CONTRAT_FEAT_BY_PROVIDER_ITEM_SUCCESS:
      return {
        ...state,
        dataByProvider: action.data,
        isLoading: false,
      };
    case ADD_CONTRAT_FEAT_ITEM_ERROR:
    case GET_CONTRAT_FEAT_LIST_ERROR:
    case GET_CONTRAT_FEAT_ITEM_ERROR:
    case DISABLE_CONTRAT_FEAT_ITEM_ERROR:
    case GET_INFORMATIONS_CONTRAT_ITEM_ERROR:
    case GET_CONTRAT_FEAT_BY_PROVIDER_ITEM_ERROR:
    case UPDATE_CONTRAT_FEAT_ITEM_ERROR: {
      return {
        ...state,
        isLoading: false,
      };
    }
    case UPDATE_CONTRAT_FEAT_ITEM_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        dataShouldUpdate: true,
        itemShouldRedirect: false,
        item: action.item,
      };
    }
    default:
      return state;
  }
};

export default auth;
