import fetch from 'services/Interceptors/AuthInterceptor';
import fetch2 from 'services/Interceptors/Auth2Interceptor';
import fetchMail from 'services/Interceptors/OrgaInterceptor';
import fetchOrga from 'services/Interceptors/MdsInterceptor';
import fetchPass from 'services/Interceptors/PasswordInterceptor';

const AuthService = {};
const PROC = 'log';
const GMC = 'gmc';
const GRMP = 'grmp';

AuthService.login = (data) => {
  return fetchMail({
    url: `/${GMC}`,
    method: 'post',
    headers: {
      'public-request': 'true',
    },
    data,
  });
};

AuthService.loginMail = (data) => {
  return fetch({
    url: `/${PROC}`,
    method: 'post',
    headers: {
      'public-request': 'true',
    },
    data,
  });
};

AuthService.signUp = (data) => {
  return fetch({
    url: '/posts',
    method: 'post',
    headers: {
      'public-request': 'true',
    },
    data,
  });
};

AuthService.listOrga = (data) => {
  return fetchOrga({
    url: `/${GMC}`,
    method: 'post',
    headers: {
      'public-request': 'true',
    },
    data: {
      sOpe: "1",
      Data: data,
    },
  });
};

AuthService.connexion = (data) => {
  return fetch({
    url: `/${PROC}`,
    method: 'post',
    headers: {
      'public-request': 'true',
    },
    data,
  });
};

AuthService.lecInfoChgMdp = (data) => {
  return fetch2({
    url: `/gmicu`,
    method: 'post',
    headers: {
      'public-request': 'true',
    },
    data: {
      sOpe: "11-MDP",
      Data: data,
    },
  });
};

AuthService.lecInfoChgNomMail = (data) => {
  return fetch2({
    url: `/gmicu`,
    method: 'post',
    headers: {
      'public-request': 'true',
    },
    data: {
      sOpe: "11-NOMMAIL",
      Data: data,
    },
  });
};

AuthService.dmdModifMdp = (data) => {
  return fetch2({
    url: `/gmicu`,
    method: 'post',
    headers: {
      'public-request': 'true',
    },
    data: {
      sOpe: "1-MDP",
      Data: data,
    },
  });
};

AuthService.dmdModifNomMail = (data) => {
  return fetch2({
    url: `/gmicu`,
    method: 'post',
    headers: {
      'public-request': 'true',
    },
    data: {
      sOpe: "1-NOMMAIL",
      Data: data,
    },
  });
};

AuthService.modifMdp = (data) => {
  return fetch2({
    url: `/gmicu`,
    method: 'post',
    headers: {
      'public-request': 'true',
    },
    data: {
      sOpe: "3-MDP",
      Data: data,
    },
  });
};

AuthService.modifNomMail = (data) => {
  return fetch2({
    url: `/gmicu`,
    method: 'post',
    headers: {
      'public-request': 'true',
    },
    data: {
      sOpe: "3-NOMMAIL",
      Data: data,
    },
  });
};

AuthService.demanderMailCachee = (data) => {
  return fetchPass({
    url: `/${GRMP}`,
    method: 'post',
    headers: {
      'public-request': 'true',
    },
    data: {
      sOpe: '30',
      Data: data,
    },
  });
};
AuthService.demanderPassTemp = (data) => {
  return fetchPass({
    url: `/${GRMP}`,
    method: 'post',
    headers: {
      'public-request': 'true',
    },
    data: {
      sOpe: '3',
      Data: data,
    },
  });
};

export default AuthService;
