import CreationDocumentService from 'services/CreationDocumentService';
import { all, takeEvery, put, fork, call } from 'redux-saga/effects';
import { Modal } from 'antd';
import {
  ADD_CREATION_DOCUMENT_ITEM,
  GET_DOCUMENT_CREATED_ITEM,
  GET_FICHE_PDF,
  LIST_DOCUMENT_CREATED_ITEM
} from '../constants/CreationDocument';
import {
  getDocumentCreatedItemSuccess,
  getDocumentCreatedItemError,
  getListDocumentCreatedError,
  getListDocumentCreatedSuccess,
  addDocumentItemError,
  addDocumentItemSuccess
} from '../actions/CreationDocument';

export function* getDocumentCreatedList() {
  yield takeEvery(LIST_DOCUMENT_CREATED_ITEM, function* ({ data }) {
    try {
      const response = yield call(CreationDocumentService.getListDocumentCreated, data);
      // console.log('getDocumentCreatedList ', response)
      yield put(getListDocumentCreatedSuccess(response.Data));
    } catch (err) {
      // console.log("error getDocumentCreatedList", err)
      yield put(getListDocumentCreatedError(err));
    }
  });
}

export function* getDocumentCreated() {
  yield takeEvery(GET_DOCUMENT_CREATED_ITEM, function* ({ data }) {
    try {
      const response = yield call(CreationDocumentService.getDocumentCreated, data);
      yield put(getDocumentCreatedItemSuccess(response.Data));
    } catch (err) {
      yield put(getDocumentCreatedItemError(err));
    }
  });
}

export function* addCreationDocument() {
  // console.log('addCreationDocument sagas ')
  yield takeEvery(ADD_CREATION_DOCUMENT_ITEM, function* ({ data }) {
    try {
      const response = yield call(CreationDocumentService.addCreationDocument, data);
      // console.log(response)
      yield put(addDocumentItemSuccess(response.Data));
      // const list = yield call(CreationDocumentService.getListDocumentCreated, {});
      // yield put(getListDocumentCreatedSuccess(list.Data));
    } catch (err) {
      // console.log(err)
      yield put(addDocumentItemError(err));
    }
  });
}

export function* getFichePDF() {
  // console.log('getFichePDF sagas ')
  yield takeEvery(GET_FICHE_PDF, function* ({ data }) {
    try {
      const response = yield call(CreationDocumentService.addCreationDocument, data);
      // if (response.Data.sMsg == "OK") {
      infoCreatePdf();
      // }
      // console.log('getFichePDF', response)
      // window.location = `/app/demande-document/${response.Data.nIDDocCree}`
      // const newResponse = yield call(CreationDocumentService.getDocumentCreated, response.Data);
      // yield put(getDocumentCreatedItemSuccess(newResponse.Data));
      // const list = yield call(CreationDocumentService.getListDocumentCreated, {});
      // yield put(getListDocumentCreatedSuccess(list.Data));
    } catch (err) {
      // console.log(err)
      yield put(addDocumentItemError(err));
    }
  });
}

function infoCreatePdf() {
  Modal.info({
    title: "",
    zIndex: "1100",
    content: (
      <p>La demande de document a été prise en compte, vous pouvez retrouver le fichier à télécharger dans la partie "Extraction pour impression".</p>
    ),
    onOk() { },
  });
}

export default function* rootSaga() {
  yield all([
    fork(getDocumentCreatedList),
    fork(getDocumentCreated),
    fork(getFichePDF),
    fork(addCreationDocument)]);
}
