import { all, takeEvery, put, fork, call } from 'redux-saga/effects';
import AxeService from 'services/AxeService';
import { getAxeFCTError, getAxeFCTSuccess, getAxeGEOError, getAxeGEOSuccess, getAxeMETError, getAxeMETSuccess, getAxeALLError, getAxeALLSuccess } from 'store/actions/Axe';
import { GET_AXE_FCT, GET_AXE_GEO, GET_AXE_MET, GET_AXE_ALL } from 'store/constants/Axe';

export function* getAxeFCT() {
  yield takeEvery(GET_AXE_FCT, function* ({ data }) {
    try {
      const response = yield call(AxeService.getAxeFCT, data);
      // console.log('getAxeFCTSuccess ', response.Data);
      yield put(getAxeFCTSuccess(response.Data.tab_CatDomFonc));
    } catch (err) {
      yield put(getAxeFCTError(err));
    }
  });
}

export function* getAxeMET() {
  yield takeEvery(GET_AXE_MET, function* ({ data }) {
    try {
      const response = yield call(AxeService.getAxeMET, data);
      // console.log('getAxeMETSuccess ', response.Data);
      yield put(getAxeMETSuccess(response.Data.tab_CatDomMetier));
    } catch (err) {
      yield put(getAxeMETError(err));
    }
  });
}

export function* getAxeGEO() {
  yield takeEvery(GET_AXE_GEO, function* ({ data }) {
    try {
      const response = yield call(AxeService.getAxeGEO, data);
      // console.log('getAxeGEOSuccess ', response.Data);
      yield put(getAxeGEOSuccess(response.Data.tab_CatDomGeo));
    } catch (err) {
      yield put(getAxeGEOError(err));
    }
  });
}

export function* getAxeALL() {
  yield takeEvery(GET_AXE_ALL, function* ({ data }) {
    try {
      const response = yield call(AxeService.getAxeALL, data);
      // console.log('getAxeALLSuccess ', response.Data);
      yield put(getAxeALLSuccess(response.Data));
    } catch (err) {
      yield put(getAxeALLError(err));
    }
  });
}

export default function* rootSaga() {
  yield all([
    fork(getAxeFCT),
    fork(getAxeMET),
    fork(getAxeGEO),
    fork(getAxeALL)]);
}
