/* eslint-disable no-unused-vars */
/* eslint-disable no-return-assign */

import _ from 'lodash';

import { defaultProcessData } from 'configs/DefaultConfig';
import Utils from 'configs/UtilFunctions';

import moment from 'moment';
import * as converterActions from './Actions';
import * as converterOrganisation from './Organisation';

// send our organisation model to MDS API organisation
const getCateg = (code, tabDomFonc) => {
  return tabDomFonc.filter((item) => item.sCode === code);
};

export const mdsProcessListToProcess = (mdsProcess, tabDomFonc) => {
  //   console.log(mdsProcess);
  const category = getCateg(mdsProcess.sDomFonc, tabDomFonc)[0].sLibelle;

  if (Utils.getEnv('REACT_APP_ENV') === 'demo') {
    // TODO, dont change it, we remove when category will be fill
    const demoData = defaultProcessData.tab_Trait.filter((item) => {
      return item.id == mdsProcess.nID_Trait % 10;
    })[0];
    return {
      key: mdsProcess.nID_Trait,
      id: mdsProcess.nID_Trait,
      name: mdsProcess.sNom_Trait,
      nameOrga: mdsProcess.sNomOrga,
      // category: demoData.category,
      category,
      creation_date: moment(new Date(mdsProcess.dhDateSaisieInit)),
      status: null,
      qualification: mdsProcess.sResponsabilite,
      update_date: moment(new Date(mdsProcess.dhDateDernierModif)),
      reference: mdsProcess.sRef_Trait,
      version: mdsProcess.sVer_Trait,
      estInactif: mdsProcess.bEstInactif,
      labelStatut: mdsProcess.bEstInactif ? 'Inactif' : 'Actif',
      id_orga: mdsProcess.nID_Orga,
    };
    // END todo
  }
  return {
    key: mdsProcess.nID_Trait,
    id: mdsProcess.nID_Trait,
    name: mdsProcess.sNom_Trait,
    category,
    creation_date: moment(new Date(mdsProcess.dhDateSaisieInit)),
    status: null,
    qualification: mdsProcess.sResponsabilite,
    update_date: moment(new Date(mdsProcess.dhDateDernierModif)),
    reference: mdsProcess.sRef_Trait,
    version: mdsProcess.sVer_Trait,
    estInactif: mdsProcess.bEstInactif,
    labelStatut: mdsProcess.bEstInactif ? 'Inactif' : 'Actif',
    id_orga: mdsProcess.nID_Orga,
  };
};

export const mdsProcessListToProcessList = (mdsProcessList, tabDomFonc) => {
  const dataArray = [];
  mdsProcessList.forEach((element) => {
    dataArray.push(mdsProcessListToProcess(element, tabDomFonc));
  });
  return dataArray;
};

export const mdsProcessItemToProcess = (mdsProcess) => {
  let itemParsed = {
    informations: {},
    finalities: {},
    legalBases: {},
  };

  Object.keys(mdsProcess.tab_Trait[0]).forEach(function (k) {
    if (k.indexOf('sFinalite') === 0) {
      itemParsed.finalities[k] = mdsProcess.tab_Trait[0][k];
    } else if (k.indexOf('BL') === 1 || k === 'sNece' || k === 'sPropor') {
      itemParsed.legalBases[k] = mdsProcess.tab_Trait[0][k];
    } else {
      itemParsed.informations[k] = mdsProcess.tab_Trait[0][k];
    }
  });
  itemParsed = {
    risks: mdsProcess.tab_QuestEV,
    actors: Utils.convertItemDateToMomentFromList(mdsProcess.tab_Acteur, {
      dDateDeb: 'beginDate',
      dDateFin: 'endDate',
    }),
    actions: converterActions.convertMdsActionListInActionsList(
      mdsProcess.tabAction,
    ),
    personnalData: mdsProcess.tabData
      ? mdsProcess.tabData.filter((item) => item.nType === 1)
      : [],
    sensibleData: mdsProcess.tabData
      ? mdsProcess.tabData.filter((item) => item.nType === 2)
      : [],
    organisation:
      mdsProcess.Data_Orga &&
      converterOrganisation.organisationToOrganisation(mdsProcess.Data_Orga),
    concernedPersons: mdsProcess.tab_PersConc,
    contrats: mdsProcess.tab_Contrat,
    recipients: mdsProcess.tab_Dest,
    transferts: mdsProcess.tab_TransData,
    ...itemParsed,
    // for now useless
    grData: mdsProcess.tabGrData,
    domFonc: mdsProcess.tab_CatDomFonc,
    catRespons: mdsProcess.tab_CatRespons,
    // mdsProcess.tab_CatPers,
  };
  // console.log('process Converted', itemParsed)
  return itemParsed;
};

export const changeCheckbox = (list, arrayKey) => {
  list.map((item) => (item[arrayKey] = item[arrayKey] ? 1 : 0));
  return list;
  // todo change chechbox true => 1 / false => 0
};
export const processItemToMdsProcess = (process) => {
  const itemParsed = {
    nBTrait: 1,
    tab_Trait: [
      { ...process.informations, ...process.finalities, ...process.legalBases },
    ],
    nBQuestEV: !_.isEmpty(process.risks) ? process.risks.length : 0,
    tab_QuestEV: changeCheckbox(process.risks, ['nCheckboxVal']),
    nBActeur: process.actors.length,
    tab_Acteur: process.actors,
    nBAction: process.actions.length,
    tabAction: converterActions.convertActionsListInMdsActionsList(process.actions),
    nBData: [...process.personnalData, ...process.sensibleData].length,
    tabData: [...process.personnalData, ...process.sensibleData],
    nBPersConc: process.concernedPersons.length,
    tab_PersConc: process.concernedPersons,
    nBContrat: process.contrats.length,
    tab_Contrat: process.contrats,
    nBDest: process.recipients.length,
    tab_Dest: process.recipients,
    nBTransData: process.transferts.length,
    tab_TransData: process.transferts,

    nBGr_Data: process.grData.length,
    tabGrData: process.grData,
  };
  // console.log('processItemToMdsProcess', itemParsed, process)
  return itemParsed;
};
