import {
  GET_COUNTRY_LIST,
  GET_COUNTRY_LIST_SUCCESS,
  GET_COUNTRY_LIST_ERROR,
  GET_FUNC_PROF_LIST,
  GET_FUNC_PROF_LIST_SUCCESS,
  GET_FUNC_PROF_LIST_ERROR,
  SHOW_LOADING,
} from '../constants/Utils';

// import * as converter from '../converter/Utils';

export const getCountryList = () => {
  return {
    type: GET_COUNTRY_LIST,
    data: {},
  };
};

export const getCountryListSuccess = (mdsCountry) => {
  return {
    type: GET_COUNTRY_LIST_SUCCESS,
    data: mdsCountry.tab_ListePays,
  };
};

export const getCountryListError = (error) => {
  return {
    type: GET_COUNTRY_LIST_ERROR,
    ...error,
  };
};
export const getProfessionnalFunctionList = () => {
  return {
    type: GET_FUNC_PROF_LIST,
    data: { sC: 0, nN: 0 },
  };
};

export const getProfessionnalFunctionListSuccess = (mdsProfFunc) => {
  // console.log(mdsProfFunc)
  return {
    type: GET_FUNC_PROF_LIST_SUCCESS,
    data: mdsProfFunc.tab_Fonct_Pro,
  };
};

export const getProfessionnalFunctionListError = (error) => {
  return {
    type: GET_FUNC_PROF_LIST_ERROR,
    ...error,
  };
};

export const showLoading = () => {
  return {
    type: SHOW_LOADING,
  };
};
