import fetch from 'services/Interceptors/MdsInterceptor';

const IncidentService = {};
const INCIDENT_ITEM = 'ginc';
const INCIDENT_LIST = 'llinc';

IncidentService.getIncidentList = () => {
  return fetch({
    url: INCIDENT_LIST,
    method: 'post',
    headers: {
      'public-request': 'true',
    },
    data: {
      nTaille: 4,
      sOpe: '1',
      Data: {},
    },
  });
};

IncidentService.getIncidentItem = (data) => {
  return fetch({
    url: INCIDENT_ITEM,
    method: 'post',
    headers: {
      'public-request': 'true',
    },
    data: {
      sOpe: '1',
      Data: data,
    },
  });
};

IncidentService.createIncidentItem = (data) => {
  return fetch({
    url: INCIDENT_ITEM,
    method: 'post',
    headers: {
      'public-request': 'true',
    },
    data: {
      sOpe: '2',
      Data: data,
    },
  });
};

IncidentService.updateIncidentItem = (data) => {
  return fetch({
    url: INCIDENT_ITEM,
    method: 'post',
    headers: {
      'public-request': 'true',
    },
    data: {
      sOpe: '3',
      Data: data,
    },
  });
};

IncidentService.getIncidentItem = (data) => {
  return fetch({
    url: INCIDENT_ITEM,
    method: 'post',
    headers: {
      'public-request': 'true',
    },
    data: {
      sOpe: '1',
      Data: data,
    },
  });
};

export default IncidentService;
