import fetch from 'services/Interceptors/MdsInterceptor';
import * as config from 'configs/AppConfig';

const ContratService = {};
const CONTRAT = 'gc';
// const CONTRAT_ITEM = 'gct';
const NATURE_CONTRAT = 'llnc';

ContratService.getNatureContrat = (data) => {
  return fetch({
    url: NATURE_CONTRAT,
    method: 'post',
    headers: {
      'public-request': 'true',
    },
    data: {
      sOpe: config.API_SCLE_READ,
      Data: data,
    },
  });
};
ContratService.getContrat = (data) => {
  return fetch({
    url: CONTRAT,
    method: 'post',
    headers: {
      'public-request': 'true',
    },
    data: {
      sOpe: config.API_SCLE_READ,
      Data: data,
    },
  });
};
ContratService.getContratItem = (data) => {
  return fetch({
    url: CONTRAT,
    method: 'post',
    headers: {
      'public-request': 'true',
    },
    data: {
      sOpe: config.API_SCLE_READ,
      Data: data,
    },
  });
};

ContratService.createContrat = (data) => {
  return fetch({
    url: CONTRAT,
    method: 'post',
    headers: {
      'public-request': 'true',
    },
    data: {
      sOpe: config.API_SCLE_ADD,
      Data: data,
    },
  });
};

ContratService.editContrat = (data) => {
  return fetch({
    url: CONTRAT,
    method: 'post',
    headers: {
      'public-request': 'true',
    },
    data: {
      sOpe: config.API_SCLE_EDIT,
      Data: data,
    },
  });
};

ContratService.deleteContrat = (data) => {
  return fetch({
    url: CONTRAT,
    method: 'post',
    headers: {
      'public-request': 'true',
    },
    data: {
      sOpe: config.API_SCLE_DELETE,
      Data: data,
    },
  });
};

ContratService.getContratByProviderItem = (data) => {
  console.log('ID PROVIDER', data);
  return fetch({
    url: `/llpct2`,
    method: 'post',
    headers: {
      'public-request': 'true',
    },
    data: {
      sOpe: config.API_SCLE_READ,
      sCle: 'A4054-45',
      Data: data,
    },
  });
};

export default ContratService;
