import {
  SIGNIN,
  SIGNIN_SUCCESS,
  SIGNIN_ERROR,
  SIGNIN_MAIL,
  SIGNIN_MAIL_SUCCESS,
  SIGNIN_MAIL_ERROR,
  SIGNOUT_ERROR,
  AUTH_USER,
  AUTH_MAIL_USER,
  SIGNOUT_SUCCESS,
  SIGNUP_SUCCESS,
  SHOW_AUTH_LOADING,
  SIGNIN_ORGA,
  SIGNIN_ORGA_SUCCESS,
  SIGNIN_ORGA_ERROR,
  SIGNOUT,
  LIST_MULTI_ORGA,
  LIST_MULTI_ORGA_SUCCESS,
  LIST_MULTI_ORGA_ERROR,
  CONNEXION_MONO_COMPTE,
  CONNEXION_MONO_COMPTE_SUCCESS,
  CONNEXION_MONO_COMPTE_ERROR,
  CONNEXION_MULTI_COMPTE_SANS_MAITRE,
  CONNEXION_MULTI_COMPTE_SANS_MAITRE_SUCCESS,
  CONNEXION_MULTI_COMPTE_SANS_MAITRE_ERROR,
  CONNEXION_MULTI_COMPTE_AVEC_MAITRE,
  CONNEXION_MULTI_COMPTE_AVEC_MAITRE_SUCCESS,
  CONNEXION_MULTI_COMPTE_AVEC_MAITRE_ERROR,
  LEC_CHG_INFO_MDP,
  LEC_CHG_INFO_MDP_SUCCESS,
  LEC_CHG_INFO_MDP_ERROR,
  LEC_CHG_INFO_NOMMAIL,
  LEC_CHG_INFO_NOMMAIL_SUCCESS,
  LEC_CHG_INFO_NOMMAIL_ERROR,
  DEMANDE_MODIF_MDP,
  DEMANDE_MODIF_MDP_SUCCESS,
  DEMANDE_MODIF_MDP_ERROR,
  DEMANDE_MODIF_NOMMAIL,
  DEMANDE_MODIF_NOMMAIL_SUCCESS,
  DEMANDE_MODIF_NOMMAIL_ERROR,
  MODIF_MDP,
  MODIF_MDP_SUCCESS,
  MODIF_MDP_ERROR,
  MODIF_NOMMAIL,
  MODIF_NOMMAIL_SUCCESS,
  MODIF_NOMMAIL_ERROR,
  RECEVOIR_MAIL_CACHEE,
  RECEVOIR_MAIL_CACHEE_SUCCESS,
  RECEVOIR_MAIL_CACHEE_ERROR,
  RECEVOIR_PASS_TEMP,
  RECEVOIR_PASS_TEMP_SUCCESS,
  RECEVOIR_PASS_TEMP_ERROR
} from '../constants/Auth';

const initState = {
  isLoading: false,
  redirect: '',
  user: JSON.parse(localStorage.getItem(AUTH_USER)),
  keyMail: JSON.parse(localStorage.getItem(AUTH_MAIL_USER)),
  isChoosedOrga: false,
  isInChoiceOrga: false,
  listMultuOrga: [],
  lecMdp: {},
  lecNomMail: {},
  dmdModifMdpRet: {},
  dmdModifNomMailRet: {},
  modifMdpRet: {},
  modifNomMailRet: {},
  mailHidden: {},
  passTemp: {},
  gotCodeMdpValidation: false,
  isModifiedMdp: false,
};

const auth = (state = initState, action) => {
  switch (action.type) {
    case SIGNIN:
      return {
        ...state,
        isLoading: true,
        isChoosedOrga: true,
        isInChoiceOrga: false,
      };
    case SIGNIN_SUCCESS:
      return {
        ...state,
        isLoading: false,
        redirect: '/app/todolist',
        user: action.user,
        keyMail: action.keyMail,
      };
    case SIGNIN_ERROR:
      return {
        ...state,
        isLoading: false,
      };

    case SIGNIN_ORGA:
      return {
        ...state,
        isLoading: true,
      };
    case SIGNIN_ORGA_SUCCESS:
      return {
        ...state,
        isLoading: false,
        redirect: '/',
        user: action.user,
      };
    case SIGNIN_ORGA_ERROR:
      return {
        ...state,
        isLoading: false,
      };

    case SIGNIN_MAIL:
      return {
        ...state,
        isLoading: true,
      };
    case SIGNIN_MAIL_SUCCESS:
      return {
        ...state,
        isLoading: false,
        redirect: '/organisation-choice',
        keyMail: action.keyMail,
        user: action.user,
        isInChoiceOrga: true,
      };
    case SIGNIN_MAIL_ERROR:
      return {
        ...state,
        isLoading: false,
      };
    case SIGNOUT_ERROR:
    case SIGNOUT_SUCCESS: {
      return {
        ...state,
        user: null,
        redirect: '/',
        isLoading: false,
      };
    }
    case SIGNUP_SUCCESS: {
      return {
        ...state,
        isLoading: false,
      };
    }
    case SHOW_AUTH_LOADING: {
      return {
        ...state,
        isLoading: true,
      };
    }
    case SIGNOUT: {
      return {
        ...state,
        isChoosedOrga: false,
        isInChoiceOrga: false,
      };
    }
    case LIST_MULTI_ORGA: {
      return {
        ...state,
        isLoading: true,
      }
    }
    case LIST_MULTI_ORGA_SUCCESS: {
      return {
        ...state,
        listMultuOrga: action.data,
        isLoading: false,
      };
    }
    case LIST_MULTI_ORGA_ERROR: {
      return {
        ...state,
        isLoading: false,
      }
    }
    case CONNEXION_MONO_COMPTE:
      return {
        ...state,
        isLoading: true,
      };
    case CONNEXION_MONO_COMPTE_SUCCESS:
      return {
        ...state,
        isLoading: false,
        redirect: '/',
        user: action.user,
      };
    case CONNEXION_MONO_COMPTE_ERROR:
      return {
        ...state,
        isLoading: false,
      };
    case CONNEXION_MULTI_COMPTE_SANS_MAITRE:
      return {
        ...state,
        isLoading: true,
      };
    case CONNEXION_MULTI_COMPTE_SANS_MAITRE_SUCCESS:
      return {
        ...state,
        isLoading: false,
        redirect: '/',
        user: action.user,
      };
    case CONNEXION_MULTI_COMPTE_SANS_MAITRE_ERROR:
      return {
        ...state,
        isLoading: false,
      };
    case CONNEXION_MULTI_COMPTE_AVEC_MAITRE:
      return {
        ...state,
        isLoading: true,
      };
    case CONNEXION_MULTI_COMPTE_AVEC_MAITRE_SUCCESS:
      return {
        ...state,
        isLoading: false,
        redirect: '/',
        user: action.user,
      };
    case CONNEXION_MULTI_COMPTE_AVEC_MAITRE_ERROR:
      return {
        ...state,
        isLoading: false,
      };
    case LEC_CHG_INFO_NOMMAIL:
      return {
        ...state,
        isLoading: true,
      };
    case LEC_CHG_INFO_NOMMAIL_SUCCESS:
      return {
        ...state,
        isLoading: false,
        lecNomMail: action.lecNomMail,
      };
    case LEC_CHG_INFO_NOMMAIL_ERROR:
      return {
        ...state,
        isLoading: false,
      };
    case LEC_CHG_INFO_MDP:
      return {
        ...state,
        isLoading: true,
      };
    case LEC_CHG_INFO_MDP_SUCCESS:
      return {
        ...state,
        isLoading: false,
        lecMdp: action.lecMdp,
      };
    case LEC_CHG_INFO_MDP_ERROR:
      return {
        ...state,
        isLoading: false,
      };
    case DEMANDE_MODIF_MDP:
      return {
        ...state,
        isLoading: true,
      };
    case DEMANDE_MODIF_MDP_SUCCESS:
      return {
        ...state,
        isLoading: false,
        dmdModifMdpRet: action.dmdModifMdpRet,
        gotCodeMdpValidation: true,
      };
    case DEMANDE_MODIF_MDP_ERROR:
      return {
        ...state,
        isLoading: false,
      };
    case DEMANDE_MODIF_NOMMAIL:
      return {
        ...state,
        isLoading: true,
      };
    case DEMANDE_MODIF_NOMMAIL_SUCCESS:
      return {
        ...state,
        isLoading: false,
        dmdModifNomMailRet: action.dmdModifNomMailRet,
      };
    case DEMANDE_MODIF_NOMMAIL_ERROR:
      return {
        ...state,
        isLoading: false,
      };
    case MODIF_MDP:
      return {
        ...state,
        isLoading: true,
      };
    case MODIF_MDP_SUCCESS:
      return {
        ...state,
        isLoading: false,
        modifMdpRet: action.modifMdpRet,
        user: JSON.parse(localStorage.getItem(AUTH_USER)),
        isModifiedMdp: true,
      };
    case MODIF_MDP_ERROR:
      return {
        ...state,
        isLoading: false,
      };
    case MODIF_NOMMAIL:
      return {
        ...state,
        isLoading: true,
      };
    case RECEVOIR_MAIL_CACHEE:
      return {
        ...state,
        isLoading: true,
      };
    case RECEVOIR_PASS_TEMP:
      return {
        ...state,
        isLoading: true,
      };
    case MODIF_NOMMAIL_SUCCESS:
      return {
        ...state,
        isLoading: false,
        modifNomMailRet: action.modifNomMailRet,
      };
    case RECEVOIR_MAIL_CACHEE_SUCCESS:
      return {
        ...state,
        isLoading: false,
        mailHidden: action.mailHidden,
      };
    case RECEVOIR_MAIL_CACHEE_ERROR:
      return {
        ...state,
        isLoading: false,
      };
    case RECEVOIR_PASS_TEMP_SUCCESS:
      return {
        ...state,
        isLoading: false,
        passTemp: action.passTemp,
      };
    case RECEVOIR_PASS_TEMP_ERROR:
      return {
        ...state,
        isLoading: false,
      };
    case MODIF_NOMMAIL_ERROR:
      return {
        ...state,
        isLoading: false,
      };
    default:
      return state;
  }
};

export default auth;
