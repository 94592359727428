import React, { useEffect } from 'react';
import { Typography } from 'antd';

const { Title } = Typography;
const MaintenancePage = (props) => {
  useEffect(() => {
    localStorage.clear();
  }, [])
  return (
    <div style={{
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      minHeight: '100vh', // Ensure that the container takes up the entire viewport height
    }}>
      <div className="row col-md-12 justify-content-center text-center">
        <div>
          <img
            id="img360"
            src={`${process.env.PUBLIC_URL}/img/icon-black.png`}
            alt="Maintenance Icon"
            className='mb-3'
            style={{
            borderRadius: '100px',
            marginLeft: '0px',
            WebkitAnimation: 'spin 4s linear infinite',
            MozAnimation: 'spin 4s linear infinite',
            animation: 'spin 4s linear infinite'
            }}
          />
          <Title className='mb-3' level={2}>Le site est actuellement en maintenance en raison d'une mise à jour.
            <br /> Excusez nous pour la gêne occasionnée.</Title>
          <Title level={3}>Merci de votre compréhension.</Title>
        </div>
      </div>
    </div>
  );
};

export default MaintenancePage;