export const ADD_ACTORS_BOOK_ITEM = 'ADD_ACTORS_BOOK_ITEM'
export const ADD_ACTORS_BOOK_ITEM_SUCCESS = 'ADD_ACTORS_BOOK_ITEM_SUCCESS'
export const ADD_ACTORS_BOOK_ITEM_ERROR = 'ADD_ACTORS_BOOK_ITEM_ERROR'
export const GET_ACTORS_BOOK_LIST = 'GET_ACTORS_BOOK_LIST'
export const GET_ACTORS_BOOK_LIST_SUCCESS = 'GET_ACTORS_BOOK_LIST_SUCCESS'
export const GET_ACTORS_BOOK_LIST_ERROR = 'GET_ACTORS_BOOK_LIST_ERROR'
export const GET_ACTORS_BOOK_ITEM = 'GET_ACTORS_BOOK_ITEM'
export const GET_ACTORS_BOOK_ITEM_SUCCESS = 'GET_ACTORS_BOOK_ITEM_SUCCESS'
export const GET_ACTORS_BOOK_ITEM_ERROR = 'GET_ACTORS_BOOK_ITEM_ERROR'
export const UPDATE_ACTORS_BOOK_ITEM = 'UPDATE_ACTORS_BOOK_ITEM'
export const UPDATE_ACTORS_BOOK_ITEM_SUCCESS = 'UPDATE_ACTORS_BOOK_ITEM_SUCCESS'
export const UPDATE_ACTORS_BOOK_ITEM_ERROR = 'UPDATE_ACTORS_BOOK_ITEM_ERROR'
export const GET_ACTORS_BOOK_WITH_AXE_LIST = 'GET_ACTORS_BOOK_WITH_AXE_LIST'
export const GET_ACTORS_BOOK_WITH_AXE_LIST_SUCCESS = 'GET_ACTORS_BOOK_WITH_AXE_LIST_SUCCESS'
export const GET_ACTORS_BOOK_WITH_AXE_LIST_ERROR = 'GET_ACTORS_BOOK_WITH_AXE_LIST_ERROR'
export const GET_MISSION_ACTORS_BOOK_LIST = 'GET_MISSION_ACTORS_BOOK_LIST'
export const GET_MISSION_ACTORS_BOOK_LIST_SUCCESS = 'GET_MISSION_ACTORS_BOOK_LIST_SUCCESS'
export const GET_MISSION_ACTORS_BOOK_LIST_ERROR = 'GET_MISSION_ACTORS_BOOK_LIST_ERROR'
export const SHOW_LOADING = 'SHOW_ACTORS_BOOK_LOADING';
export const DELETE_ACTORS_BOOK = 'DELETE_ACTORS_BOOK';
export const DELETE_ACTORS_BOOK_ITEM_SUCCESS = 'DELETE_ACTORS_BOOK_ITEM_SUCCESS';
export const DELETE_ACTORS_BOOK_ERROR = 'DELETE_ACTORS_BOOK_ERROR';