import {
  GET_TODOLIST_LIST,
  GET_TODOLIST_LIST_SUCCESS,
  GET_TODOLIST_LIST_ERROR,
  CLOSE_TODOLIST_ITEM,
  CLOSE_TODOLIST_ITEM_SUCCESS,
  CLOSE_TODOLIST_ITEM_ERROR,
  SHOW_LOADING,
} from '../constants/Todolist';

export const getTodolistList = () => {
  return {
    type: GET_TODOLIST_LIST,
    data: { "nEstFini": 0, "sSens": "DESC", "nParamPrem": 0, "nParamNbEnr": 5 },
  };
};

export const getTodolistListSuccess = (data) => {
  return {
    type: GET_TODOLIST_LIST_SUCCESS,
    data,
  };
};

export const getTodolistListError = (error) => {
  return {
    type: GET_TODOLIST_LIST_ERROR,
    ...error,
  };
};

export const closeTodolistItem = (nIDTodoList) => {
  return {
    type: CLOSE_TODOLIST_ITEM,
    data: nIDTodoList,
  };
};

export const closeTodolistItemSuccess = (data) => {
  return {
    type: CLOSE_TODOLIST_ITEM_SUCCESS,
    data,
  };
};

export const closeTodolistItemError = (error) => {
  return {
    type: CLOSE_TODOLIST_ITEM_ERROR,
    ...error,
  };
};

export const showLoading = () => {
  return {
    type: SHOW_LOADING,
  };
};
