import {
  GET_PROVIDER_LIST,
  GET_PROVIDER_LIST_SUCCESS,
  GET_PROVIDER_LIST_ERROR,
  UPDATE_PROVIDER_ITEM,
  UPDATE_PROVIDER_ITEM_SUCCESS,
  UPDATE_PROVIDER_ERROR,
  GET_PROVIDER_ITEM,
  GET_PROVIDER_ITEM_SUCCESS,
  GET_PROVIDER_ITEM_ERROR,
  ADD_PROVIDER,
  ADD_PROVIDER_ITEM_SUCCESS,
  ADD_PROVIDER_ERROR,
  DELETE_PROVIDER,
  DELETE_PROVIDER_ITEM_SUCCESS,
  DELETE_PROVIDER_ERROR,
  SHOW_LOADING,
} from '../constants/Provider';

import * as converter from '../converter/Provider';

export const getProviderList = () => {
  return {
    type: GET_PROVIDER_LIST,
    data: {
      sC: 0,
      nN: 0,
    },
  };
};

export const getProviderListSuccess = (mdsProvider) => {
  return {
    type: GET_PROVIDER_LIST_SUCCESS,
    data: converter.convertMdsProviderListInProvider(mdsProvider),
  };
};

export const getProviderListError = (error) => {
  return {
    type: GET_PROVIDER_LIST_ERROR,
    ...error,
  };
};

export const updateProvider = (id, provider) => {

  return {
    type: UPDATE_PROVIDER_ITEM,
    data: {
      nBPresta: 1,
      tab_Presta: [converter.convertProviderToMdsProvider(id, provider),],
    },
  };
};

export const updateProviderSuccess = (mdsProvider) => {
  return {
    type: UPDATE_PROVIDER_ITEM_SUCCESS,
    item: converter.convertMdsProviderItemInProvider(mdsProvider.tab_Presta[0]),
  };
};

export const updateProviderError = (error) => {
  return {
    type: UPDATE_PROVIDER_ERROR,
    ...error,
  };
};

export const addProvider = (provider) => {

  return {
    type: ADD_PROVIDER,
    data: {
      nBPresta: 1,
      tab_Presta: [converter.convertAddProviderToMdsProvider(provider),],
    },
  };
};

export const addProviderSuccess = (mdsProvider) => {
  return {
    type: ADD_PROVIDER_ITEM_SUCCESS,
    item: converter.convertAddMdsProviderItemInProvider(mdsProvider.tab_Presta[0]),
  };
};

export const addProviderError = (error) => {
  return {
    type: ADD_PROVIDER_ERROR,
    ...error,
  };
};

export const deleteProvider = (idProvider) => {

  return {
    type: DELETE_PROVIDER,
    data: {
      nBPresta: 1,
      tab_Presta: [converter.convertDeleteProviderToMdsProvider(idProvider),],
    },
  };
};

export const deleteProviderSuccess = (mdsProvider) => {
  return {
    type: DELETE_PROVIDER_ITEM_SUCCESS,
    item: converter.convertAddMdsProviderItemInProvider(mdsProvider.tab_Presta[0]),
  };
};

export const deleteProviderError = (error) => {
  return {
    type: DELETE_PROVIDER_ERROR,
    ...error,
  };
};

export const getProviderItem = (id) => {
  return {
    type: GET_PROVIDER_ITEM,
    data: {
      nBPresta: 1,
      tab_Presta: [{ nIDPresta: id }]

    }
  };
};

export const getProviderItemSuccess = (item) => {
  return {
    type: GET_PROVIDER_ITEM_SUCCESS,
    item: converter.convertMdsProviderItemInProvider(item.tab_Presta[0]),
  };
};

export const getProviderItemError = (error) => {
  return {
    type: GET_PROVIDER_ITEM_ERROR,
    ...error
  };
};

export const showLoading = () => {
  return {
    type: SHOW_LOADING,
  };
};