import { all, takeEvery, put, fork, call } from 'redux-saga/effects';
import ContratFeatService from 'services/ContratFeatService';
import {
  ADD_CONTRAT_FEAT_ITEM,
  GET_CONTRAT_FEAT_LIST,
  GET_CONTRAT_FEAT_ITEM,
  UPDATE_CONTRAT_FEAT_ITEM,
  DISABLE_CONTRAT_FEAT_ITEM,
  GET_INFORMATIONS_CONTRAT_ITEM,
  GET_CONTRAT_FEAT_BY_PROVIDER_ITEM
} from '../constants/ContratFeat';

import {
  addContratItemError,
  addContratItemSuccess,
  getContratItemError,
  getContratItemSuccess,
  updateContratItemError,
  updateContratItemSuccess,
  getContratListError,
  getContratListSuccess,
  disableContratItemError,
  disableContratItemSuccess,
  getInformationsContratError,
  getInformationsContratSuccess,
  getContratByProviderItemSuccess,
  getContratByProviderItemError
} from '../actions/ContratFeat';

export function* getContratList() {
  yield takeEvery(GET_CONTRAT_FEAT_LIST, function* ({ data }) {
    try {
      const response = yield call(ContratFeatService.getContratList, data);
      yield put(getContratListSuccess(response.Data));
    } catch (err) {
      yield put(getContratListError(err));
    }
  });
}
export function* getContratItem() {
  yield takeEvery(GET_CONTRAT_FEAT_ITEM, function* ({ data }) {
    try {
      const response = yield call(ContratFeatService.getContratItem, data);
      yield put(getContratItemSuccess(response.Data));
    } catch (err) {
      yield put(getContratItemError(err));
    }
  });
}

export function* updateContratItem() {
  yield takeEvery(UPDATE_CONTRAT_FEAT_ITEM, function* ({ data }) {
    try {
      const response = yield call(ContratFeatService.updateContratItem, data);
      yield put(updateContratItemSuccess(response.Data));
    } catch (err) {
      yield put(updateContratItemError(err));
    }
  });
}

export function* addContratItem() {
  yield takeEvery(ADD_CONTRAT_FEAT_ITEM, function* ({ data }) {
    try {
      const response = yield call(ContratFeatService.createContratItem, data);
      yield put(addContratItemSuccess(response.Data));
    } catch (err) {
      yield put(addContratItemError(err));
    }
  });
}

export function* disableContratItem() {
  yield takeEvery(DISABLE_CONTRAT_FEAT_ITEM, function* ({ data }) {
    try {
      const response = yield call(ContratFeatService.disableContratItem, data);
      yield put(disableContratItemSuccess(response.Data));
    } catch (err) {
      yield put(disableContratItemError(err));
    }
  });
}

export function* getInformationsContrat() {
  yield takeEvery(GET_INFORMATIONS_CONTRAT_ITEM, function* ({ data }) {
    try {
      const response = yield call(ContratFeatService.getInformationsContrat, data);
      yield put(getInformationsContratSuccess(response.Data));
    } catch (err) {
      yield put(getInformationsContratError(err));
    }
  });
}

export function* getContratByProviderItem() {
  yield takeEvery(GET_CONTRAT_FEAT_BY_PROVIDER_ITEM, function* ({ data }) {
    try {
      const response = yield call(ContratFeatService.getContratByProviderItem, data);
      yield put(getContratByProviderItemSuccess(response.Data));
    } catch (err) {
      // console.log(err);
      yield put(getContratByProviderItemError(err));
    }
  });
}

export default function* rootSaga() {
  yield all([
    fork(getContratList),
    fork(getContratItem),
    fork(addContratItem),
    fork(updateContratItem),
    fork(disableContratItem),
    fork(getInformationsContrat),
    fork(getContratByProviderItem)
  ]);
}
