export const ADD_INCIDENT_ITEM = 'ADD_INCIDENT_ITEM';
export const ADD_INCIDENT_ITEM_SUCCESS = 'ADD_INCIDENT_ITEM_SUCCESS';
export const ADD_INCIDENT_ITEM_ERROR = 'ADD_INCIDENT_ITEM_ERROR';
export const GET_INCIDENT_LIST = 'GET_INCIDENT_LIST';
export const GET_INCIDENT_LIST_SUCCESS = 'GET_INCIDENT_LIST_SUCCESS';
export const GET_INCIDENT_LIST_ERROR = 'GET_INCIDENT_LIST_ERROR';
export const GET_INCIDENT_ITEM = 'GET_INCIDENT_ITEM';
export const GET_INCIDENT_ITEM_SUCCESS = 'GET_INCIDENT_ITEM_SUCCESS';
export const GET_INCIDENT_ITEM_ERROR = 'GET_INCIDENT_ITEM_ERROR';
export const UPDATE_INCIDENT_ITEM = 'UPDATE_INCIDENT_ITEM';
export const UPDATE_INCIDENT_ITEM_SUCCESS = 'UPDATE_INCIDENT_ITEM_SUCCESS';
export const UPDATE_INCIDENT_ITEM_ERROR = 'UPDATE_INCIDENT_ITEM_ERROR';
export const SHOW_LOADING = 'SHOW_INCIDENT_LOADING';
