import { LOADING_ITEM, LOADING_LIST } from 'configs/AppConfig';
import {
  ADD_CREATION_DOCUMENT_ITEM,
  ADD_CREATION_DOCUMENT_ITEM_SUCCESS,
  ADD_CREATION_DOCUMENT_ITEM_ERROR,
  LIST_DOCUMENT_CREATED_ITEM,
  LIST_DOCUMENT_CREATED_ITEM_SUCCESS,
  LIST_DOCUMENT_CREATED_ITEM_ERROR,
  GET_DOCUMENT_CREATED_ITEM,
  GET_DOCUMENT_CREATED_ITEM_SUCCESS,
  GET_DOCUMENT_CREATED_ITEM_ERROR,  
  SHOW_LOADING,
  DO_NOTHING,
  GET_FICHE_PDF,
  GET_FICHE_PDF_SUCCESS,
  GET_FICHE_PDF_ERROR,
} from '../constants/CreationDocument';

const initState = {
  isLoading: false,
  isLoadingAdd: false,
  data: [],
  dataShouldUpdate: false,
  item: {
    message: ''
  }
};

const auth = (state = initState, action) => {
  switch (action.type) {
    case SHOW_LOADING: {
      return {
        ...state,
        isLoading: true,
      };
    }

    case GET_DOCUMENT_CREATED_ITEM: {
      return { ...state, isLoading: true }
    }

    case LIST_DOCUMENT_CREATED_ITEM : {
      return {
        ...state,
        isLoading: true,
      };
    }
    case ADD_CREATION_DOCUMENT_ITEM : {
      return {
        ...state,
        isLoadingAdd: true,
      };
    }
    case GET_FICHE_PDF : {
      return {
        ...state,
        isLoading: true,
      };
    }

    case GET_DOCUMENT_CREATED_ITEM_SUCCESS : {
      return {
        ...state,
        data: action?.data,
        dataShouldUpdate: true,
        isLoading: false,
        item: action?.item,
      };
    }

    case LIST_DOCUMENT_CREATED_ITEM_SUCCESS: {
      return {
        ...state,
        item: action.item,
        dataShouldUpdate: false,
        isLoading: false,
      };
    }

    case ADD_CREATION_DOCUMENT_ITEM_SUCCESS: {
      return {
        ...state,
        item: action.data,
        dataShouldUpdate: true,
        isLoadingAdd: false,
      };
    }

    case ADD_CREATION_DOCUMENT_ITEM_ERROR : {
      return {
        ...state,
        isLoadingAdd: false,
      };
    }
    case LIST_DOCUMENT_CREATED_ITEM_ERROR:
    case GET_DOCUMENT_CREATED_ITEM_ERROR: 
    case GET_FICHE_PDF_ERROR : {
      return {
        ...state,
        isLoading: false,
      };
    }

    case DO_NOTHING : 
    case GET_FICHE_PDF_SUCCESS : {
      // console.log('DO_NOTHING')
      return {
        ...state,
        item: action.item,
        dataShouldUpdate: false,
        isLoading: false,
      };
    }

    default:
      return state;
  }
};

export default auth;

