import {
  ADD_DEMANDE_DE_DROITS_ITEM,
  ADD_DEMANDE_DE_DROITS_ITEM_SUCCESS,
  ADD_DEMANDE_DE_DROITS_ITEM_ERROR,
  GET_DEMANDE_DE_DROITS_LIST,
  GET_DEMANDE_DE_DROITS_LIST_SUCCESS,
  GET_DEMANDE_DE_DROITS_LIST_ERROR,
  GET_DEMANDE_DE_DROITS_ITEM,
  GET_DEMANDE_DE_DROITS_ITEM_SUCCESS,
  GET_DEMANDE_DE_DROITS_ITEM_ERROR,
  UPDATE_DEMANDE_DE_DROITS_ITEM,
  UPDATE_DEMANDE_DE_DROITS_ITEM_SUCCESS,
  UPDATE_DEMANDE_DE_DROITS_ITEM_ERROR,
  SHOW_LOADING,
} from '../constants/DemandeDeDroits';

export const addDemandeDeDroitsItem = (item) => {
  return {
    type: ADD_DEMANDE_DE_DROITS_ITEM,
    data: item,
  };
};

export const addDemandeDeDroitsItemSuccess = (data) => {
  return {
    type: ADD_DEMANDE_DE_DROITS_ITEM_SUCCESS,
    message: 'La demande de droits a bien été ajoutée',
    data,
  };
};

export const addDemandeDeDroitsItemError = (error) => {
  return {
    type: ADD_DEMANDE_DE_DROITS_ITEM_ERROR,
    ...error,
  };
};

export const getDemandeDeDroitsList = () => {
  return {
    type: GET_DEMANDE_DE_DROITS_LIST,
    data: {
      sC: 0,
      nN: 0,
    },
  };
};

export const getDemandeDeDroitsListSuccess = (data) => {
  return {
    type: GET_DEMANDE_DE_DROITS_LIST_SUCCESS,
    data,
  };
};

export const getDemandeDeDroitsListError = (error) => {
  return {
    type: GET_DEMANDE_DE_DROITS_LIST_ERROR,
    ...error,
  };
};

export const getDemandeDeDroitsItem = (id) => {
  return {
    type: GET_DEMANDE_DE_DROITS_ITEM,
    data: { DataREG_DD: { nIDREG_DD: id } },
  };
};

export const getDemandeDeDroitsItemSuccess = (item) => {
  return {
    type: GET_DEMANDE_DE_DROITS_ITEM_SUCCESS,
    item,
  };
};

export const getDemandeDeDroitsItemError = (error) => {
  return {
    type: GET_DEMANDE_DE_DROITS_ITEM_ERROR,
    ...error,
  };
};

export const updateDemandeDeDroitsItem = (item) => {
  return {
    type: UPDATE_DEMANDE_DE_DROITS_ITEM,
    data: { DataREG_DD: item },
  };
};

export const updateDemandeDeDroitsItemSuccess = (item) => {
  return {
    type: UPDATE_DEMANDE_DE_DROITS_ITEM_SUCCESS,
    message: 'La demande de droits a bien été mise a jour',
    item,
  };
};

export const updateDemandeDeDroitsItemError = (error) => {
  return {
    type: UPDATE_DEMANDE_DE_DROITS_ITEM_ERROR,
    ...error,
  };
};

export const showLoading = () => {
  return {
    type: SHOW_LOADING,
  };
};
