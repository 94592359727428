import { all, takeEvery, put, fork, call } from 'redux-saga/effects';
import OrganisationService from 'services/OrganisationService';
import { GET_ORGANISATION, GET_ORGANISATION_INFOS, UPDATE_ORGANISATION } from '../constants/Organisation';
import {
  getOrganisationSuccess,
  getOrganisationError,
  updateOrganisationSuccess,
  updateOrganisationError,
  getOrganisationInfosSuccess,
  getOrganisationInfosError,
} from '../actions/Organisation';

export function* getOrganisation() {
  yield takeEvery(GET_ORGANISATION, function* ({ data }) {
    try {
      const response = yield call(OrganisationService.getOrganisation, data);
      // console.log('Get organisation width data : ', data , ' and response ', response);
      yield put(getOrganisationSuccess(response.Data));
    } catch (err) {
      // console.log(err);
      yield put(getOrganisationError(err));
    }
  });
}

export function* updateOrganisation() {
  yield takeEvery(UPDATE_ORGANISATION, function* ({ data }) {
    try {
      const response = yield call(OrganisationService.updateOrganisation, data);
      // console.log('Update organisation width data : ', data, 'and response ', response);
      yield put(updateOrganisationSuccess(response.Data));
    } catch (err) {
      // console.log(err);
      yield put(updateOrganisationError(err));
    }
  });
}

export default function* rootSaga() {
  yield all([
    fork(getOrganisation),
    fork(updateOrganisation),
  ]);
}
