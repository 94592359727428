import {
  ADD_CONTRAT_ITEM,
  ADD_CONTRAT_ITEM_SUCCESS,
  ADD_CONTRAT_ITEM_ERROR,
  GET_CONTRAT_LIST,
  GET_CONTRAT_LIST_SUCCESS,
  GET_CONTRAT_LIST_ERROR,
  GET_CONTRAT_ITEM,
  GET_CONTRAT_ITEM_SUCCESS,
  GET_CONTRAT_ITEM_ERROR,
  GET_NATURE_CONTRAT_LIST,
  GET_NATURE_CONTRAT_LIST_SUCCESS,
  GET_NATURE_CONTRAT_LIST_ERROR,
  UPDATE_CONTRAT_ITEM,
  UPDATE_CONTRAT_ITEM_SUCCESS,
  UPDATE_CONTRAT_ITEM_ERROR,
  DELETE_CONTRAT_ITEM,
  DELETE_CONTRAT_ITEM_SUCCESS,
  DELETE_CONTRAT_ITEM_ERROR,
  SHOW_LOADING,
  GET_CONTRAT_BY_PROVIDER_ITEM,
  GET_CONTRAT_BY_PROVIDER_ITEM_SUCCESS,
  GET_CONTRAT_BY_PROVIDER_ITEM_ERROR,
} from '../constants/Contrat';

import * as converter from '../converter/Contrat';

export const addContratItem = (item) => {
  return {
    type: ADD_CONTRAT_ITEM,
    data: {
      nNbContrat: 1,
      tab_Contrat: [converter.contratItemToMdsContrat(null, item)],
    },
  };
};

export const addContratItemSuccess = (item) => {
  return {
    type: ADD_CONTRAT_ITEM_SUCCESS,
    message: 'Le contrat a bien été crée',
    item: converter.mdsContratItemToContrat(item.tab_Contrat[0]),
  };
};

export const addContratItemError = (error) => {
  return {
    type: ADD_CONTRAT_ITEM_ERROR,
    ...error,
  };
};

export const getContratList = () => {
  return {
    type: GET_CONTRAT_LIST,
    data: { tab_Contrat: [{ nIDContrat: 0 }] },
  };
};

export const getContratListSuccess = (data) => {
  return {
    type: GET_CONTRAT_LIST_SUCCESS,
    data: converter.mdsContratListToContratList(data.tab_Contrat),
  };
};

export const getContratListError = (error) => {
  return {
    type: GET_CONTRAT_LIST_ERROR,
    ...error,
  };
};

export const getNatureContratList = () => {
  return {
    type: GET_NATURE_CONTRAT_LIST,
    data: {},
  };
};

export const getNatureContratListSuccess = (data) => {
  return {
    type: GET_NATURE_CONTRAT_LIST_SUCCESS,
    data: converter.mdsNatureContratListToNatureContratList(data.tab_NatContrat),
  };
};

export const getNatureContratListError = (error) => {
  return {
    type: GET_NATURE_CONTRAT_LIST_ERROR,
    ...error,
  };
};

export const getContratItem = (id) => {
  if (id) {
    return {
      type: GET_CONTRAT_ITEM,
      data: { tab_Contrat: [{ nIDContrat: id }] },
      // data: { dataContrat: { nIDContrat: id } },
    };
  }
  return {
    type: GET_CONTRAT_ITEM_SUCCESS,
    item: {},
  };
};

export const getContratItemSuccess = (item) => {
  return {
    type: GET_CONTRAT_ITEM_SUCCESS,
    item: converter.mdsContratItemToContrat(item.tab_Contrat[0]),
    /*
    item: {
      contrat: converter.mdsContratItemToContrat(item.DataContrat),
      processes: item.tab_ListeTrait,
      natureContrats: item.tab_NatContrat,
      providers: item.tab_Presta
    },
    */
  };
};

export const getContratItemError = (error) => {
  return {
    type: GET_CONTRAT_ITEM_ERROR,
    ...error,
  };
};

export const updateContrat = (id, item) => {
  return {
    type: UPDATE_CONTRAT_ITEM,
    data: {
      tab_Contrat: [
        {
          ...converter.contratItemToMdsContrat(id, item),
        },
      ],
    },
  };
};

export const updateContratSuccess = (item) => {
  // console.log("updateContratSuccess", item)
  return {
    type: UPDATE_CONTRAT_ITEM_SUCCESS,
    message: 'Le contrat a bien été mise a jour',
    item: converter.mdsContratItemToContrat(item.tab_Contrat[0]),
  };
};

export const updateContratError = (error) => {
  return {
    type: UPDATE_CONTRAT_ITEM_ERROR,
    ...error,
  };
};

export const deleteContrat = (id) => {
  return {
    type: DELETE_CONTRAT_ITEM,
    data: {
      tab_Contrat: [
        {
          nIDContrat: id,
        },
      ],
    },
  };
};

export const deleteContratSuccess = (item) => {
  return {
    type: DELETE_CONTRAT_ITEM_SUCCESS,
    message: 'Le contrat a bien été supprimé',
    item: converter.mdsContratItemToContrat(item.tab_Contrat[0]),
  };
};

export const deleteContratError = (error) => {
  return {
    type: DELETE_CONTRAT_ITEM_ERROR,
    ...error,
  };
};

export const showLoading = () => {
  return {
    type: SHOW_LOADING,
  };
};

export const getContratByProviderItem = (id) => {
  return {
    type: GET_CONTRAT_BY_PROVIDER_ITEM,
    data: { nIDPrestataire: id },
  };
};

export const getContratByProviderItemSuccess = (data) => {
  return {
    type: GET_CONTRAT_BY_PROVIDER_ITEM_SUCCESS,
    data: data.tab_PrestaCTRTRT,
  };
};

export const getContratByProviderItemError = (error) => {
  return {
    type: GET_CONTRAT_BY_PROVIDER_ITEM_ERROR,
    ...error,
  };
};
