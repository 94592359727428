export const GET_PROVIDER_LIST = 'GET_PROVIDER_LIST';
export const GET_PROVIDER_LIST_SUCCESS = 'GET_PROVIDER_LIST_SUCCESS';
export const GET_PROVIDER_LIST_ERROR = 'GET_PROVIDER_LIST_ERROR';
export const UPDATE_PROVIDER_ITEM = 'UPDATE_PROVIDER_ITEM';
export const UPDATE_PROVIDER_ITEM_SUCCESS = 'UPDATE_PROVIDER_ITEM_SUCCESS';
export const UPDATE_PROVIDER_ERROR = 'UPDATE_PROVIDER_ERROR';
export const ADD_PROVIDER = 'ADD_PROVIDER';
export const ADD_PROVIDER_ITEM_SUCCESS = 'ADD_PROVIDER_ITEM_SUCCESS';
export const ADD_PROVIDER_ERROR = 'ADD_PROVIDER_ERROR';
export const DELETE_PROVIDER = 'DELETE_PROVIDER';
export const DELETE_PROVIDER_ITEM_SUCCESS = 'DELETE_PROVIDER_ITEM_SUCCESS';
export const DELETE_PROVIDER_ERROR = 'DELETE_PROVIDER_ERROR';
export const SHOW_SUCCESS_MESSAGE = 'SHOW_SUCCESS_MESSAGE';
export const GET_PROVIDER_ITEM = 'GET_PROVIDER_ITEM';
export const GET_PROVIDER_ITEM_SUCCESS = 'GET_PROVIDER_ITEM_SUCCESS';
export const GET_PROVIDER_ITEM_ERROR = 'GET_PROVIDER_ITEM_ERROR';

export const SHOW_LOADING = 'SHOW_PROVIDER_LOADING';