import {
  GET_AXE_FCT, GET_AXE_FCT_ERROR,
  GET_AXE_FCT_SUCCESS, GET_AXE_GEO,
  GET_AXE_GEO_ERROR, GET_AXE_GEO_SUCCESS,
  GET_AXE_MET, GET_AXE_MET_ERROR,
  GET_AXE_MET_SUCCESS,
  GET_AXE_ALL,
  GET_AXE_ALL_SUCCESS,
  GET_AXE_ALL_ERROR,
  SHOW_LOADING
} from "store/constants/Axe";

export const getAxeFCT = () => {
  return {
    type: GET_AXE_FCT,
    data: {},
  };
};

export const getAxeFCTSuccess = (data) => {
  return {
    type: GET_AXE_FCT_SUCCESS,
    data,
  };
};

export const getAxeFCTError = (error) => {
  return {
    type: GET_AXE_FCT_ERROR,
    ...error,
  };
};

export const getAxeMET = () => {
  return {
    type: GET_AXE_MET,
    data: {},
  };
};

export const getAxeMETSuccess = (data) => {
  return {
    type: GET_AXE_MET_SUCCESS,
    data,
  };
};

export const getAxeMETError = (error) => {
  return {
    type: GET_AXE_MET_ERROR,
    ...error,
  };
};

export const getAxeGEO = () => {
  return {
    type: GET_AXE_GEO,
    data: {},
  };
};

export const getAxeGEOSuccess = (data) => {
  return {
    type: GET_AXE_GEO_SUCCESS,
    data,
  };
};

export const getAxeGEOError = (error) => {
  return {
    type: GET_AXE_GEO_ERROR,
    ...error,
  };
};

export const getAxeALL = () => {
  return {
    type: GET_AXE_ALL,
    data: {},
  };
};

export const getAxeALLSuccess = (data) => {
  return {
    type: GET_AXE_ALL_SUCCESS,
    data,
  };
};

export const getAxeALLError = (error) => {
  return {
    type: GET_AXE_ALL_ERROR,
    ...error,
  };
};


export const showLoading = () => {
  return {
    type: SHOW_LOADING,
  };
};
