import React, { useEffect } from 'react';
import { Route, Switch, Redirect, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { ConfigProvider } from 'antd';

import { hideNotification } from 'store/actions/App'
import openNotification from 'components/Notification';

import AuthLayout from 'layouts/AuthLayout';
import AppLayout from 'layouts/AppLayout';

import { langConfig } from 'configs/LangConfig';
import { APP_PREFIX_PATH, AUTH_PREFIX_PATH } from 'configs/AppConfig';
import Utils from 'configs/UtilFunctions'
import AuthMailLayout from './auth-mail-views/organisationChoice';

function RouteInterceptor({ children, isAuthenticated, ...rest }) {
  return (
    <Route
      {...rest}
      render={({ location }) =>
        isAuthenticated || Utils.getEnv('REACT_APP_ENV') === 'noInternet' ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: AUTH_PREFIX_PATH,
              state: { from: location },
            }}
          />
        )}
    />
  );
}

export const Views = (props) => {
  const { locale, token, location, message } = props;
  const currentAppLocale = langConfig.filter(l => l.langId === locale);

  useEffect(() => {
    if (message.type != null) {
      openNotification(message);
      props.hideNotification()
    }
  });

  return (
    <ConfigProvider locale={currentAppLocale[0].antd}>
      <Switch>
        <Route exact path="/">
          <Redirect to={APP_PREFIX_PATH} />
        </Route>
        <Route path={AUTH_PREFIX_PATH}>
          <AuthLayout />
        </Route>
        <Route path="/organisation-choice">
          <AuthMailLayout />
        </Route>
        <RouteInterceptor path={APP_PREFIX_PATH} isAuthenticated={token}>
          <AppLayout location={location} />
        </RouteInterceptor>
      </Switch>
    </ConfigProvider>
  );
};

const mapStateToProps = ({ theme, auth, app }) => {
  return {
    message: app.message,
    locale: auth.user && auth.user.Op ? auth.user.Op.sLangue.toLowerCase() : theme.locale,
    token: auth.user && auth.user.token ? auth.user.token : false,
    user: auth.user,
    keyMail: auth.keyMail,
  };
};

const mapDispatchToProps = {
  hideNotification
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Views));
