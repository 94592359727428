import fetch from 'services/Interceptors/MdsInterceptor';
import * as config from 'configs/AppConfig';

const ProcessService = {};
const PROC_MODEL = 'gmt';

ProcessService.getProcessModel = (data) => {
  return fetch({
    url: PROC_MODEL,
    method: 'post',
    headers: {
      'public-request': 'true',
    },
    data: {
      sOpe: config.API_SCLE_READ,
      Data: data,
    },
  });
};

ProcessService.createProcessModel = (data) => {
  return fetch({
    url: PROC_MODEL,
    method: 'post',
    headers: {
      'public-request': 'true',
    },
    data: {
      sOpe: config.API_SCLE_ADD,
      Data: data,
    },
  });
};

ProcessService.updateProcessModel = (data) => {
  return fetch({
    url: PROC_MODEL,
    method: 'post',
    headers: {
      'public-request': 'true',
    },
    data: {
      sOpe: config.API_SCLE_EDIT,
      Data: data,
    },
  });
};

ProcessService.disableProcessModel = (data) => {
  return fetch({
    url: PROC_MODEL,
    method: 'post',
    headers: {
      'public-request': 'true',
    },
    data: {
      sOpe: config.API_SCLE_DELETE,
      Data: data,
    },
  });
};

export default ProcessService;
