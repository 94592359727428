
/**
 * Conversion Part 
 */

// send our organisation model to MDS API organisation
export const organisationToMdsOrganisation = (idOrga, organisation) => {
    // TODO Convert all the keys to our model
    return {
        nIDOrga: idOrga,
        sNom: organisation.nameOrga,
        sSiret: organisation.siret,
        // sSectActiv: organisation.sectActiv,
        // nIDSectActiv: organisation.idSectActiv,
        // nIDModel: organisation.idModel,
        sVoie: organisation.voie,
        sNum: organisation.num,
        sCodePost: organisation.codePost,
        sCommune: organisation.commune,
        sContact: organisation.contact,
        sFonction: organisation.fonction,
        sTel: organisation.tel,
        sMob: organisation.mob,
        sMail: organisation.mail,
        // nIDOrg_Mere: organisation.idOrgMere,
        // sStatut: organisation.statut,
        // dhDern_Mod: organisation.dhDern_Mod,
        // sLogo: organisation.logo,
        sNomRT: organisation.nomRT,
        sAdrRT: organisation.adrRT,
        sVilleRT: organisation.villeRT,
        sCPRT: organisation.cprt,
        sTelRT: organisation.telRT,
        sMailRT: organisation.mailRT,
        sNomDPO: organisation.nomDPO,
        sAdrDPO: organisation.adrDPO,
        sVilleDPO: organisation.villeDPO,
        sCPDPO: organisation.cpdpo,
        sTelDPO: organisation.telDPO,
        sMailDPO: organisation.mailDPO,
        sNomRelai: organisation.nomRelai,
        sAdrRelai: organisation.adrRelai,
        sVilleRelai: organisation.villeRelai,
        sCPRelai: organisation.cpRelai,
        sTelRelai: organisation.telRelai,
        sMailRelai: organisation.mailRelai,
        sCodeOrga: organisation.codeOrga,
        sCodeGroupe: organisation.codeGroupe,
    };
};

export const mdsOrgaTableInOrga = (mdsOrgaTable) => {
    return organisationToOrganisation(mdsOrgaTable.Data_Orga);

};

export const convertMdsOrgaListDpo = (mdsOrgaTable) => {
    return {
        key: mdsOrgaTable.nIDInter,
        iDInter: mdsOrgaTable.nIDInter,
        iDPresta: mdsOrgaTable.nIDPresta,
        raiSocPresta: mdsOrgaTable.sRaiSocPresta,
        nom: mdsOrgaTable.sNom,
        prenom: mdsOrgaTable.sPrenom,
        tel: mdsOrgaTable.sTel,
        mail: mdsOrgaTable.sMail,
        estModifiable: mdsOrgaTable.bEstModifiable,
        estUser: mdsOrgaTable.bEstUser,
        adresse: mdsOrgaTable.sAdresse,
        ville: mdsOrgaTable.sVille,
        cP: mdsOrgaTable.sCP,
        estInactif: mdsOrgaTable.bEstInactif,
        dateDeb: mdsOrgaTable.dDateDeb,
        dateFin: mdsOrgaTable.dDateFin,
        comm: mdsOrgaTable.sComm,
        codeFonction: mdsOrgaTable.sCodeFonction,
        niveau: mdsOrgaTable.nNiveau,
        tabCatDom: mdsOrgaTable.tabCatDom,
    };

};
export const convertMdsOrgaListDpoList = (mdsOrgaTable) => {
    const dataArray = [];
    mdsOrgaTable.tab_DPO.forEach(element => {
        dataArray.push(convertMdsOrgaListDpo(element))
    });

    return dataArray;
}
export const convertMdsOrgaListRelai = (mdsOrgaTable) => {
    return {
        key: mdsOrgaTable.nIDInter,
        iDInter: mdsOrgaTable.nIDInter,
        iDPresta: mdsOrgaTable.nIDPresta,
        raiSocPresta: mdsOrgaTable.sRaiSocPresta,
        nom: mdsOrgaTable.sNom,
        prenom: mdsOrgaTable.sPrenom,
        tel: mdsOrgaTable.sTel,
        mail: mdsOrgaTable.sMail,
        estModifiable: mdsOrgaTable.bEstModifiable,
        estUser: mdsOrgaTable.bEstUser,
        adresse: mdsOrgaTable.sAdresse,
        ville: mdsOrgaTable.sVille,
        cP: mdsOrgaTable.sCP,
        estInactif: mdsOrgaTable.bEstInactif,
        dateDeb: mdsOrgaTable.dDateDeb,
        dateFin: mdsOrgaTable.dDateFin,
        comm: mdsOrgaTable.sComm,
        codeFonction: mdsOrgaTable.sCodeFonction,
        niveau: mdsOrgaTable.nNiveau,
        tabCatDom: mdsOrgaTable.tabCatDom,
    };

};
export const convertMdsOrgaListRelaiList = (mdsOrgaTable) => {
    const dataArray = [];
    mdsOrgaTable.tab_Relai.forEach(element => {
        dataArray.push(convertMdsOrgaListRelai(element))
    });

    return dataArray;
}
// send our organisation model to MDS API organisation
export const organisationToOrganisation = (mdsOrga) => {
    return {
        idOrga: mdsOrga.nIDOrga,
        nameOrga: mdsOrga.sNom,
        siret: mdsOrga.sSiret,
        // sSectActiv: organisation.sectActiv,
        // nIDSectActiv: organisation.idSectActiv,
        // nIDModel: organisation.idModel,
        voie: mdsOrga.sVoie,
        num: mdsOrga.sNum,
        codePost: mdsOrga.sCodePost,
        commune: mdsOrga.sCommune,
        contact: mdsOrga.sContact,
        fonction: mdsOrga.sFonction,
        tel: mdsOrga.sTel,
        mob: mdsOrga.sMob,
        mail: mdsOrga.sMail,
        // nIDOrg_Mere: organisation.idOrgMere,
        // sStatut: organisation.statut,
        // dhDern_Mod: organisation.dhDern_Mod,
        // sLogo: organisation.logo,
        nomRT: mdsOrga.sNomRT,
        adrRT: mdsOrga.sAdrRT,
        villeRT: mdsOrga.sVilleRT,
        cprt: mdsOrga.sCPRT,
        telRT: mdsOrga.sTelRT,
        mailRT: mdsOrga.sMailRT,
        nomDPO: mdsOrga.sNomDPO,
        adrDPO: mdsOrga.sAdrDPO,
        villeDPO: mdsOrga.sVilleDPO,
        cpdpo: mdsOrga.sCPDPO,
        telDPO: mdsOrga.sTelDPO,
        mailDPO: mdsOrga.sMailDPO,
        nomRelai: mdsOrga.sNomRelai,
        adrRelai: mdsOrga.sAdrRelai,
        villeRelai: mdsOrga.sVilleRelai,
        cpRelai: mdsOrga.sCPRelai,
        telRelai: mdsOrga.sTelRelai,
        mailRelai: mdsOrga.sMailRelai,
        codeOrga: mdsOrga.sCodeOrga,
        codeGroupe: mdsOrga.sCodeGroupe,
    };

}
