import { all } from 'redux-saga/effects';

// Saga Call
import Actions from './Actions';
import ActorsBook from './ActorsBook';
import Auth from './Auth';
import Contrat from './Contrat';
import Dashboard from './Dashboard';
import Document from './Document';
import Faq from './Faq';
import Organisation from './Organisation';
import Provider from './Provider';
import Process from './Process';
import ProcessHistory from './ProcessHistory';
import ProcessModel from './ProcessModel';
import DocumentModel from './DocumentModel';
import Utils from './Utils';
import CreationDocument from './CreationDocument';
import Pia from './Pia';
import Axe from './Axe';
import Incident from './Incident';
import MultiOrga from './MultiOrga';
import DemandeDeDroits from './DemandeDeDroits';
import FunctionalProcedure from './FunctionalProcedure';
import ContratFeat from './ContratFeat';
import DocManRegLawReq from './DocManRegLawReq';
import OptRefManagement from './OptRefManagement';
import Todolist from './Todolist';
import Raci from './Raci';
import UsersManagement from './UsersManagement';

export default function* rootSaga() {
  yield all([
    Actions(),
    ActorsBook(),
    Auth(),
    Contrat(),
    Dashboard(),
    Document(),
    Faq(),
    Organisation(),
    Provider(),
    Process(),
    ProcessHistory(),
    ProcessModel(),
    Utils(),
    CreationDocument(),
    Pia(),
    Axe(),
    Incident(),
    DocumentModel(),
    MultiOrga(),
    DemandeDeDroits(),
    FunctionalProcedure(),
    ContratFeat(),
    DocManRegLawReq(),
    OptRefManagement(),
    Todolist(),
    Raci(),
    UsersManagement(),
  ]);
}
