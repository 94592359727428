import { all, takeEvery, put, fork, call } from 'redux-saga/effects';

import FunctionalProcedureService from 'services/FunctionalProcedureService';

import {
  addFuncProcItemSuccess,
  addFuncProcItemError,
  getFuncProcItemSuccess,
  getFuncProcItemError,
  updateFuncProcItemSuccess,
  updateFuncProcItemError,
  addFuncProcStepItemSuccess,
  addFuncProcStepItemError,
  getFuncProcStepItemSuccess,
  getFuncProcStepItemError,
  updateFuncProcStepItemSuccess,
  updateFuncProcStepItemError,
  deleteFuncProcStepItemSuccess,
  deleteFuncProcStepItemError,
  getFuncProcWithStepItemSuccess,
  getFuncProcWithStepItemError,
  getBlankFuncProcSuccess,
  getBlankFuncProcError,
  getFuncProcListSuccess,
  getFuncProcListError,
  addNewVersionFuncProcItemSuccess,
  addNewVersionFuncProcItemError
} from '../actions/FunctionalProcedure';

import {
  GET_FUNC_PROC_ITEM,
  ADD_FUNC_PROC_ITEM,
  UPDATE_FUNC_PROC_ITEM,
  GET_FUNC_PROC_STEP_ITEM,
  ADD_FUNC_PROC_STEP_ITEM,
  UPDATE_FUNC_PROC_STEP_ITEM,
  DELETE_FUNC_PROC_STEP_ITEM,
  GET_FUNC_PROC_LIST,
  GET_FUNC_PROC_WITH_STEP_ITEM,
  GET_BLANK_FUNC_PROC,
  ADD_NEW_VERSION_FUNC_PROC_ITEM,
} from '../constants/FunctionalProcedure';


export function* getFuncProcItem() {
  yield takeEvery(GET_FUNC_PROC_ITEM, function* ({ data }) {
    try {
      const response = yield call(FunctionalProcedureService.getFuncProcItem, data);
      yield put(getFuncProcItemSuccess(response.Data));
    } catch (err) {
      yield put(getFuncProcItemError(err));
    }
  });
}

export function* addFuncProcItem() {
  yield takeEvery(ADD_FUNC_PROC_ITEM, function* ({ data }) {
    try {
      const response = yield call(FunctionalProcedureService.addFuncProcItem, data);
      yield put(addFuncProcItemSuccess(response.Data));
    } catch (err) {
      yield put(addFuncProcItemError(err));
    }
  });
}

export function* updateFuncProcItem() {
  yield takeEvery(UPDATE_FUNC_PROC_ITEM, function* ({ data }) {
    try {
      yield call(FunctionalProcedureService.updateFuncProcItem, data);
      yield put(updateFuncProcItemSuccess(data));
    } catch (err) {
      yield put(updateFuncProcItemError(err));
    }
  });
}

export function* getFuncProcStepItem() {
  yield takeEvery(GET_FUNC_PROC_STEP_ITEM, function* ({ data }) {
    try {
      const response = yield call(FunctionalProcedureService.getFuncProcStepItem, data);
      yield put(getFuncProcStepItemSuccess(response.Data));
    } catch (err) {
      yield put(getFuncProcStepItemError(err));
    }
  });
}

export function* addFuncProcStepItem() {
  yield takeEvery(ADD_FUNC_PROC_STEP_ITEM, function* ({ data }) {
    try {
      const response = yield call(FunctionalProcedureService.addFuncProcStepItem, data);
      yield put(addFuncProcStepItemSuccess(response.Data));
    } catch (err) {
      yield put(addFuncProcStepItemError(err));
    }
  });
}

export function* updateFuncProcStepItem() {
  yield takeEvery(UPDATE_FUNC_PROC_STEP_ITEM, function* ({ data }) {
    try {
      yield call(FunctionalProcedureService.updateFuncProcStepItem, data);
      yield put(updateFuncProcStepItemSuccess(data));
    } catch (err) {
      yield put(updateFuncProcStepItemError(err));
    }
  });
}

export function* deleteFuncProcStepItem() {
  yield takeEvery(DELETE_FUNC_PROC_STEP_ITEM, function* ({ data }) {
    try {
      const response = yield call(FunctionalProcedureService.deleteFuncProcStepItem, data);
      yield put(deleteFuncProcStepItemSuccess(response.Data));
    } catch (err) {
      yield put(deleteFuncProcStepItemError(err));
    }
  });
}

export function* getFuncProcWithStepItem() {
  yield takeEvery(GET_FUNC_PROC_WITH_STEP_ITEM, function* ({ data }) {
    try {
      const response = yield call(FunctionalProcedureService.getFuncProcWithStepItem, data);
      yield put(getFuncProcWithStepItemSuccess(response.Data));
    } catch (err) {
      yield put(getFuncProcWithStepItemError(err));
    }
  });
}

export function* getBlankFuncProc() {
  yield takeEvery(GET_BLANK_FUNC_PROC, function* ({ data }) {
    try {
      const response = yield call(FunctionalProcedureService.getBlankFuncProc, data);
      yield put(getBlankFuncProcSuccess(response.Data));
    } catch (err) {
      yield put(getBlankFuncProcError(err));
    }
  });
}

export function* getFuncProcList() {
  yield takeEvery(GET_FUNC_PROC_LIST, function* ({ data }) {
    try {
      const response = yield call(FunctionalProcedureService.getFuncProcList, data);
      yield put(getFuncProcListSuccess(response.Data));
    } catch (err) {
      yield put(getFuncProcListError(err));
    }
  });
}

export function* addNewVersionFuncProcItem() {
  yield takeEvery(ADD_NEW_VERSION_FUNC_PROC_ITEM, function* ({ data }) {
    try {
      const response = yield call(FunctionalProcedureService.addNewVersionFuncProcItem, data);
      yield put(addNewVersionFuncProcItemSuccess(response.Data));
    } catch (err) {
      yield put(addNewVersionFuncProcItemError(err));
    }
  });
}

export default function* rootSaga() {
  yield all([
    fork(getFuncProcItem),
    fork(addFuncProcItem),
    fork(updateFuncProcItem),
    fork(getFuncProcStepItem),
    fork(addFuncProcStepItem),
    fork(updateFuncProcStepItem),
    fork(deleteFuncProcStepItem),
    fork(getFuncProcWithStepItem),
    fork(getBlankFuncProc),
    fork(getFuncProcList),
    fork(addNewVersionFuncProcItem),
  ]);
}
