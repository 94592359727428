import { all, takeEvery, put, fork, call } from 'redux-saga/effects';

import DemandeDeDroitsService from 'services/DemandeDeDroitsService';

import {
  addDemandeDeDroitsItemError,
  addDemandeDeDroitsItemSuccess,
  getDemandeDeDroitsItemError,
  getDemandeDeDroitsItemSuccess,
  updateDemandeDeDroitsItemError,
  updateDemandeDeDroitsItemSuccess,
  getDemandeDeDroitsListError,
  getDemandeDeDroitsListSuccess,
  getDemandeDeDroitsTypeListSuccess,
  getDemandeDeDroitsTypeListError,
} from '../actions/DemandeDeDroits';

import {
  GET_DEMANDE_DE_DROITS_LIST,
  GET_DEMANDE_DE_DROITS_ITEM,
  UPDATE_DEMANDE_DE_DROITS_ITEM,
  ADD_DEMANDE_DE_DROITS_ITEM,
} from '../constants/DemandeDeDroits';

export function* getDemandeDeDroitsList() {
  yield takeEvery(GET_DEMANDE_DE_DROITS_LIST, function* ({ data }) {
    try {
      const response = yield call(
        DemandeDeDroitsService.getDemandeDeDroitsList,
        data,
      );
      yield put(getDemandeDeDroitsListSuccess(response.Data));
    } catch (err) {
      yield put(getDemandeDeDroitsListError(err));
    }
  });
}
export function* getDemandeDeDroitsItem() {
  yield takeEvery(GET_DEMANDE_DE_DROITS_ITEM, function* ({ data }) {
    try {
      const response = yield call(
        DemandeDeDroitsService.getDemandeDeDroitsItem,
        data,
      );
      yield put(getDemandeDeDroitsItemSuccess(response.Data));
    } catch (err) {
      yield put(getDemandeDeDroitsItemError(err));
    }
  });
}

export function* updateDemandeDeDroitsItem() {
  yield takeEvery(UPDATE_DEMANDE_DE_DROITS_ITEM, function* ({ data }) {
    try {
      yield call(DemandeDeDroitsService.updateDemandeDeDroitsItem, data);
      yield put(updateDemandeDeDroitsItemSuccess(data));
    } catch (err) {
      yield put(updateDemandeDeDroitsItemError(err));
    }
  });
}

export function* addDemandeDeDroitsItem() {
  yield takeEvery(ADD_DEMANDE_DE_DROITS_ITEM, function* ({ data }) {
    try {
      const response = yield call(
        DemandeDeDroitsService.addDemandeDeDroitsItem,
        data,
      );
      yield put(addDemandeDeDroitsItemSuccess(response.Data));
    } catch (err) {
      yield put(addDemandeDeDroitsItemError(err));
    }
  });
}

export default function* rootSaga() {
  yield all([
    fork(getDemandeDeDroitsList),
    fork(getDemandeDeDroitsItem),
    fork(addDemandeDeDroitsItem),
    fork(updateDemandeDeDroitsItem),
  ]);
}
