import {
  ADD_CONTRAT_FEAT_ITEM,
  ADD_CONTRAT_FEAT_ITEM_SUCCESS,
  ADD_CONTRAT_FEAT_ITEM_ERROR,
  GET_CONTRAT_FEAT_LIST,
  GET_CONTRAT_FEAT_LIST_SUCCESS,
  GET_CONTRAT_FEAT_LIST_ERROR,
  GET_CONTRAT_FEAT_ITEM,
  GET_CONTRAT_FEAT_ITEM_SUCCESS,
  GET_CONTRAT_FEAT_ITEM_ERROR,
  UPDATE_CONTRAT_FEAT_ITEM,
  UPDATE_CONTRAT_FEAT_ITEM_SUCCESS,
  UPDATE_CONTRAT_FEAT_ITEM_ERROR,
  DISABLE_CONTRAT_FEAT_ITEM,
  DISABLE_CONTRAT_FEAT_ITEM_SUCCESS,
  DISABLE_CONTRAT_FEAT_ITEM_ERROR,
  GET_INFORMATIONS_CONTRAT_ITEM,
  GET_INFORMATIONS_CONTRAT_ITEM_SUCCESS,
  GET_INFORMATIONS_CONTRAT_ITEM_ERROR,
  SHOW_CONTRAT_FEAT_LOADING,
  GET_CONTRAT_FEAT_BY_PROVIDER_ITEM_SUCCESS,
  GET_CONTRAT_FEAT_BY_PROVIDER_ITEM,
  GET_CONTRAT_FEAT_BY_PROVIDER_ITEM_ERROR,
} from '../constants/ContratFeat';

import * as converter from '../converter/Contrat';

export const addContratItem = (item) => {
  return {
    type: ADD_CONTRAT_FEAT_ITEM,
    data: {
      DataContrat: item,
    },
  };
};

export const addContratItemSuccess = (item) => {
  return {
    type: ADD_CONTRAT_FEAT_ITEM_SUCCESS,
    message: 'Le contrat a bien été crée',
    item,
  };
};

export const addContratItemError = (error) => {
  return {
    type: ADD_CONTRAT_FEAT_ITEM_ERROR,
    ...error,
  };
};

export const getContratList = () => {
  return {
    type: GET_CONTRAT_FEAT_LIST,
    data: { tab_Contrat: [{ nIDContrat: 0 }] },
  };
};

export const getContratListSuccess = (data) => {
  return {
    type: GET_CONTRAT_FEAT_LIST_SUCCESS,
    data,
  };
};

export const getContratListError = (error) => {
  return {
    type: GET_CONTRAT_FEAT_LIST_ERROR,
    ...error,
  };
};

export const getContratItem = (id) => {
  return {
    type: GET_CONTRAT_FEAT_ITEM,
    data: { DataContrat: { nIDContrat: id } },
  };
};

export const getContratItemSuccess = (item) => {
  return {
    type: GET_CONTRAT_FEAT_ITEM_SUCCESS,
    item,
  };
};

export const getContratItemError = (error) => {
  return {
    type: GET_CONTRAT_FEAT_ITEM_ERROR,
    ...error,
  };
};

export const updateContratItem = (item) => {
  return {
    type: UPDATE_CONTRAT_FEAT_ITEM,
    data: {
      DataContrat: item,
    },
  };
};

export const updateContratItemSuccess = (item) => {
  return {
    type: UPDATE_CONTRAT_FEAT_ITEM_SUCCESS,
    message: 'Le contrat a bien été mise a jour',
    item,
  };
};

export const updateContratItemError = (error) => {
  return {
    type: UPDATE_CONTRAT_FEAT_ITEM_ERROR,
    ...error,
  };
};

export const showLoading = () => {
  return {
    type: SHOW_CONTRAT_FEAT_LOADING,
  };
};

export const disableContratItem = (id) => {
  return {
    type: DISABLE_CONTRAT_FEAT_ITEM,
    data: { DataContrat: { nIDContrat: id } },
  };
};

export const disableContratItemSuccess = (item) => {
  return {
    type: DISABLE_CONTRAT_FEAT_ITEM_SUCCESS,
    item,
  };
};

export const disableContratItemError = (error) => {
  return {
    type: DISABLE_CONTRAT_FEAT_ITEM_ERROR,
    ...error,
  };
};

export const getInformationsContrat = () => {
  return {
    type: GET_INFORMATIONS_CONTRAT_ITEM,
    data: {},
  };
};

export const getInformationsContratSuccess = (infos) => {
  return {
    type: GET_INFORMATIONS_CONTRAT_ITEM_SUCCESS,
    infos,
  };
};

export const getInformationsContratError = (error) => {
  return {
    type: GET_INFORMATIONS_CONTRAT_ITEM_ERROR,
    ...error,
  };
};

export const getContratByProviderItem = (id) => {
  return {
    type: GET_CONTRAT_FEAT_BY_PROVIDER_ITEM,
    data: { nIDPrestataire: id },
  };
};

export const getContratByProviderItemSuccess = (data) => {
  return {
    type: GET_CONTRAT_FEAT_BY_PROVIDER_ITEM_SUCCESS,
    data: data.tab_PrestaCTRTRT,
  };
};

export const getContratByProviderItemError = (error) => {
  return {
    type: GET_CONTRAT_FEAT_BY_PROVIDER_ITEM_ERROR,
    ...error,
  };
};
