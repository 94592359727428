import {
  ADD_PROCESS_HISTORY_ITEM,
  ADD_PROCESS_HISTORY_ITEM_SUCCESS,
  ADD_PROCESS_HISTORY_ITEM_ERROR,
  GET_PROCESS_HISTORY_LIST,
  GET_PROCESS_HISTORY_LIST_SUCCESS,
  GET_PROCESS_HISTORY_LIST_ERROR,
  GET_PROCESS_HISTORY_ITEM,
  GET_PROCESS_HISTORY_ITEM_SUCCESS,
  GET_PROCESS_HISTORY_ITEM_ERROR,
  SHOW_LOADING,
} from '../constants/ProcessHistory';

import * as converter from '../converter/ProcessHistory';
import * as processConverter from '../converter/Process';

export const addProcessHistoryItem = (id, motif) => {
  return {
    type: ADD_PROCESS_HISTORY_ITEM,
    data: {
      nNb_HistoTraitement: 1,
      tab_HistoTraitement: [{ nIdTrait: id, sMotifHisto: motif, bForceEnr: true }]
    },
  };
};

export const addProcessHistoryItemSuccess = (item) => {
  // console.log(item.tab_HistoTraitement);
  return {
    type: ADD_PROCESS_HISTORY_ITEM_SUCCESS,
    item
  }
};

export const addProcessHistoryItemError = (error) => {
  return {
    type: ADD_PROCESS_HISTORY_ITEM_ERROR,
    ...error
  };
};

export const getProcessHistoryList = (idTraitment) => {
  return {
    type: GET_PROCESS_HISTORY_LIST,
    data: {
      nNb_HistoTraitement: 1,
      tab_HistoTraitement: [{ nIdTrait: idTraitment }]
    }
  };
};

export const getProcessHistoryListSuccess = (data) => {
  return {
    type: GET_PROCESS_HISTORY_LIST_SUCCESS,
    data: converter.mdsProcessHistoryListToProcessHistoryList(data.tab_HistoTraitement),
  };
};

export const getProcessHistoryListError = (error) => {
  return {
    type: GET_PROCESS_HISTORY_LIST_ERROR,
    ...error
  };
};

export const getProcessHistoryItem = (id) => {
  return {
    type: GET_PROCESS_HISTORY_ITEM,
    data: {
      nNb_HistoTraitement: 1,
      tab_HistoTraitement: [{ nIdHistoTrait: id }]
    },
  };
};

export const getProcessHistoryItemSuccess = (item) => {
  return {
    type: GET_PROCESS_HISTORY_ITEM_SUCCESS,
    item: processConverter.mdsProcessItemToProcess(item.tab_HistoTraitement[0].jsonHisto),
    idProcess: item.tab_HistoTraitement[0].nIdTrait
  }
};

export const getProcessHistoryItemError = (error) => {
  return {
    type: GET_PROCESS_HISTORY_ITEM_ERROR,
    ...error
  };
};

export const showLoading = () => {
  return {
    type: SHOW_LOADING,
  };
};
