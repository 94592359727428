import _, { parseInt } from 'lodash';
import Utils from 'configs/UtilFunctions';
import { parse } from '@babel/core';
import * as converterActions from './Actions';
import * as converterOrganisation from './Organisation';
// send our organisation model to MDS API organisation

export const mdsProcessModelListToProcessModel = (mdsProcessModel) => {
  return mdsProcessModel[0];
};

export const mdsProcessModelListToProcessModelList = (mdsProcessModelList) => {
  const dataArray = [];
  mdsProcessModelList.forEach((element) => {
    dataArray.push(mdsProcessModelItemToProcessModelItem(element));
  });
  return dataArray;
};

export const mdsProcessModelItemToProcessModelItem = (mdsProcessModel) => {
  /*
    dhDateHeureModele: "2020-02-15T16:53:25.554"
    jsonModele: null
    nIdModeleTrait: 31
    sCommentaire: "Ensemble des traitements réalisés par l'assistante de direction de la Direction Générale du CCAS"
    sNomModele: "Assistante de Direction (Direction générale du CCA"
    sNomTraitement: "Assistante de Direction (Direction générale du CCAS)"
    sVersionLogiciel: ""
    sVersionTrait: "V1 - Ref-005"
    */
  return {
    idModel: mdsProcessModel.nIdModeleTrait,
    isModifiable: mdsProcessModel.bEstModifiable,
    codeBase: mdsProcessModel.sCodeBaseModele,
    modelName: mdsProcessModel.sNomModele,
    processName: mdsProcessModel.sNomTraitement,
    versionLog: mdsProcessModel.sVersionLogiciel,
    versionTrait: mdsProcessModel.sVersionTrait,
    commentaire: mdsProcessModel.sCommentaire,
    dateModel: mdsProcessModel.dhDateHeureModele,
  };
};

export const processModelItemToMdsProcessModel = (processModel) => {
  return processModel;
};

export const changeCheckbox = (list, arrayKey) => {
  list.map((item) => (item[arrayKey] = item[arrayKey] ? 1 : 0));
  return list;
};
export const processModeleToMdsProcess = (
  process,
  nomModele,
  codeModele,
  idModele = null,
) => {
  let modeleId = null;
  if (idModele !== null) {
    modeleId = parseInt(idModele);
  }
  const itemParsed = {
    tab_ModeleTrait: [
      {
        nIdModeleTrait: modeleId,
        sCodeBaseModele: codeModele,
        sNomModele: nomModele,
        sCommentaire: '',
        jsonModele: {
          nBTrait: 1,
          tab_Trait: [
            {
              ...process.informations,
              ...process.finalities,
              ...process.legalBases,
            },
          ],
          nBQuestEV: !_.isEmpty(process.risks) ? process.risks.length : 0,
          tab_QuestEV: changeCheckbox(process.risks, ['nCheckboxVal']),
          nBActeur: process.actors.length,
          tab_Acteur: process.actors,
          nBAction: process.actions.length,
          tabAction: converterActions.convertActionsListInMdsActionsList(
            process.actions,
          ),
          nBData: [...process.personnalData, ...process.sensibleData].length,
          tabData: [...process.personnalData, ...process.sensibleData],
          nBPersConc: process.concernedPersons.length,
          tab_PersConc: process.concernedPersons,
          nBContrat: process.contrats.length,
          tab_Contrat: process.contrats,
          nBDest: process.recipients.length,
          tab_Dest: process.recipients,
          nBTransData: process.transferts.length,
          tab_TransData: process.transferts,

          nBGr_Data: process.grData.length,
          tabGrData: process.grData,
        },
      },
    ],
  };
  // console.log('processItemToMdsProcess', itemParsed, process)
  return itemParsed;
};

export const mdsModeleItemToProcess = (
  mdsProcess,
  codeBaseModele,
  nomModele,
  idModel,
) => {
  let itemParsed = {
    informations: {},
    finalities: {},
    legalBases: {},
  };

  Object.keys(mdsProcess.tab_Trait[0]).forEach(function (k) {
    if (k.indexOf('sFinalite') === 0) {
      itemParsed.finalities[k] = mdsProcess.tab_Trait[0][k];
    } else if (k.indexOf('BL') === 1 || k === 'sNece' || k === 'sPropor') {
      itemParsed.legalBases[k] = mdsProcess.tab_Trait[0][k];
    } else {
      itemParsed.informations[k] = mdsProcess.tab_Trait[0][k];
    }
  });
  itemParsed = {
    sCodeBaseModele: codeBaseModele,
    sNomModele: nomModele,
    idModel,
    risks: mdsProcess.tab_QuestEV,
    actors: Utils.convertItemDateToMomentFromList(mdsProcess.tab_Acteur, {
      dDateDeb: 'beginDate',
      dDateFin: 'endDate',
    }),
    actions: converterActions.convertMdsActionListInActionsList(
      mdsProcess.tabAction,
    ),
    personnalData: mdsProcess.tabData
      ? mdsProcess.tabData.filter((item) => item.nType === 1)
      : [],
    sensibleData: mdsProcess.tabData
      ? mdsProcess.tabData.filter((item) => item.nType === 2)
      : [],
    organisation:
      mdsProcess.Data_Orga &&
      converterOrganisation.organisationToOrganisation(mdsProcess.Data_Orga),
    concernedPersons: mdsProcess.tab_PersConc,
    contrats: mdsProcess.tab_Contrat,
    recipients: mdsProcess.tab_Dest,
    transferts: mdsProcess.tab_TransData,
    ...itemParsed,
    // for now useless
    grData: mdsProcess.tabGrData,
    domFonc: mdsProcess.tab_CatDomFonc,
    catRespons: mdsProcess.tab_CatRespons,
    // mdsProcess.tab_CatPers,
  };
  // console.log('process Converted', itemParsed)
  return itemParsed;
};
