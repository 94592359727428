import {
  ADD_PROVIDER,
  ADD_PROVIDER_ITEM_SUCCESS,
  ADD_PROVIDER_ERROR,
  DELETE_PROVIDER,
  DELETE_PROVIDER_ITEM_SUCCESS,
  DELETE_PROVIDER_ERROR,
  GET_PROVIDER_LIST,
  GET_PROVIDER_LIST_SUCCESS,
  GET_PROVIDER_LIST_ERROR,
  GET_PROVIDER_ITEM,
  GET_PROVIDER_ITEM_SUCCESS,
  GET_PROVIDER_ITEM_ERROR,
  UPDATE_PROVIDER_ITEM,
  UPDATE_PROVIDER_ITEM_SUCCESS,
  UPDATE_PROVIDER_ERROR,
  SHOW_LOADING,
} from '../constants/Provider';

const initState = {
  isLoading: false,
  message: { type: null, content: '' },
  dataShouldUpdate: false,
  redirect: '',
  data: [],
  item: {},
};

const auth = (state = initState, action) => {
  switch (action.type) {
    case GET_PROVIDER_LIST:
    case DELETE_PROVIDER:
    case ADD_PROVIDER:
    case UPDATE_PROVIDER_ITEM:
    case GET_PROVIDER_ITEM:
      return {
        ...state,
        isLoading: true,
      };
    case ADD_PROVIDER_ITEM_SUCCESS:
      return {
        ...state,
        isLoading: false,
        item: action.item,
        dataShouldUpdate: true,
      };
    case DELETE_PROVIDER_ITEM_SUCCESS:
      return {
        ...state,
        isLoading: false,
        item: action.data,
        dataShouldUpdate: true,
      };
    case GET_PROVIDER_LIST_SUCCESS:
      return {
        ...state,
        isLoading: false,
        dataShouldUpdate: false,
        data: action.data,
      };
    case GET_PROVIDER_ITEM_SUCCESS:
      return {
        ...state,
        isLoading: false,
        item: action.item,
      };
    case UPDATE_PROVIDER_ITEM_SUCCESS:
      return {
        ...state,
        isLoading: false,
        item: action.item,
        dataShouldUpdate: true,
      };
    case ADD_PROVIDER_ERROR:
    case DELETE_PROVIDER_ERROR:
    case GET_PROVIDER_ITEM_ERROR:
    case GET_PROVIDER_LIST_ERROR:
    case UPDATE_PROVIDER_ERROR:
      return {
        ...state,
        showErrorMessage: true,
        isLoading: false,
      };
    case SHOW_LOADING:
      return {
        ...state,
        isLoading: true,
      };
    default:
      return state;
  }
};

export default auth;
