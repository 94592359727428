
/**
 * Conversion Part 
 */


// send our provider model to MDS API provider
export const convertDeleteProviderToMdsProvider = (idProvider) => {
  // TODO Convert all the keys to our model
  return {
    nIDPresta: idProvider,
  };
};

export const convertAddProviderToMdsProvider = (provider) => {
  // TODO Convert all the keys to our model
  return {
    nIDPresta: 0,
    sRaisoc: provider.raison,
    sSiret: provider.siret,
    sCape: "",
    sAdr1: provider.addr,
    sAdr2: "",
    sCPost: provider.codePost,
    sCommune: provider.commune,
    sContact: provider.contact,
    sTel: provider.num,
    sMail: provider.mail,
    sComment: provider.comment,
    nPos: provider.relationPos,
    nCat: provider.partnerCat,
    sStatut: "",
    dhDern_Mod: provider.lastUp,
    sCheckListP: "",
  };
};

// send our provider model to MDS API provider
export const convertProviderToMdsProvider = (idProvider, provider) => {
  // TODO Convert all the keys to our model

  return {
    nIDPresta: idProvider,
    sRaisoc: provider.raison,
    sSiret: provider.siret,
    sCape: "",
    sAdr1: provider.addr,
    sAdr2: "",
    sCPost: provider.codePost,
    sCommune: provider.commune,
    sContact: provider.contact,
    sTel: provider.num,
    sMail: provider.mail,
    sComment: provider.comment,
    nPos: provider.relationPos,
    nCat: provider.partnerCat,
    sStatut: "Actif",
    dhDern_Mod: provider.lastUp,
    sCheckListP: "",
    bClauseRGPDCTR: provider.clauseRGPDCTR,
    bAnnxRGPDCTR: provider.annxRGPDCTR,
    bClauseRGPDCGV: provider.clauseRGPDCGV,
    bAnnxSecu: provider.annxSecu,
  };
};

export const convertAddMdsProviderItemInProvider = (mdsProvider) => {

  return {
    idProvider: mdsProvider.nIDPresta
  };
};

export const convertMdsProviderItemInProvider = (mdsProvider) => {

  return {
    key: mdsProvider.nIDPresta,
    idProvider: mdsProvider.nIDPresta,
    raison: mdsProvider.sRaisoc,
    siret: mdsProvider.sSiret,
    cape: mdsProvider.sCape,
    addr: mdsProvider.sAdr1,
    addr2: mdsProvider.sAdr2,
    codePost: mdsProvider.sCPost,
    commune: mdsProvider.sCommune,
    contact: mdsProvider.sContact,
    num: mdsProvider.sTel,
    mail: mdsProvider.sMail,
    comment: mdsProvider.sComment,
    relationPos: mdsProvider.nPos,
    statut: mdsProvider.sStatut,
    partnerCat: mdsProvider.nCat,
    lastUp: mdsProvider.dhDern_Mod,
    checklist: mdsProvider.sCheckListP,
    clauseRGPDCTR: mdsProvider.bClauseRGPDCTR,
    annxRGPDCTR: mdsProvider.bAnnxRGPDCTR,
    clauseRGPDCGV: mdsProvider.bClauseRGPDCGV,
    annxSecu: mdsProvider.bAnnxSecu,
  };
};

export const convertMdsProviderListItemsInProvider = (mdsProvider) => {

  switch (mdsProvider.nPos) {
    case 1:
      mdsProvider.nPos = "Interne";
      break;
    case 2:
      mdsProvider.nPos = "Externe";
      break;
    default:
      mdsProvider.nPos = "Interne";
      break;
  }

  switch (mdsProvider.nCat) {
    case 1:
      mdsProvider.nCat = "Sous-traitant";
      break;
    case 2:
      mdsProvider.nCat = "Prestataires";
      break;
    case 3:
      mdsProvider.nCat = "Tiers autorisÃ©s";
      break;
    case 4:
      mdsProvider.nCat = "Autres (GIE, Filiale, etc.)";
      break;
    default:
      mdsProvider.nCat = "Sous-traitant";
      break;
  }
  return {
    key: mdsProvider.nIDPresta,
    idProvider: mdsProvider.nIDPresta,
    raison: mdsProvider.sRaisoc,
    siret: mdsProvider.sSiret,
    cape: mdsProvider.sCape,
    addr: mdsProvider.sAdr1,
    addr2: mdsProvider.sAdr2,
    codePost: mdsProvider.sCPost,
    commune: mdsProvider.sCommune,
    contact: mdsProvider.sContact,
    num: mdsProvider.sTel,
    mail: mdsProvider.sMail,
    comment: mdsProvider.sComment,
    relationPos: mdsProvider.nPos,
    status: mdsProvider.sStatut,
    partnerCat: mdsProvider.nCat,
    lastUp: mdsProvider.dhDern_Mod,
    checklist: mdsProvider.sCheckListP,
    clauseRGPDCTR: mdsProvider.bClauseRGPDCTR,
    annxRGPDCTR: mdsProvider.bAnnxRGPDCTR,
    clauseRGPDCGV: mdsProvider.bClauseRGPDCGV,
    annxSecu: mdsProvider.bAnnxSecu,
  };
};

export const convertMdsProviderListInProvider = (mdsProvider) => {

  const dataArray = [];
  mdsProvider.tab_Presta.forEach(element => {

    dataArray.push(convertMdsProviderListItemsInProvider(element))

  });

  return dataArray;

};
