import fetch from 'services/Interceptors/MdsInterceptor';
import { API_SCLE_READ, API_SCLE_EDIT, API_SCLE_READ_LIST_99, API_SCLE_READ_LIST_11 } from 'configs/AppConfig';

const ActionsService = {};
const PROC = 'glpa';
const PROCITEM = 'gpa';
const PROCSCENARIO = 'gsa';
const PROCACTOR = 'gi';

ActionsService.getActionsList = (data) => {
  return fetch({
    url: `/${PROC}`,
    method: 'post',
    headers: {
      'public-request': 'true',
    },
    data: {
      sOpe: API_SCLE_READ_LIST_99,
      Data: data,
    },
  });
};

ActionsService.getActionItem = (data) => {
  return fetch({
    url: `/${PROCITEM}`,
    method: 'post',
    headers: {
      'public-request': 'true',
    },
    data: {
      sOpe: API_SCLE_READ,
      Data: data,
    },
  });
};

ActionsService.getActionsScenariosList = (data) => {
  return fetch({
    url: `/${PROCSCENARIO}`,
    method: 'post',
    headers: {
      'public-request': 'true',
    },
    data: {
      sOpe: API_SCLE_READ_LIST_99,
      Data: data,
    },
  });
};

ActionsService.getActorsList = (data) => {
  return fetch({
    url: `/${PROCACTOR}`,
    method: 'post',
    headers: {
      'public-request': 'true',
    },
    data: {
      sOpe: API_SCLE_READ_LIST_11,
      Data: data,
    },
  });
};

ActionsService.updateAction = (data) => {
  return fetch({
    url: `/${PROCITEM}`,
    method: 'post',
    headers: {
      'public-request': 'true',
    },
    data: {
      sOpe: API_SCLE_EDIT,
      Data: data,
    },
  });
};

export default ActionsService;
