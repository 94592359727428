import React from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import navigationConfig from 'configs/NavigationConfig';
import { SIDE_NAV_LIGHT, NAV_TYPE_SIDE } from 'configs/constants/ThemeConstant';
import utils from 'configs/UtilFunctions';
import { onMobileNavToggle } from 'store/actions/Theme';

import { withTranslation } from 'react-i18next';

import { Menu, Grid, Popover, Badge } from 'antd';
import Icon from '../Icon';

const { SubMenu } = Menu;
const { useBreakpoint } = Grid;

const setDefaultOpen = (key) => {
  const keyList = [];
  let keyString = '';
  if (key) {
    const arr = key.split('-');
    for (let index = 0; index < arr.length; index += 1) {
      const elm = arr[index];
      keyString = index === 0 ? elm : `${keyString}-${elm}`;
      keyList.push(keyString);
    }
  }
  return keyList;
};

const contentPiaAlert = (
  <div>
    <p className="text-info">Le PIA sera disponible dans la prochaine version.</p>
  </div>
);

const SideNavContent = (props) => {
  const {
    sideNavTheme,
    routeInfo,
    hideGroupTitle,
    t,
    // onMobileNavToggle,
  } = props;
  const isMobile = !utils.getBreakPoint(useBreakpoint()).includes('lg');
  const closeMobileNav = () => {
    if (isMobile) {
      props.onMobileNavToggle(false);
    }
  };
  // console.log('EO', props.user);
  return (
    <Menu
      theme={sideNavTheme === SIDE_NAV_LIGHT ? 'light' : 'dark'}
      mode="inline"
      style={{ height: '100%', borderRight: 0 }}
      defaultSelectedKeys={[routeInfo?.key]}
      defaultOpenKeys={setDefaultOpen(routeInfo?.key)}
      className={hideGroupTitle ? 'hide-group-title' : ''}
    >
      {navigationConfig.map((menu) =>
        menu.submenu.length > 0 ? (
          <Menu.ItemGroup key={menu.key} title={t(menu.title)}>
            {menu.submenu.map((subMenuFirst) =>
              subMenuFirst.submenu.length > 0 ? (
                <SubMenu
                  icon={
                    subMenuFirst.icon ? <Icon type={subMenuFirst?.icon} /> : null
                  }
                  key={subMenuFirst.key}
                  title={t(subMenuFirst.title)}
                >
                  {subMenuFirst.submenu.map((subMenuSecond) => (
                    <Menu.Item key={subMenuSecond.key}>
                      {subMenuSecond.icon ? (
                        <Icon type={subMenuSecond?.icon} />
                      ) : null}
                      <span>{t(subMenuSecond.title)}</span>
                      <Link
                        onClick={() => closeMobileNav()}
                        to={subMenuSecond.path}
                      />
                    </Menu.Item>
                  ))}
                </SubMenu>
              ) : (
                <Menu.Item
                  key={subMenuFirst.key}
                  disabled={
                    (subMenuFirst.key === 'referenceManagement' && 
                    props.user.role !== 'ADMIN') ||
                    (subMenuFirst.key === 'usersManagement' && props.user.role !== 'ADMIN') ||
                    (subMenuFirst.key === 'usersManagement' && !props.user.Op.bModDroitUs) ||
                    (subMenuFirst.key === 'usersManagement' && props.user.Op.bModDroitUs == null) ||
                    (subMenuFirst.key === 'pia' && !props.user.Op.bModulePIA) ||
                    (subMenuFirst.key === 'pia' &&
                      props.user.Op.bModulePIA == null) ||
                    (subMenuFirst.key === 'grpc' && !props.user.Op.bModuleGRPC) ||
                    (subMenuFirst.key === 'grpc' &&
                      props.user.Op.bModuleGRPC == null) ||
                    (subMenuFirst.key === 'demandeDeDroits' &&
                      !props.user.Op.bModuleRDD) ||
                    (subMenuFirst.key === 'demandeDeDroits' &&
                      props.user.Op.bModuleRDD == null) ||
                    (subMenuFirst.key === 'riskManagement' &&
                      !props.user.Op.bModuleInc) ||
                    (subMenuFirst.key === 'riskManagement' &&
                      props.user.Op.bModuleInc == null) 
                      ||
                    (subMenuFirst.key === 'procedure' && !props.user.Op.bModGesPRC) ||
                    (subMenuFirst.key === 'procedure' && props.user.Op.bModGesPRC == null)
                    // ||
                    // (subMenuFirst.key === 'creationDocument')
                  }
                >
                  {
                    /* subMenuFirst.key == "pia" ? (
                        <Popover content={contentPiaAlert} title="" placement="bottom">
                          {subMenuFirst.icon ? <Icon type={subMenuFirst.icon} /> : null}
                          <span>
                            {t(subMenuFirst.title)}
                          </span>
                          <Link onClick={() => closeMobileNav()} to={subMenuFirst.path} />
                        </Popover>
                      ) : (
                        <>
                          {subMenuFirst.icon ? <Icon type={subMenuFirst.icon} /> : null}
                          <span>
                            {t(subMenuFirst.title)}
                          </span>
                          <Link onClick={() => closeMobileNav()} to={subMenuFirst.path} />
                        </>
                      ) */
                    <>
                      {subMenuFirst.icon ? <Icon type={subMenuFirst.icon} /> : null}
                      <span>{t(subMenuFirst.title)}</span>
                      {subMenuFirst.key === 'todolist' ? (
                        <>
                          {' '}
                          <Badge count={0} />
                        </>
                      ) : (
                        <></>
                      )}

                      <Link
                        onClick={() => closeMobileNav()}
                        to={subMenuFirst.path}
                      />
                    </>
                  }
                </Menu.Item>
              ),
            )}
          </Menu.ItemGroup>
        ) : (
          <Menu.Item
            key={menu.key}
            disabled={
              (menu.key === 'pia' && !props.user.Op.bModulePIA) ||
              (menu.key === 'pia' && props.user.Op.bModulePIA == null) ||
              (menu.key === 'grpc' && !props.user.Op.bModuleGRPC) ||
              (menu.key === 'grpc' && props.user.Op.bModuleGRPC == null) ||
              (menu.key === 'demandeDeDroits' && !props.user.Op.bModuleRDD) ||
              (menu.key === 'demandeDeDroits' && props.user.Op.bModuleRDD == null) ||
              (menu.key === 'riskManagement' && !props.user.Op.bModuleInc) ||
              (menu.key === 'riskManagement' && props.user.Op.bModuleInc == null) ||
              (menu.key === 'procedure' && !props.user.Op.bModGesPRC) ||
              (menu.key === 'procedure' && props.user.Op.bModGesPRC == null)
              // ||
              // (menu.key === 'creationDocument')
            }
          >
            {menu.icon ? <Icon type={menu?.icon} /> : null}
            <span>{t(menu?.title)}</span>
            {menu.path ? (
              <Link onClick={() => closeMobileNav()} to={menu.path} />
            ) : null}
          </Menu.Item>
        ),
      )}
    </Menu>
  );
};

const TopNavContent = (props) => {
  const { topNavColor, t } = props;
  return (
    <Menu mode="horizontal" style={{ backgroundColor: topNavColor }}>
      {navigationConfig.map((menu) =>
        menu.submenu.length > 0 ? (
          <SubMenu
            key={menu.key}
            popupClassName="top-nav-menu"
            title={
              <span>
                {menu.icon ? <Icon type={menu?.icon} /> : null}
                <span>{t(menu.title)}</span>
              </span>
            }
          >
            {menu.submenu.map((subMenuFirst) =>
              subMenuFirst.submenu.length > 0 ? (
                <SubMenu
                  key={subMenuFirst.key}
                  icon={
                    subMenuFirst.icon ? <Icon type={subMenuFirst?.icon} /> : null
                  }
                  title={t(subMenuFirst.title)}
                >
                  {subMenuFirst.submenu.map((subMenuSecond) => (
                    <Menu.Item key={subMenuSecond.key}>
                      <span>{t(subMenuSecond.title)}</span>
                      <Link to={subMenuSecond.path} />
                    </Menu.Item>
                  ))}
                </SubMenu>
              ) : (
                <Menu.Item
                  key={subMenuFirst.key}
                  disabled={
                    (subMenuFirst.key === 'pia' && !props.user.Op.bModulePIA) ||
                    (subMenuFirst.key === 'riskManagement' &&
                      props.user.Op.bModulePIA == null) ||
                    (subMenuFirst.key === 'grpc' && !props.user.Op.bModuleGRPC) ||
                    (subMenuFirst.key === 'riskManagement' &&
                      props.user.Op.bModuleGRPC == null) ||
                    (subMenuFirst.key === 'riskManagement' &&
                      !props.user.Op.bModuleInc) ||
                    (subMenuFirst.key === 'riskManagement' &&
                      props.user.Op.bModuleInc == null) ||
                      (subMenuFirst.key === 'procedure' &&
                        !props.user.Op.bModGesPRC) ||
                      (subMenuFirst.key === 'procedure' &&
                        props.user.Op.bModGesPRC == null)
                    // ||
                    // (subMenuFirst.key === 'creationDocument')
                  }
                >
                  {subMenuFirst.icon ? <Icon type={subMenuFirst?.icon} /> : null}
                  <span>{t(subMenuFirst.title)}</span>
                  <Link to={subMenuFirst.path} />
                </Menu.Item>
              ),
            )}
          </SubMenu>
        ) : (
          <Menu.Item
            key={menu.key}
            disabled={
              (menu.key === 'pia' && !props.user.Op.bModulePIA) ||
              (menu.key === 'pia' && props.user.Op.bModulePIA == null) ||
              (menu.key === 'grpc' && !props.user.Op.bModuleGRPC) ||
              (menu.key === 'grpc' && props.user.Op.bModuleGRPC == null) ||
              (menu.key === 'riskManagement' && !props.user.Op.bModuleInc) ||
              (menu.key === 'riskManagement' && props.user.Op.bModuleInc == null) ||
              (menu.key === 'procedure' && !props.user.Op.bModGesPRC) ||
              (menu.key === 'procedure' && props.user.Op.bModGesPRC == null)
              // ||
              // (menu.key === 'creationDocument')
            }
          >
            {menu.icon ? <Icon type={menu?.icon} /> : null}
            <span>{t(menu?.title)}</span>
            {menu.path ? <Link to={menu.path} /> : null}
          </Menu.Item>
        ),
      )}
    </Menu>
  );
};

const MenuContent = (props) => {
  return props.type === NAV_TYPE_SIDE ? (
    <SideNavContent {...props} />
  ) : (
    <TopNavContent {...props} />
  );
};

const mapStateToProps = ({ theme, auth }) => {
  const { sideNavTheme, topNavColor } = theme;
  return { sideNavTheme, topNavColor, user: auth.user };
};

export default withTranslation()(
  connect(mapStateToProps, { onMobileNavToggle })(MenuContent),
);
