import { all, takeEvery, put, fork, call } from 'redux-saga/effects';

import OptRefManagementService from 'services/OptRefManagementService';

import {
  getElTableItemSuccess,
  getElTableItemError,
  getEmptyStructSuccess,
  getEmptyStructError,
  getListTablesSuccess,
  getListTablesError,
  getListElTableSuccess,
  getListElTableError,
  saveNewRefTableSuccess,
  saveNewRefTableError,
  updateRefTableSuccess,
  updateTotalRefTableError,
  updateTotalRefTableSuccess,
  updateRefTableError,
  deleteRefTableSuccess,
  deleteRefTableError
} from '../actions/OptRefManagement';

import {
  GET_EL_TABLE_ITEM,
  GET_EMPTY_STRUCT,
  GET_LIST_TABLES,
  GET_LIST_EL_TABLE,
  SAVE_NEW_REF_TABLE,
  UPDATE_REF_TABLE,
  UPDATE_TOTAL_REF_TABLE,
  DELETE_REF_TABLE,
} from '../constants/OptRefManagement';

export function* getElTableItem() {
  yield takeEvery(GET_EL_TABLE_ITEM, function* ({ data }) {
    try {
      const response = yield call(OptRefManagementService.getElTable, data);
      yield put(getElTableItemSuccess(response.Data));
    } catch (err) {
      yield put(getElTableItemError(err));
    }
  });
}

export function* getEmptyStruct() {
  yield takeEvery(GET_EMPTY_STRUCT, function* ({ data }) {
    try {
      const response = yield call(OptRefManagementService.getEmptyStruct, data);
      yield put(getEmptyStructSuccess(response.Data));
    } catch (err) {
      yield put(getEmptyStructError(err));
    }
  });
}

export function* getListTables() {
  yield takeEvery(GET_LIST_TABLES, function* ({ data }) {
    try {
      const response = yield call(OptRefManagementService.getListTables, data);
      yield put(getListTablesSuccess(response.Data));
    } catch (err) {
      yield put(getListTablesError(err));
    }
  });
}

export function* getListElTable() {
  yield takeEvery(GET_LIST_EL_TABLE, function* ({ data }) {
    try {
      if (data.sCodeTableRef !== undefined) {
        const response = yield call(OptRefManagementService.getListElTable, data);
        yield put(getListElTableSuccess(response.Data, data.sCodeTableRef));
      }
    } catch (err) {
      yield put(getListElTableError(err));
    }
  });
}

export function* saveNewRefTable() {
  yield takeEvery(SAVE_NEW_REF_TABLE, function* ({ data }) {
    try {
      const response = yield call(OptRefManagementService.saveNewRefTable, data);
      console.log("ATO AM SAGA", data)
      yield put(saveNewRefTableSuccess(response.Data));
    } catch (err) {
      yield put(saveNewRefTableError(err));
    }
  });
}

export function* updateRefTable() {
  yield takeEvery(UPDATE_REF_TABLE, function* ({ data }) {
    try {
      const response = yield call(OptRefManagementService.updateRefTable, data);
      yield put(updateRefTableSuccess(response.Data));
    } catch (err) {
      yield put(updateRefTableError(err));
    }
  });
}

export function* updateTotalRefTable() {
  yield takeEvery(UPDATE_TOTAL_REF_TABLE, function* ({ data }) {
    try {
      const response = yield call(OptRefManagementService.updateTotalRefTable, data);
      yield put(updateTotalRefTableSuccess(response.Data));
    } catch (err) {
      yield put(updateTotalRefTableError(err));
    }
  });
}

export function* deleteRefTable() {
  yield takeEvery(DELETE_REF_TABLE, function* ({ data }) {
    try {
      const response = yield call(OptRefManagementService.deleteRefTable, data);
      yield put(deleteRefTableSuccess(response.Data));
    } catch (err) {
      yield put(deleteRefTableError(err));
    }
  });
}

export default function* rootSaga() {
  yield all([
    fork(getElTableItem),
    fork(getEmptyStruct),
    fork(getListTables),
    fork(getListElTable),
    fork(saveNewRefTable),
    fork(updateRefTable),
    fork(updateTotalRefTable),
    fork(deleteRefTable),
  ]);
}
