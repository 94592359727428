import {
  GET_EL_TABLE_ITEM,
  GET_EL_TABLE_ITEM_SUCCESS,
  GET_EL_TABLE_ITEM_ERROR,
  GET_EMPTY_STRUCT,
  GET_EMPTY_STRUCT_SUCCESS,
  GET_EMPTY_STRUCT_ERROR,
  GET_LIST_TABLES,
  GET_LIST_TABLES_SUCCESS,
  GET_LIST_TABLES_ERROR,
  GET_LIST_EL_TABLE,
  GET_LIST_EL_TABLE_SUCCESS,
  GET_LIST_EL_TABLE_ERROR,
  SAVE_NEW_REF_TABLE,
  SAVE_NEW_REF_TABLE_SUCCESS,
  SAVE_NEW_REF_TABLE_ERROR,
  UPDATE_REF_TABLE,
  UPDATE_REF_TABLE_SUCCESS,
  UPDATE_REF_TABLE_ERROR,
  UPDATE_TOTAL_REF_TABLE,
  UPDATE_TOTAL_REF_TABLE_SUCCESS,
  UPDATE_TOTAL_REF_TABLE_ERROR,
  DELETE_REF_TABLE,
  DELETE_REF_TABLE_SUCCESS,
  DELETE_REF_TABLE_ERROR,
  SET_EL_TABLE,
  SHOW_LOADING
} from '../constants/OptRefManagement';

const initState = {
  isLoading: false,
  redirect: '',
  elTableItem: {},
  emptyStruct: {},
  listTables: [],
  listElTable: {},
  selectedElTable: {}
};

const auth = (state = initState, action) => {
  switch (action.type) {
    case GET_EL_TABLE_ITEM:
    case GET_EMPTY_STRUCT:
    case GET_LIST_TABLES:
    case GET_LIST_EL_TABLE:
    case SAVE_NEW_REF_TABLE:
    case DELETE_REF_TABLE:
      return {
        ...state,
        isLoading: true,
      };

    case UPDATE_REF_TABLE:
    case UPDATE_TOTAL_REF_TABLE:
      return {
        ...state,
        isLoading: true,
      };

    case GET_EL_TABLE_ITEM_SUCCESS:
      return {
        ...state,
        elTableItem: action.item,
        isLoading: false,
      };
    case GET_EMPTY_STRUCT_SUCCESS:
      return {
        ...state,
        emptyStruct: action.item,
        isLoading: false,
      };
    case GET_LIST_TABLES_SUCCESS: {
      return {
        ...state,
        listTables: action.data,
        isLoading: false,
      };
    }
    case GET_LIST_EL_TABLE_SUCCESS: {
      return {
        ...state,
        listElTable: { code: action.code, data: action.data },
        isLoading: false,
      };
    }
    case DELETE_REF_TABLE_SUCCESS:
    case UPDATE_REF_TABLE_SUCCESS:
    case SAVE_NEW_REF_TABLE_SUCCESS:
    case UPDATE_TOTAL_REF_TABLE_SUCCESS:
      return {
        ...state,
        isLoading: false,
      };
    case GET_EL_TABLE_ITEM_ERROR:
    case GET_EMPTY_STRUCT_ERROR:
    case GET_LIST_TABLES_ERROR:
    case GET_LIST_EL_TABLE_ERROR:
    case SAVE_NEW_REF_TABLE_ERROR:
    case UPDATE_REF_TABLE_ERROR:
    case UPDATE_TOTAL_REF_TABLE_ERROR:
    case DELETE_REF_TABLE_ERROR:
      return {
        ...state,
        isLoading: false,
      };
    case SHOW_LOADING: {
      return {
        ...state,
        isLoading: true,
      };
    }
    case SET_EL_TABLE: {
      return {
        selectedElTable: action.data
      }
    }
    default:
      return state;
  }
};

export default auth;
