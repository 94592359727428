import {
  GET_FAQ_LIST,
  GET_FAQ_LIST_SUCCESS,
  GET_FAQ_LIST_ERROR,
  SHOW_LOADING,
} from '../constants/Faq';


export const getFaqList = () => {
  return {
    type: GET_FAQ_LIST,
    data: {}
  };
};

export const getFaqListSuccess = (data) => {
  return {
    type: GET_FAQ_LIST_SUCCESS,
    data: data.tab_FAQ,
  };
};

export const getFaqListError = (error) => {
  return {
    type: GET_FAQ_LIST_ERROR,
    ...error
  };
};

export const showLoading = () => {
  return {
    type: SHOW_LOADING,
  };
};
