import { all, takeEvery, put, fork, call } from 'redux-saga/effects';
import ContratService from 'services/ContratService';
import {
  ADD_CONTRAT_ITEM,
  GET_CONTRAT_LIST,
  GET_CONTRAT_ITEM,
  GET_NATURE_CONTRAT_LIST,
  UPDATE_CONTRAT_ITEM,
  DELETE_CONTRAT_ITEM,
  GET_CONTRAT_BY_PROVIDER_ITEM,
} from '../constants/Contrat';

import {
  addContratItemError,
  addContratItemSuccess,
  getContratItemError,
  getContratItemSuccess,
  updateContratError,
  updateContratSuccess,
  getContratListError,
  getContratListSuccess,
  getNatureContratListError,
  getNatureContratListSuccess,
  deleteContratError,
  deleteContratSuccess,
  getContratByProviderItemSuccess,
  getContratByProviderItemError,
} from '../actions/Contrat';

export function* getContratList() {
  yield takeEvery(GET_CONTRAT_LIST, function* ({ data }) {
    try {
      const response = yield call(ContratService.getContrat, data);
      yield put(getContratListSuccess(response.Data));
    } catch (err) {
      // console.log("error getContratList", err)
      yield put(getContratListError(err));
    }
  });
}
export function* getContratItem() {
  yield takeEvery(GET_CONTRAT_ITEM, function* ({ data }) {
    try {
      const response = yield call(ContratService.getContratItem, data);
      yield put(getContratItemSuccess(response.Data));
    } catch (err) {
      // console.log("error getContratItem", err)
      yield put(getContratItemError(err));
    }
  });
}

export function* getNatureContratList() {
  yield takeEvery(GET_NATURE_CONTRAT_LIST, function* ({ data }) {
    try {
      const response = yield call(ContratService.getNatureContrat, data);
      yield put(getNatureContratListSuccess(response.Data));
    } catch (err) {
      // console.log("error getContratItem", err)
      yield put(getNatureContratListError(err));
    }
  });
}

export function* updateContrat() {
  yield takeEvery(UPDATE_CONTRAT_ITEM, function* ({ data }) {
    try {
      const response = yield call(ContratService.editContrat, data);
      yield put(updateContratSuccess(response.Data));
    } catch (err) {
      // console.log("error updateContrat", err)
      yield put(updateContratError(err));
    }
  });
}

export function* addContratItem() {
  yield takeEvery(ADD_CONTRAT_ITEM, function* ({ data }) {
    try {
      const response = yield call(ContratService.createContrat, data);
      yield put(addContratItemSuccess(response.Data));
    } catch (err) {
      // console.log("error addContratItem", err)
      yield put(addContratItemError(err));
    }
  });
}

export function* deleteContrat() {
  yield takeEvery(DELETE_CONTRAT_ITEM, function* ({ data }) {
    try {
      const response = yield call(ContratService.deleteContrat, data);
      yield put(deleteContratSuccess(response.Data));
    } catch (err) {
      // console.log("error addContratItem", err)
      yield put(deleteContratError(err));
    }
  });
}

export function* getContratByProviderItem() {
  yield takeEvery(GET_CONTRAT_BY_PROVIDER_ITEM, function* ({ data }) {
    try {
      const response = yield call(ContratService.getContratByProviderItem, data);
      yield put(getContratByProviderItemSuccess(response.Data));
    } catch (err) {
      // console.log(err);
      yield put(getContratByProviderItemError(err));
    }
  });
}

export default function* rootSaga() {
  yield all([
    fork(getContratList),
    fork(getContratItem),
    fork(getNatureContratList),
    fork(addContratItem),
    fork(deleteContrat),
    fork(updateContrat),
    fork(getContratByProviderItem),
  ]);
}
