export const GET_PIA = 'GET_PIA';
export const GET_PIA_SUCCESS = 'GET_PIA_SUCCESS';
export const GET_PIA_ERROR = 'GET_PIA_ERROR';

export const GET_BASE_PIA = 'GET_BASE_PIA';
export const GET_BASE_PIA_SUCCESS = 'GET_BASE_PIA_SUCCESS';
export const GET_BASE_PIA_ERROR = 'GET_BASE_PIA_ERROR';

export const GET_PIA_LIST = 'GET_PIA_LIST';
export const GET_PIA_LIST_SUCCESS = 'GET_PIA_LIST_SUCCESS';
export const GET_PIA_LIST_ERROR = 'GET_PIA_LIST_ERROR';

export const ADD_PIA = 'ADD_PIA';
export const ADD_PIA_SUCCESS = 'ADD_PIA_SUCCESS';
export const ADD_PIA_ERROR = 'ADD_PIA_ERROR';

export const ADD_TRAIT_PIA = 'ADD_TRAIT_PIA';
export const ADD_TRAIT_PIA_SUCCESS = 'ADD_TRAIT_PIA_SUCCESS';
export const ADD_TRAIT_PIA_ERROR = 'ADD_TRAIT_PIA_ERROR';

export const UPDATE_PIA_ITEM = 'UPDATE_PIA_ITEM';
export const UPDATE_PIA_ITEM_SUCCESS = 'UPDATE_PIA_ITEM_SUCCESS';
export const UPDATE_PIA_ITEM_ERROR = 'UPDATE_PIA_ITEM_ERROR';

export const DELETE_PIA = 'DELETE_PIA';
export const DELETE_PIA_SUCCESS = 'DELETE_PIA_SUCCESS';
export const DELETE_PIA_ERROR = 'DELETE_PIA_ERROR';


export const SHOW_LOADING = 'SHOW_PIA_LOADING';
