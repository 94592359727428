import {
  GET_AXE_FCT,
  GET_AXE_FCT_ERROR,
  GET_AXE_FCT_SUCCESS,
  GET_AXE_GEO,
  GET_AXE_GEO_ERROR,
  GET_AXE_GEO_SUCCESS,
  GET_AXE_MET,
  GET_AXE_MET_ERROR,
  GET_AXE_MET_SUCCESS,
  GET_AXE_ALL,
  GET_AXE_ALL_SUCCESS,
  GET_AXE_ALL_ERROR,
  SHOW_LOADING,
} from '../constants/Axe';

const initState = {
  isLoading: false,
  dataShouldUpdate: true,
  axeFCT: [], // Axe Domaine Fonctionnel
  axeGEO: [], // Axe Geographique
  axeMET: [], // Axe Métier
  axeALL: [], // tout les Axes
};

const auth = (state = initState, action) => {
  switch (action.type) {
    case SHOW_LOADING: {
      return {
        ...state,
        isLoading: true,
      };
    }

    case GET_AXE_FCT:
    case GET_AXE_GEO:
    case GET_AXE_MET:
    case GET_AXE_ALL: {
      return {
        ...state,
        isLoading: true,
      };
    }

    case GET_AXE_FCT_SUCCESS: {
      return {
        ...state,
        axeFCT: action.data,
        dataShouldUpdate: true,
        isLoading: false,
      };
    }

    case GET_AXE_GEO_SUCCESS: {
      return {
        ...state,
        axeGEO: action.data,
        dataShouldUpdate: true,
        isLoading: false,
      };
    }

    case GET_AXE_MET_SUCCESS: {
      return {
        ...state,
        axeMET: action.data,
        dataShouldUpdate: true,
        isLoading: false,
      };
    }
    case GET_AXE_ALL_SUCCESS: {
      return {
        ...state,
        axeALL: action.data,
        dataShouldUpdate: true,
        isLoading: false,
      };
    }

    case GET_AXE_FCT_ERROR:
    case GET_AXE_GEO_ERROR:
    case GET_AXE_MET_ERROR:
    case GET_AXE_ALL_ERROR: {
      return {
        ...state,
        isLoading: false,
      };
    }

    default:
      return state;
  }
};

export default auth;
