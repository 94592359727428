import { all, takeEvery, put, fork, call } from 'redux-saga/effects';

import OrganisationService from 'services/MultiOrgaService';

import {
  addOrganisationItemError,
  addOrganisationItemSuccess,
  getOrganisationItemError,
  getOrganisationItemSuccess,
  updateOrganisationItemError,
  updateOrganisationItemSuccess,
  getOrganisationListError,
  getOrganisationListSuccess,
  getOrganisationTypeListSuccess,
  getOrganisationTypeListError,
  getOrganisationInfosSuccess,
  getOrganisationInfosError,
} from '../actions/MultiOrga';

import {
  GET_ORGANISATION_LIST,
  GET_ORGANISATION_ITEM,
  UPDATE_ORGANISATION_ITEM,
  ADD_ORGANISATION_ITEM,
  GET_ORGANISATION_TYPE_LIST,
  GET_ORGANISATION_INFOS,
} from '../constants/Organisation';

export function* getOrganisationList() {
  yield takeEvery(GET_ORGANISATION_LIST, function* ({ data }) {
    try {
      const response = yield call(OrganisationService.getOrganisationList, data);
      yield put(getOrganisationListSuccess(response.Data));
    } catch (err) {
      yield put(getOrganisationListError(err));
    }
  });
}
export function* getOrganisationTypeList() {
  yield takeEvery(GET_ORGANISATION_TYPE_LIST, function* ({ data }) {
    try {
      const response = yield call(OrganisationService.getOrganisationTypeList, data);
      yield put(getOrganisationTypeListSuccess(response.Data));
    } catch (err) {
      yield put(getOrganisationTypeListError(err));
    }
  });
}
export function* getOrganisationItem() {
  yield takeEvery(GET_ORGANISATION_ITEM, function* ({ data }) {
    try {
      const response = yield call(OrganisationService.getOrganisationItem, data);
      yield put(getOrganisationItemSuccess(response.Data));
    } catch (err) {
      yield put(getOrganisationItemError(err));
    }
  });
}

export function* updateOrganisationItem() {
  yield takeEvery(UPDATE_ORGANISATION_ITEM, function* ({ data }) {
    try {
      yield call(OrganisationService.updateOrganisationItem, data);
      yield put(updateOrganisationItemSuccess(data));
    } catch (err) {
      yield put(updateOrganisationItemError(err));
    }
  });
}

export function* addOrganisationItem() {
  yield takeEvery(ADD_ORGANISATION_ITEM, function* ({ data }) {
    try {
      const response = yield call(OrganisationService.addOrganisationItem, data);
      yield put(addOrganisationItemSuccess(response.Data));
    } catch (err) {
      yield put(addOrganisationItemError(err));
    }
  });
}

export function* getOrganisationInfos() {
  yield takeEvery(GET_ORGANISATION_INFOS, function* ({ data }) {
    try {
      const response = yield call(OrganisationService.getOrganisationInfos, data);
      // console.log('Get organisation width data : ', data , ' and response ', response);
      yield put(getOrganisationInfosSuccess(response.Data));
    } catch (err) {
      // console.log(err);
      yield put(getOrganisationInfosError(err));
    }
  });
}

export default function* rootSaga() {
  yield all([
    fork(getOrganisationList),
    fork(getOrganisationItem),
    fork(addOrganisationItem),
    fork(updateOrganisationItem),
    fork(getOrganisationTypeList),
    fork(getOrganisationInfos),
  ]);
}
