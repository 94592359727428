import {
  ADD_PROCESS_ITEM,
  ADD_PROCESS_ITEM_SUCCESS,
  ADD_PROCESS_ITEM_ERROR,
  GET_PROCESS_LIST,
  GET_PROCESS_LIST_SUCCESS,
  GET_PROCESS_LIST_ERROR,
  GET_PROCESS_ITEM,
  GET_PROCESS_ITEM_SUCCESS,
  GET_PROCESS_ITEM_ERROR,
  UPDATE_PROCESS_ITEM,
  UPDATE_PROCESS_ITEM_SUCCESS,
  UPDATE_PROCESS_ITEM_ERROR,
  RESET_LAST_ITEM_ADDED,
  RESET_LAST_ITEM_EDITED,
  SHOW_LOADING,
} from '../constants/Process';

import { GET_PROCESS_MODEL_ITEM } from '../constants/ProcessModel';

const initState = {
  isLoading: false,
  isUpdated: false,
  data: [],
  dataShouldUpdate: false,
  item: {
    informations: {},
    risks: [],
    finalities: {},
    legalBases: {},
    actors: [],
    actions: [],
    personnalData: [],
    sensibleData: [],
    concernedPersons: [],
    contrats: [],
    recipients: [],
    transferts: [],
  },
  addedItem: {},
  editedItem: {},
  history: [],
};

const auth = (state = initState, action) => {
  switch (action.type) {
    case SHOW_LOADING: {
      return {
        ...state,
        isLoading: true,
      };
    }
    case GET_PROCESS_ITEM:
    case GET_PROCESS_MODEL_ITEM: {
      return {
        ...state,
        item: initState.item,
        isLoading: true,
      };
    }
    case ADD_PROCESS_ITEM:
    case GET_PROCESS_LIST:
    case UPDATE_PROCESS_ITEM: {
      return {
        ...state,
        isLoading: true,
        isUpdated: false,
      };
    }
    case ADD_PROCESS_ITEM_ERROR:
    case GET_PROCESS_LIST_ERROR:
    case GET_PROCESS_ITEM_ERROR:
    case UPDATE_PROCESS_ITEM_ERROR: {
      return {
        ...state,
        isLoading: false,
      };
    }

    case ADD_PROCESS_ITEM_SUCCESS: {
      return {
        ...state,
        addedItem: action.data.tab_Trait[0],
        // item: action.item,
        dataShouldUpdate: true,
        isLoading: false,
      };
    }
    case GET_PROCESS_LIST_SUCCESS: {
      return {
        ...state,
        data: action.data,
        dataShouldUpdate: false,
        isLoading: false,
      };
    }
    case GET_PROCESS_ITEM_SUCCESS: {
      return {
        ...state,
        item: action.item,
        isLoading: false,
      };
    }
    case UPDATE_PROCESS_ITEM_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        dataShouldUpdate: true,
        item: action.item,
        editedItem: action.item,
        isUpdated: true,

      };
    }
    case RESET_LAST_ITEM_ADDED: {
      return {
        ...state,
        addedItem: {},
      };
    }
    case RESET_LAST_ITEM_EDITED: {
      return {
        ...state,
        editedItem: {},
        isUpdated: false,
      };
    }
    default:
      return state;
  }
};

export default auth;
