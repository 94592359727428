import { notification } from 'antd';

export const openSuccessNotification = (props = {}) => {
  notification.open({
    message: props.title ? props.title : 'La mise à jour a été bien réalisée.',
    // description: props.message ? props.message : 'La donnée a bien été mise à jour',
    duration: 3,
    style: {
      'backgroundColor': '#E6F1ED'
    }
  });
};

export const openNotification = (props = {}) => {

  const getInfo = () => {
    switch (props.type) {
      case 'success':
        return {
          message: props.title ? props.title : 'La mise à jour a été bien réalisée.',
          // description: props.content ? props.content : 'La donnée a bien été mise à jour',
          style: {
            'backgroundColor': '#E6F1ED',
            'color': 'black'
          }
        }
      case 'error':
        return {
          message: props.title ? props.title : 'Un probleme est survenu.',
          // description: props.content ? props.content : 'Un probleme est survenu',
          style: {
            'backgroundColor': '#f8d7da',
            'color': 'white'
          }
        }
      case 'info':
      default:
        return {
          message: props.title ? props.title : 'L\'action a bien été executé.',
          // description: props.content ? props.content : 'l\'action a bien été executé',
          style: {
            'backgroundColor': '#d1ecf1',
            'color': 'white'
          }
        }
    }
  }
  notification.open(getInfo())
}

export default openNotification;
