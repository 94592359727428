import { all, takeEvery, put, fork, call } from 'redux-saga/effects';
import ActorsBookServices from 'services/ActorsBookServices';
import {
  ADD_ACTORS_BOOK_ITEM,
  GET_ACTORS_BOOK_LIST,
  GET_ACTORS_BOOK_ITEM,
  UPDATE_ACTORS_BOOK_ITEM,
  GET_ACTORS_BOOK_WITH_AXE_LIST,
  GET_MISSION_ACTORS_BOOK_LIST,
  DELETE_ACTORS_BOOK,
} from '../constants/ActorsBook';
import {
  createActorsBookSuccess,
  // createActorsBookError,
  getActorsBookListSuccess,
  getActorsBookListError,
  getActorsBookItemSuccess,
  getActorsBookItemError,
  updateActorsBookSuccess,
  updateActorsBookError,
  getActorsBookWithAxeListSuccess,
  getActorsBookWithAxeListError,
  getMissionActorsBookListError,
  getMissionActorsBookListSuccess,
  deleteActorsBookSuccess,
  deleteActorsBookError,
} from '../actions/ActorsBook';

/*
const dataDefault = [{
  key: '1',
  id: '1',
  providerName: 'Google',
  relationType: 'Prestataire',
  processNumbers: '-',
  status: 'Actif',
  update_date: new Date(2020, 11, 5).toLocaleString()
}, {
  key: '2',
  id: '2',
  providerName: 'Zoom',
  relationType: 'Sous-traitant',
  processNumbers: '-',
  status: 'Inactif',
  update_date: new Date(2020, 10, 5).toLocaleString()
}]
*/
export function* createActorsBook() {
  yield takeEvery(ADD_ACTORS_BOOK_ITEM, function* ({ data }) {
    try {
      const response = yield call(ActorsBookServices.createActorsBook, data);
      yield put(createActorsBookSuccess(response.Data));
    } catch (err) {
      // console.log(err);
      yield put(getActorsBookListError(err));
    }
  });
}

export function* getActorsBookList() {
  yield takeEvery(GET_ACTORS_BOOK_LIST, function* ({ data }) {
    try {
      const response = yield call(ActorsBookServices.getActorsBookList, data);
      yield put(getActorsBookListSuccess(response.Data));
    } catch (err) {
      // console.log(err);
      yield put(getActorsBookListError(err));
    }
  });
}

export function* getActorsBookWithAxeList() {
  // console.log('atoy ee')
  yield takeEvery(GET_ACTORS_BOOK_WITH_AXE_LIST, function* ({ data }) {
    try {
      const response = yield call(ActorsBookServices.getActorsBookWithAxe, data);
      // console.log('getActorsBookWithAxeList ', response);
      yield put(getActorsBookWithAxeListSuccess(response.Data));
    } catch (err) {
      yield put(getActorsBookWithAxeListError(err));
    }
  });
}

export function* getActorsBookItem() {
  yield takeEvery(GET_ACTORS_BOOK_ITEM, function* ({ data }) {
    try {
      const response = yield call(ActorsBookServices.getActorsBook, data);
      yield put(getActorsBookItemSuccess(response.Data));
    } catch (err) {
      // console.log(err);
      yield put(getActorsBookItemError(err));
    }
  });
}
export function* updateActorsBook() {
  yield takeEvery(UPDATE_ACTORS_BOOK_ITEM, function* ({ data }) {
    try {
      const response = yield call(ActorsBookServices.updateActorsBook, data);
      yield put(updateActorsBookSuccess(response.Data));
    } catch (err) {
      // console.log(err);
      yield put(updateActorsBookError(err));
    }
  });
}

export function* getMissionActorsBookList() {
  yield takeEvery(GET_MISSION_ACTORS_BOOK_LIST, function* ({ data }) {
    try {
      const response = yield call(ActorsBookServices.getMissionActorsBookList, data);
      // console.log('getMissionActorsBookList ', response);
      yield put(getMissionActorsBookListSuccess(response.Data.tab_MissionTRT));
    } catch (err) {
      // console.log('getActorsBookWithAxeList Error ', err);
      yield put(getMissionActorsBookListError(err));
    }
  });
}

export function* deleteActorsBook() {
  yield takeEvery(DELETE_ACTORS_BOOK, function* ({ data }) {
    try {
      const response = yield call(ActorsBookServices.deleteActorsBook, data);
      yield put(deleteActorsBookSuccess(response.Data));
    } catch (err) {
      yield put(deleteActorsBookError(err));
    }
  });
}

export default function* rootSaga() {
  yield all([
    fork(createActorsBook),
    fork(getActorsBookList),
    fork(getActorsBookItem),
    fork(updateActorsBook),
    fork(getActorsBookWithAxeList),
    fork(getMissionActorsBookList),
    fork(deleteActorsBook),
  ]);
}
