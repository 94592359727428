import {
  UPDATE_ORGANISATION,
  UPDATE_ORGANISATION_SUCCESS,
  UPDATE_ORGANISATION_ERROR,
  GET_ORGANISATION,
  GET_ORGANISATION_SUCCESS,
  GET_ORGANISATION_ERROR,
  SHOW_LOADING,
  GET_ORGANISATION_INFOS,
  GET_ORGANISATION_INFOS_SUCCESS,
  GET_ORGANISATION_INFOS_ERROR,
} from '../constants/Organisation';

const initState = {
  isLoading: false,
  redirect: '',
  data: {},
  dataResp: {}
};

const auth = (state = initState, action) => {
  switch (action.type) {
    case GET_ORGANISATION:
      return {
        ...state,
        isLoading: true,
      };
    case GET_ORGANISATION_SUCCESS:
      return {
        ...state,
        data: action.data,
        isLoading: false,
      };
    case UPDATE_ORGANISATION_ERROR:
    case GET_ORGANISATION_ERROR:
      return {
        ...state,
        isLoading: false,
      };
    case UPDATE_ORGANISATION:
      return {
        ...state,
        isLoading: true,
      };
    case UPDATE_ORGANISATION_SUCCESS:
      return {
        ...state,
        isLoading: false,
        data: action.data,
      };
    case SHOW_LOADING: {
      return {
        ...state,
        isLoading: true,
      };
    }
    default:
      return state;
  }
};

export default auth;
