import {
  GET_ACTIONS_LIST,
  GET_ACTIONS_LIST_SUCCESS,
  GET_ACTIONS_LIST_ERROR,
  GET_ACTIONS_LIST_SCENARIOS,
  GET_ACTIONS_LIST_SCENARIOS_SUCCESS,
  GET_ACTIONS_LIST_SCENARIOS_ERROR,
  UPDATE_ACTION_ITEM,
  UPDATE_ACTION_ITEM_SUCCESS,
  UPDATE_ACTION_ITEM_ERROR,
  GET_ACTIONS_ITEM,
  GET_ACTIONS_ITEM_SUCCESS,
  GET_ACTIONS_ITEM_ERROR,
  SHOW_LOADING,
} from '../constants/Actions';

const initState = {
  isLoading: false,
  message: { type: null, content: '' },
  dataShouldUpdate: false,
  redirect: '',
  data: {
    tab_ActTRT: [],
    tab_ActPIA: [],
    tab_ActINC: [],
  },
  scenario: {
    tab_ActScenario: [],
    tab_ActAction: [],
  },
  item: {},
  typeStatus: [{
    id: 1,
    key: 'Proposée',
    value: 'Proposée',
  }, {
    id: 2,
    key: 'Validée',
    value: 'Validée',
  }, {
    id: 3,
    key: 'Planifiée',
    value: 'Planifiée',
  }, {
    id: 4,
    key: 'Réalisée',
    value: 'Réalisée',
  }]
};

const auth = (state = initState, action) => {
  switch (action.type) {

    case GET_ACTIONS_LIST_SUCCESS:
      return {
        ...state,
        isLoading: false,
        dataShouldUpdate: false,
        data: action.data,
      };
    case GET_ACTIONS_LIST_SCENARIOS:
      return {
        ...state,
        isLoading: true,
      };
    case GET_ACTIONS_LIST_SCENARIOS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        dataShouldUpdate: false,
        scenario: action.scenario,
      };
    case GET_ACTIONS_LIST_SCENARIOS_ERROR:
    case GET_ACTIONS_ITEM_ERROR:
    case GET_ACTIONS_LIST_ERROR:
    case UPDATE_ACTION_ITEM_ERROR:
      return {
        ...state,
        showErrorMessage: true,
        isLoading: false,
      };
    case GET_ACTIONS_LIST:
    case GET_ACTIONS_ITEM:
      return {
        ...state,
        isLoading: true,
      };
    case GET_ACTIONS_ITEM_SUCCESS: {
      return {
        ...state,
        item: action.item,
      };
    }
    case UPDATE_ACTION_ITEM:
      return {
        ...state,
        isLoading: true,
      };
    case UPDATE_ACTION_ITEM_SUCCESS:
      return {
        ...state,
        isLoading: false,
        item: action.item,
      };
    case SHOW_LOADING: {
      return {
        ...state,
        isLoading: true,
      };
    }
    default:
      return state;
  }
};

export default auth;
