export const ADD_PROCESS_ITEM = 'ADD_PROCESS_ITEM';
export const ADD_PROCESS_ITEM_SUCCESS = 'ADD_PROCESS_ITEM_SUCCESS';
export const ADD_PROCESS_ITEM_ERROR = 'ADD_PROCESS_ITEM_ERROR';
export const GET_PROCESS_LIST = 'GET_PROCESS_LIST';
export const GET_PROCESS_LIST_SUCCESS = 'GET_PROCESS_LIST_SUCCESS';
export const GET_PROCESS_LIST_ERROR = 'GET_PROCESS_LIST_ERROR';
export const GET_PROCESS_ITEM = 'GET_PROCESS_ITEM';
export const GET_PROCESS_ITEM_SUCCESS = 'GET_PROCESS_ITEM_SUCCESS';
export const GET_PROCESS_ITEM_ERROR = 'GET_PROCESS_ITEM_ERROR';
export const UPDATE_PROCESS_ITEM = 'UPDATE_PROCESS_ITEM';
export const UPDATE_PROCESS_ITEM_SUCCESS = 'UPDATE_PROCESS_ITEM_SUCCESS';
export const UPDATE_PROCESS_ITEM_ERROR = 'UPDATE_PROCESS_ITEM_ERROR';
export const DISABLE_PROCESS_ITEM = 'DISABLE_PROCESS_ITEM';
export const DISABLE_PROCESS_ITEM_SUCCESS = 'DISABLE_PROCESS_ITEM_SUCCESS';
export const DISABLE_PROCESS_ITEM_ERROR = 'DISABLE_PROCESS_ITEM_ERROR';
export const RESET_LAST_ITEM_ADDED = 'RESET_LAST_ITEM_ADDED';
export const RESET_LAST_ITEM_EDITED = 'RESET_LAST_ITEM_EDITED';

export const SHOW_LOADING = 'SHOW_PROCESS_LOADING';
