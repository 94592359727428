import {
  ADD_PROCESS_HISTORY_ITEM,
  GET_PROCESS_HISTORY_LIST,
  GET_PROCESS_HISTORY_ITEM,
  ADD_PROCESS_HISTORY_ITEM_SUCCESS,
  ADD_PROCESS_HISTORY_ITEM_ERROR,
  GET_PROCESS_HISTORY_LIST_SUCCESS,
  GET_PROCESS_HISTORY_LIST_ERROR,
  GET_PROCESS_HISTORY_ITEM_SUCCESS,
  GET_PROCESS_HISTORY_ITEM_ERROR,
  SHOW_LOADING,
} from '../constants/ProcessHistory';

const initState = {
  isLoading: false,
  message: {
    type: null,
    content: '',
  },
  idProcess: null,
  data: [],
  item: {
    informations: {},
    risks: [],
    finalities: {},
    legalBases: {},
    actors: [],
    actions: [],
    personnalData: [],
    sensibleData: [],
    concernedPersons: [],
    contrats: [],
    recipients: [],
    transferts: [],
  },
};

const auth = (state = initState, action) => {
  switch (action.type) {
    case SHOW_LOADING: {
      return {
        ...state,
        isLoading: true,
      };
    }
    case GET_PROCESS_HISTORY_LIST: {
      return {
        ...state,
        isLoading: true
      };
    }
    case ADD_PROCESS_HISTORY_ITEM:
    case GET_PROCESS_HISTORY_ITEM: {
      return {
        ...state,
        isLoading: true,
      };
    }
    case ADD_PROCESS_HISTORY_ITEM_SUCCESS: {
      return {
        ...state,
        isLoading: false,
      };
    }
    case GET_PROCESS_HISTORY_LIST_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        data: action.data,
      };
    }
    case GET_PROCESS_HISTORY_ITEM_SUCCESS: {
      return {
        ...state,
        item: action.item,
        isLoading: false,
        idProcess: action.idProcess
      };
    }
    case ADD_PROCESS_HISTORY_ITEM_ERROR:
    case GET_PROCESS_HISTORY_LIST_ERROR:
    case GET_PROCESS_HISTORY_ITEM_ERROR: {
      return {
        ...state,
        isLoading: false,
      };
    }
    default:
      return state;
  }
};

export default auth;
