import { all, takeEvery, put, fork, call } from 'redux-saga/effects';
import FaqService from 'services/FaqService';
import { GET_FAQ_LIST } from '../constants/Faq';

import { getFaqListError, getFaqListSuccess } from '../actions/Faq';

export function* getFaqList() {
  yield takeEvery(GET_FAQ_LIST, function* ({ data }) {
    try {
      const response = yield call(FaqService.getFaqList, data);
      yield put(getFaqListSuccess(response.Data));
    } catch (err) {
      yield put(getFaqListError(err));
    }
  });
}

export default function* rootSaga() {
  yield all([fork(getFaqList)]);
}
