import CreationDocumentService from 'services/CreationDocumentService';
import {
  ADD_CREATION_DOCUMENT_ITEM,
  ADD_CREATION_DOCUMENT_ITEM_SUCCESS,
  ADD_CREATION_DOCUMENT_ITEM_ERROR,
  LIST_DOCUMENT_CREATED_ITEM,
  LIST_DOCUMENT_CREATED_ITEM_SUCCESS,
  LIST_DOCUMENT_CREATED_ITEM_ERROR,
  GET_DOCUMENT_CREATED_ITEM,
  GET_DOCUMENT_CREATED_ITEM_SUCCESS,
  GET_DOCUMENT_CREATED_ITEM_ERROR,
  SHOW_LOADING,
  DO_NOTHING,
  GET_FICHE_PDF,
} from '../constants/CreationDocument';

import * as converter from '../converter/Actions';
import * as docConverter from '../converter/CreationDocument';

export const addDocumentItem = (item) => {
  return {
    type: ADD_CREATION_DOCUMENT_ITEM,
    data: {
      sTypeDemDoc: item.typeDoc,
      tabID: item.idTraitement || [],
      nFormatDemDoc: item.format,
    },
  };
};

export const getFichePDF = (item) => {
  return {
    type: GET_FICHE_PDF,
    data: {
      sTypeDemDoc: item.typeDoc,
      tabID: item.idTraitement || [],
      nFormatDemDoc: item.format,
    },
  };
};

export const addDocumentItemSuccess = (item) => {
  return {
    type: ADD_CREATION_DOCUMENT_ITEM_SUCCESS,
    data: {
      message: 'La demande de document a été pris en compte',
    },
  };
};

export const addDocumentItemError = (error) => {
  return {
    type: ADD_CREATION_DOCUMENT_ITEM_ERROR,
    ...error,
  };
};

export const getListDocumentCreated = () => {
  return {
    type: LIST_DOCUMENT_CREATED_ITEM,
    data: {
      // sC: 0,
      // nN: 0,
    },
  };
};

export const getListDocumentCreatedSuccess = (mdsDocumentsCreated) => {
  return {
    type: GET_DOCUMENT_CREATED_ITEM_SUCCESS,
    data: docConverter.convertDocumentList(mdsDocumentsCreated),
  };
};

export const getListDocumentCreatedError = (error) => {
  return {
    type: LIST_DOCUMENT_CREATED_ITEM_ERROR,
    ...error,
  };
};

export const getDocumentCreatedItem = (id) => {
  return {
    type: GET_DOCUMENT_CREATED_ITEM,
    data: {
      nIDDocCree: id,
    },
  };
};

export const getDocumentCreatedItemSuccess = (item) => {
  // console.log('item download File ', item)
  CreationDocumentService.downloadFile(item);
  return {
    type: DO_NOTHING, // GET_DOCUMENT_CREATED_ITEM_SUCCESS
    item: item.data,
  };
};

export const getDocumentCreatedItemError = (error) => {
  return {
    type: GET_DOCUMENT_CREATED_ITEM_ERROR,
    ...error,
  };
};

export const showLoading = () => {
  return {
    type: SHOW_LOADING,
  };
};
