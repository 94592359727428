import { ConsoleSqlOutlined } from "@ant-design/icons";
import {
  ADD_PIA,
  ADD_PIA_ERROR,
  ADD_PIA_SUCCESS,
  DELETE_PIA,
  DELETE_PIA_ERROR,
  DELETE_PIA_SUCCESS,
  GET_PIA,
  GET_PIA_ERROR,
  GET_PIA_LIST,
  GET_PIA_LIST_ERROR,
  GET_PIA_LIST_SUCCESS,
  GET_PIA_SUCCESS,
  SHOW_LOADING,
  GET_BASE_PIA,
  GET_BASE_PIA_SUCCESS,
  GET_BASE_PIA_ERROR,
  ADD_TRAIT_PIA,
  ADD_TRAIT_PIA_SUCCESS,
  ADD_TRAIT_PIA_ERROR,
  UPDATE_PIA_ITEM_ERROR,
  UPDATE_PIA_ITEM,
  UPDATE_PIA_ITEM_SUCCESS
} from "../constants/Pia";

const initState = {
  isLoading: false,
  data: [],
  dataShouldUpdate: true,
  itemShouldRedirect: false,
  item: {}
};

const auth = (state = initState, action) => {
  switch (action.type) {
    case SHOW_LOADING: {
      return {
        ...state,
        isLoading: true,
      };
    }
    case ADD_TRAIT_PIA:
    case ADD_PIA:
    case DELETE_PIA:
    case GET_PIA_LIST:
    case GET_BASE_PIA:
    case UPDATE_PIA_ITEM:
    case GET_PIA: {
      return {
        ...state,
        isLoading: true,
      };
    }

    case GET_PIA_SUCCESS:
      {
        return {
          ...state,
          item: action.data,
          dataShouldUpdate: true,
          isLoading: false,
        };
      }
    case GET_BASE_PIA_SUCCESS:
      {
        return {
          ...state,
          item: action.data,
          dataShouldUpdate: true,
          itemShouldRedirect: false,
          isLoading: false,
        };
      }
    case GET_PIA_LIST_SUCCESS: {
      return {
        ...state,
        data: action.data,
        dataShouldUpdate: false,
        isLoading: false,
        itemShouldRedirect: false,
      };
    }
    case UPDATE_PIA_ITEM_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        dataShouldUpdate: true,
        item: action.item,
      };
    }

    case ADD_TRAIT_PIA_SUCCESS:
    case DELETE_PIA_SUCCESS:
    case ADD_PIA_SUCCESS: {
      return {
        ...state,
        item: action.item,
        dataShouldUpdate: true,
        isLoading: false,
      };
    }

    case ADD_PIA_ERROR:
    case GET_PIA_ERROR:
    case GET_PIA_LIST_ERROR:
    case DELETE_PIA_ERROR:
    case GET_BASE_PIA_ERROR:
    case UPDATE_PIA_ITEM_ERROR:
    case ADD_TRAIT_PIA_ERROR:
      {
        return {
          ...state,
          itemShouldRedirect: true,
          isLoading: false,
        };
      }

    default:
      return state;
  }
};

export default auth;
