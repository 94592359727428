import fetch from 'services/Interceptors/MdsInterceptor';
import * as config from 'configs/AppConfig';

const DocManRegLawReqService = {};

DocManRegLawReqService.getDocument = (data) => {
  return fetch({
    url: 'gddrd',
    method: 'post',
    headers: {
      'public-request': 'true',
    },
    data: {
      sOpe: config.API_SCLE_READ,
      Data: data,
    },
  });
};

DocManRegLawReqService.getListDocument = (data) => {
  return fetch({
    url: 'gddrd',
    method: 'post',
    headers: {
      'public-request': 'true',
    },
    data: {
      sOpe: 11,
      Data: data,
    },
  });
};

DocManRegLawReqService.recordDocument = (data) => {
  return fetch({
    url: 'gddrd',
    method: 'post',
    headers: {
      'public-request': 'true',
    },
    data: {
      sOpe: config.API_SCLE_ADD,
      Data: data,
    },
  });
};

DocManRegLawReqService.downloadFile = (data) => {
  // const docSplitBase64 = data.sDocRegDD.split('_base64_');
  // const doc = data.sDocRegDD.split('_');
  const blob = new Blob([b64toBlob(data.sDocRegDD)]);
  const fileName = `${data.sNomDocRegDD}.${data.sFormat_DocRegDD}`;
  const link = document.createElement('a');

  link.href = URL.createObjectURL(blob);
  link.target = '_blank';
  link.download = fileName;

  // some browser needs the anchor to be in the doc
  document.body.append(link);
  link.click();
  link.remove();

  // in case the Blob uses a lot of memory
  setTimeout(() => URL.revokeObjectURL(link.href), 7000);
};

const b64toBlob = (b64Data, sliceSize = 512) => {
  const byteCharacters = atob(b64Data.replaceAll('-', '+').replaceAll('_', '/'));
  const byteArrays = [];

  for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
    const slice = byteCharacters.slice(offset, offset + sliceSize);
    const byteNumbers = new Array(slice.length);

    for (let i = 0; i < slice.length; i += 1) {
      byteNumbers[i] = slice.charCodeAt(i);
    }
    const byteArray = new Uint8Array(byteNumbers);
    byteArrays.push(byteArray);
  }

  const blob = new Blob(byteArrays);
  return blob;
};

export default DocManRegLawReqService;
