import fetch from 'services/Interceptors/MdsInterceptor';
import * as config from 'configs/AppConfig';

const ContratFeatService = {};
const CONTRAT = 'gct6';

ContratFeatService.getContratItem = (data) => {
  return fetch({
    url: CONTRAT,
    method: 'post',
    headers: {
      'public-request': 'true',
    },
    data: {
      sOpe: config.API_SCLE_READ,
      Data: data,
    },
  });
};

ContratFeatService.createContratItem = (data) => {
  return fetch({
    url: CONTRAT,
    method: 'post',
    headers: {
      'public-request': 'true',
    },
    data: {
      sOpe: config.API_SCLE_ADD,
      Data: data,
    },
  });
};

ContratFeatService.updateContratItem = (data) => {
  return fetch({
    url: CONTRAT,
    method: 'post',
    headers: {
      'public-request': 'true',
    },
    data: {
      sOpe: config.API_SCLE_EDIT,
      Data: data,
    },
  });
};

ContratFeatService.disableContratItem = (data) => {
  return fetch({
    url: CONTRAT,
    method: 'post',
    headers: {
      'public-request': 'true',
    },
    data: {
      sOpe: "4-2",
      Data: data,
    },
  });
};

ContratFeatService.getContratList = (data) => {
  return fetch({
    url: CONTRAT,
    method: 'post',
    headers: {
      'public-request': 'true',
    },
    data: {
      sOpe: "11",
      Data: data,
    },
  });
};

ContratFeatService.getInformationsContrat = (data) => {
  return fetch({
    url: CONTRAT,
    method: 'post',
    headers: {
      'public-request': 'true',
    },
    data: {
      sOpe: "10",
      Data: data,
    },
  });
};

ContratFeatService.getContratByProviderItem = (data) => {
  return fetch({
    url: `/llpct2`,
    method: 'post',
    headers: {
      'public-request': 'true',
    },
    data: {
      sOpe: config.API_SCLE_READ,
      sCle: 'A4054-45',
      Data: data,
    },
  });
};


export default ContratFeatService;
