import {
  DashboardOutlined,
  TeamOutlined,
  FileTextOutlined,
  BarsOutlined,
  GlobalOutlined,
  FolderOutlined,
  LayoutOutlined,
  OrderedListOutlined,
  DeliveredProcedureOutlined,
  PullRequestOutlined,
  ThunderboltOutlined,
  FileDoneOutlined,
  FileSearchOutlined,
  UsergroupAddOutlined
} from '@ant-design/icons';
import { SuitcaseSVG, BuildingSVG, NotepadSVG } from 'assets/svg/mds';

import { APP_PREFIX_PATH } from 'configs/AppConfig';

const dashBoardNavTree = [
  {
    key: 'general',
    title: 'GENERAL',
    submenu: [
      {
        key: 'dashboard',
        path: `${APP_PREFIX_PATH}/dashboard`,
        // title: 'TABLEAU_DE_BORD',
        title: 'Tableau de bord',
        icon: LayoutOutlined,
        submenu: [],
      },
      {
        key: 'todolist',
        path: `${APP_PREFIX_PATH}/todolist`,
        title: 'Mes tâches',
        icon: OrderedListOutlined,
        submenu: [],
      },
      // {
      //   key: 'organisation',
      //   path: `${APP_PREFIX_PATH}/organisation`,
      //   title: 'ORGANISATION',
      //   icon: BuildingSVG,
      //   submenu: [],
      // },
      {
        key: 'multi-orga',
        path: `${APP_PREFIX_PATH}/multi-orga`,
        title: 'Organisation',
        // title: 'ORGANISATION',
        icon: BuildingSVG,
        submenu: [],
      },
      {
        key: 'actorsbook',
        path: `${APP_PREFIX_PATH}/actorsbook`,
        // title: 'ANNUAIRE_DES_ACTEURS',
        title: 'Annuaire des acteurs',
        icon: TeamOutlined,
        submenu: [],
      },
      {
        key: 'provider',
        path: `${APP_PREFIX_PATH}/provider`,
        // title: 'PRESTATAIRES',
        title: 'Prestataires',
        icon: SuitcaseSVG,
        submenu: [],
      },
      {
        key: 'contrat',
        path: `${APP_PREFIX_PATH}/contrat`,
        // title: 'CONTRAT',
        title: 'Contrats',
        icon: FileTextOutlined,
        submenu: [],
      },
      {
        key: 'process',
        path: `${APP_PREFIX_PATH}/traitement`,
        // title: 'TRAITEMENTS',
        title: 'Traitements',
        icon: BarsOutlined,
        submenu: [],
      },
      {
        key: 'pia',
        path: `${APP_PREFIX_PATH}/pia`,
        title: 'PIA / AIPD',
        icon: GlobalOutlined,
        submenu: [],
      },
      {
        key: 'actions',
        path: `${APP_PREFIX_PATH}/actions`,
        // title: 'ACTIONS',
        title: 'Actions',
        icon: ThunderboltOutlined,
        submenu: [],
      },
      {
        key: 'grpc',
        path: `${APP_PREFIX_PATH}/grpc`,
        title: 'GRPC',
        icon: NotepadSVG,
        submenu: [],
      },
      {
        key: 'demandeDeDroits',
        path: `${APP_PREFIX_PATH}/demande-de-droits`,
        // title: 'DEMANDE_DE_DROITS',
        title: 'Demande de droits',
        icon: PullRequestOutlined,
        submenu: [],
      },
      {
        key: 'document',
        path: `${APP_PREFIX_PATH}/document`,
        title: 'Bibliothèque RGPD',
        icon: FolderOutlined,
        submenu: [],
      },
      {
        key: 'creationDocument',
        path: `${APP_PREFIX_PATH}/demande-document`,
        // title: 'DEMANDE_DE_DOCUMENTS',
        title: 'Extraction pour impression',
        icon: FileDoneOutlined,
        submenu: [],
      },
      {
        key: 'riskManagement',
        path: `${APP_PREFIX_PATH}/gestion-incident`,
        // title: 'GESTION_INCIDENTS',
        title: 'Gestion incidents',
        icon: FileDoneOutlined,
        submenu: [],
      },
      {
        key: 'referenceManagement',
        path: `${APP_PREFIX_PATH}/gestion-options-reference`,
        // title: 'GESTION_OPTION_REFERENCE',
        title: 'Gestion option référence',
        icon: FileSearchOutlined,
        submenu: [],
      },
      // {
      //   key: 'usersManagement',
      //   path: `${APP_PREFIX_PATH}/gestion_utilisateurs`,
      //   title: 'Gestion utilisateurs',
      //   icon: UsergroupAddOutlined,
      //   submenu: [],
      // },
      {
        key: 'procedure',
        path: `${APP_PREFIX_PATH}/procedure`,
        // title: 'GESTION_PROCEDURES',
        title: 'Gestion procedures',
        icon: DeliveredProcedureOutlined,
        submenu: [],
      },
      // {
      //   key: 'creationDocManRegLawReq',
      //   path: `${APP_PREFIX_PATH}/gddrd`,
      //   title: 'GESTION_DOCUMENT_DROIT_REGISTRE',
      //   icon: FileDoneOutlined,
      //   submenu: [],
      // },
    ],
  },
];

const resourcesNavTree = [
  {
    key: 'resources',
    title: 'ressources',
    icon: DashboardOutlined,

    submenu: [
      {
        key: 'template',
        path: `${APP_PREFIX_PATH}/template`,
        title: 'modèles',
        icon: FolderOutlined,
        submenu: [],
      },
      {
        key: 'faq',
        path: `${APP_PREFIX_PATH}/faq`,
        title: 'FAQ',
        title: 'F.A.Q',
        icon: FolderOutlined,
        submenu: [],
      },
    ],
  },
];

const navigationConfig = [...dashBoardNavTree, ...resourcesNavTree];
export default navigationConfig;
