import fetch from 'services/Interceptors/MdsInterceptor';
import * as config from 'configs/AppConfig';

const ProcessService = {};
const PROC_LIST = 'llt';
const PROC_ITEM = 'gt4';

ProcessService.getProcessList = (data) => {
  return fetch({
    url: PROC_LIST,
    method: 'post',
    headers: {
      'public-request': 'true',
    },
    data: {
      sOpe: '22',
      Data: data,
    },
  });
};

ProcessService.getProcessItem = (data) => {
  return fetch({
    url: PROC_ITEM,
    method: 'post',
    headers: {
      'public-request': 'true',
    },
    data: {
      sOpe: config.API_SCLE_READ,
      Data: data,
    },
  });
};

ProcessService.updateProcessItem = (data) => {
  return fetch({
    url: PROC_ITEM,
    method: 'post',
    headers: {
      'public-request': 'true',
    },
    data: {
      sOpe: config.API_SCLE_EDIT,
      Data: data,
    },
  });
};

ProcessService.createProcessItem = (data) => {
  return fetch({
    url: PROC_ITEM,
    method: 'post',
    headers: {
      'public-request': 'true',
    },
    data: {
      sOpe: config.API_SCLE_ADD,
      Data: data,
    },
  });
};

ProcessService.disableProcessItem = (data) => {
  return fetch({
    url: PROC_ITEM,
    method: 'post',
    headers: {
      'public-request': 'true',
    },
    data: {
      sOpe: config.API_SCLE_DELETE,
      sCle: 'A8428-330',
      nTaille: 48,
      Data: data,
    },
  });
};

export default ProcessService;
