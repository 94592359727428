import { all, takeEvery, put, fork, call } from 'redux-saga/effects';

import ProcessHistoryService from 'services/ProcessHistoryService';
import { ADD_PROCESS_HISTORY_ITEM, GET_PROCESS_HISTORY_LIST, GET_PROCESS_HISTORY_ITEM } from '../constants/ProcessHistory';

import {
  addProcessHistoryItemError,
  addProcessHistoryItemSuccess,
  getProcessHistoryItemError,
  getProcessHistoryItemSuccess,
  getProcessHistoryListError,
  getProcessHistoryListSuccess,
} from '../actions/ProcessHistory';


export function* addProcessHistoryItem() {
  yield takeEvery(ADD_PROCESS_HISTORY_ITEM, function* ({ data }) {
    try {
      const response = yield call(ProcessHistoryService.addProcessHistory, data);
      yield put(addProcessHistoryItemSuccess(response.Data));

    } catch (err) {
      // console.log("error addProcessHistoryItem", err)
      yield put(addProcessHistoryItemError(err));
    }
  });
}

export function* getProcessHistoryList() {
  yield takeEvery(GET_PROCESS_HISTORY_LIST, function* ({ data }) {
    try {
      const response = yield call(ProcessHistoryService.getProcessHistory, data);
      yield put(getProcessHistoryListSuccess(response.Data));
    } catch (err) {
      // console.log("error getProcessHistoryList", err)
      yield put(getProcessHistoryListError(err));
    }
  });
}
export function* getProcessHistoryItem() {
  yield takeEvery(GET_PROCESS_HISTORY_ITEM, function* ({ data }) {
    try {
      const response = yield call(ProcessHistoryService.getProcessHistory, data);
      yield put(getProcessHistoryItemSuccess(response.Data));

    } catch (err) {
      // console.log("error getProcessHistoryItem", err)
      yield put(getProcessHistoryItemError(err));
    }
  });
}

export default function* rootSaga() {
  yield all([
    fork(addProcessHistoryItem),
    fork(getProcessHistoryList),
    fork(getProcessHistoryItem)]);
}


