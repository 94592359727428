import {
  ADD_ORGANISATION_ITEM,
  ADD_ORGANISATION_ITEM_SUCCESS,
  ADD_ORGANISATION_ITEM_ERROR,
  GET_ORGANISATION_LIST,
  GET_ORGANISATION_LIST_SUCCESS,
  GET_ORGANISATION_LIST_ERROR,
  GET_ORGANISATION_ITEM,
  GET_ORGANISATION_ITEM_SUCCESS,
  GET_ORGANISATION_ITEM_ERROR,
  UPDATE_ORGANISATION_ITEM,
  UPDATE_ORGANISATION_ITEM_SUCCESS,
  UPDATE_ORGANISATION_ITEM_ERROR,
  SHOW_LOADING,
  GET_ORGANISATION_TYPE_LIST,
  GET_ORGANISATION_TYPE_LIST_SUCCESS,
  GET_ORGANISATION_TYPE_LIST_ERROR,
  GET_ORGANISATION_INFOS,
  GET_ORGANISATION_INFOS_SUCCESS,
  GET_ORGANISATION_INFOS_ERROR,
} from '../constants/Organisation';

export const addOrganisationItem = (item) => {
  return {
    type: ADD_ORGANISATION_ITEM,
    data: item,
  };
};

export const addOrganisationItemSuccess = (data) => {
  return {
    type: ADD_ORGANISATION_ITEM_SUCCESS,
    message: 'L\'organisation a bien été ajouté',
    data,
  };
};

export const addOrganisationItemError = (error) => {
  return {
    type: ADD_ORGANISATION_ITEM_ERROR,
    ...error,
  };
};

export const getOrganisationList = () => {
  return {
    type: GET_ORGANISATION_LIST,
    data: {
      sC: 0,
      nN: 0,
    },
  };
};

export const getOrganisationTypeList = () => {
  return {
    type: GET_ORGANISATION_TYPE_LIST,
    data: {
      sC: 0,
      nN: 0,
    },
  };
};

export const getOrganisationListSuccess = (data) => {
  return {
    type: GET_ORGANISATION_LIST_SUCCESS,
    data
  };
};

export const getOrganisationTypeListSuccess = (data) => {
  return {
    type: GET_ORGANISATION_TYPE_LIST_SUCCESS,
    data
  };
};

export const getOrganisationListError = (error) => {
  return {
    type: GET_ORGANISATION_LIST_ERROR,
    ...error,
  };
};

export const getOrganisationTypeListError = (error) => {
  return {
    type: GET_ORGANISATION_TYPE_LIST_ERROR,
    ...error,
  };
};

export const getOrganisationItem = (id) => {
  return {
    type: GET_ORGANISATION_ITEM,
    data: { nIDOrganisation: id },
  };
};

export const getOrganisationItemSuccess = (item) => {
  return {
    type: GET_ORGANISATION_ITEM_SUCCESS,
    item,
  };
};

export const getOrganisationItemError = (error) => {
  return {
    type: GET_ORGANISATION_ITEM_ERROR,
    ...error,
  };
};

export const updateOrganisationItem = (item) => {
  return {
    type: UPDATE_ORGANISATION_ITEM,
    data: item,
  };
};

export const updateOrganisationItemSuccess = (item) => {
  return {
    type: UPDATE_ORGANISATION_ITEM_SUCCESS,
    message: 'Le traitement a bien été mise a jour',
    item
  };
};

export const updateOrganisationItemError = (error) => {
  return {
    type: UPDATE_ORGANISATION_ITEM_ERROR,
    ...error,
  };
};

export const getOrganisationInfos = () => {
  return {
    type: GET_ORGANISATION_INFOS,
    data: {},
  };
};

export const getOrganisationInfosSuccess = (item) => {
  return {
    type: GET_ORGANISATION_INFOS_SUCCESS,
    item
  };
};

export const getOrganisationInfosError = (error) => {
  return {
    type: GET_ORGANISATION_INFOS_ERROR,
    ...error
  };
};

export const showLoading = () => {
  return {
    type: SHOW_LOADING,
  };
};

