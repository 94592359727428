import { all, takeEvery, put, fork, call } from 'redux-saga/effects';

import TodolistService from 'services/TodolistService';

import {
  getTodolistListError,
  getTodolistListSuccess,
  closeTodolistItemError,
  closeTodolistItemSuccess,
} from '../actions/Todolist';

import {
  GET_TODOLIST_LIST,
  CLOSE_TODOLIST_ITEM,
} from '../constants/Todolist';

export function* getTodolistList() {
  yield takeEvery(GET_TODOLIST_LIST, function* ({ data }) {
    try {
      const response = yield call(TodolistService.getTodolistList, data);
      yield put(getTodolistListSuccess(response.Data));
    } catch (err) {
      yield put(getTodolistListError(err));
    }
  });
}

export function* closeTodolistItem() {
  yield takeEvery(CLOSE_TODOLIST_ITEM, function* ({ data }) {
    try {
      const response = yield call(TodolistService.closeTodolistItem, data);
      yield put(closeTodolistItemSuccess(response.Data));
    } catch (err) {
      yield put(closeTodolistItemError(err));
    }
  });
}

export default function* rootSaga() {
  yield all([
    fork(getTodolistList),
    fork(closeTodolistItem),
  ]);
}
