import fetch from 'services/Interceptors/MdsInterceptor';
import * as config from 'configs/AppConfig';


const UtilsService = {};
const PROC_COUNTRY = 'llp';
const PROC_FONCPROF = 'lfp';

UtilsService.getCountryList = (data) => {
    return fetch({
        url: PROC_COUNTRY,
        method: 'post',
        headers: {
            'public-request': 'true',
        },
        data: {
            sOpe: config.API_SCLE_READ,
            Data: data,
        },
    });
};

UtilsService.getProfessionnalFunctionList = (data) => {
    return fetch({
        url: PROC_FONCPROF,
        method: 'post',
        headers: {
            'public-request': 'true',
        },
        data: {
            sOpe: config.API_SCLE_READ,
            Data: data,
        },
    });
};

export default UtilsService;
