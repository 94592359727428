import {
  ADD_INCIDENT_ITEM,
  ADD_INCIDENT_ITEM_SUCCESS,
  ADD_INCIDENT_ITEM_ERROR,
  GET_INCIDENT_ITEM,
  GET_INCIDENT_ITEM_SUCCESS,
  GET_INCIDENT_ITEM_ERROR,
  SHOW_LOADING,
  GET_INCIDENT_LIST_SUCCESS,
  GET_INCIDENT_LIST_ERROR,
  GET_INCIDENT_LIST,
  UPDATE_INCIDENT_ITEM,
  UPDATE_INCIDENT_ITEM_ERROR,
  UPDATE_INCIDENT_ITEM_SUCCESS,
} from '../constants/Incident';

const initState = {
  isLoading: false,
  data: [],
  dataShouldUpdate: false,
  item: {},
};

const auth = (state = initState, action) => {
  switch (action.type) {
    case SHOW_LOADING: {
      return {
        ...state,
        isLoading: true,
      };
    }
    case GET_INCIDENT_ITEM: {
      return {
        ...state,
        item: initState.item,
        isLoading: true,
      };
    }

    case ADD_INCIDENT_ITEM:
    case ADD_INCIDENT_ITEM_ERROR:
    case GET_INCIDENT_ITEM_ERROR: {
      return {
        ...state,
        isLoading: false,
      };
    }
    case GET_INCIDENT_ITEM_SUCCESS: {
      return {
        ...state,
        item: action.item,
        isLoading: false,
      };
    }
    case ADD_INCIDENT_ITEM_SUCCESS: {
      return {
        ...state,
        item: action.item,
        dataShouldUpdate: true,
        isLoading: false,
      };
    }

    case UPDATE_INCIDENT_ITEM:
    case UPDATE_INCIDENT_ITEM_ERROR:
    case UPDATE_INCIDENT_ITEM_SUCCESS: {
      return {
        ...state,
        item: action.item,
        dataShouldUpdate: true,
        isLoading: false,
      };
    }

    case GET_INCIDENT_LIST:
    case GET_INCIDENT_LIST_ERROR:
    case GET_INCIDENT_LIST_SUCCESS: {
      return {
        ...state,
        data: action.data,
        dataShouldUpdate: false,
        isLoading: false,
      };
    }

    default:
      return state;
  }
};

export default auth;
