import fetch from 'services/Interceptors/MdsInterceptor';
import * as config from 'configs/AppConfig';

const OptRefManagementService = {};

OptRefManagementService.getElTable = (data) => {
  return fetch({
    url: 'gor',
    method: 'post',
    headers: {
      'public-request': 'true',
    },
    data: {
      sOpe: config.API_SCLE_READ,
      Data: data,
    },
  });
};

OptRefManagementService.getEmptyStruct = (data) => {
  return fetch({
    url: 'gor',
    method: 'post',
    headers: {
      'public-request': 'true',
    },
    data: {
      sOpe: config.API_SCLE_READ,
      Data: data,
    },
  });
};


OptRefManagementService.getListTables = (data) => {
  return fetch({
    url: 'gor',
    method: 'post',
    headers: {
      'public-request': 'true',
    },
    data: {
      sOpe: data.length !== 0 ? `99-${data.lang}` : "99",
      Data: data,
    },
  });
};

OptRefManagementService.getListElTable = (data) => {
  return fetch({
    url: 'gor',
    method: 'post',
    headers: {
      'public-request': 'true',
    },
    data: {
      sOpe: data.lang.length !== 0 ? `11-${data.lang}` : "11",
      Data: { sCodeTableRef: data.sCodeTableRef },
    },
  });
};

OptRefManagementService.saveNewRefTable = (data) => {
  return fetch({
    url: 'gor',
    method: 'post',
    headers: {
      'public-request': 'true',
    },
    data: {
      sOpe: '2',
      Data: data,
    },
  });
};

OptRefManagementService.updateRefTable = (data) => {
  return fetch({
    url: 'gor',
    method: 'post',
    headers: {
      'public-request': 'true',
    },
    data: {
      sOpe: '33',
      Data: data,
    },
  });
};

OptRefManagementService.updateTotalRefTable = (data) => {
  return fetch({
    url: 'gor',
    method: 'post',
    headers: {
      'public-request': 'true',
    },
    data: {
      sOpe: '3',
      Data: data,
    },
  });
};

OptRefManagementService.deleteRefTable = (data) => {
  return fetch({
    url: `gor`,
    method: 'post',
    headers: {
      'public-request': 'true',
    },
    data: {
      sOpe: '4',
      Data: data,
    },
  });
};

export default OptRefManagementService;
