import {
  ADD_PROCESS_ITEM,
  ADD_PROCESS_ITEM_SUCCESS,
  ADD_PROCESS_ITEM_ERROR,
  GET_PROCESS_LIST,
  GET_PROCESS_LIST_SUCCESS,
  GET_PROCESS_LIST_ERROR,
  GET_PROCESS_ITEM,
  GET_PROCESS_ITEM_SUCCESS,
  GET_PROCESS_ITEM_ERROR,
  UPDATE_PROCESS_ITEM,
  UPDATE_PROCESS_ITEM_SUCCESS,
  UPDATE_PROCESS_ITEM_ERROR,
  DISABLE_PROCESS_ITEM,
  DISABLE_PROCESS_ITEM_SUCCESS,
  DISABLE_PROCESS_ITEM_ERROR,
  RESET_LAST_ITEM_ADDED,
  RESET_LAST_ITEM_EDITED,
  SHOW_LOADING,
} from '../constants/Process';

import * as converter from '../converter/Process';

export const addProcessItem = (item) => {
  return {
    type: ADD_PROCESS_ITEM,
    data: converter.processItemToMdsProcess(item),
  };
};

export const addProcessItemSuccess = (data) => {
  return {
    type: ADD_PROCESS_ITEM_SUCCESS,
    message: 'Le traitement a bien été ajouté',
    data,
  };
};

export const addProcessItemError = (error) => {
  return {
    type: ADD_PROCESS_ITEM_ERROR,
    ...error,
  };
};

export const getProcessList = () => {
  return {
    type: GET_PROCESS_LIST,
    data: {
      sC: 0,
      nN: 0,
    },
  };
};

export const getProcessListSuccess = (data) => {
  return {
    type: GET_PROCESS_LIST_SUCCESS,
    data: converter.mdsProcessListToProcessList(data.tab_Trait, data.tab_CatDomFonc),
  };
};

export const getProcessListError = (error) => {
  return {
    type: GET_PROCESS_LIST_ERROR,
    ...error,
  };
};

export const getProcessItem = (id) => {
  return {
    type: GET_PROCESS_ITEM,
    data: { nBTrait: 1, tab_Trait: [{ nID_Trait: id }] },
  };
};

export const getNewProcessItem = () => {
  return {
    type: GET_PROCESS_ITEM,
    data: { nBTrait: 1, tab_Trait: [{ nID_Trait: 0 }] },
  };
};

export const getProcessItemSuccess = (item) => {
  return {
    type: GET_PROCESS_ITEM_SUCCESS,
    item: converter.mdsProcessItemToProcess(item),
  };
};

export const getProcessItemError = (error) => {
  return {
    type: GET_PROCESS_ITEM_ERROR,
    ...error,
  };
};

export const updateProcessItem = (item) => {
  return {
    type: UPDATE_PROCESS_ITEM,
    data: {
      nBTrait: 1,
      ...converter.processItemToMdsProcess(item),
    },
  };
};

export const updateProcessItemSuccess = (item) => {
  return {
    type: UPDATE_PROCESS_ITEM_SUCCESS,
    message: 'Le traitement a bien été mise a jour',
    item: converter.mdsProcessItemToProcess(item),
    // item,
  };
};

export const updateProcessItemError = (error) => {
  return {
    type: UPDATE_PROCESS_ITEM_ERROR,
    ...error,
  };
};

export const disableProcessItem = (id) => {
  return {
    type: DISABLE_PROCESS_ITEM,
    data: {
      nBTrait: 1,
      tab_Trait: [
        {
          nID_Trait: id,
        },
      ],
    },
  };
};

export const disableProcessItemSuccess = (item) => {
  return {
    type: DISABLE_PROCESS_ITEM_SUCCESS,
    message: 'Le traitement a bien été mise a jour',
    item: converter.mdsProcessItemToProcess(item),
    // item,
  };
};

export const disableProcessItemError = (error) => {
  return {
    type: DISABLE_PROCESS_ITEM_ERROR,
    ...error,
  };
};

export const showLoading = () => {
  return {
    type: SHOW_LOADING,
  };
};

export const resetLastItemAdded = () => {
  return {
    type: RESET_LAST_ITEM_ADDED,
  };
};
export const resetLastItemEdited = () => {
  return {
    type: RESET_LAST_ITEM_EDITED,
  };
};
