import {
  ADD_PIA, ADD_PIA_ERROR,
  ADD_PIA_SUCCESS, DELETE_PIA,
  DELETE_PIA_ERROR, DELETE_PIA_SUCCESS,
  GET_PIA, GET_PIA_ERROR, GET_PIA_LIST, GET_PIA_LIST_ERROR, GET_PIA_LIST_SUCCESS, GET_PIA_SUCCESS,
  GET_BASE_PIA, GET_BASE_PIA_SUCCESS, GET_BASE_PIA_ERROR,
  ADD_TRAIT_PIA,
  ADD_TRAIT_PIA_SUCCESS,
  ADD_TRAIT_PIA_ERROR,
  SHOW_LOADING,
  UPDATE_PIA_ITEM,
  UPDATE_PIA_ITEM_SUCCESS,
  UPDATE_PIA_ITEM_ERROR
}
  from "../constants/Pia";

export const getPIA = (id) => {
  return {
    type: GET_PIA,
    data: { DataPIA: { nIDPIA: id } },
  };
};

export const getPIASuccess = (data) => {
  return {
    type: GET_PIA_SUCCESS,
    data,
  };
};

export const getPIAError = (error) => {
  return {
    type: GET_PIA_ERROR,
    ...error,
  };
};

export const createTraitPIA = (id) => {
  return {
    type: ADD_TRAIT_PIA,
    data: { DataPIA: { nIDTraitement: id } },
  };
};

export const createTraitPIASuccess = (data) => {
  return {
    type: ADD_TRAIT_PIA_SUCCESS,
    data,
  };
};

export const createTraitPIAError = (error) => {
  return {
    type: ADD_TRAIT_PIA_ERROR,
    ...error,
  };
};

export const getBasePIA = (idTraitement) => {
  return {
    type: GET_BASE_PIA,
    data: { DataPIA: { nIDTraitement: idTraitement } },
  };
};

export const getBasePIASuccess = (data) => {
  return {
    type: GET_BASE_PIA_SUCCESS,
    data,
  };
};

export const getBasePIAError = (error) => {
  return {
    type: GET_BASE_PIA_ERROR,
    ...error,
  };
};

export const getPIAList = () => {
  return {
    type: GET_PIA_LIST,
    data: {
      sC: 0,
      nN: 0,
    },
  };
};

export const getPIAListSuccess = (data) => {
  return {
    type: GET_PIA_LIST_SUCCESS,
    data,
  };
};

export const getPIAListError = (error) => {
  return {
    type: GET_PIA_LIST_ERROR,
    ...error,
  };
};

export const createPIA = (item) => {
  return {
    type: ADD_PIA,
    data: item
  };
};

export const createPIASuccess = (item) => {
  return {
    type: ADD_PIA_SUCCESS,
    message: 'Le PIA a bien été crée',
    item,
  };
};

export const createPIAError = (error) => {
  return {
    type: ADD_PIA_ERROR,
    ...error
  };
};

export const updatePIAItem = (item) => {
  return {
    type: UPDATE_PIA_ITEM,
    data: item,
  };
};

export const updatePIAItemSuccess = (item) => {
  return {
    type: UPDATE_PIA_ITEM_SUCCESS,
    message: 'Le PIA a bien été mise a jour',
    item
  };
};

export const updatePIAItemError = (error) => {
  return {
    type: UPDATE_PIA_ITEM_ERROR,
    ...error,
  };
};

export const deletePIA = (nIDTrait, nIDPIA) => {
  return {
    type: DELETE_PIA,
    data: {
      nIDTrait,
      nIDPIA
    },
  };
};

export const deletePIASuccess = (item) => {
  return {
    type: DELETE_PIA_SUCCESS,
    message: 'Le PIA a bien été supprimé',
    item,
  };
};

export const deletePIAError = (error) => {
  return {
    type: DELETE_PIA_ERROR,
    ...error
  };
};


export const showLoading = () => {
  return {
    type: SHOW_LOADING,
  };
};
