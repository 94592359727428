import { combineReducers } from 'redux';
import Actions from './Actions';
import ActorsBook from './ActorsBook';
import App from './App';
import Auth from './Auth';
import Dashboard from './Dashboard';
import Document from './Document';
import Faq from './Faq';
import Organisation from './Organisation';
import Process from './Process';
import ProcessHistory from './ProcessHistory';
import ProcessModel from './ProcessModel';
import DocumentModel from './DocumentModel';
import Provider from './Provider';
import Theme from './Theme';
import Utils from './Utils';
import CreationDocument from './CreationDocument';
import Pia from './Pia';
import Axe from './Axe';
import Incident from './Incident';
import MultiOrga from './MultiOrga';
import DemandeDeDroits from './DemandeDeDroits';
import FunctionalProcedure from './FunctionalProcedure';
import ContratFeat from './ContratFeat';
import DocManRegLawReq from './DocManRegLawReq';
import OptRefManagement from './OptRefManagement';
import Todolist from './Todolist';
import Raci from './Raci';
import UsersManagement from './UsersManagement';

const appReducer = combineReducers({
  actions: Actions,
  actorsBook: ActorsBook,
  auth: Auth,
  app: App,
  dashboard: Dashboard,
  document: Document,
  faq: Faq,
  organisation: Organisation,
  process: Process,
  processHistory: ProcessHistory,
  processModel: ProcessModel,
  documentModel: DocumentModel,
  provider: Provider,
  theme: Theme,
  utils: Utils,
  creationDocument: CreationDocument,
  pia: Pia,
  axe: Axe,
  incident: Incident,
  multiOrga: MultiOrga,
  demandeDeDroits: DemandeDeDroits,
  functionalProcedure: FunctionalProcedure,
  contratFeat: ContratFeat,
  docManRegLawReq: DocManRegLawReq,
  optRefManagement: OptRefManagement,
  todolist: Todolist,
  raci: Raci,
  usersManagement: UsersManagement,
});

const rootReducer = (state, action) => {
  if (action.type === 'SIGNOUT_SUCCESS' || action.type === 'SIGNOUT_ERROR') {
    return appReducer(undefined, action);
  }
  return appReducer(state, action);
};

export default rootReducer;
