export const ADD_PROCESS_HISTORY_ITEM = 'ADD_PROCESS_HISTORY_ITEM';
export const ADD_PROCESS_HISTORY_ITEM_SUCCESS = 'ADD_PROCESS_HISTORY_ITEM_SUCCESS';
export const ADD_PROCESS_HISTORY_ITEM_ERROR = 'ADD_PROCESS_HISTORY_ITEM_ERROR';
export const GET_PROCESS_HISTORY_LIST = 'GET_PROCESS_HISTORY_LIST';
export const GET_PROCESS_HISTORY_LIST_SUCCESS = 'GET_PROCESS_HISTORY_LIST_SUCCESS';
export const GET_PROCESS_HISTORY_LIST_ERROR = 'GET_PROCESS_HISTORY_LIST_ERROR';
export const GET_PROCESS_HISTORY_ITEM = 'GET_PROCESS_HISTORY_ITEM';
export const GET_PROCESS_HISTORY_ITEM_SUCCESS = 'GET_PROCESS_HISTORY_ITEM_SUCCESS';
export const GET_PROCESS_HISTORY_ITEM_ERROR = 'GET_PROCESS_HISTORY_ITEM_ERROR';

export const SHOW_LOADING = 'SHOW_PROCESS_HISTORY_LOADING';