import {
  GET_ORGANISATION,
  GET_ORGANISATION_SUCCESS,
  GET_ORGANISATION_ERROR,
  UPDATE_ORGANISATION,
  UPDATE_ORGANISATION_SUCCESS,
  UPDATE_ORGANISATION_ERROR,
  SHOW_LOADING,
} from '../constants/Organisation';

import * as converter from '../converter/Organisation';

export const getOrganisation = (idOrga) => {
  return {
    type: GET_ORGANISATION,
    data: {},
  };
};

export const getOrganisationSuccess = (mdsOrgaTable) => {
  return {
    type: GET_ORGANISATION_SUCCESS,
    data: {
      data_Orga: converter.mdsOrgaTableInOrga(mdsOrgaTable),
      tab_DPO: converter.convertMdsOrgaListDpoList(mdsOrgaTable),
      tab_Relai: converter.convertMdsOrgaListRelaiList(mdsOrgaTable),
    }
  };
};

export const getOrganisationError = (error) => {
  return {
    type: GET_ORGANISATION_ERROR,
    ...error
  };
};

export const updateOrganisation = (user, organisation) => {
  return {
    type: UPDATE_ORGANISATION,
    data: {
      Data_Orga: converter.organisationToMdsOrganisation(user.idOrg, organisation),
    },
  };
};

export const updateOrganisationSuccess = (mdsOrgaTable) => {
  return {
    type: UPDATE_ORGANISATION_SUCCESS,
    data: converter.mdsOrgaTableInOrga(mdsOrgaTable),
    message: 'L\'item a bien été mise a jour'
  };
};

export const updateOrganisationError = (error) => {
  return {
    type: UPDATE_ORGANISATION_ERROR,
    ...error,
  };
};

export const showLoading = () => {
  return {
    type: SHOW_LOADING,
  };
};
