// UsersManagement.js
import {
    ADD_USER_MANAGEMENT_ITEM,
    ADD_USER_MANAGEMENT_ITEM_SUCCESS,
    ADD_USER_MANAGEMENT_ITEM_ERROR,
    GET_USER_MANAGEMENT_LIST,
    GET_USER_MANAGEMENT_LIST_SUCCESS,
    GET_USER_MANAGEMENT_LIST_ERROR,
    GET_USER_MANAGEMENT_ITEM,
    GET_USER_MANAGEMENT_ITEM_SUCCESS,
    GET_USER_MANAGEMENT_ITEM_ERROR,
    UPDATE_USER_MANAGEMENT_ITEM,
    UPDATE_USER_MANAGEMENT_ITEM_SUCCESS,
    UPDATE_USER_MANAGEMENT_ITEM_ERROR,
    SHOW_LOADING,
  } from '../constants/UsersManagement';
  
  const initState = {
    isLoading: false,
    data: [],
    dataShouldUpdate: true,
    itemShouldRedirect: false,
    item: {},
  };
  
  const auth = (state = initState, action) => {
    switch (action.type) {
      case SHOW_LOADING: {
        return {
          ...state,
          isLoading: true,
        };
      }
  
      case ADD_USER_MANAGEMENT_ITEM:
      case GET_USER_MANAGEMENT_LIST:
      case UPDATE_USER_MANAGEMENT_ITEM:
      case GET_USER_MANAGEMENT_ITEM: {
        return {
          ...state,
          isLoading: true,
        };
      }
  
      case GET_USER_MANAGEMENT_ITEM_SUCCESS: {
        return {
          ...state,
          item: action.item,
          dataShouldUpdate: true,
          isLoading: false,
        };
      }
      case GET_USER_MANAGEMENT_LIST_SUCCESS: {
        return {
          ...state,
          data: action.data,
          dataShouldUpdate: false,
          isLoading: false,
          itemShouldRedirect: false,
        };
      }
      case UPDATE_USER_MANAGEMENT_ITEM_SUCCESS: {
        return {
          ...state,
          isLoading: false,
          dataShouldUpdate: true,
          item: action.item,
        };
      }
  
      case ADD_USER_MANAGEMENT_ITEM_SUCCESS: {
        return {
          ...state,
          item: action.item,
          dataShouldUpdate: true,
          isLoading: false,
        };
      }
  
      case ADD_USER_MANAGEMENT_ITEM_ERROR:
      case GET_USER_MANAGEMENT_LIST_ERROR:
      case GET_USER_MANAGEMENT_ITEM_ERROR:
      case UPDATE_USER_MANAGEMENT_ITEM_ERROR: {
        return {
          ...state,
          itemShouldRedirect: true,
          isLoading: false,
        };
      }
  
      default:
        return state;
    }
  };
  
export default auth;
  