/* eslint-disable */
import moment, { relativeTimeRounding } from "moment";
import keywordExtractor from 'keyword-extractor';

import * as DateConstant from 'configs/constants/DateConstant'
class Utils {


  
  static getEnv(key) {
    // console.log(window._env_)
    if (typeof window !== undefined && window._env_ !== undefined) {
      return window._env_[key]
    }
    return process.env[key]
  }

  /**
   * Get first character from first & last sentences of a username
   * @param {String} name - Username
   * @return {String} 2 characters string
   */
  static getNameInitial(name) {
    const initials = name.match(/\b\w/g) || [];
    return ((initials.shift() || '') + (initials.pop() || '')).toUpperCase();
  }

  /**
   * Get current path related object from Navigation Tree
   * @param {Array} navTree - Navigation Tree from directory 'configs/NavigationConfig'
   * @param {String} path - Location path you looking for e.g '/app/dashboards/analytic'
   * @return {Object} object that contained the path string
   */
  static getRouteInfo(navTree, path) {
    if (navTree.path === path || navTree.path + '/' === path) {
      return navTree;
    }
    let route;
    for (const p in navTree) {
      if (navTree.hasOwnProperty(p) && typeof navTree[p] === 'object') {
        route = this.getRouteInfo(navTree[p], path);
        if (route) {
          return route;
        }
      }
    }
    return route
  }

  /**
   * Get accessible color contrast
   * @param {String} hex - Hex color code e.g '#3e82f7'
   * @return {String} 'dark' or 'light'
   */
  static getColorContrast(hex) {
    const threshold = 130;
    function cutHex(h) {
      return h.charAt(0) === '#' ? h.substring(1, 7) : h;
    }
    function hexToR(h) {
      return parseInt(cutHex(h).substring(0, 2), 16);
    }
    function hexToG(h) {
      return parseInt(cutHex(h).substring(2, 4), 16);
    }
    function hexToB(h) {
      return parseInt(cutHex(h).substring(4, 6), 16);
    }
    const hRed = hexToR(hex);
    const hGreen = hexToG(hex);
    const hBlue = hexToB(hex);
    const cBrightness = (hRed * 299 + hGreen * 587 + hBlue * 114) / 1000;
    if (cBrightness > threshold) {
      return 'dark';
    }
    return 'light';
  }

  /**
   * Darken or lighten a hex color
   * @param {String} color - Hex color code e.g '#3e82f7'
   * @param {Number} percent - Percentage -100 to 100, positive for lighten, negative for darken
   * @return {String} Darken or lighten color
   */
  static shadeColor(color, percent) {
    let R = parseInt(color.substring(1, 3), 16);
    let G = parseInt(color.substring(3, 5), 16);
    let B = parseInt(color.substring(5, 7), 16);
    R = parseInt((R * (100 + percent)) / 100);
    G = parseInt((G * (100 + percent)) / 100);
    B = parseInt((B * (100 + percent)) / 100);
    R = R < 255 ? R : 255;
    G = G < 255 ? G : 255;
    B = B < 255 ? B : 255;
    const RR = R.toString(16).length === 1 ? `0${R.toString(16)}` : R.toString(16);
    const GG = G.toString(16).length === 1 ? `0${G.toString(16)}` : G.toString(16);
    const BB = B.toString(16).length === 1 ? `0${B.toString(16)}` : B.toString(16);
    return `#${RR}${GG}${BB}`;
  }

  /**
   * Returns either a positive or negative
   * @param {Number} number - number value
   * @param {any} positive - value that return when positive
   * @param {any} negative - value that return when negative
   * @return {any} positive or negative value based on param
   */
  static getSignNum(number, positive, negative) {
    if (number > 0) {
      return positive;
    }
    if (number < 0) {
      return negative;
    }
    return null;
  }

  /**
   * Returns either ascending or descending value
   * @param {Object} a - antd Table sorter param a
   * @param {Object} b - antd Table sorter param b
   * @param {String} key - object key for compare
   * @return {any} a value minus b value
   */
  static antdTableSorter(a, b, key) {
    if (typeof a[key] === 'number' && typeof b[key] === 'number') {
      return a[key] - b[key];
    }

    if (typeof a[key] === 'string' && typeof b[key] === 'string') {
      a = a[key].toLowerCase();
      b = b[key].toLowerCase();
      return a > b ? -1 : b > a ? 1 : 0;
    }
    return false;
  }

  /**
   * Filter array of object
   * @param {Array} list - array of objects that need to filter
   * @param {String} key - object key target
   * @param {any} value  - value that excluded from filter
   * @return {Array} a value minus b value
   */
  static filterArray(list, key, value) {
    let data = list;
    if (list) {
      data = list.filter((item) => item[key] === value);
    }
    return data;
  }

  /**
   * Remove object from array by value
   * @param {Array} list - array of objects
   * @param {String} key - object key target
   * @param {any} value  - target value
   * @return {Array} Array that removed target object
   */
  static deleteArrayRow(list, key, value) {
    let data = list;
    if (list) {
      data = list.filter((item) => item[key] !== value);
    }
    return data;
  }

  /**
   * Wild card search on all property of the object
   * @param {Number | String} input - any value to search
   * @param {Array} list - array for search
   * @return {Array} array of object contained keyword
   */
  static wildCardSearch(list, input) {
    const searchText = (item) => {
      Object.keys(item).forEach((key) => {
        if (
          item[key] != null &&
          item[key]
            .toString()
            .toUpperCase()
            .indexOf(input.toString().toUpperCase()) !== -1
        ) {
          return true;
        }
        return false;
      });
    };
    return list.filter((value) => searchText(value));
  }

  /**
   * Get Breakpoint
   * @param {Object} screens - Grid.useBreakpoint() from antd
   * @return {Array} array of breakpoint size
   */
  static getBreakPoint(screens) {
    const breakpoints = [];

    Object.keys(screens).forEach((key) => {
      if (Object.prototype.hasOwnProperty.call(screens, key)) {
        const element = screens[key];
        if (element) {
          breakpoints.push(key);
        }
      }
    });
    return breakpoints;
  }

  static convertItemDateToMoment(data, format = DateConstant.DATE_FORMAT_YYYY_MM_DD) {
    if (data != null) {
      return moment(data);
    }
    return null
  }

  static convertItemDateToMomentFromList(data, keys, format = DateConstant.DATE_FORMAT_YYYY_MM_DD) {
    var newData = [];
    if (typeof data === 'object' && data !== null) {
      data.map(item => {
        Object.keys(keys).forEach((key) => {
          item[keys[key]] = this.convertItemDateToMoment(item[key], format);
        })
        newData.push(item);
      })
    }
    //console.log(newData);
    return newData;
  }

  static convertMomentDateToItemDate(data, keys, format = DateConstant.DATE_FORMAT_YYYY_MM_DD) {
    var newData = [];
    data.map(item => {
      Object.keys(keys).forEach((key) => {
        item[key] = item[keys[key]].format(format);
      })
      newData.push(item);
    })
    //console.log(newData);
    return newData;
  }

  static formateDate(data, format = DateConstant.DATE_FORMAT_YYYY_MM_DD) {
    const date = moment(data)
    if (date.isValid()) return date.format(format)
    return ""
  }

  static formateFrenchDate(data, format = DateConstant.DATE_FORMAT_DD_MM_YYYY) {
    const date = moment(data)
    if (date.isValid()) return date.format(format)
    return ""
  }


  /**
   * Ugly method to get the "mTaille" needed from the API
   * @param {Json} json - the json we have to know the size
   */
  static getJsonByteSize(json) {
    return JSON.stringify(json)
      .replaceAll(',', ', ')
      .replace('{', '{ ')
      .replace('}', ' }').length;
  }

  static parseJsonToString(json) {
    return JSON.stringify(json);
  }

  static parseStringToJson(strJson) {
    const parsedJson = JSON.parse(strJson);
    Object.keys(parsedJson).forEach((k) => {
      if (Utils.IsJsonString(parsedJson[k])) {
        parsedJson[k] = Utils.parseStringToJson(parsedJson[k]);
      }
    });
    return parsedJson;
  }

  static IsJsonString(str) {
    if (!Utils.isNumeric(str) && typeof str === 'string') {
      try {
        JSON.parse(str);
        return true;
      } catch (e) {
        return false;
      }
    }
    return false;
  }

  static isNumeric(n) {
    return !Number.isNaN(parseFloat(n)) && Number.isFinite(n);
  }

  static getDefaultApiData() {
    return {
      dhDateCX: new Date(),
      dhDatedeCx: new Date(),
    };
  }

  static arrayRemove(arr, value) {

    return arr.filter(function (ele) {
      return ele !== value;
    });
  }

  static usePrevious(ref, effect, value) {
    effect(() => {
      ref.current = value;
    });
    return ref.current;
  }

    /**
   * Ugly method to get the "getKeyWords" needed from Document
   * @param {String} sentence - the sentence that we have to get all keywords
   */
     static getKeyWords(sentence) {
        let existingWords = [];
        let keywords = '';
        // searching with multiple settings to find more keywords
        const settingsToSearchKeywords = [
          {
            language: 'french',
            remove_digits: true,
            return_changed_case: true,
            remove_duplicates: true,
            return_chained_words: true
          },
          {
            language: 'french',
            remove_digits: false,
            return_changed_case: true,
            remove_duplicates: true,
            return_chained_words: true
          },
          {
            language: 'french',
            remove_digits: false,
            return_changed_case: true,
            remove_duplicates: true,
            return_chained_words: false
          }
        ]

        const keywordsExtracted = settingsToSearchKeywords.reduce(
          (acc, settings) => [...acc, ...keywordExtractor.extract(sentence, settings)],
          []
        )

        // removing duplicates due to search with multiple settings
        const keywordsClean = keywordsExtracted.filter(
          (keyword, index) => keywordsExtracted.indexOf(keyword) === index
        )

        keywordsClean.forEach(word => {
          const newWord = word.split(' ');
          newWord.forEach(char => {
            if (!(existingWords.indexOf(char) > -1)) {
              existingWords.push(char);
              keywords += char + ' ';
            }
          })
        });

        return keywords;
    }

}

export default Utils;
