import {
  GET_DASHBOARD_DATA,
  GET_DASHBOARD_DATA_SUCCESS,
  GET_DASHBOARD_DATA_ERROR,
  SHOW_LOADING,
  GET_BASE_LEGALE_DATA,
  GET_BASE_LEGALE_DATA_SUCCESS,
  GET_BASE_LEGALE_DATA_ERROR,
  GET_CRITERE_QUANTITATIF_DATA_SUCCESS,
  GET_CRITERE_QUANTITATIF_DATA,
  GET_SYNTHESE_ACTION_DATA_ERROR,
  GET_SYNTHESE_ACTION_DATA_SUCCESS,
  GET_SYNTHESE_ACTION_DATA,
  GET_CRITERE_QUANTITATIF_DATA_ERROR,
} from '../constants/Dashboard';

export const getDashboardData = () => {
  return {
    type: GET_DASHBOARD_DATA,
    data: {},
  };
}; 

export const getDashboardDataSuccess = (mdsDashboard) => {
  return {
    type: GET_DASHBOARD_DATA_SUCCESS,
    data: convertMdsDashboard(mdsDashboard),
  };
};

export const getDashboardDataError = (error) => {
  return {
    type: GET_DASHBOARD_DATA_ERROR,
    ...error,
  };
};

export const getSyntheseActionData = () => {
  return {
    type: GET_SYNTHESE_ACTION_DATA,
    data: {},
  };
}; 

export const getSyntheseActionDataSuccess = (mdsDashboard) => {
  return {
    type: GET_SYNTHESE_ACTION_DATA_SUCCESS,
    data: convertSyntheseActionDashboard(mdsDashboard),
  };
};

export const getSyntheseActionDataError = (error) => {
  return {
    type: GET_SYNTHESE_ACTION_DATA_ERROR,
    ...error,
  };
};

export const getCritereQuantitatifData = () => {
  return {
    type: GET_CRITERE_QUANTITATIF_DATA,
    data: {},
  };
}; 

export const getCritereQuantitatifDataSuccess = (mdsDashboard) => {
  return {
    type: GET_CRITERE_QUANTITATIF_DATA_SUCCESS,
    data: mdsDashboard,
  };
};

export const getCritereQuantitatifDataError = (error) => {
  return {
    type: GET_CRITERE_QUANTITATIF_DATA_ERROR,
    ...error,
  };
};

export const getBaseLegaleData = () => {
  return {
    type: GET_BASE_LEGALE_DATA,
    data: {},
  };
}; 

export const getBaseLegaleDataSuccess = (mdsDashboard) => {
  return {
    type: GET_BASE_LEGALE_DATA_SUCCESS,
    data: mdsDashboard,
  };
};

export const getBaseLegaleDataError = (error) => {
  return {
    type: GET_BASE_LEGALE_DATA_ERROR,
    ...error,
  };
};

export const showLoading = () => {
  return {
    type: SHOW_LOADING,
  };
};

/**
 * Conversion Part 
 */

const convertMdsDashboard = (mdsDashboard) => {
  return {
    nbActions: mdsDashboard.nNbTotalAction,
    nbActionsTrait: mdsDashboard.nNbActTrait,
    nbActionsPIA: mdsDashboard.nNbActPIA,
    nbActionsInc: mdsDashboard.nNbActInc,
    nbActPro: mdsDashboard.nNbProposee,
    nbActVal: mdsDashboard.nNbValidee,
    nbActPlan: mdsDashboard.nNbPlanifiee,
    nbActReal: mdsDashboard.nNbRealisee,
    nNbPr_0 : mdsDashboard.nNbPr_0,
    nNbPr_1 : mdsDashboard.nNbPr_1,
    nNbPr_2 : mdsDashboard.nNbPr_2,
    nNbPr_3 : mdsDashboard.nNbPr_3,
    nNbPr_4 : mdsDashboard.nNbPr_4,
    nNbVa_0 : mdsDashboard.nNbVa_0,
    nNbVa_1 : mdsDashboard.nNbVa_1,
    nNbVa_2 : mdsDashboard.nNbVa_2,
    nNbVa_3 : mdsDashboard.nNbVa_3,
    nNbVa_4 : mdsDashboard.nNbVa_4,
    nNbPl_0 : mdsDashboard.nNbPl_0,
    nNbPl_1 : mdsDashboard.nNbPl_1,
    nNbPl_2 : mdsDashboard.nNbPl_2,
    nNbPl_3 : mdsDashboard.nNbPl_3,
    nNbPl_4 : mdsDashboard.nNbPl_4,
    nNbRe_0 : mdsDashboard.nNbRe_0,
    nNbRe_1 : mdsDashboard.nNbRe_1,
    nNbRe_2 : mdsDashboard.nNbRe_2,
    nNbRe_3 : mdsDashboard.nNbRe_3,
    nNbRe_4 : mdsDashboard.nNbRe_4,
    tab_CatPrioAction : mdsDashboard.tab_CatPrioAction,
  };
};

const convertSyntheseActionDashboard = (mdsDashboard) => {
  return {
    nbActions: mdsDashboard.nNbTotalAction,
    nbActionsTrait: mdsDashboard.nNbActTrait,
    nbActionsPIA: mdsDashboard.nNbActPIA,
    nbActionsInc: mdsDashboard.nNbActInc,
    nbActPro: mdsDashboard.nNbProposee,
    nbActVal: mdsDashboard.nNbValidee,
    nbActPlan: mdsDashboard.nNbPlanifiee,
    nbActReal: mdsDashboard.nNbRealisee,
    nNbPr_0 : mdsDashboard.nNbPr_0,
    nNbPr_1 : mdsDashboard.nNbPr_1,
    nNbPr_2 : mdsDashboard.nNbPr_2,
    nNbPr_3 : mdsDashboard.nNbPr_3,
    nNbPr_4 : mdsDashboard.nNbPr_4,
    nNbVa_0 : mdsDashboard.nNbVa_0,
    nNbVa_1 : mdsDashboard.nNbVa_1,
    nNbVa_2 : mdsDashboard.nNbVa_2,
    nNbVa_3 : mdsDashboard.nNbVa_3,
    nNbVa_4 : mdsDashboard.nNbVa_4,
    nNbPl_0 : mdsDashboard.nNbPl_0,
    nNbPl_1 : mdsDashboard.nNbPl_1,
    nNbPl_2 : mdsDashboard.nNbPl_2,
    nNbPl_3 : mdsDashboard.nNbPl_3,
    nNbPl_4 : mdsDashboard.nNbPl_4,
    nNbRe_0 : mdsDashboard.nNbRe_0,
    nNbRe_1 : mdsDashboard.nNbRe_1,
    nNbRe_2 : mdsDashboard.nNbRe_2,
    nNbRe_3 : mdsDashboard.nNbRe_3,
    nNbRe_4 : mdsDashboard.nNbRe_4,
    tab_CatPrioAction : mdsDashboard.tab_CatPrioAction,
  };
};

