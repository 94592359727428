import fetch from 'services/Interceptors/MdsInterceptor';
import { API_SCLE_READ } from 'configs/AppConfig';

const DashboardService = {};
const PROC = 'lsa';
const SyntheseAction = 'lsa';
const CritereQuantitatif = 'ldcq';
const BaseLegal = 'ldbl';

DashboardService.getDashboardData = (data) => {
  return fetch({
    url: `/${PROC}`,
    method: 'post',
    headers: {
      'public-request': 'true',
    },
    data: {
      sOpe: "1-2",
      Data: data,
    },
  }); 
};

DashboardService.getSyntheseActionData = (data) => {
  return fetch({
    url: `/${SyntheseAction}`,
    method: 'post',
    headers: {
      'public-request': 'true',
    },
    data: {
      sOpe: API_SCLE_READ,
      Data: data,
    },
  }); 
};

DashboardService.getCritereQuantitatifData = (data) => {
  return fetch({
    url: `/${CritereQuantitatif}`,
    method: 'post',
    headers: {
      'public-request': 'true',
    },
    data: {
      sOpe: "1-2",
      Data: data,
    },
  }); 
};

DashboardService.getBaseLegaleData = (data) => {
  return fetch({
    url: `/${BaseLegal}`,
    method: 'post',
    headers: {
      'public-request': 'true',
    },
    data: {
      sOpe: "1-2",
      Data: data,
    },
  }); 
};

export default DashboardService;
