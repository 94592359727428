import fetch from 'services/Interceptors/MdsInterceptor';
import * as config from 'configs/AppConfig';

const ProviderService = {};
const PROC = 'gpr';

ProviderService.getProviderItem = (data) => {
  return fetch({
    url: `/${PROC}`,
    method: 'post',
    headers: {
      'public-request': 'true',
    },
    data: {
      sOpe: config.API_SCLE_READ,
      Data: data,
    },
  });
};
ProviderService.getProviderList = (data) => {
  return fetch({
    url: `/${PROC}`,
    method: 'post',
    headers: {
      'public-request': 'true',
    },
    data: {
      sOpe: config.API_SCLE_READ_LIST_11,
      Data: data,
    },
  });
};

ProviderService.updateProvider = (data) => {
  return fetch({
    url: `/${PROC}`,
    method: 'post',
    headers: {
      'public-request': 'true',
    },
    data: {
      sOpe: config.API_SCLE_EDIT,
      Data: data,
    },
  });
};

ProviderService.addProvider = (data) => {
  return fetch({
    url: `/${PROC}`,
    method: 'post',
    headers: {
      'public-request': 'true',
    },
    data: {
      sOpe: config.API_SCLE_ADD,
      Data: data,
    },
  });
};

ProviderService.deleteProvider = (data) => {
  return fetch({
    url: `/${PROC}`,
    method: 'post',
    headers: {
      'public-request': 'true',
    },
    data: {
      sOpe: config.API_SCLE_DELETE,
      Data: data,
    },
  });
};

export default ProviderService;
