import React, { useEffect, useState } from 'react';
import { Layout, Grid } from 'antd';
import { connect } from 'react-redux';
import SideNav from 'components/Layout/SideNav';
import TopNav from 'components/Layout/TopNav';
import MobileNav from 'components/Layout/MobileNav';
import HeaderNav from 'components/Layout/HeaderNav';
import Footer from 'components/Layout/Footer';
import AppViews from 'views/app-views';
import navigationConfig from 'configs/NavigationConfig';
import {
  SIDE_NAV_WIDTH,
  SIDE_NAV_COLLAPSED_WIDTH,
  NAV_TYPE_SIDE,
  NAV_TYPE_TOP,
} from 'configs/constants/ThemeConstant';
import utils from 'configs/UtilFunctions';
import fetch from 'services/Interceptors/MdsInterceptor';
import Spinner from 'components/Loading/Spinner'

const { Content } = Layout;
const { useBreakpoint } = Grid;
const PROC = 'lsa';

export const AppLayout = ({ navCollapsed, navType, location }) => {
  const [tabTrad, setTabTrad] = useState("");
  useEffect(() => {
    getIsa()
  }, []);

  const getIsa = async () => {
    return fetch({
      url: `/${PROC}`,
      method: 'post',
      headers: {
        'public-request': 'true',
      },
      data: {
        sOpe: "1",
        Data: {},
      },
    })
      .then((res) => {
        setTabTrad(res.Data);
        // console.log(tabTrad);
      })
      .catch(err => {
        if (err?.response?.status == 400 && err?.response?.data?.CodeErreur === 100021) {
          // console.log(err?.response);
          setTimeout(() => {
            window.location.href = `${process.env.PUBLIC_URL}/auth/login`;
            localStorage.clear();
          }, 500);
        }
      });
  }

  const currentRouteInfo = utils.getRouteInfo(navigationConfig, location.pathname);

  const screens = utils.getBreakPoint(useBreakpoint());
  const isMobile = !screens.includes('lg');
  const isNavSide = navType === NAV_TYPE_SIDE;
  const isNavTop = navType === NAV_TYPE_TOP;
  const getLayoutGutter = () => {
    if (isNavTop || isMobile) {
      return 0;
    }
    return navCollapsed ? SIDE_NAV_COLLAPSED_WIDTH : SIDE_NAV_WIDTH;
  };
  return (
    (
      (tabTrad != "") ?
        (
          <Layout>
            <HeaderNav isMobile={isMobile} />
            {isNavTop && !isMobile ? <TopNav routeInfo={currentRouteInfo} /> : null}
            <Layout className="app-container">
              {isNavSide && !isMobile ? <SideNav routeInfo={currentRouteInfo} /> : null}
              <Layout className="app-layout" style={{ paddingLeft: getLayoutGutter() }}>
                <div className={`app-content ${isNavTop ? 'layout-top-nav' : ''}`}>
                  <Content>
                    <AppViews />
                  </Content>
                </div>
                <Footer />
              </Layout>
            </Layout>
            {isMobile && <MobileNav />}
          </Layout>
        )
        :
        <Spinner verticalAlign="center" withText />
    )
  );
};

const mapStateToProps = ({ theme }) => {
  const { navCollapsed, navType, locale } = theme;
  return { navCollapsed, navType, locale };
};

export default connect(mapStateToProps)(React.memo(AppLayout));
