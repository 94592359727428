import {
    GET_DOCUMENT_MODEL_LIST_SUCCESS,
    GET_DOCUMENT_MODEL_LIST_ERROR,
    SHOW_DOCUMENT_MODEL_LOADING,
  } from '../constants/DocumentModel';
  
  const initState = {
    isLoading: false,
    message: {
      type: null,
      content: '',
    },
    data: [],
  };
  
  const auth = (state = initState, action) => {
    switch (action.type) {
      case SHOW_DOCUMENT_MODEL_LOADING: {
        return {
          ...state,
          isLoading: true,
        };
      }
      case GET_DOCUMENT_MODEL_LIST_SUCCESS: {
        return {
          ...state,
          data: action.data,
        };
      }
      case GET_DOCUMENT_MODEL_LIST_ERROR: {
        return {
          ...state,
          isLoading: false,
        };
      }
      default:
        return state;
    }
  };
  
  export default auth;
  