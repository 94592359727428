import { all, takeEvery, put, fork, call } from 'redux-saga/effects';

import DocManRegLawReqService from 'services/DocManRegLawReqService';

import {
  recordDocumentRegLawSuccess,
  recordDocumentRegLawError,
  getListDocumentRegLawSuccess,
  getListDocumentRegLawError,
  getDocumentRegLawSuccess,
  getDocumentRegLawError,
} from '../actions/DocManRegLawReq';

import {
  RECORD_DOCUMENT_REG_LAW_ITEM,
  LIST_DOCUMENT_REG_LAW,
  GET_DOCUMENT_REG_LAW,
} from '../constants/DocManRegLawReq';

export function* getListDocumentRegLaw() {
  yield takeEvery(LIST_DOCUMENT_REG_LAW, function* ({ data }) {
    try {
      const response = yield call(DocManRegLawReqService.getListDocument, data);
      yield put(getListDocumentRegLawSuccess(response.Data));
    } catch (err) {
      yield put(getListDocumentRegLawError(err));
    }
  });
}

export function* getDocument() {
  yield takeEvery(GET_DOCUMENT_REG_LAW, function* ({ data }) {
    try {
      const response = yield call(DocManRegLawReqService.getDocument, data);
      yield put(getDocumentRegLawSuccess(response.Data));
    } catch (err) {
      yield put(getDocumentRegLawError(err));
    }
  });
}

export function* recordDocument() {
  yield takeEvery(RECORD_DOCUMENT_REG_LAW_ITEM, function* ({ data }) {
    try {
      const response = yield call(DocManRegLawReqService.recordDocument, data);
      yield put(recordDocumentRegLawSuccess(response.Data));
    } catch (err) {
      yield put(recordDocumentRegLawError(err));
    }
  });
}

export default function* rootSaga() {
  yield all([
    fork(getListDocumentRegLaw),
    fork(getDocument),
    fork(recordDocument),
  ]);
}
