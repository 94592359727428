import DocManRegLawReqService from 'services/DocManRegLawReqService';
import {
  RECORD_DOCUMENT_REG_LAW_ITEM,
  RECORD_DOCUMENT_REG_LAW_ITEM_SUCCESS,
  RECORD_DOCUMENT_REG_LAW_ITEM_ERROR,
  LIST_DOCUMENT_REG_LAW,
  LIST_DOCUMENT_REG_LAW_SUCCESS,
  LIST_DOCUMENT_REG_LAW_ERROR,
  GET_DOCUMENT_REG_LAW,
  GET_DOCUMENT_REG_LAW_SUCCESS,
  GET_DOCUMENT_REG_LAW_ERROR,
  SHOW_LOADING,
  DO_NOTHING,
  GET_FICHE_PDF,
} from '../constants/DocManRegLawReq';

// import * as converter from '../converter/Actions';
// import * as docConverter from '../converter/DocManRegLawReq';

export const recordDocumentRegLaw = (item) => {
  return {
    type: RECORD_DOCUMENT_REG_LAW_ITEM,
    data: item,
  };
};

export const getFichePDF = (item) => {
  return {
    type: GET_FICHE_PDF,
    data: {
      sTypeDemDoc: item.typeDoc,
      nIDDocRattache: item.idTraitement,
      nFormatDemDoc: item.format,
    },
  };
};

export const recordDocumentRegLawSuccess = (item) => {
  return {
    type: RECORD_DOCUMENT_REG_LAW_ITEM_SUCCESS,
    data: {
      message: 'La demande de document a été pris en compte',
    },
  };
};

export const recordDocumentRegLawError = (error) => {
  return {
    type: RECORD_DOCUMENT_REG_LAW_ITEM_ERROR,
    ...error,
  };
};

export const getListDocumentRegLaw = (data) => {
  return {
    type: LIST_DOCUMENT_REG_LAW,
    data,
  };
};

export const getListDocumentRegLawSuccess = (data) => {
  return {
    type: LIST_DOCUMENT_REG_LAW_SUCCESS,
    data
  };
};

export const getListDocumentRegLawError = (error) => {
  return {
    type: LIST_DOCUMENT_REG_LAW_ERROR,
    ...error,
  };
};

export const getDocumentRegLaw = (id) => {
  return {
    type: GET_DOCUMENT_REG_LAW,
    data: {
      nIDDocRegDD: id,
    },
  };
};

export const getDocumentRegLawSuccess = (item) => {
  // console.log('item download File ', item)
  // DocManRegLawReqService.downloadFile(item);
  return {
    type: GET_DOCUMENT_REG_LAW_SUCCESS, // GET_DOCUMENT_CREATED_ITEM_SUCCESS
    item,
  };
};

export const getDocumentRegLawError = (error) => {
  return {
    type: GET_DOCUMENT_REG_LAW_ERROR,
    ...error,
  };
};

export const showLoading = () => {
  return {
    type: SHOW_LOADING,
  };
};
