import { SIDE_NAV_LIGHT, NAV_TYPE_SIDE } from 'configs/constants/ThemeConstant';

import Utils from 'configs/UtilFunctions';

export const APP_NAME = 'MDS';
export const API_BASE_URL = Utils.getEnv('REACT_APP_API_BASE_URL');
export const API_PROXY_URL = Utils.getEnv('REACT_APP_API_PROXY_URL');
export const SOCKET_ENDPOINT = Utils.getEnv('REACT_APP_SOCKET_ENDPOINT');
export const SOCKET_ENDPOINT_HTTP = Utils.getEnv('REACT_APP_SOCKET_ENDPOINT_HTTP');
export const SOCKET_ENDPOINT_LOCAL = Utils.getEnv('REACT_APP_SOCKET_ENDPOINT_LOCAL');
export const APP_PREFIX_PATH = '/app';
export const AUTH_PREFIX_PATH = '/auth';

export const API_SCLE_READ = 1;
export const API_SCLE_ADD = 2;
export const API_SCLE_EDIT = 3;
export const API_SCLE_DELETE = 4;
export const API_SCLE_DOWNLOAD = 5;
export const API_SCLE_READ_6 = 6;
export const API_SCLE_READ_LIST_11 = '11-1';
export const API_SCLE_READ_LIST_99 = 99;

export const API_DOCU_CONTR = 'CTR';
export const API_DOCU_TRAIT = 'TRT';
export const API_DOCU_PREST = 'PRS';
export const API_DOCU_PIA = 'PIA';
export const API_DOCU_INCI = 'INC';
export const API_DOCU_ALL = 'TT';

export const LOADING_LIST = 'loading_list';
export const LOADING_ITEM = 'loading_item';

export const THEME_CONFIG = {
  navCollapsed: false,
  sideNavTheme: SIDE_NAV_LIGHT,
  locale: 'fr',
  navType: NAV_TYPE_SIDE,
  topNavColor: '#3e82f7',
  headerNavColor: '#ffffff',
  mobileNav: false,
};

export const LANGUAGE_CONFIG = [
  {
    langName: 'English',
    langId: 'en',
    icon: 'us',
    lang: 'En',
  },
  {
    langName: 'Espagnol',
    langId: 'es',
    icon: 'es',
    lang: 'Es',
  },
  {
    langName: 'French',
    langId: 'fr',
    icon: 'fr',
    lang: 'Fr',
  },
];
