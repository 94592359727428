import React from 'react';
import { Spin } from 'antd';
import PropTypes from 'prop-types';
import { LoadingOutlined } from '@ant-design/icons';

const Icon = <LoadingOutlined style={{ fontSize: 35 }} spin />;

const Spinner = (props) => {
  const { align, cover, verticalAlign } = props;
  return (
    <div className={`loading text-${align} cover-${cover} vertical-${verticalAlign}`}>
      <Spin indicator={Icon} />
    </div>
  );
};

Spinner.propTypes = {
  align: PropTypes.string,
  cover: PropTypes.string,
};

Spinner.defaultProps = {
  align: 'center',
  cover: 'inline',
};

export default Spinner;
