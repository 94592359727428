export const GET_ORGANISATION = 'GET_ORGANISATION';
export const GET_ORGANISATION_SUCCESS = 'GET_ORGANISATION_SUCCESS';
export const GET_ORGANISATION_ERROR = 'GET_ORGANISATION_ERROR';
export const UPDATE_ORGANISATION = 'UPDATE_ORGANISATION';
export const UPDATE_ORGANISATION_SUCCESS = 'UPDATE_ORGANISATION_SUCCESS';
export const UPDATE_ORGANISATION_ERROR = 'UPDATE_ORGANISATION_ERROR';

export const ADD_ORGANISATION_ITEM = 'ADD_ORGANISATION_ITEM';
export const ADD_ORGANISATION_ITEM_SUCCESS = 'ADD_ORGANISATION_ITEM_SUCCESS';
export const ADD_ORGANISATION_ITEM_ERROR = 'ADD_ORGANISATION_ITEM_ERROR';
export const GET_ORGANISATION_LIST = 'GET_ORGANISATION_LIST';
export const GET_ORGANISATION_LIST_SUCCESS = 'GET_ORGANISATION_LIST_SUCCESS';
export const GET_ORGANISATION_LIST_ERROR = 'GET_ORGANISATION_LIST_ERROR';
export const GET_ORGANISATION_ITEM = 'GET_ORGANISATION_ITEM';
export const GET_ORGANISATION_ITEM_SUCCESS = 'GET_ORGANISATION_ITEM_SUCCESS';
export const GET_ORGANISATION_ITEM_ERROR = 'GET_ORGANISATION_ITEM_ERROR';
export const UPDATE_ORGANISATION_ITEM = 'UPDATE_ORGANISATION_ITEM';
export const UPDATE_ORGANISATION_ITEM_SUCCESS = 'UPDATE_ORGANISATION_ITEM_SUCCESS';
export const UPDATE_ORGANISATION_ITEM_ERROR = 'UPDATE_ORGANISATION_ITEM_ERROR';

export const GET_ORGANISATION_TYPE_LIST = 'GET_ORGANISATION_TYPE_LIST';
export const GET_ORGANISATION_TYPE_LIST_SUCCESS = 'GET_ORGANISATION_TYPE_LIST_SUCCESS';
export const GET_ORGANISATION_TYPE_LIST_ERROR = 'GET_ORGANISATION_TYPE_LIST_ERROR';

export const GET_ORGANISATION_INFOS = 'GET_ORGANISATION_INFOS';
export const GET_ORGANISATION_INFOS_SUCCESS = 'GET_ORGANISATION_INFOS_SUCCESS';
export const GET_ORGANISATION_INFOS_ERROR = 'GET_ORGANISATION_INFOS_ERROR';

export const SHOW_LOADING = 'SHOW_ORGANISATION_LOADING';
