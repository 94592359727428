export const GET_EL_TABLE_ITEM = 'GET_EL_TABLE_ITEM';
export const GET_EL_TABLE_ITEM_SUCCESS = 'GET_EL_TABLE_ITEM_SUCCESS';
export const GET_EL_TABLE_ITEM_ERROR = 'GET_EL_TABLE_ITEM_ERROR';

export const GET_EMPTY_STRUCT = 'GET_EMPTY_STRUCT';
export const GET_EMPTY_STRUCT_SUCCESS = 'GET_EMPTY_STRUCT_SUCCESS';
export const GET_EMPTY_STRUCT_ERROR = 'GET_EMPTY_STRUCT_ERROR';

export const GET_LIST_TABLES = 'GET_LIST_TABLES';
export const GET_LIST_TABLES_SUCCESS = 'GET_LIST_TABLES_SUCCESS';
export const GET_LIST_TABLES_ERROR = 'GET_LIST_TABLES_ERROR';

export const GET_LIST_EL_TABLE = 'GET_LIST_EL_TABLE';
export const GET_LIST_EL_TABLE_SUCCESS = 'GET_LIST_EL_TABLE_SUCCESS';
export const GET_LIST_EL_TABLE_ERROR = 'GET_LIST_EL_TABLE_ERROR';

export const SAVE_NEW_REF_TABLE = 'SAVE_NEW_REF_TABLE';
export const SAVE_NEW_REF_TABLE_SUCCESS = 'SAVE_NEW_REF_TABLE_SUCCESS';
export const SAVE_NEW_REF_TABLE_ERROR = 'SAVE_NEW_REF_TABLE_ERROR';

export const UPDATE_REF_TABLE = 'UPDATE_REF_TABLE';
export const UPDATE_REF_TABLE_SUCCESS = 'UPDATE_REF_TABLE_SUCCESS';
export const UPDATE_REF_TABLE_ERROR = 'UPDATE_REF_TABLE_ERROR';

export const UPDATE_TOTAL_REF_TABLE = 'UPDATE_TOTAL_REF_TABLE';
export const UPDATE_TOTAL_REF_TABLE_SUCCESS = 'UPDATE_TOTAL_REF_TABLE_SUCCESS';
export const UPDATE_TOTAL_REF_TABLE_ERROR = 'UPDATE_TOTAL_REF_TABLE_ERROR';

export const DELETE_REF_TABLE = 'DELETE_REF_TABLE';
export const DELETE_REF_TABLE_SUCCESS = 'DELETE_REF_TABLE_SUCCESS';
export const DELETE_REF_TABLE_ERROR = 'DELETE_REF_TABLE_ERROR';

export const SET_EL_TABLE = 'SET_EL_TABLE';

export const SHOW_LOADING = 'SHOW_INCIDENT_LOADING';
