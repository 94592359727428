export const GET_FUNC_PROC_ITEM = 'GET_FUNC_PROC_ITEM';
export const GET_FUNC_PROC_ITEM_SUCCESS = 'GET_FUNC_PROC_ITEM_SUCCESS';
export const GET_FUNC_PROC_ITEM_ERROR = 'GET_FUNC_PROC_ITEM_ERROR';

export const ADD_FUNC_PROC_ITEM = 'ADD_FUNC_PROC_ITEM';
export const ADD_FUNC_PROC_ITEM_SUCCESS = 'ADD_FUNC_PROC_ITEM_SUCCESS';
export const ADD_FUNC_PROC_ITEM_ERROR = 'ADD_FUNC_PROC_ITEM_ERROR';

export const UPDATE_FUNC_PROC_ITEM = 'UPDATE_FUNC_PROC_ITEM';
export const UPDATE_FUNC_PROC_ITEM_SUCCESS = 'UPDATE_FUNC_PROC_ITEM_SUCCESS';
export const UPDATE_FUNC_PROC_ITEM_ERROR = 'UPDATE_FUNC_PROC_ITEM_ERROR';

export const GET_FUNC_PROC_STEP_ITEM = 'GET_FUNC_PROC_STEP_ITEM';
export const GET_FUNC_PROC_STEP_ITEM_SUCCESS = 'GET_FUNC_PROC_STEP_ITEM_SUCCESS';
export const GET_FUNC_PROC_STEP_ITEM_ERROR = 'GET_FUNC_PROC_STEP_ITEM_ERROR';

export const ADD_FUNC_PROC_STEP_ITEM = 'ADD_FUNC_PROC_STEP_ITEM';
export const ADD_FUNC_PROC_STEP_ITEM_SUCCESS = 'ADD_FUNC_PROC_STEP_ITEM_SUCCESS';
export const ADD_FUNC_PROC_STEP_ITEM_ERROR = 'ADD_FUNC_PROC_STEP_ITEM_ERROR';

export const UPDATE_FUNC_PROC_STEP_ITEM = 'UPDATE_FUNC_PROC_STEP_ITEM';
export const UPDATE_FUNC_PROC_STEP_ITEM_SUCCESS = 'UPDATE_FUNC_PROC_STEP_ITEM_SUCCESS';
export const UPDATE_FUNC_PROC_STEP_ITEM_ERROR = 'UPDATE_FUNC_PROC_STEP_ITEM_ERROR';

export const DELETE_FUNC_PROC_STEP_ITEM = 'DELETE_FUNC_PROC_STEP_ITEM';
export const DELETE_FUNC_PROC_STEP_ITEM_SUCCESS = 'DELETE_FUNC_PROC_STEP_ITEM_SUCCESS';
export const DELETE_FUNC_PROC_STEP_ITEM_ERROR = 'DELETE_FUNC_PROC_STEP_ITEM_ERROR';

export const GET_FUNC_PROC_LIST = 'GET_FUNC_PROC_LIST';
export const GET_FUNC_PROC_LIST_SUCCESS = 'GET_FUNC_PROC_LIST_SUCCESS';
export const GET_FUNC_PROC_LIST_ERROR = 'GET_FUNC_PROC_LIST_ERROR';

export const GET_FUNC_PROC_WITH_STEP_ITEM = 'GET_FUNC_PROC_WITH_STEP_ITEM';
export const GET_FUNC_PROC_WITH_STEP_ITEM_SUCCESS = 'GET_FUNC_PROC_WITH_STEP_ITEM_SUCCESS';
export const GET_FUNC_PROC_WITH_STEP_ITEM_ERROR = 'GET_FUNC_PROC_WITH_STEP_ITEM_ERROR';

export const GET_BLANK_FUNC_PROC = 'GET_BLANK_FUNC_PROC'
export const GET_BLANK_FUNC_PROC_SUCCESS = 'GET_BLANK_FUNC_PROC_SUCCESS'
export const GET_BLANK_FUNC_PROC_ERROR = 'GET_BLANK_FUNC_PROC_ERROR'

export const ADD_NEW_VERSION_FUNC_PROC_ITEM = 'ADD_NEW_VERSION_FUNC_PROC_ITEM'
export const ADD_NEW_VERSION_FUNC_PROC_ITEM_SUCCESS = 'ADD_NEW_VERSION_FUNC_PROC_ITEM_SUCCESS'
export const ADD_NEW_VERSION_FUNC_PROC_ITEM_ERROR = 'ADD_NEW_VERSION_FUNC_PROC_ITEM_ERROR'

export const SHOW_LOADING = 'SHOW_FUNC_PROC_LOADING';
