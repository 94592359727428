import { all, takeEvery, put, fork, call } from 'redux-saga/effects';
import UtilsService from 'services/UtilsService';
import { GET_COUNTRY_LIST, GET_FUNC_PROF_LIST } from '../constants/Utils';


import {
    getCountryListError,
    getCountryListSuccess,
    getProfessionnalFunctionListError,
    getProfessionnalFunctionListSuccess,
} from '../actions/Utils';



export function* getCountryList() {
    yield takeEvery(GET_COUNTRY_LIST, function* ({ data }) {
        try {
            const response = yield call(UtilsService.getCountryList, data);
            yield put(getCountryListSuccess(response.Data));
        } catch (err) {
            console.log("error getCountryList", err)
            yield put(getCountryListError, (err));
        }
    });
}
export function* getProfessionnalFunctionList() {
    yield takeEvery(GET_FUNC_PROF_LIST, function* ({ data }) {
        try {
            const response = yield call(UtilsService.getProfessionnalFunctionList, data);
            yield put(getProfessionnalFunctionListSuccess(response.Data));
        } catch (err) {
            console.log("error getProfessionnalFunctionList", err)
            yield put(getProfessionnalFunctionListError, (err));
        }
    });
}

export default function* rootSaga() {
    yield all([
        fork(getCountryList),
        fork(getProfessionnalFunctionList),
    ]);
}
