// send our organisation model to MDS API organisation
export const mdsActorToActor = (mdsActors) => {
  // TODO Convert all the keys to our model
  return {
    key: mdsActors.nIDInter,
    idInter: mdsActors.nIDInter,
    idPresta: mdsActors.nIDPresta,
    nom: mdsActors.sNom,
    prenom: mdsActors.sPrenom,
    tel: mdsActors.sTel,
    mail: mdsActors.sMail,
    raiSocPresta: mdsActors.sRaiSocPresta,
    isEditable: mdsActors.bEstModifiable,
    adresse: mdsActors.sAdresse,
    ville: mdsActors.sVille,
    cP: mdsActors.sCP,
    comm: mdsActors.sComm,
    codeFonction: mdsActors.sCodeFonction,
    tabCatDom: mdsActors.tabCatDom,
    estInactif: mdsActors.bEstInactif,
    labelStatut: mdsActors.bEstInactif ? 'Inactif' : 'Actif',
  };
};

export const mdsActorToActorList = (mdsActorsList) => {
  const dataArray = [];
  mdsActorsList.tabLstAct_OK.forEach((element) => {
    dataArray.push(mdsActorToActor(element));
  });

  return dataArray;
};

export const actorToMdsActor = (mdsActors) => {
  // TODO Convert all the keys to mds model
  return {
    nIDInter: mdsActors.idInter,
    nIDPresta: mdsActors.idPresta,
    sNom: mdsActors.nom,
    sPrenom: mdsActors.prenom,
    sTel: mdsActors.tel,
    sMail: mdsActors.mail,
    codeFonction: mdsActors.sCodeFonction,
  };
};

export const actorToMdsActorCreate = (mdsActors) => {
  // TODO Convert all the keys to mds model
  return {
    nIDInter: mdsActors.idInter,
    nIDPresta: mdsActors.idPresta,
    sNom: mdsActors.nom,
    sPrenom: mdsActors.prenom,
    sTel: mdsActors.tel,
    sMail: mdsActors.mail,
    sAdresse: mdsActors.adresse,
    sVille: mdsActors.ville,
    sCP: mdsActors.cP,
    sComm: mdsActors.comm,
    sCodeFonction: mdsActors.codeFonction,
    tabCatDom: mdsActors.tabCatDom,
  };
};

export const convertDeleteActorsBookToMdsActorsBook = (mdsActors) => {
  return {
    nIDInter: mdsActors.idInter,
  };
};
