import {
  GET_FAQ_LIST,
  GET_FAQ_LIST_SUCCESS,
  GET_FAQ_LIST_ERROR,
  SHOW_LOADING,
} from '../constants/Faq';

const initState = {
  isLoading: false,
  message: {
    type: null,
    content: '',
  },
  data: [],
};

const auth = (state = initState, action) => {
  switch (action.type) {
    case SHOW_LOADING: {
      return {
        ...state,
        isLoading: true,
      };
    }
    case GET_FAQ_LIST: {
      return {
        ...state,
        isLoading: true,
      };
    }
    case GET_FAQ_LIST_SUCCESS: {
      return {
        ...state,
        data: action.data,
        isLoading: false,
      };
    }
    case GET_FAQ_LIST_ERROR: {
      return {
        ...state,
        isLoading: false
      };
    }
    default:
      return state;
  }
};

export default auth;
