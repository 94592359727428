import {
  ADD_INCIDENT_ITEM,
  ADD_INCIDENT_ITEM_SUCCESS,
  ADD_INCIDENT_ITEM_ERROR,
  GET_INCIDENT_ITEM,
  GET_INCIDENT_ITEM_SUCCESS,
  GET_INCIDENT_ITEM_ERROR,
  SHOW_LOADING,
  GET_INCIDENT_LIST,
  GET_INCIDENT_LIST_SUCCESS,
  GET_INCIDENT_LIST_ERROR,
  UPDATE_INCIDENT_ITEM_ERROR,
  UPDATE_INCIDENT_ITEM_SUCCESS,
  UPDATE_INCIDENT_ITEM,
} from '../constants/Incident';

// import * as converter from '../converter/Incident';

export const addIncidentItem = (item) => {
  return {
    type: ADD_INCIDENT_ITEM,
    data: item,
  };
};

export const addIncidentItemSuccess = (data) => {
  return {
    type: ADD_INCIDENT_ITEM_SUCCESS,
    message: "L'incident a bien été ajouté",
    data,
  };
};

export const addIncidentItemError = (error) => {
  return {
    type: ADD_INCIDENT_ITEM_ERROR,
    ...error,
  };
};

export const updateIncidentItem = (item) => {
  return {
    type: UPDATE_INCIDENT_ITEM,
    data: item,
  };
};

export const updateIncidentItemSuccess = (data) => {
  return {
    type: UPDATE_INCIDENT_ITEM_SUCCESS,
    message: "L'incident a bien été mise a jour",
    data,
  };
};

export const updateIncidentItemError = (error) => {
  return {
    type: UPDATE_INCIDENT_ITEM_ERROR,
    ...error,
  };
};

export const getIncidentList = () => {
  return {
    type: GET_INCIDENT_LIST,
    data: {
      sC: 0,
      nN: 0,
    },
  };
};

export const getIncidentListSuccess = (data) => {
  return {
    type: GET_INCIDENT_LIST_SUCCESS,
    data,
  };
};

export const getIncidentListError = (error) => {
  return {
    type: GET_INCIDENT_LIST_ERROR,
    ...error,
  };
};

export const getIncidentItem = (id) => {
  return {
    type: GET_INCIDENT_ITEM,
    data: { DataIncident: { nIDIncident: id } },
  };
};

export const getIncidentItemSuccess = (item) => {
  return {
    type: GET_INCIDENT_ITEM_SUCCESS,
    item,
  };
};

export const getIncidentItemError = (error) => {
  return {
    type: GET_INCIDENT_ITEM_ERROR,
    ...error,
  };
};

export const showLoading = () => {
  return {
    type: SHOW_LOADING,
  };
};
export const getNewIncidentItem = (idTraitement) => {
  return {
    type: GET_INCIDENT_ITEM,
    data: { DataIncident: { nIDTraitement: idTraitement } },
  };
};
