import React from 'react';
import { APP_NAME } from 'configs/AppConfig';

const CGU = `${process.env.PUBLIC_URL}/MDS Logiciel_Conformité RGPD_Condititions d'utilisation.pdf`;
const POC = `${process.env.PUBLIC_URL}/MDS Logiciel_Conformité RGPD_CONFIDENTIALITE.pdf`;
export default function Footer() {
  return (
    <footer className="footer">
      <span>
        {' '}
        Copyright &copy; &nbsp;
        {new Date().getFullYear()}
        {' '}
&nbsp;
        <span className="font-weight-semibold">{APP_NAME}</span>
        {' '}
&nbsp;
        All rights reserved. | Powered by BlockNes.tech 
      </span>
      <div>
        <a className="text-gray" href={CGU} target="_blank" rel="noopener noreferrer">
          Conditions générales d'utilisation
        </a>
        <span className="mx-2 text-muted"> | </span>
        <a className="text-gray" href={POC} target="_blank" rel="noopener noreferrer">
          Politique de confidentialité
        </a>
      </div>
    </footer>
  );
}
