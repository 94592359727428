import { all, takeEvery, put, fork, call } from 'redux-saga/effects';
import ProviderService from 'services/ProviderService';

import {
  GET_PROVIDER_LIST,
  GET_PROVIDER_ITEM,
  UPDATE_PROVIDER_ITEM,
  ADD_PROVIDER,
  DELETE_PROVIDER,
} from '../constants/Provider';
import {
  getProviderListSuccess,
  getProviderListError,
  getProviderItemSuccess,
  getProviderItemError,
  updateProviderSuccess,
  updateProviderError,
  addProviderSuccess,
  addProviderError,
  deleteProviderSuccess,
  deleteProviderError,
} from '../actions/Provider';

export function* getProviderList() {
  yield takeEvery(GET_PROVIDER_LIST, function* ({ data }) {
    try {
      const response = yield call(ProviderService.getProviderList, data);
      yield put(getProviderListSuccess(response.Data));
    } catch (err) {
      yield put(getProviderListError(err));
    }
  });
}

export function* getProviderItem() {
  yield takeEvery(GET_PROVIDER_ITEM, function* ({ data }) {
    try {
      const response = yield call(ProviderService.getProviderItem, data);
      yield put(getProviderItemSuccess(response.Data));
    } catch (err) {
      // console.log(err);
      yield put(getProviderItemError(err));
    }
  });
}

export function* updateProvider() {
  yield takeEvery(UPDATE_PROVIDER_ITEM, function* ({ data }) {
    try {
      const response = yield call(ProviderService.updateProvider, data);
      // console.log(response)
      yield put(updateProviderSuccess(response.Data));
    } catch (err) {
      // console.log(err);
      yield put(updateProviderError(err));
    }
  });
}

export function* addProvider() {
  yield takeEvery(ADD_PROVIDER, function* ({ data }) {
    try {
      const response = yield call(ProviderService.addProvider, data);
      yield put(addProviderSuccess(response.Data));
    } catch (err) {
      // console.log(err);
      yield put(addProviderError(err));
    }
  });
}

export function* deleteProvider() {
  yield takeEvery(DELETE_PROVIDER, function* ({ data }) {
    try {
      const response = yield call(ProviderService.deleteProvider, data);
      yield put(deleteProviderSuccess(response.Data));
    } catch (err) {
      console.log(err);
      yield put(deleteProviderError(err));
    }
  });
}

export default function* rootSaga() {
  yield all([
    fork(getProviderList),
    fork(getProviderItem),
    fork(updateProvider),
    fork(addProvider),
    fork(deleteProvider),
  ]);
}
