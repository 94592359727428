import {
  ADD_DOCUMENT_ITEM,
  ADD_DOCUMENT_ITEM_SUCCESS,
  ADD_DOCUMENT_ITEM_ERROR,
  GET_DOCUMENT_LIST,
  GET_DOCUMENT_LIST_SUCCESS,
  GET_DOCUMENT_LIST_ERROR,
  GET_DOCUMENT_ITEM,
  GET_DOCUMENT_ITEM_SUCCESS,
  GET_DOCUMENT_ITEM_ERROR,
  DDL_DOCUMENT_ITEM,
  DDL_DOCUMENT_ITEM_SUCCESS,
  UPDATE_DOCUMENT_ITEM,
  UPDATE_DOCUMENT_ITEM_SUCCESS,
  UPDATE_DOCUMENT_ITEM_ERROR,
  SHOW_LOADING,
} from '../constants/Document';

import { API_DOCU_ALL } from '../../configs/AppConfig'

import * as converter from '../converter/Document';

export const addDocumentItem = (item) => {
  return {
    type: ADD_DOCUMENT_ITEM,
    data: converter.documentItemToMdsDocumentItem(item)
  };
};

export const addDocumentItemSuccess = (item) => {
  return {
    type: ADD_DOCUMENT_ITEM_SUCCESS,
    item: converter.mdsDocumentItemToDocumentItem(item),
    message: 'Le document a bien été ajouté',
  };
};

export const addDocumentItemError = (error) => {
  return {
    type: ADD_DOCUMENT_ITEM_ERROR,
    ...error
  };
};

export const getDocumentList = (type = API_DOCU_ALL, id = 0) => {
  return {
    type: GET_DOCUMENT_LIST,
    data: { sTypeDemande: type, nIDDemande: id }
  };
};

export const getDocumentListSuccess = (data) => {
  return {
    type: GET_DOCUMENT_LIST_SUCCESS,
    data: converter.mdsDocumentListToDocumentList(data.tabListeDoc),
  };
};

export const getDocumentListError = (error) => {
  return {
    type: GET_DOCUMENT_LIST_ERROR,
    ...error
  };
};

export const ddlDocumentItem = (id) => {
  return {
    type: DDL_DOCUMENT_ITEM,
    data: { nIDRapport: id },
  };
};

export const ddlDocumentItemSuccess = (file) => {
  // console.log(file);
  return {
    type: DDL_DOCUMENT_ITEM_SUCCESS,
    file,
  };
};

export const ddlDocumentItemError = (error) => {
  return {
    type: GET_DOCUMENT_ITEM_ERROR,
    ...error
  };
};

export const getDocumentInfo = (id) => {
  return {
    type: GET_DOCUMENT_ITEM,
    data: { nIDRapport: id },
  };
};

export const getDocumentInfoSuccess = (item) => {
  return {
    type: GET_DOCUMENT_ITEM_SUCCESS,
    item: converter.mdsDocumentItemToDocumentItem(item),
  };
};

export const getDocumentInfoError = (error) => {
  return {
    type: GET_DOCUMENT_ITEM_ERROR,
    ...error
  };
};

export const updateDocumentItem = (item) => {
  return {
    type: UPDATE_DOCUMENT_ITEM,
    data: {
      nBTrait: 1,
      ...converter.documentItemToMdsDocumentItem(item)
    },
  };
};

export const updateDocumentItemSuccess = (item) => {
  return {
    type: UPDATE_DOCUMENT_ITEM_SUCCESS,
    message: 'Le document a bien été mise a jour',
    item: converter.mdsDocumentItemToDocumentItem(item),
  };
};

export const updateDocumentItemError = (error) => {
  return {
    type: UPDATE_DOCUMENT_ITEM_ERROR,
    ...error
  };
};

export const showLoading = () => {
  return {
    type: SHOW_LOADING,
  };
};
