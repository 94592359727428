import { LOADING_ITEM, LOADING_LIST } from 'configs/AppConfig';
import {
  ADD_DOCUMENT_ITEM,
  ADD_DOCUMENT_ITEM_SUCCESS,
  ADD_DOCUMENT_ITEM_ERROR,
  GET_DOCUMENT_LIST,
  GET_DOCUMENT_LIST_SUCCESS,
  GET_DOCUMENT_LIST_ERROR,
  GET_DOCUMENT_ITEM,
  DDL_DOCUMENT_ITEM_SUCCESS,
  DDL_DOCUMENT_ITEM_ERROR,
  DDL_DOCUMENT_ITEM,
  GET_DOCUMENT_ITEM_SUCCESS,
  GET_DOCUMENT_ITEM_ERROR,
  UPDATE_DOCUMENT_ITEM,
  UPDATE_DOCUMENT_ITEM_SUCCESS,
  UPDATE_DOCUMENT_ITEM_ERROR,
  SHOW_LOADING,
} from '../constants/Document';


const initState = {
  isLoading: false,
  data: [],
  item: {},
  needRefreshData: true,
  file: null,
  typeRapport: [{
    key: 1,
    value: 'Facture',
  }, {
    key: 2,
    value: 'Document commercial',
  }, {
    key: 3,
    value: 'Document juridique',
  }, {
    key: 4,
    value: 'Document social (bul. paie, etc)',
  }, {
    key: 5,
    value: 'Rapport',
  }, {
    key: 6,
    value: 'Pièce justificative',
  }, {
    key: 7,
    value: 'Autre',
  }]
};

const auth = (state = initState, action) => {
  switch (action.type) {
    case SHOW_LOADING: {
      return {
        ...state,
        isLoading: true,
      };
    }
    case GET_DOCUMENT_LIST: {
      return {
        ...state,
        data: [],
        isLoading: LOADING_LIST,
        needRefreshData: false,
      };
    }
    case ADD_DOCUMENT_ITEM: {
      return { ...state, isLoading: true }
    }
    case GET_DOCUMENT_ITEM:
    case DDL_DOCUMENT_ITEM:
    case UPDATE_DOCUMENT_ITEM: {
      return {
        ...state,
        isLoading: LOADING_ITEM,
      };
    }

    case ADD_DOCUMENT_ITEM_ERROR:
    case GET_DOCUMENT_LIST_ERROR:
    case GET_DOCUMENT_ITEM_ERROR:
    case DDL_DOCUMENT_ITEM_ERROR:
    case UPDATE_DOCUMENT_ITEM_ERROR: {
      return {
        ...state,
        isLoading: false,
      };
    }

    case ADD_DOCUMENT_ITEM_SUCCESS: {
      return {
        ...state,
        item: action.item,
        needRefreshData: true,
        isLoading: false,
      };
    }

    case DDL_DOCUMENT_ITEM_SUCCESS: {
      // console.log("reducer", action)
      return {
        ...state,
        // file: window.URL.createObjectURL(action.file),
        file: action.file,
        isLoading: false,
      };
    }
    case GET_DOCUMENT_LIST_SUCCESS: {
      return {
        ...state,
        data: action.data,
        needRefreshData: false,
        isLoading: false,
      };
    }
    case GET_DOCUMENT_ITEM_SUCCESS: {
      return {
        ...state,
        item: action.item,
        isLoading: false,
      };
    }
    case UPDATE_DOCUMENT_ITEM_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        item: action.item,
        needRefreshData: true,
      };
    }
    default:
      return state;
  }
};

export default auth;
