export const GET_ACTIONS_LIST = 'GET_ACTIONS_LIST';
export const GET_ACTIONS_LIST_SUCCESS = 'GET_ACTIONS_LIST_SUCCESS';
export const GET_ACTIONS_LIST_ERROR = 'GET_ACTIONS_LIST_ERROR';
export const GET_ACTIONS_LIST_SCENARIOS = 'GET_ACTIONS_LIST_SCENARIOS';
export const GET_ACTIONS_LIST_SCENARIOS_SUCCESS = 'GET_ACTIONS_LIST_SCENARIOS_SUCCESS';
export const GET_ACTIONS_LIST_SCENARIOS_ERROR = 'GET_ACTIONS_LIST_SCENARIOS_ERROR';
export const UPDATE_ACTION_ITEM = 'UPDATE_ACTION_ITEM';
export const UPDATE_ACTION_ITEM_SUCCESS = 'UPDATE_ACTION_ITEM_SUCCESS';
export const UPDATE_ACTION_ITEM_ERROR = 'UPDATE_ACTION_ITEM_ERROR';
export const SHOW_SUCCESS_MESSAGE = 'SHOW_SUCCESS_MESSAGE';
export const GET_ACTIONS_ITEM = 'GET_ACTIONS_ITEM';
export const GET_ACTIONS_ITEM_SUCCESS = 'GET_ACTIONS_ITEM_SUCCESS';
export const GET_ACTIONS_ITEM_ERROR = 'GET_ACTIONS_ITEM_ERROR';

export const SHOW_LOADING = 'SHOW_ACTIONS_LOADING';