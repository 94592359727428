import { all, takeEvery, put, fork, call } from 'redux-saga/effects';

import IncidentService from 'services/IncidentService';

import {
  addIncidentItemError,
  addIncidentItemSuccess,
  getIncidentItemError,
  getIncidentItemSuccess,
  getIncidentListError,
  getIncidentListSuccess,
  updateIncidentItemError,
  updateIncidentItemSuccess,
} from '../actions/Incident';

import {
  ADD_INCIDENT_ITEM,
  GET_INCIDENT_ITEM,
  GET_INCIDENT_LIST,
  UPDATE_INCIDENT_ITEM,
} from '../constants/Incident';

export function* getIncidentList() {
  yield takeEvery(GET_INCIDENT_LIST, function* ({ data }) {
    try {
      const response = yield call(IncidentService.getIncidentList, data);
      yield put(getIncidentListSuccess(response.Data));
    } catch (err) {
      yield put(getIncidentListError(err));
    }
  });
}

export function* addIncidentItem() {
  yield takeEvery(ADD_INCIDENT_ITEM, function* ({ data }) {
    try {
      const response = yield call(IncidentService.createIncidentItem, data);
      yield put(addIncidentItemSuccess(response.Data));
    } catch (err) {
      yield put(addIncidentItemError(err));
    }
  });
}

export function* updateIncidentItem() {
  yield takeEvery(UPDATE_INCIDENT_ITEM, function* ({ data }) {
    try {
      yield call(IncidentService.updateIncidentItem, data);
      yield put(updateIncidentItemSuccess(data));
    } catch (err) {
      yield put(updateIncidentItemError(err));
    }
  });
}

export function* getIncidentItem() {
  yield takeEvery(GET_INCIDENT_ITEM, function* ({ data }) {
    try {
      const response = yield call(IncidentService.getIncidentItem, data);
      yield put(getIncidentItemSuccess(response.Data));
    } catch (err) {
      yield put(getIncidentItemError(err));
    }
  });
}

export default function* rootSaga() {
  yield all([
    fork(addIncidentItem),
    fork(getIncidentList),
    fork(getIncidentItem),
    fork(updateIncidentItem),
  ]);
}
