import {
  GET_COUNTRY_LIST,
  GET_COUNTRY_LIST_SUCCESS,
  GET_COUNTRY_LIST_ERROR,
  GET_FUNC_PROF_LIST,
  GET_FUNC_PROF_LIST_SUCCESS,
  GET_FUNC_PROF_LIST_ERROR,
  SHOW_LOADING,
} from '../constants/Utils';

const initState = {
  isLoading: false,
  country: [],
  profFunc: [],
  redirect: '',
  data: [],
  item: {},
};

const auth = (state = initState, action) => {
  switch (action.type) {
    case GET_COUNTRY_LIST:
    case GET_FUNC_PROF_LIST:
      return {
        ...state,
        isLoading: true,
      };
    case GET_COUNTRY_LIST_SUCCESS:
      return {
        ...state,
        isLoading: false,
        country: action.data,
      };
    case GET_FUNC_PROF_LIST_SUCCESS:
      return {
        ...state,
        isLoading: false,
        profFunc: action.data,
      };
    case GET_COUNTRY_LIST_ERROR:
    case GET_FUNC_PROF_LIST_ERROR:
      return {
        ...state,
        isLoading: false,
      };

    case SHOW_LOADING: {
      return {
        ...state,
        isLoading: true,
      };
    }
    default:
      return state;
  }
};

export default auth;
