import {
  GET_PROCESS_MODEL_LIST_SUCCESS,
  GET_PROCESS_MODEL_LIST_ERROR,
  GET_PROCESS_MODEL_ITEM_SUCCESS,
  GET_PROCESS_MODEL_ITEM_ERROR,
  SHOW_LOADING,
  ADD_PROCESS_MODELE,
  ADD_PROCESS_MODELE_SUCCESS,
  ADD_PROCESS_MODELE_ERROR,
  UPDATE_PROCESS_MODELE,
  UPDATE_PROCESS_MODELE_SUCCESS,
  UPDATE_PROCESS_MODELE_ERROR,
  DISABLE_PROCESS_MODELE,
  DISABLE_PROCESS_MODELE_SUCCESS,
  DISABLE_PROCESS_MODELE_ERROR,
} from '../constants/ProcessModel';

const initState = {
  isLoading: false,
  dataShouldUpdate: false,
  dataShouldDelete: false,
  message: {
    type: null,
    content: '',
  },
  data: [],
};

const auth = (state = initState, action) => {
  switch (action.type) {
    case SHOW_LOADING: {
      return {
        ...state,
        isLoading: true,
      };
    }
    case UPDATE_PROCESS_MODELE:
    case ADD_PROCESS_MODELE: {
      return {
        ...state,
        dataShouldUpdate: false,
        isLoading: true,
      };
    }
    case DISABLE_PROCESS_MODELE: {
      return {
        ...state,
        dataShouldDelete: false,
        isLoading: true,
      };
    }
    case UPDATE_PROCESS_MODELE_SUCCESS:
    case ADD_PROCESS_MODELE_SUCCESS: {
      return {
        ...state,
        dataShouldUpdate: true,
        isLoading: false,
      };
    }
    case DISABLE_PROCESS_MODELE_SUCCESS: {
      return {
        ...state,
        dataShouldDelete: true,
        isLoading: false,
      };
    }
    case GET_PROCESS_MODEL_LIST_SUCCESS: {
      return {
        ...state,
        data: action.data,
      };
    }
    case GET_PROCESS_MODEL_ITEM_SUCCESS: {
      return {
        ...state,
        item: action.item,
      };
    }
    case GET_PROCESS_MODEL_LIST_ERROR:
    case GET_PROCESS_MODEL_ITEM_ERROR:
    case DISABLE_PROCESS_MODELE_ERROR:
    case ADD_PROCESS_MODELE_ERROR: {
      return {
        ...state,
        isLoading: false,
        dataShouldUpdate: false,
      };
    }
    case UPDATE_PROCESS_MODELE_ERROR: {
      return {
        ...state,
        isLoading: false,
        dataShouldDelete: false,
      };
    }
    default:
      return state;
  }
};

export default auth;
