
import moment from "moment";
import { DATE_FORMAT_YYYY_MM_DD } from "configs/constants/DateConstant"
// send our organisation model to MDS API organisation
export const mdsContratListToContrat = (mdsContrat) => {
    return mdsContrat[0];
}

export const mdsContratListToContratList = (mdsContratList) => {
    const dataArray = [];
    mdsContratList.forEach(element => {
        dataArray.push(mdsContratItemToContrat(element))
    });
    return dataArray;

};

export const mdsContratItemToContrat = (mdsContrat) => {
    return {
        dateDeb: moment(mdsContrat.dDDeb),
        dateFin: moment(mdsContrat.dDFin),
        dateRev: moment(mdsContrat.dDRev),
        dateInactif: moment(mdsContrat.dDateInactif),
        contratId: mdsContrat.nIDContrat,
        natContratId: mdsContrat.nIDNatContrat,
        prestId: mdsContrat.nIDPrest,
        traitementId: mdsContrat.nIDTraitement,
        traitementExcl: mdsContrat.tabExclusionTr,
        state: mdsContrat.nIDTraitement === undefined || mdsContrat.nIDTraitement === 0 ? "global" : "specific",
        commentaire: mdsContrat.sCommentaire,
        natContrat: mdsContrat.sNat_Contrat,
        prestataire: mdsContrat.sPrestataire,
        statut: mdsContrat.sStatut,
        clauseRGPDCTR: mdsContrat.bClauseRGPDCTR,
        annxRGPDCTR: mdsContrat.bAnnxRGPDCTR,
        clauseRGPDCGV: mdsContrat.bClauseRGPDCGV,
        annxSecu: mdsContrat.bAnnxSecu,
    };
}

export const contratItemToMdsContrat = (id, contrat) => {

    return {
        dDDeb: contrat.dateDeb !== undefined ? contrat.dateDeb.format(DATE_FORMAT_YYYY_MM_DD) : null,
        dDFin: (contrat.dateFin === undefined || contrat.dateFin === null) ? null : contrat.dateFin.format(DATE_FORMAT_YYYY_MM_DD),
        dDRev: (contrat.dateRev === undefined || contrat.dateRev === null) ? null : contrat.dateRev.format(DATE_FORMAT_YYYY_MM_DD),
        dDateInactif: contrat.dateInactif,
        nIDContrat: id,
        nIDNatContrat: contrat.natContratId,
        nIDPrest: contrat.prestId,
        nIDTraitement: contrat.traitementId,
        tabExclusionTr: contrat.traitementExcl,
        sCommentaire: contrat.commentaire,
        sNat_Contrat: contrat.natContrat,
        sPrestataire: contrat.prestataire,
        sStatut: contrat.statut,
        bClauseRGPDCTR: contrat.clauseRGPDCTR,
        bAnnxRGPDCTR: contrat.annxRGPDCTR,
        bClauseRGPDCGV: contrat.clauseRGPDCGV,
        bAnnxSecu: contrat.annxSecu,
    };
}


export const mdsNatureContratListToNatureContratList = (mdsNatureContratList) => {
    const dataArray = [];
    mdsNatureContratList.forEach(mdsNatureContrat => {
        dataArray.push(
            {
                natContratId: mdsNatureContrat.nIDNatContrat,
                libelle: mdsNatureContrat.sLibelle,
            }
        )
    });
    return dataArray;

};